import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faSync } from '@fortawesome/free-solid-svg-icons';
import * as Colors from '../../consts/colors';
import SettingsPinView from '../common/SettingsPinView';
import ButtonView from '../common/ButtonView';
import * as actions from '../../actions/index';
import ModalView from '../common/ModalView';
import config from '../../config.json';
import ErrorBoundary from '../common/ErrorBoundary';

class GeneralView extends Component {
  constructor(props) {
    super(props);
    try {
      this.state = {
        valueChanged: false,
        showModal: false,
        cancelled: false,
        refreshPressed: false
      };
      this.onCancelAppConfigSaveSettings = this.onCancelAppConfigSaveSettings.bind(
        this
      );
      this.onSaveAppConfigSaveSettings = this.onSaveAppConfigSaveSettings.bind(
        this
      );
      this.onAppPinEntryChanged = this.onAppPinEntryChanged.bind(this);
    } catch (error) {
      this.setState(() => {
        throw error;
      });
    }
  }

  componentDidMount() {
    try {
      const isChecked = !!this.props.appPinEntryChecked;
      this.props.changeTmpAppEntryChecked(isChecked);
    } catch (error) {
      this.props.logError(
        error.message
          ? error.message
          : 'Error Occured in GeneralView-> ComponentDidMount'
      );
    }
  }

  componentWillUnmount() {
    this.props.resetConfigurationChanged();
  }

  onSaveAppConfigSaveSettings() {
    try {
      if (this.props.tmpAppPinEntryChecked === true) {
        if (this.props.tmpSettingsPin.includes('#')) {
          alert('Enter 4 digit PIN!');
          return;
        }
      }

      this.props.saveConfigSettings({
        appPinEntryChecked: this.props.tmpAppPinEntryChecked,
        settingsPin: this.props.tmpAppPinEntryChecked
          ? this.props.tmpSettingsPin
          : '####'
      });
      alert('CA4K App configuration have been saved.');
      this.props.addAndShowMessage('App Config have been updated.');

      console.log(this.props.settingsPin);
    } catch (error) {
      this.setState(() => {
        throw error;
      });
    }
  }

  onCancelAppConfigSaveSettings() {
    this.props.cancelSaveConfigSettings();
  }

  onAppPinEntryChanged(event) {
    try {
      const isChecked = event.target.checked;
      this.props.changeTmpAppEntryChecked(isChecked);
      this.props.configurationChanged();
    } catch (error) {
      this.setState(() => {
        throw error;
      });
    }
  }

  onCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    //console.log('Redux PIN:', this.props.settingsPin);
    //console.log('State PIN:', this.state.pin);

    return (
      <ErrorBoundary>
        <div
          style={styles.container}
          onClick={() => {
            this.props.resetInactivityTimer();
          }}
        >
          <div style={styles.buttonContainer}>
            <div
              onClick={() => {
                try {
                  this.props.refreshAppConfigSettings();

                  if (
                    this.props.tmpSelectedBadgeOfUser &&
                    this.props.tmpSelectedBadgeOfUser.Badge
                  ) {
                    //console.log(
                    //  'Temp ViewSelection:Badge, Facility',
                    //  this.props.tmpSelectedBadgeOfUser.Badge,
                    //  this.props.tmpSelectedBadgeOfUser.Facility
                    //);
                    this.props.fetchAccessReaderListByBadge(
                      this.props.tmpSelectedBadgeOfUser.Badge,
                      this.props.tmpSelectedBadgeOfUser.Facility,
                      true,
                      this.props.requestWirelessLockStatus
                        ? this.props.requestWirelessLockStatus
                        : false
                    );
                  }
                  this.setState({ ...this.state, refreshPressed: true });
                  setTimeout(() => {
                    this.setState({ ...this.state, refreshPressed: false }); // after 1 seconds state updates for animation
                  }, 0.2 * 1000);
                } catch (error) {
                  this.setState(() => {
                    throw error;
                  });
                }
              }}
              style={styles.refresh}
            >
              {this.state.refreshPressed === false && (
                <FontAwesomeIcon
                  icon={faSync}
                  size="1x"
                  color={Colors.Purple}
                />
              )}
              {this.state.refreshPressed === true && (
                <FontAwesomeIcon
                  icon={faSync}
                  size="1x"
                  color={Colors.Purple}
                  rotation={90}
                />
              )}
            </div>
            <ButtonView
              label="Save"
              enabled={this.props.configChanged}
              instyle={styles.save}
              onPress={event => {
                event.preventDefault();
                this.onSaveAppConfigSaveSettings();
              }}
            />
            <div style={{ marginLeft: 20 }}>
              <ButtonView
                label="Cancel"
                instyle={styles.cancel}
                enabled={this.props.configChanged}
                onPress={() => {
                  try {
                    if (this.props.configChanged === true) {
                      this.setState({ ...this.state, showModal: true });
                    } else this.setState({ ...this.state, cancelled: true });
                  } catch (error) {
                    this.setState(() => {
                      throw error;
                    });
                  }
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={styles.appVersionRow}>
              <FontAwesomeIcon icon={faCircle} size="xs" color={Colors.Black} />
              <p style={styles.baseURLLabel}>App Version</p>
              <p style={styles.baseURL}>{config.versionName}</p>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={styles.baseURLRow}>
              <FontAwesomeIcon icon={faCircle} size="xs" color={Colors.Black} />
              <p style={styles.baseURLLabel}>Base URL </p>
            </div>
            <p style={styles.baseURL}>{this.props.appBaseURL}</p>
          </div>

          <div style={styles.logoutRow}>
            <ButtonView
              instyle={styles.logout}
              label="Log Out"
              onPress={() => {
                try {
                  this.props.logOut();
                } catch (error) {
                  this.setState(() => {
                    throw error;
                  });
                }
              }}
            />
          </div>
          <div style={styles.titleRow}>
            <FontAwesomeIcon icon={faCircle} size="xs" color={Colors.Black} />
            <p style={styles.general}>General</p>
          </div>

          <div style={styles.appPINEntryRow}>
            <p> - Login Session Timeout</p>
            <div>
              <select
                value={this.props.tmpSessionTimeOut}
                style={styles.selectBox}
                onChange={event => {
                  this.props.changeSessionTimeOut(event.target.value);
                }}
              >
                <option value="5">5 mins</option>
                <option value="10">10 mins</option>
                <option value="15">15 mins</option>
                <option value="20">20 mins</option>
                <option value="30">30 mins</option>
                <option value="35">35 mins</option>
                <option value="40">40 mins</option>
                <option value="45">45 mins</option>
                <option value="50">50 mins</option>
                <option value="55">55 mins</option>
                <option value="60">60 mins</option>
              </select>
            </div>
          </div>

          <div style={styles.appPINEntryRow}>
            <p> - App PIN Entry</p>
            <div style={styles.appPINEntryCheckBox}>
              <input
                type="checkbox"
                checked={this.props.tmpAppPinEntryChecked}
                onChange={this.onAppPinEntryChanged}
              />
            </div>
          </div>

          {this.props.tmpAppPinEntryChecked === true && <SettingsPinView />}

          {this.state.showModal === true && (
            <ModalView
              onClose={this.onCloseModal}
              dialogStyle={styles.dialogStyle}
              shouldCloseOnOverlayClick
            >
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <p
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: 16,
                    justifyContent: 'center'
                  }}
                >
                  Are you sure about to save the App Config changes?
                </p>
              </div>

              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: '16px'
                }}
              >
                <ButtonView
                  label="No"
                  onPress={() => {
                    try {
                      this.onCancelAppConfigSaveSettings();
                      this.onCloseModal();
                      this.setState({
                        ...this.state,
                        cancelled: true,
                        showModal: false
                      });
                    } catch (error) {
                      this.setState(() => {
                        throw error;
                      });
                    }
                  }}
                  instyle={styles.cancelDialog}
                />

                <ButtonView
                  label="Yes"
                  onPress={() => {
                    try {
                      this.onSaveAppConfigSaveSettings();
                      this.onCloseModal();
                      this.setState({
                        ...this.state,
                        cancelled: true,
                        showModal: false
                      });
                    } catch (error) {
                      this.setState(() => {
                        throw error;
                      });
                    }
                  }}
                  instyle={styles.saveDialog}
                />
              </div>
            </ModalView>
          )}
        </div>
      </ErrorBoundary>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    height: 365,
    justifyContent: 'flex-start',
    marginRight: 20,
    flexDirection: 'column',
    backgroundColor: Colors.White
  },
  dialogStyle: {
    backgroundColor: Colors.VeryPaleBlue,
    padding: 15,
    width: 300,
    height: 'auto'
  },
  saveDialog: {
    margin: 2,
    width: 80,
    borderRadius: 4,
    height: 32,
    color: Colors.Black,
    fontSize: 15,
    textAlign: 'center',
    backgroundColor: Colors.LightGray,
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'pointer'
  },
  refresh: { width: 45, textAlign: 'center' },
  cancelDialog: {
    margin: 2,
    width: 80,
    borderRadius: 4,
    height: 32,
    color: Colors.Black,
    fontSize: 15,
    textAlign: 'center',
    backgroundColor: Colors.LightGray,
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'pointer'
  },
  baseURLLabel: {
    marginLeft: 8,
    marginRight: 8,
    fontSize: 15,
    width: 130
  },
  baseURL: {
    fontSize: 15,
    fontWeight: 'bold',
    paddingLeft: 3,
    paddingRight: 3,
    paddingBottom: 3,
    paddingTop: 3,
    marginRight: 8,
    marginLeft: 56
  },
  appVersionRow: {
    marginLeft: 10,
    height: 20,
    display: 'flex',
    flexDirection: 'row',
    marginTop: 2,
    marginBottom: 2,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  baseURLRow: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'row',
    marginTop: 2,
    marginBottom: 2,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  logoutRow: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 28,
    height: 0,
    marginTop: 4,
    justifyContent: 'flex-end',
    alignItems: 'center',
    visibility: 'hidden'
  },
  logout: {
    backgroundColor: Colors.LightGray,
    color: Colors.Black,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'black',
    padding: 5,
    fontSize: 13,
    marginRight: 5,
    borderRadius: 8,
    fontWeight: 600,
    width: 100,
    justifyContent: 'center',
    height: 32,
    alignItems: 'center',
    cursor: 'pointer',
    ':active': {
      backgroundColor: Colors.Gray,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'black'
    }
  },
  label: { textAlign: 'left' },
  portNumberlabel: { width: 100, paddingLeft: 20, fontSize: 15 },
  passwordRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 40
  },
  password: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.LightGray,
    marginLeft: 10,
    borderRadius: 6
  },
  userRow: {
    display: 'flex',
    flexDirection: 'row',
    height: 32,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 40
  },
  user: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.LightGray,
    alignSelf: 'center',
    marginLeft: 10,
    borderRadius: 6
  },
  portNumberRow: {
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 40
  },
  portNumber: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.LightGray,
    marginLeft: 10,
    width: 30,
    borderRadius: 6
  },

  serveripaddressRow: {
    display: 'flex',
    flexDirection: 'row',
    height: 32,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 40
  },
  serveripaddress: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.LightGray,
    borderRadius: 6,
    marginLeft: 10,
    width: 140
  },
  appPINEntryCheckBox: {
    display: 'flex',
    marginLeft: 8,
    marginRight: 11,
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center'
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10
  },
  general: { marginLeft: 10, fontSize: 17 },
  appPINEntryRow: {
    display: 'flex',
    flexDirection: 'row',
    height: 40,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 60,
    fontSize: '0.90em'
  },
  blackLine: { height: 1, width: '100%', borderBottom: '2px solid black' },
  selectBox: {
    padding: 4,
    marginRight: 18,
    flex: 2,
    width: 90
  },
  save: {
    margin: 2,
    width: 80,
    height: 32,
    fontSize: 15,
    color: Colors.White,
    textAlign: 'center',
    backgroundColor: Colors.MostPureBlue,
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'pointer',
    borderRadius: 8,
    borderColor: Colors.Black,
    display: 'inline-block',
    textDecoration: 'none',
    boxShadow: '0.5px 0.5px 1px #00000000',
    ':active': {
      backgroundColor: Colors.Gray,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'black'
    }
  },
  cancel: {
    margin: 2,
    width: 80,
    height: 32,
    color: Colors.Black,
    fontSize: 15,
    textAlign: 'center',
    backgroundColor: Colors.LightGray,
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'pointer',
    borderRadius: 8,
    borderColor: Colors.Black,
    display: 'inline-block',
    textDecoration: 'none',
    boxShadow: '0.5px 0.5px 1px #00000000',
    ':active': {
      backgroundColor: Colors.Gray,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'black'
    }
  },
  buttonContainer: {
    display: 'flex',
    height: 40,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: 30,
    marginBottom: 2,
    marginRight: 17,
    marginTop: 8
  }
};

const mapStateToProps = state => ({
  baseURL: state.appConfig.baseURL,
  appBaseURL: state.appConfig.appBaseURL,
  serverIPAddress: state.settings.serverIPAddress,
  portNo: state.settings.portNo,
  user: state.settings.user,
  password: state.settings.password,
  tmpSessionTimeOut: state.settings.tmpSessionTimeOut,
  appPinEntryChecked: state.pin.appPinEntryChecked,
  tmpAppPinEntryChecked: state.pin.tmpAppPinEntryChecked,
  settingsPin: state.pin.settingsPin,
  tmpSettingsPin: state.pin.tmpSettingsPin,
  configChanged: state.settings.configChanged,
  tmpSelectedBadgeOfUser: state.token.tmpSelectedBadgeOfUser,
  requestWirelessLockStatus: state.door.requestWirelessLockStatus
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logOut: actions.logOut,
      setAppPIN: actions.setAppPIN,
      setServerIPAddress: actions.setServerIPAddress,
      setPortNo: actions.setPortNo,
      setUser: actions.setUser,
      setPassword: actions.setPassword,
      changeTmpAppEntryChecked: actions.changeTmpAppEntryChecked,
      //changeTmpSettingsPin: actions.changeTmpSettingsPin,
      //addMessage: actions.addMessage,
      addAndShowMessage: actions.addAndShowMessage,
      configurationChanged: actions.configurationChanged,
      saveConfigSettings: actions.saveConfigSettings,
      cancelSaveConfigSettings: actions.cancelSaveConfigSettings,
      changeSessionTimeOut: actions.changeSessionTimeOut,
      resetInactivityTimer: actions.resetInactivityTimer,
      //showGeneralMessage: actions.showGeneralMessage,
      resetConfigurationChanged: actions.resetConfigurationChanged,
      fetchAccessReaderListByBadge: actions.fetchAccessReaderListByBadge,
      refreshAppConfigSettings: actions.refreshAppConfigSettings,
      logError: actions.logError
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(GeneralView)
);

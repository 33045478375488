import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ButtonView from '../common/ButtonView.js';
import * as Colors from '../../consts/colors';
import '../../style.css';
import * as actions from '../../actions/index';

class PersonnelScreenView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSearchOption: 'firstName',
      searchText: ''
    };

    this.handleSearchText = this.handleSearchText.bind(this);
    this.handleSearchOption = this.handleSearchOption.bind(this);
  }

  handleSearchText(event) {
    this.props.resetInactivityTimer();
    this.setState({
      searchText: event.target.value
    });
    this.props.onChangeText(
      this.state.selectedSearchOption,
      event.target.value
    );
  }

  handleSearchOption(event) {
    this.setState({ selectedSearchOption: event.target.value });
    this.props.onChangeText(event.target.value, this.state.searchText);
  }

  render() {
    return (
      <div
        onClick={() => {
          this.props.resetInactivityTimer();
        }}
        style={styles.box}
      >
        <div style={{ display: 'flex', marginBottom: 5 }}>
          <div style={{ flex: '2 2 auto', paddingLeft: 2 }}>Field</div>
          <div style={{ flex: '3 3 auto', paddingLeft: 8 }}>Value</div>
          <div style={{ width: 69 }} />
        </div>
        <div style={styles.searchBox}>
          <select
            onChange={this.handleSearchOption}
            style={styles.selectBox}
            value={this.state.selectedSearchOption}
          >
            <option value="firstName">First Name</option>
            <option value="lastName">Last Name</option>
            <option value="badge">Badge</option>
          </select>
          <input
                    style={styles.inputType}
                    value={this.state.searchText}
                    onChange={this.handleSearchText}
                    placeholder={
                        this.state.selectedSearchOption === 'badge'
                            ? 'Enter Number'
                            : 'Enter Text'
                    }
                    type={this.state.selectedSearchOption === 'badge'? "number" :"text"}
          />
          <ButtonView
            label="Search"
            instyle={styles.search}
            onPress={this.props.SearchButtonPressed}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  searchBox: {
    display: 'flex',
    //flexDirection: 'row',
    justifyContent: 'space-between'
    //alignItems: 'stretch',
    //alignContent: 'stretch'
  },
  selectBox: {
    backgroundColor: Colors.VeryPaleCyan,
    padding: 4,
    flex: '2 2 0'
  },
  inputType: {
    backgroundColor: Colors.VeryPaleCyan,
    padding: 4,
    width: '100%',
    flex: '3 3 0',
    margin: '0 10px'
  },
  search: {
    //margin: 2,
    width: 69,
    height: 33,
    color: Colors.White,
    fontSize: 14,
    textAlign: 'center',
    backgroundColor: Colors.DeepGreen,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 4,
    borderColor: Colors.Black,
    cursor: 'pointer',
    display: 'inline-block',
    textDecoration: 'none',
    boxShadow: '0.5px 0.5px 1px #00000000',
    ':active': {
      backgroundColor: Colors.Gray,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'black'
    }
  },
  box: {
    marginTop: 10
  },
  label: {
    paddingLeft: 20,
    paddingBottom: 5
  },
  buttonContainer: {
    flex: 1,
    height: 40,
    display: 'flex',
    backgroundColor: Colors.White,
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
};

const mapStateToProps = state => ({
  viewSelection: state.settings.viewSelection,
  connectStatus: state.connectStatus,
  generalMessage: state.generalMessage
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetInactivityTimer: actions.resetInactivityTimer
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(PersonnelScreenView)
);

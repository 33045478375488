import React from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import * as Colors from '../../consts/colors';
import * as actions from '../../actions/index';

class PinCheckView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      p1: '',
      p2: '',
      p3: '',
      p4: '',
      pin: '',
      switchToHome: false
    };

    this.handleTextChangeP1 = this.handleTextChangeP1.bind(this);
    this.handleTextChangeP2 = this.handleTextChangeP2.bind(this);
    this.handleTextChangeP3 = this.handleTextChangeP3.bind(this);
    this.handleTextChangeP4 = this.handleTextChangeP4.bind(this);
    this.verifyPinAction = this.verifyPinAction.bind(this);
    this.p1 = React.createRef();
    this.p2 = React.createRef();
    this.p3 = React.createRef();
    this.p4 = React.createRef();
  }

  formatValue(val) {
    return val !== '' ? val : '#';
  }

  handleTextChangeP1(event) {
    if (event.target.value >= 0 && event.target.value <= 9) {
      this.setState({
        ...this.state,
        p1: event.target.value,
        pin: `${this.formatValue(event.target.value)}${this.formatValue(
          this.state.p2
        )}${this.formatValue(this.state.p3)}${this.formatValue(this.state.p4)}`
      });
      this.p2.current.focus();
    } else {
      event.preventDefault();
    }
  }

  handleTextChangeP2(event) {
    if (event.target.value >= 0 && event.target.value <= 9) {
      this.setState({
        ...this.state,
        p2: event.target.value,
        pin: `${this.formatValue(this.state.p1)}${this.formatValue(
          event.target.value
        )}${this.formatValue(this.state.p3)}${this.formatValue(this.state.p4)}`
      });
      this.p3.current.focus();
    } else {
      event.preventDefault();
    }
  }

  handleTextChangeP3(event) {
    if (event.target.value >= 0 && event.target.value <= 9) {
      this.setState({
        ...this.state,
        p3: event.target.value,
        pin: `${this.formatValue(this.state.p1)}${this.formatValue(
          this.state.p2
        )}${this.formatValue(event.target.value)}${this.formatValue(
          this.state.p4
        )}`
      });
      this.p4.current.focus();
    } else {
      event.preventDefault();
    }
  }

  handleTextChangeP4(event) {
    if (event.target.value >= 0 && event.target.value <= 9) {
      this.setState({
        ...this.state,
        p4: event.target.value,
        pin: `${this.formatValue(this.state.p1)}${this.formatValue(
          this.state.p2
        )}${this.formatValue(this.state.p3)}${this.formatValue(
          event.target.value
        )}`
      });
      this.p1.current.focus();
    } else {
      event.preventDefault();
    }
  }

  verifyPinAction() {
    //console.log(this.state.pin);
    console.log(this.props.settingsPin);

    if (this.state.pin === this.props.settingsPin) {
      this.props.changeAppEntrySession(false);

      this.setState({
        ...this.state,
        switchToHome: true
      });
    } else {
      console.log(this.state.switchToHome);
      alert('PIN Verification Failed!');
    }
  }

  render() {
    if (this.state.switchToHome) {
      return <Redirect to="/home" />;
    }
    return (
      <div style={styles.container}>
        <p style={{ color: Colors.White }}>Please Enter PIN </p>
        <div style={styles.pcontainer}>
          <div style={styles.p1Container}>
            <input
              ref={this.p1}
              type="text"
              name="p1"
              tabIndex={1}
              style={styles.p1}
              pattern="[0-9]{1}"
              maxLength={1}
              onChange={this.handleTextChangeP1}
              value={this.state.p1}
              title="only digits are allowed"
            />
          </div>
          <div style={styles.p2Container}>
            <input
              ref={this.p2}
              type="text"
              name="p2"
              tabIndex={1}
              style={styles.p2}
              pattern="[0-9]{1}"
              maxLength={1}
              onChange={this.handleTextChangeP2}
              value={this.state.p2}
              title="only digits are allowed"
            />
          </div>
          <div style={styles.p3Container}>
            <input
              ref={this.p3}
              type="text"
              name="p3"
              tabIndex={1}
              style={styles.p3}
              pattern="[0-9]{1}"
              maxLength={1}
              onChange={this.handleTextChangeP3}
              value={this.state.p3}
              title="only digits are allowed"
            />
          </div>
          <div style={styles.p4Container}>
            <input
              ref={this.p4}
              type="text"
              name="p4"
              tabIndex={1}
              style={styles.p4}
              pattern="[0-9]{1}"
              maxLength={1}
              onChange={this.handleTextChangeP4}
              value={this.state.p4}
              title="only digits are allowed"
            />
          </div>
        </div>
        <div>
          <input
            onClick={this.verifyPinAction}
            type="button"
            style={styles.submitButton}
            value="Verify"
            tabIndex={1}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20
  },
  pcontainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    flex: 1,
    padding: 10,
    marginBottom: 10
  },
  p1: {
    width: 30,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    borderRadius: 8,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.LightGray
  },
  p2: {
    width: 30,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    borderRadius: 8,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.LightGray
  },
  p3: {
    width: 30,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    borderRadius: 8,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.LightGray
  },
  p4: {
    width: 30,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    borderRadius: 8,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.LightGray
  },
  p1Container: {
    display: 'flex',
    border: '1px solid #f5f5f5',
    height: 30,
    width: 'auto',
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 8
  },
  p2Container: {
    display: 'flex',
    border: '1px solid #f5f5f5',
    height: 30,
    width: 'auto',
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 8
  },
  p3Container: {
    display: 'flex',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#f5f5f5',
    height: 30,
    width: 'auto',
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 8
  },
  p4Container: {
    display: 'flex',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#f5f5f5',
    height: 30,
    width: 'auto',
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 8
  },
  line: {
    height: 12,
    backgroundColor: Colors.Gray,
    marginTop: 5
  },
  verifyButton: {
    padding: 5
  },
  submitButton: {
    backgroundColor: Colors.Purple,
    borderColor: Colors.Black,
    borderStyle: 'solid',
    borderWidth: 0.5,
    color: Colors.Green,
    width: 90,
    height: 40,
    marginTop: 10,
    padding: 10,
    fontSize: 16,
    alignSelf: 'center',
    fontWeight: 900,
    cursor: 'pointer',
    boxShadow: '1px 1px 2px #000000',
    ':active': {
      backgroundColor: Colors.Gray,
      borderWidth: 0.5,
      borderStyle: 'solid',
      borderColor: 'black'
    }
  }
};

const mapStateToProps = state => ({
  settingsPin: state.pin.settingsPin
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeAppEntrySession: actions.changeAppEntrySession
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(PinCheckView)
);

import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import * as Colors from '../../consts/colors';
import DoorItemView from './DoorItemView';
import * as actions from '../../actions/index';
import ButtonView from '../common/ButtonView';
import ModalView from '../common/ModalView';

import {
  PAGE_NAMES,
  ADMIN_ROLE_ID,
  SCREEN_CODES,
  SECURITY_LEVEL_CODES
} from '../../consts/consts';

class DoorView extends Component {
  constructor(props) {
    super(props);
    this.doUnlock = this.doUnlock.bind(this);
    this.txtPINCode = React.createRef();
    this.txtCommonCode = React.createRef();
    this.checkIfPINOrCommonCodeValid = this.checkIfPINOrCommonCodeValid.bind();
      this.onCloseModal = this.onCloseModal.bind();
      this.isEmpty = this.isEmpty.bind();

    let doorPrivilege = this.props.privileges.find(
      privilege => privilege.ScreenID === SCREEN_CODES.MANUAL_DOORS
    );

    if (typeof doorPrivilege === 'undefined') {
      doorPrivilege = null;
    }

      this.props.writeLog('OperatorID: ' + this.props.operatorId + "My DoorView--> " + 'My DoorViewPrivilege: ' + JSON.stringify(doorPrivilege));

    this.state = { doorPrivilege };
    //console.log('Door Privilege: ', doorPrivilege);
    this.props.refreshAppConfigSettings(); 
    if (
      this.props.tmpSelectedBadgeOfUser &&
      this.props.tmpSelectedBadgeOfUser.Badge
    ) {
      //console.log(
      //  'Temp ViewSelection:Badge, Facility',
      //  this.props.tmpSelectedBadgeOfUser.Badge,
      //  this.props.tmpSelectedBadgeOfUser.Facility
      //);
      this.props.fetchAccessReaderListByBadge(
        this.props.tmpSelectedBadgeOfUser.Badge,
          this.props.tmpSelectedBadgeOfUser.Facility,
          false, 
       false
          
      );
    }
    }

      isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
}

  UNSAFE_componentWillMount() {
    this.props.setCurrentPageName(PAGE_NAMES.DOORS);
  }

  showDoorList(doors) {
    if (
      this.props.tmpSelectedBadgeOfUser &&
      this.props.tmpSelectedBadgeOfUser.Badge &&
      doors
    ) {
      const filteredDoorList = [];
      if (
        this.props.tmpViewSelection[0].itemList &&
        this.props.tmpViewSelection[0].itemList.length > 0
      ) {
        const doorItemList = this.props.tmpViewSelection[0].itemList;

        //console.log('DoorItemList :', doorItemList, doors);

        for (let i = 0; i < doors.length; ++i) {
          if (doorItemList[i].checked === true) {
            filteredDoorList.push(doors[i]);
          }
        }
      }


      const doorList = filteredDoorList.map((door, index) => (
        <DoorItemView
              key={door.doorNo}
              currentIndex={index}
              doorName={door.doorName}
              doorCAObjectID={door.caObjectID}
              doorStatus={door.locked}
              statusDescription={door.statusDef}
              panelNo={door.panelNo}
              doorNo={door.doorNo}
              iskeypadEnabled={door.iskeypadEnabled}
              cardOnlyTimeSchedule={door.cardOnlyTimeSchedule}
              noTransactionOnValid={door.noTransactionOnValid=== true? true: false}
        />
      ));

      return <div>{doorList}</div>;
    }
    return <div>{}</div>;
  }

    checkIfPINOrCommonCodeValid() {

        if (this.props.isPINCodeEnabled === true) {
            if (this.txtPINCode.current.value.length > 9) {
                return false;
            }
        } else if (this.props.isCommonCodeEnabled === true) {
            if (this.txtCommonCode.current.value.length > 9) {
                return false;
            }
        } else {

            return true;
        }

        return true;

    }

 

    doUnlock() {

        if ((this.txtCommonCode.current === null && this.isEmpty(this.txtPINCode.current.value)) || (this.txtPINCode.current === null && this.isEmpty(this.txtCommonCode.current.value))) {
            return;
         }

        this.props.resetPINCodeEnabledStatus();
        const doorInfo = { ...this.props.currentOperatingDoor };

        const currentOperatingDoor = { ...this.props.currentOperatingDoor, isCommonCodeEnabled: this.props.isCommonCodeEnabled, isPINCodeEnabled: this.props.isPINCodeEnabled};
        this.props.setCurrentOperatingDoor(currentOperatingDoor);
        this.props.writeLog('OperatorID: ' + this.props.operatorId + " --> " + 'CurrentOperatingDoor:' + JSON.stringify(currentOperatingDoor));

        this.props.resetPINCodeEnabledStatus();

        if (this.props.isPINCodeEnabled === true) {
            let PIN = 0;
            if (!this.isEmpty(parseInt(this.txtPINCode.current.value))) {
                PIN = parseInt(this.txtPINCode.current.value) === 0 ? -1 : parseInt(this.txtPINCode.current.value);
            }
            const doorInfoWithPIN = { ...doorInfo, PIN };
            console.log("DoorInfo With PIN:", doorInfoWithPIN);
            this.props.sendUnlockMyDoor(doorInfoWithPIN, this.props.baseURL);
        } else if (this.props.isCommonCodeEnabled === true) {
            let CommonCode = 0;
            if (!this.isEmpty(parseInt(this.txtCommonCode.current.value))) {
                CommonCode = parseInt(this.txtCommonCode.current.value) === 0 ? -1 : parseInt(this.txtCommonCode.current.value);
            }
            const doorInfoWithCommonCode = { ...doorInfo, CommonCode };
            console.log("DoorInfo With CommonCode:", doorInfoWithCommonCode);
            this.props.sendUnlockMyDoor(doorInfoWithCommonCode, this.props.baseURL);
        } else {
            if (this.props.isPINCommonCodeNotScheduled === true) {
                return;
            }

            this.props.sendUnlockMyDoor(doorInfo, this.props.baseURL);
        }

        this.props.resetCommonCodeEnabledStatus();
        this.props.resetCommonCodeEnabledStatus();
    }

    onCloseModal() {
    }

  render() {
    return (
      <div style={styles.container}>
        <div>
          <div style={styles.expandContainer}>
            <Link style={styles.underlineless} to="/home">
              <div>
                <FontAwesomeIcon
                  icon={faMinus}
                  size="1x"
                  color={Colors.White}
                />
              </div>
            </Link>
            <div style={styles.functionText}>My Doors</div>
          </div>
            </div>

        {this.props.doors && this.props.doors.length === 0 && (
          <div style={styles.emptyList}>No Doors Found</div>
        )}
        {this.props.roleId === ADMIN_ROLE_ID &&
          this.showDoorList(this.props.doors)}
        {this.props.roleId !== ADMIN_ROLE_ID &&
          this.state.doorPrivilege &&
          this.state.doorPrivilege.SecurityLevel ===
            SECURITY_LEVEL_CODES.FULL_CONTROL &&
                this.showDoorList(this.props.doors)}

            {this.props.isPINCodeEnabled === true && (
                <ModalView
                    onClose={this.onCloseModal}
                    dialogStyle={styles.dialogStyle}
                    shouldCloseOnOverlayClick
                >
                    <div
                        style={{ flex: 1, display: 'flex', flexDirection: 'column', background: Colors.White, width: 'auto', height: 'auto', padding: 30 }}
                    >
                        <div
                            style={{
                                fontSize: 18,
                                flex: 1,
                                backgroundColor: Colors.Green,
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'center',
                                fontWeight: 'bold',
                                padding: 5,
                                color: Colors.White
                            }}
                        >
                            Enter PIN Code
                </div>

                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <p style={{ fontSize: 14 }}>PIN:</p>
                            <input
                                type="number"
                                ref={this.txtPINCode}
                                required pattern="^[0-9]{9}$"
                                min="0"
                                max="999999999"
                                name="txtPINCode"
                                style={{
                                    width: 140,
                                    height: 20,
                                    fontWeight: 'bold',
                                    marginLeft: 5,
                                    backgroundColor: Colors.VeryPaleCyan
                                }}


                                onKeyPress={(e) => {
                                    if (e.charCode >= 48 && e.charCode <= 57) {
                                        const pinCodeInputted = e.target.value + String.fromCharCode(e.charCode);
                                        if (pinCodeInputted >= 0 && pinCodeInputted <= 999999999) {

                                        }
                                        else {
                                            e.preventDefault();

                                        }

                                    } else {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }

                                }}

                                //onKeyDown={(e) => {
                                //    // console.log("jdskdkjsdkjsdja",e);
                                //    //if (e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 9) { }
                                //    //else if (this.txtPINCode.current.value.length >= 9 || this.txtPINCode.current.length === 0 || e.keyCode === 110 || e.keyCode === 109 || !(e.keyCode >= 48 && e.keyCode <= 57)) {
                                //    //    e.preventDefault();
                                //    //}

                                //    if ((e.charCode >= 48 && e.charCode <= 57) || (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 9 ) {
                                //    } else {
                                //        e.preventDefault();
                                //        e.stopPropagation();
                                //    }

                                //}}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <ButtonView
                                label="Cancel"
                                onPress={() => {
                                    this.props.resetPINCodeEnabledStatus();
                                    this.props.cancelUnlockPressed();
                                }}
                                instyle={styles.cancel}
                            />

                            <ButtonView
                                label="Unlock Door"
                                onPress={this.doUnlock}
                                instyle={styles.doUnlock}
                            />
                        </div>
                    </div>
                </ModalView>
            )}

            {this.props.isCommonCodeEnabled === true && (
                <ModalView
                    onClose={this.onCloseModal}
                    dialogStyle={styles.dialogStyle}
                    shouldCloseOnOverlayClick
                >
                    <div
                        style={{ flex: 1, display: 'flex', flexDirection: 'column', background: Colors.White, width: 'auto', height: 'auto', padding: 30 }}
                    >
                        <div
                            style={{
                                fontSize: 18,
                                flex: 1,
                                backgroundColor: Colors.Green,
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'center',
                                fontWeight: 'bold',
                                padding: 5,
                                color: Colors.White
                            }}
                        >
                            Enter Common Code
                </div>

                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <p style={{ fontSize: 14 }}>Common Code:</p>
                            <input
                                type="number"
                                min="0"
                                max="999999999"

                                required pattern="^[0-9]{9}$"
                                ref={this.txtCommonCode}
                                name="txtCommonCode"
                                style={{
                                    width: 140,
                                    height: 20,
                                    fontWeight: 'bold',
                                    marginLeft: 5,
                                    backgroundColor: Colors.VeryPaleCyan
                                }}

                                onKeyPress={(e) => {
                                     if (e.charCode >= 48 && e.charCode <= 57) {
                                         const commonCodeInputted = e.target.value + String.fromCharCode(e.charCode);
                                             if (commonCodeInputted >= 0 && commonCodeInputted <= 999999999) {

                                              }
                                              else {
                                                 e.preventDefault();

                                              }
                                            
                                         } else {
                                              e.preventDefault();
                                              e.stopPropagation();
                                         }

                                }}


                                //onKeyDown={(e) => {
                                //    //if (e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 9) { }
                                //    //else if (this.txtCommonCode.current.value.length >= 9 || this.txtCommonCode.current.length === 0 || e.keyCode === 110 || e.keyCode === 109 || !(e.keyCode >= 48 && e.keyCode <= 57)) {
                                //    //    e.preventDefault();
                                //    //}

                                //    if ((e.charCode >= 48 && e.charCode <= 57) || (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 9) {
                                //    } else {
                                //        e.preventDefault();
                                //        e.stopPropagation();
                                //    }
                                     
                                //}}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <ButtonView
                                label="Cancel"
                                onPress={() => {
                                    this.props.resetCommonCodeEnabledStatus();
                                    this.props.cancelUnlockPressed();
                                }}
                                instyle={styles.cancel}
                            />

                            <ButtonView
                                label="Unlock Door"
                                onPress={this.doUnlock}
                                instyle={styles.doUnlock}
                            />
                        </div>
                    </div>
                </ModalView>
            )}

      </div>
    ); // Cannot configure door list who has no valid badge as personnel
  }
}

const styles = {
  underlineless: {
    textDecoration: 'none'
  },
  emptyList: {
    display: 'flex',
    flex: 1,
    fontSize: 17,
    fontWeight: '600',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.White,
    height: 100,
    marginBottom: 5
  },
  container: {
    display: 'flex',
    flex: 1,
    height: 'auto',
    marginRight: 20,
    flexDirection: 'column',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 2.5,
    borderBottomLeftRadius: 2.5,
    paddingTop: 0.3,
    paddingLeft: 0.3,
    paddingRight: 0.3,
    paddingBottom: 0.3,
      borderColor: Colors.Green,
      backgroundColor: Colors.Green
  },
  expandContainer: {
    flex: 1,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 10,
    backgroundColor: Colors.Green,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10
  },

  functionText: {
    color: Colors.White,
    textAlign: 'left',
    marginLeft: 20,
    fontSize: 20,
    fontWeight: 800
  },
  horizontalLine: {
    height: 1,
    display: 'flex',
    flex: 1,
    borderBottom: '1px solid',
    borderColor: Colors.Purple
    },
    doUnlock: {
        margin: 2,
        fontWeeight: 'bold',
        width: 'auto',
        height: 32,
        color: Colors.Black,
        fontSize: 15,
        textAlign: 'center',
        backgroundColor: Colors.LightGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },

    cancel: {
        margin: 2,
        width: 80,
        height: 32,
        fontWeeight: 'bold',
        color: Colors.Black,
        fontSize: 15,
        textAlign: 'center',
        backgroundColor: Colors.LightGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    }
};

const mapStateToProps = state => ({
  accessToken: state.token.accessToken,
  tmpSelectedBadgeOfUser: state.token.tmpSelectedBadgeOfUser,
  doors: state.door.doors,
  tmpViewSelection: state.settings.tmpViewSelection,
  privileges: state.token.privileges,
  operatorId: state.token.operatorId,
  roleId: state.token.roleId,
  requestWirelessLockStatus: state.door.requestWirelessLockStatus,
  requestStatus: state.page.refreshStatus,
  isPINCodeEnabled: state.door.isPINCodeEnabled,
  isCommonCodeEnabled: state.door.isCommonCodeEnabled,
   currentOperatingDoor: state.door.currentOperatingDoor,
    isPINCommonCodeNotScheduled: state.door.isPINCommonCodeNotScheduled,
   badgeValidStatus: state.door.badgeValidStatus,
  operatorId: state.token.operatorId
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAccessReaderListByBadge: actions.fetchAccessReaderListByBadge,
      setCurrentPageName: actions.setCurrentPageName,
      refreshAppConfigSettings: actions.refreshAppConfigSettings,
      resetPINCodeEnabledStatus: actions.resetPINCodeEnabledStatus,
      resetCommonCodeEnabledStatus: actions.resetCommonCodeEnabledStatus,
       sendUnlockMyDoor: actions.sendUnlockMyDoor,
      setCurrentOperatingDoor: actions.setCurrentOperatingDoor,
      resetPINCOmmonCodeNotSCheduled: actions.resetPINCOmmonCodeNotSCheduled,
      cancelUnlockPressed: actions.cancelUnlockPressed,
      writeLog: actions.writeLog
    },
    dispatch
  );

export default Radium(connect(mapStateToProps, mapDispatchToProps)(DoorView));

import * as ActionTypes from '../actions/types';

const defaultState = {
    personnelPIN: '',
    //appEntryChecked: false,
    appPinSessionChecked: true,
    tmpAppPinSessionChecked: true,
    appPinEntryChecked: false,
    tmpAppPinEntryChecked: false,
    settingsPin: '####',
    tmpSettingsPin: '####',
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.SAVE_CONFIG_SETTINGS: {
            const { appPinEntryChecked, settingsPin } = !action.payload ? {} : action.payload;
            return {
                ...state,
                appPinEntryChecked,
                settingsPin,
                appPinSessionChecked: state.tmpAppPinSessionChecked
            };
        }

        case ActionTypes.CANCEL_SAVE_CONFIG_SETTINGS: {
            return {
                ...state,
                tmpAppPinEntryChecked: state.appPinEntryChecked,
                tmpSettingsPin: state.settingsPin,
                tmpAppPinSessionChecked: state.appPinSessionChecked
            };
        }

        case ActionTypes.CHANGE_PERSONNEL_PIN:
            return {
                ...state,
                personnelPIN: action.payload
            };
        case ActionTypes.CHANGE_TMP_SETTINGS_PIN:
            return {
                ...state,
                tmpSettingsPin: action.payload
            };
        case ActionTypes.LOAD_SETTINGS_PIN:
            return {
                ...state,
                ...action.payload
            };
        case ActionTypes.CHANGE_TMP_APP_ENTRY_CHECKED: {
            return {
                ...state,
                tmpAppPinEntryChecked: action.payload,
                //appPinEntryChecked: action.payload,
                //settingsPin: action.payload === true ? '####' : state.settingsPin
            };
        }

        case ActionTypes.CHECK_APP_ENTRY_SESSION:
            return {
                ...state,
                //  appPinSessionChecked: action.payload
                tmpAppPinSessionChecked: action.payload
            };

        case ActionTypes.LOGOUT: {
            return { ...state, tmpAppPinSessionChecked: true };
        }

        default:
            return state;
    }
};

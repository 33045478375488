import React, { Component } from "react";
import Radium from "radium";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import * as Colors from "../../consts/colors";
import LockdownAreaItemView from "./LockdownAreaItemView.js";
import ButtonView from "../common/ButtonView";
import * as actions from "../../actions/index";
import {
  PAGE_NAMES,
  ADMIN_ROLE_ID,
  SCREEN_CODES,
  SECURITY_LEVEL_CODES
} from "../../consts/consts";

class LockdownView extends Component {
  constructor(props) {
    super(props);
    this.canLockdownBeRemoved = this.canLockdownBeRemoved.bind(this);
    this.canAllLockdownAreasBeDeactivated = this.canAllLockdownAreasBeDeactivated.bind(
      this
    );
    this.checkIfAnyLockdownAreaIsAssociatedWithTL = this.checkIfAnyLockdownAreaIsAssociatedWithTL.bind(
      this
    );

    const lockDownPrivilege = this.props.privileges.find(
      privilege => privilege.ScreenID === SCREEN_CODES.LOCKDOWN
    );
    this.state = { lockDownPrivilege };
      this.props.writeLog('OperatorID: ' + this.props.operatorId + "Lockdown View--> " + 'lockdownPrivilege: ' + JSON.stringify(lockDownPrivilege));

  }

  UNSAFE_componentWillMount() {
    this.props.setCurrentPageName(PAGE_NAMES.LOCKDOWN);
    this.props.fetchAllLockDownArea();
    this.props.fetchAllThreatLevels();
  }

  showLockdownAreaList(lockdownAreas) {
    console.log("lockdown list", lockdownAreas);
    const lockdownAreaList = lockdownAreas.map(lockdownArea => (
      <LockdownAreaItemView
        key={lockdownArea.areaNo}
        areaNo={lockdownArea.areaNo}
        areaName={lockdownArea.areaName}
        lockdownStatus={lockdownArea.activated}
        canLockdownBeRemoved={this.canLockdownBeRemoved(lockdownArea.areaNo)}
      />
    ));
    return <div>{lockdownAreaList}</div>;
  }

  canLockdownBeRemoved(areaNo) {
    try {
      const filteredThreats = this.props.threats.filter(
        threat =>
          threat.lockoutArea === parseInt(areaNo, 10) &&
          threat.activated === true
      ); // finding associated areaNo associated threat levels
      console.log("filteredTheats :", filteredThreats);

      if (filteredThreats && filteredThreats.length >= 1) {
        return false;
      }
      return true;
    } catch (exception) {
      console.log("Exception: ", exception);
    }
  }

  checkIfAnyLockdownAreaIsAssociatedWithTL() {
    const lockDownAreas = this.props.lockdownAreas;
    for (let i = 0; i < lockDownAreas.length; ++i) {
      try {
        const lockdownArea = lockDownAreas[i];

        const filteredThreats = this.props.threats.filter(
          threat =>
            threat.lockoutArea === parseInt(lockdownArea.areaNo, 10) &&
            threat.activated === true
        ); // finding associated areaNo associated threat levels

        if (filteredThreats && filteredThreats.length >= 1) {
          return true;
        }
        return false;
      } catch (exception) {
        console.log("Exception: ", exception);
      }
    }

    return true;
  }

  canAllLockdownAreasBeDeactivated() {
    const lockDownAreas = this.props.lockdownAreas;
    for (let i = 0; i < lockDownAreas.length; ++i) {
      try {
        const lockdownArea = lockDownAreas[i];

        const filteredThreats = this.props.threats.filter(
          threat =>
            threat.lockoutArea === parseInt(lockdownArea.areaNo, 10) &&
            threat.activated === true
        ); // finding associated areaNo associated threat levels

        console.log("filteredThreats:------", filteredThreats);

        if (filteredThreats && filteredThreats.length >= 1) {
          return false;
        }
        return true;
      } catch (exception) {
        console.log("Exception: ", exception);
      }
    }

    return true;
  }

  render() {
    return (
      <div style={styles.container}>
        <div style={styles.expandContainer}>
          <Link style={styles.underlineless} to="/home">
            <div>
              <FontAwesomeIcon icon={faMinus} size="1x" color={Colors.White} />
            </div>
          </Link>
          <div style={styles.functionText}>Lockdown</div>
        </div>
        {this.props.lockdownAreas.length === 0 && (
          <div style={styles.emptyList}>No Lockdown Areas Found</div>
        )}

        {this.props.roleId === ADMIN_ROLE_ID &&
          this.props.lockdownAreas.length !== 0 && (
            <div style={styles.lockdownAreasContainer}>
              <div style={styles.lockdownallareas}>Lockdown All Areas</div>
              <div style={styles.lockdownStatusButton}>
                <Link style={styles.underlineless} to="/lockdown">
                  <ButtonView
                    label="Activate"
                    instyle={
                      this.props.lockdownAreas.some(
                        lockdownArea => lockdownArea.activated === false
                      )
                        ? styles.activate
                        : styles.disabled
                    }
                    enabled={this.props.lockdownAreas.some(
                      lockdownArea => lockdownArea.activated === false
                    )}
                    onPress={() => {
                      if (this.checkIfAnyLockdownAreaIsAssociatedWithTL()) {
                        alert(
                          "One or more lockdown areas are associated with activated threat level. Please activate by tapping on lockdown area item."
                        );
                      } else {
                        const isConfirmed = window.confirm(
                          "Confirm Activation for all lockdown areas?"
                        );
                          if (isConfirmed === true) {
                          this.props.writeLog('OperatorID: ' + this.props.operatorId + "Lockdown View--> ActivateAllArea");
                          this.props.sendActivateAllLockdownAreas();
                          alert(
                            "Activation command is sent for all lock down areas."
                          );
                        }
                      }
                    }}
                  />
                </Link>
                <Link style={styles.underlineless} to="/lockdown">
                  <ButtonView
                    label="Deactivate"
                    instyle={
                      this.props.lockdownAreas.some(
                        lockdownArea => lockdownArea.activated === true
                      )
                        ? styles.deactivate
                        : styles.disabled
                    }
                    enabled={this.props.lockdownAreas.some(
                      lockdownArea => lockdownArea.activated === true
                    )}
                    onPress={() => {
                      if (this.canAllLockdownAreasBeDeactivated() === true) {
                        const isConfirmed = window.confirm(
                          "Confirm Deactivation for all lockdown areas?"
                        );
                          if (isConfirmed === true) {
                              this.props.writeLog('OperatorID: ' + this.props.operatorId + "Lockdown View--> DeactivateAllArea");
                          this.props.sendDeactivateAllLockdownAreas();
                          alert(
                            "Deactivation command is sent for all lock down areas."
                          );
                        }
                      } else {
                        alert(
                          "Lockdown areas are associated with activated threat levels."
                        );
                      }
                    }}
                  />
                </Link>
              </div>
            </div>
          )}
        {this.props.roleId !== ADMIN_ROLE_ID &&
          this.state.lockDownPrivilege &&
          this.state.lockDownPrivilege.SecurityLevel ===
            SECURITY_LEVEL_CODES.FULL_CONTROL &&
          this.props.lockdownAreas.length !== 0 && (
            <div style={styles.lockdownAreasContainer}>
              <div style={styles.lockdownallareas}>Lockdown All Areas</div>
              <div style={styles.lockdownStatusButton}>
                <Link style={styles.underlineless} to="/lockdown">
                  <ButtonView
                    label="Activate"
                    instyle={
                      this.props.lockdownAreas.some(
                        lockdownArea => lockdownArea.activated === false
                      )
                        ? styles.activate
                        : styles.disabled
                    }
                    enabled={this.props.lockdownAreas.some(
                      lockdownArea => lockdownArea.activated === false
                    )}
                    onPress={() => {
                      const isConfirmed = window.confirm(
                        "Confirm Activation for all lockdown areas?"
                      );
                        if (isConfirmed === true) {
                            this.props.writeLog('OperatorID: ' + this.props.operatorId + "Lockdown View--> ActivateAllArea");
                        this.props.sendActivateAllLockdownAreas();
                        alert(
                          "Activation command is sent for all lock down areas."
                        );
                      }
                    }}
                  />
                </Link>
                <Link style={styles.underlineless} to="/lockdown">
                  <ButtonView
                    label="Deactivate"
                    instyle={
                      this.props.lockdownAreas.some(
                        lockdownArea => lockdownArea.activated === true
                      )
                        ? styles.deactivate
                        : styles.disabled
                    }
                    enabled={this.props.lockdownAreas.some(
                      lockdownArea => lockdownArea.activated === true
                    )}
                    onPress={() => {
                      if (this.canAllLockdownAreasBeDeactivated() === true) {
                        const isConfirmed = window.confirm(
                          "Confirm Deactivation for all lockdown areas?"
                        );
                          if (isConfirmed === true) {
                          this.props.writeLog('OperatorID: ' + this.props.operatorId + "Lockdown View--> DeactivateAllArea");
                          this.props.sendDeactivateAllLockdownAreas();
                          alert(
                            "Deactivation command is sent for all lock down areas."
                          );
                        }
                      } else {
                        alert(
                          "Lockdown areas are associated with activated threat levels."
                        );
                      }
                    }}
                  />
                </Link>
              </div>
            </div>
          )}

        <div style={styles.listContainer}>
          {this.props.roleId === ADMIN_ROLE_ID &&
            this.showLockdownAreaList(this.props.lockdownAreas)}
          {this.props.roleId !== ADMIN_ROLE_ID &&
            this.state.lockDownPrivilege &&
            this.state.lockDownPrivilege.SecurityLevel ===
              SECURITY_LEVEL_CODES.FULL_CONTROL &&
            this.showLockdownAreaList(this.props.lockdownAreas)}
        </div>
      </div>
    );
  }
}

const styles = {
  underlineless: {
    textDecoration: "none"
  },
  emptyList: {
    display: "flex",
    flex: 1,
    fontSize: 17,
    fontWeight: "600",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.White,
    height: 100
  },

  disabled: {
    backgroundColor: Colors.Gray,
    color: Colors.White,
    borderWidth: "0px",
    borderStyle: "solid",
    borderColor: Colors.LightGray,
    padding: 5,
    fontSize: 13,
    marginRight: 5,
    borderRadius: 8,
    fontWeight: 600,
    width: 100,
    justifyContent: "center",
    height: 32,
    alignItems: "center"
  },

  activate: {
    backgroundColor: Colors.Red,
    color: Colors.White,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "black",
    padding: 5,
    fontSize: 13,
    marginRight: 5,
    borderRadius: 8,
    fontWeight: 600,
    width: 100,
    justifyContent: "center",
    height: 32,
    alignItems: "center",
    cursor: "pointer",
    boxShadow: "0.5px 0.5px 1px #000000",
    ":active": {
      backgroundColor: Colors.Gray,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "black"
    }
  },
  deactivate: {
    backgroundColor: Colors.DeepGreen,
    color: Colors.White,
    padding: 5,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "black",
    justifyContent: "center",
    height: 32,
    fontWeight: 600,
    borderRadius: 8,
    width: 100,
    fontSize: 13,
    alignItems: "center",
    cursor: "pointer",
    boxShadow: "0.5px 0.5px 1px #000000",
    ":active": {
      backgroundColor: Colors.Gray,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "black"
    }
  },
  container: {
    display: "flex",
    flex: 1,
    height: "auto",
    justifyContent: "center",
    marginRight: 20,
    flexDirection: "column",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: 10,
    borderColor: Colors.Green,
    backgroundColor: Colors.White
  },
  expandContainer: {
    flex: 1,
    height: 30,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 10,
    backgroundColor: Colors.Green,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10
  },
  lockdownallareas: {
    textAlign: "center",
    paddingTop: 2,
    fontSize: 16,
    fontWeight: "700"
  },
  lockdownAreasContainer: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "black",
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 6,
    height: "auto",
    padding: 5,
    backgroundColor: Colors.LightGray
  },
  lockdownStatusButton: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  listContainer: { marginLeft: 10, marginRight: 10, marginBottom: 20 },

  functionText: {
    color: Colors.White,
    textAlign: "left",
    marginLeft: 20,
    fontSize: 20,
    fontWeight: 800
  },
  horizontalLine: {
    height: 1,
    width: "100%",
    borderBottom: "1px solid",
    borderColor: Colors.Purple
  }
};

const mapStateToProps = state => ({
  accessToken: state.token.accessToken,
  lockdownAreas: state.lockdownArea.lockdownAreas,
  threats: state.threat.threats,
  privileges: state.token.privileges,
  operatorId: state.token.operatorId,
  roleId: state.token.roleId,
  operatorId: state.token.operatorId
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendActivateAllLockdownAreas: actions.sendActivateAllLockdownAreas,
      sendDeactivateAllLockdownAreas: actions.sendDeactivateAllLockdownAreas,
      setCurrentPageName: actions.setCurrentPageName,
      fetchAllLockDownArea: actions.fetchAllLockDownArea,
      fetchAllThreatLevels: actions.fetchAllThreatLevels,
      writeLog: actions.writeLog
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(LockdownView)
);

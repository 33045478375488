import { eventChannel, END } from 'redux-saga';
/* eslint import/no-webpack-loader-syntax: "off" */
import 'expose-loader?jQuery!jquery';
/* eslint import/no-webpack-loader-syntax: "off" */
import 'expose-loader?$!jquery';
import $ from 'jquery';
import 'ms-signalr-client';

const createSignalrChannel = (baseURL, liveEventsHub) =>
  eventChannel((emitter) => {
    const connectionL = $.hubConnection(baseURL.replace(/\/$/, ''));

    const proxyL = connectionL.createHubProxy(liveEventsHub);

    proxyL.on('alert', (msg) => {
      emitter(msg);
    });

    connectionL
      .start({
        transport: ['webSockets', 'longPolling']
      })
      .done(() => {
        console.log(`Now connected, connection ID=${connectionL.id}`);
      })
      .fail(() => {
        console.log('Could not connect');
      });

    // The subscriber must return an unsubscribe function
    return () => {
      if (connectionL) {
        connectionL.stop();
      }
    };
  });

export { createSignalrChannel };

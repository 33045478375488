import { all } from 'redux-saga/effects';
import httpSaga from './http';
import refreshSaga from './refresh';
import localStorageSaga from './localstorage';
import eventSaga from './event';
import inactivitySaga from './inactivity';
import unlockMyDoorSaga from './unlock';

export function* mainSaga() {
    yield all([refreshSaga(), httpSaga(), localStorageSaga(), eventSaga(), inactivitySaga(), unlockMyDoorSaga()]);
}

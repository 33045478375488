import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Colors from '../../consts/colors.js';
import HeaderView from '../common/HeaderView';
import FooterView from '../common/FooterView';
import GeneralView from './GeneralView';
import ViewSelectionView from './ViewSelectionView';
import ViewOptionsView from './ViewOptionsView';
import MessageOptionsView from './MessageOptionsView';
import * as actions from '../../actions/index';
import { PAGE_NAMES } from '../../consts/consts';
import ErrorBoundary from '../common/ErrorBoundary';

class ConfigScreen extends Component {
  UNSAFE_componentWillMount() {
    this.props.setCurrentPageName(PAGE_NAMES.CONFIG);
  }
  render() {
    const connectionStatus = this.props.connectStatus.connected
      ? 'Online'
      : 'Offline';
    const statusMessage = this.props.connectStatus.connected
      ? this.props.connectStatus.onLineMessage
      : this.props.connectStatus.offLineMessage;
    return (
      <ErrorBoundary>
        <div
          onClick={() => {
            this.props.resetInactivityTimer();
          }}
          style={styles.container}
        >
          <div styles={styles.header}>
            <HeaderView
              title="CA4K App Config"
              hasBack
              backPath="/home"
              NoRefreshButton
            />
          </div>
          <div style={styles.bodyContainer}>
            <GeneralView />
            <div style={styles.blackLine} />
            <ViewSelectionView />
            <div style={styles.blackLine} />
            <ViewOptionsView />
            <div style={styles.blackLine} />
            <MessageOptionsView />
          </div>
          <div style={styles.footer}>
            <FooterView
              messageList={this.props.generalMessage.messageList}
              status={connectionStatus}
              message={statusMessage}
              lockdown="Sys Normal"
            />
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  blackLine: {
    height: 1,
    width: '98%',
    borderBottom: '1px solid black',
    marginLeft: 10
  },
  bodyContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowY: 'scroll',
    backgroundColor: Colors.White
  },
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    width: '100%'
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%'
  }
};

const mapStateToProps = state => ({
  accessToken: state.token.accessToken,
  currentPage: state.page.currentPage,
  connectStatus: state.connectStatus,
  generalMessage: state.generalMessage
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCurrentPageName: actions.setCurrentPageName,
      resetInactivityTimer: actions.resetInactivityTimer
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(ConfigScreen)
);

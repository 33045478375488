import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import * as Colors from '../../consts/colors';
import ViewSelectionItemView from './ViewSelectionItemView';
import * as actions from '../../actions/index';
import {
    PAGE_NAMES,
    SORTING_ORDER,
    SECURITY_LEVEL_CODES,
    ADMIN_ROLE_ID,
    SCREEN_CODES
} from '../../consts/consts';


class ViewSelectionView extends Component {
  constructor(props) {
    super(props);
      this.showViewSelectionList = this.showViewSelectionList.bind(this);

      const badgeHoldersInPrivilege = this.props.privileges.find(
          privilege => privilege.ScreenID === SCREEN_CODES.BADGEHOLDERIN
      );

      let doorPrivilege = this.props.privileges.find(
          privilege => privilege.ScreenID === SCREEN_CODES.MANUAL_DOORS
      );

      let relayPrivilege = this.props.privileges.find(
          privilege => privilege.ScreenID === SCREEN_CODES.MANUAL_RELAY
      );

      if (typeof doorPrivilege === 'undefined') {
          doorPrivilege = null;
      }

      if (typeof relayPrivilege === 'undefined') {
          relayPrivilege = null;
      }
      const lockDownPrivilege = this.props.privileges.find(
          privilege => privilege.ScreenID === SCREEN_CODES.LOCKDOWN
      );

      const personnelPrivilege = this.props.privileges.find(
          privilege => privilege.ScreenID === SCREEN_CODES.PERSONNEL
      );

      const scheduleChangesPrivilege = this.props.privileges.find(
          privilege => privilege.ScreenID === SCREEN_CODES.SCHEDULECHANGES
      );

      const statusPrivilege = this.props.privileges.find(
          privilege => privilege.ScreenID === SCREEN_CODES.STATUS
      );

      const threatLevelPrivilege = this.props.privileges.find(
          privilege => privilege.ScreenID === SCREEN_CODES.THREATLEVEL
      );

      this.state = { badgeHoldersInPrivilege, threatLevelPrivilege, statusPrivilege, scheduleChangesPrivilege, personnelPrivilege, lockDownPrivilege, doorPrivilege, relayPrivilege };
     
   

  }

  showViewSelectionList(allViewSelections) {
      const viewSelectionList = allViewSelections.map((viewSelection, index) => {
          if (index === 0 && this.props.roleId !== ADMIN_ROLE_ID && (this.state.doorPrivilege === null || typeof (this.state.doorPrivilege) === 'undefined' || this.props.menuPriviledges.MyDoorMenuEnabled === false)) {
              return (<div></div>);
          }

          if (index === 1 && this.props.roleId !== ADMIN_ROLE_ID && (this.state.lockDownPrivilege === null || typeof (this.state.lockDownPrivilege) === 'undefined' || this.props.menuPriviledges.LockDownAreaMenuEnabled === false)) {
              return (<div></div>);
          }

          if (index === 2 && this.props.roleId !== ADMIN_ROLE_ID && (this.state.threatLevelPrivilege === null || typeof (this.state.threatLevelPrivilege) === 'undefined' || this.props.menuPriviledges.ThreatLevelMenuEnabled === false)) {
              return (<div></div>);
          }
          if (index === 3 && this.props.roleId !== ADMIN_ROLE_ID && (this.state.statusPrivilege === null || typeof (this.state.statusPrivilege) === 'undefined' || this.props.menuPriviledges.StatusMenuEnabled === false)) {
              return (<div></div>);
          }
          if (index === 5 && this.props.roleId !== ADMIN_ROLE_ID && (this.state.personnelPrivilege === null || typeof (this.state.personnelPrivilege) === 'undefined' || this.props.menuPriviledges.PersonnelMenuEnabled === false)) {
              return (<div></div>);
          }
          if (index === 4 && this.props.roleId !== ADMIN_ROLE_ID && (this.state.relayPrivilege === null || typeof (this.state.relayPrivilege) === 'undefined' || this.props.menuPriviledges.ControlMenuEnabled === false)) {
              return (<div></div>);
          }

          if (index === 6 && this.props.roleId !== ADMIN_ROLE_ID && (this.state.badgeHoldersInPrivilege === null || typeof (this.state.badgeHoldersInPrivilege) === 'undefined' || this.props.menuPriviledges.BadgeHoldersInMenuEnabled === false)) {
              return (<div></div>);
          }
          if (index === 7 && this.props.roleId !== ADMIN_ROLE_ID && (this.state.scheduleChangesPrivilege === null || typeof (this.state.scheduleChangesPrivilege) === 'undefined' || this.props.menuPriviledges.ScheduleChangesMenuEnabled === false)) {
              return (<div></div>);
          }


       return   (
              <ViewSelectionItemView
                  key={index}
                  name={viewSelection.name}
                  selected={viewSelection.status}
                  itemList={viewSelection.itemList}
                  handleViewSelection={() => {
                      const viewSelectionInState = this.props.tmpViewSelection;
                      if (index === 0) {
                          this.props.toggleMyDoorsMenu(viewSelectionInState[index].status);
                          //this.props.addMessage('System Settings Updated [ My Doors ]');
                      } else if (index === 1) {
                          this.props.toggleLockDownMenu(viewSelectionInState[index].status);
                          //this.props.addMessage('System Settings Updated [ Lockdown ]');
                      } else if (index === 2) {
                          this.props.toggleThreatLevelMenu(
                              viewSelectionInState[index].status
                          );
                          //this.props.addMessage('System Settings Updated [ Threat Level ]');
                      } else if (index === 3) {
                          this.props.toggleStatusMenu(viewSelectionInState[index].status);
                          //this.props.addMessage('System Settings Updated [ Status ]');
                      } else if (index === 4) {
                          this.props.toggleControlMenu(viewSelectionInState[index].status);
                          //this.props.addMessage('System Settings Updated [ Control ]');
                      } else if (index === 5) {
                          this.props.togglePersonnelMenu(viewSelectionInState[index].status);
                          //this.props.addMessage('System Settings Updated [ Personnel ]');
                      } else if (index === 6) {
                          this.props.toggleBadgeHoldersInMenu(
                              viewSelectionInState[index].status
                          );
                          //this.props.addMessage('System Settings Updated [ Badge Holders In ]');
                      } else if (index === 7) {
                          this.props.toggleScheduledChangesMenu(
                              viewSelectionInState[index].status
                          );
                          //this.props.addMessage('System Settings Updated [ Scheduled Changes ]');
                      }
                  }}
              />
          )
      });



    return <div>{viewSelectionList}</div>;
  }

  render() {
    return (
      <div style={styles.container}>
        <div style={styles.titleRow}>
          <FontAwesomeIcon icon={faCircle} size="xs" color={Colors.Black} />
          <p style={styles.viewSelection}>View Selections</p>
        </div>
        {this.props.tmpViewSelection &&
          this.showViewSelectionList(this.props.tmpViewSelection)}
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    height: 'auto',
    justifyContent: 'flex-start',
    marginRight: 20,
    flexDirection: 'column',
    backgroundColor: Colors.White
  },
  viewSelection: { marginLeft: 10, fontSize: 17 },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10
  },
  blackLine: { height: 1, width: '100%', borderBottom: '2px solid black' }
};

const mapStateToProps = state => ({
  //viewSelection: state.settings.viewSelection,
  tmpViewSelection: state.settings.tmpViewSelection,
    badges: state.token.badges,
    roleId: state.token.roleId,
    privileges: state.token.privileges,
        menuPriviledges: state.priviledge
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleMyDoorsMenu: actions.toggleMyDoorsMenu,
      toggleLockDownMenu: actions.toggleLockDownMenu,
      toggleThreatLevelMenu: actions.toggleThreatLevelMenu,
      toggleStatusMenu: actions.toggleStatusMenu,
      toggleControlMenu: actions.toggleControlMenu,
      togglePersonnelMenu: actions.togglePersonnelMenu,
      toggleBadgeHoldersInMenu: actions.toggleBadgeHoldersInMenu,
      toggleScheduledChangesMenu: actions.toggleScheduledChangesMenu,
      toggleViewSelectionSubItem: actions.toggleViewSelectionSubItem
      //addMessage: actions.addMessage
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(ViewSelectionView)
);

import React from "react";
import Radium from "radium";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Colors from "../../consts/colors";
import * as actions from "../../actions/index";

const LockdownAreaItemView = props => (
  <div
    style={
      props.canLockdownBeRemoved === true
        ? styles.container
        : styles.InactiveContainer
    }
    onClick={() => {
      if (props.lockdownStatus === true) {
        if (props.canLockdownBeRemoved === true) {
          const isConfirmed = window.confirm(
            `Confirm lockdown removal of ${props.areaName}?`
          );

          if (isConfirmed === true) {
            props.sendDeactivateTheLockdownArea(props.areaNo);
            alert("Deactivate command is sent.");
          }
        } else {
          alert(
            `Lockdown Area ${props.areaNo} is associated with an activated threat level.`
          );
        }
      } else if (props.lockdownStatus === false) {
        const isConfirmed = window.confirm(`Confirm activate ${props.areaName}?`);

        if (isConfirmed === true) {
          props.sendActivateTheLockdownArea(props.areaNo);
          alert("Activate command is sent.");
        }
      }
    }}
  >
    <div style={styles.areaName}>{props.areaName}</div>
    {props.lockdownStatus === true && (
      <div style={styles.inLockDown}>In Lockdown</div>
    )}
  </div>
);

const styles = {
  container: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "black",
    display: "flex",
    flex: 1,
    height: 36,
    marginTop: 15,
    marginRight: 10,
    marginLeft: 10,
    flexDirection: "row",
    borderRadius: 6,
    backgroundColor: Colors.LightGrayishViolet,
    cursor: "pointer",
    boxShadow: "0.5px 0.5px 0.5px #000000",
    ":active": {
      backgroundColor: Colors.Gray,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "black"
    }
  },
  InactiveContainer: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "black",
    display: "flex",
    flex: 1,
    height: 36,
    marginTop: 15,
    marginRight: 10,
    marginLeft: 10,
    flexDirection: "row",
    borderRadius: 6,
    backgroundColor: Colors.Gray,
    boxShadow: "0.5px 0.5px 0.5px #000000",
    ":active": {
      backgroundColor: Colors.Gray,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "black"
    }
  },
  areaName: {
    fontSize: 14,
    height: 30,
    flex: 1,
    alignSelf: "center",
    alignItems: "center",
    paddingLeft: 10,
    display: "flex",
    fontWeight: "bold"
  },
  inLockDown: {
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    fontSize: 14,
    width: 90,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: 5,
    paddingRight: 5,
    color: Colors.White,
    textAlign: "center",
    fontWeight: "600",

    backgroundColor: Colors.Red
  }
};

const mapStateToProps = state => ({
  accessToken: state.token.accessToken,
  lockdownAreas: state.lockdownArea.lockdownAreas
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendActivateTheLockdownArea: actions.sendActivateTheLockdownArea,
      sendDeactivateTheLockdownArea: actions.sendDeactivateTheLockdownArea
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(LockdownAreaItemView)
);

import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import * as Colors from '../../consts/colors';
import ButtonView from '../common/ButtonView';
import * as actions from '../../actions/index';
import ModalView from '../common/ModalView';
import * as workerTimers from 'worker-timers';

class DoorItemView extends Component {
    constructor(props) {
        super(props);
        this.state = { unlockPressed: false };
        this.doUnlock = this.doUnlock.bind(this);
        this.checkIfPINCommonCodeEnabled = this.checkIfPINCommonCodeEnabled.bind(this);
        this.props.resetPINCodeEnabledStatus();
        this.props.resetCommonCodeEnabledStatus();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.statusDescription !== this.props.statusDescription || nextProps.cancelUnlock === true) {
            this.setState({
                unlockPressed: false
            }, () => { console.log(""); });
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.statusDescription !== this.props.statusDescription) {
            this.setState({
                unlockPressed: false
            }, () => { console.log(""); });
        }
    }

    checkIfPINCommonCodeEnabled() {
        this.props.resetPINCOmmonCodeNotSCheduled();
        this.props.resetCancelUnlockPressed();
        const {
            accessToken,
            panelNo,
            doorNo,
            doorCAObjectID,
            doorStatus,
            doorName,
            iskeypadEnabled,
            cardOnlyTimeSchedule,
            noTransactionOnValid,
            tmpSelectedBadgeOfUser,
            isPINCodeEnabled,
            isCommonCodeEnabled
        } = this.props;

        const doorInfo = {
            accessToken,
            panelNo,
            doorNo,
            locked: doorStatus,
            doorName,
            doorCAObjectID,
            noTransactionOnValid,
            isPINCodeEnabled,
            isCommonCodeEnabled,
            badge:
                tmpSelectedBadgeOfUser && tmpSelectedBadgeOfUser.Badge
                    ? tmpSelectedBadgeOfUser.Badge
                    : 0,
            facility:
                tmpSelectedBadgeOfUser && tmpSelectedBadgeOfUser.Facility
                    ? tmpSelectedBadgeOfUser.Facility
                    : 0,
            requestStatus: this.props.requestStatus,
            wlockRequestStatus: this.props.requestWirelessLockStatus
           
        };

        this.props.writeLog('OperatorID: ' + this.props.operatorId + " MyDoorItem --> :  DoorNo: " + doorNo + ", DoorName:" + doorName + ", panelNo: " + panelNo + ", isPINCodeEnabled: " + isPINCodeEnabled + "isCommonCodeEnable: " + isCommonCodeEnabled + ", requestStatus: " + this.props.requestStatus + ", wlockStatus: " + this.props.wlockRequestStatus + "NoTransactionOnValid: " + noTransactionOnValid + "doorStatus Before Unlock:" + doorStatus);

        this.props.setCurrentOperatingDoor(doorInfo);
        this.props.checkIfPINCommonCodeEnabled(doorInfo);

        if (this.props.isPINCommonCodeNotScheduled !== true) {
            this.setState({
                unlockPressed: true
            });
        } else {
            this.props.resetPINCOmmonCodeNotSCheduled();
        }

        setTimeout(() => {
            //Start the timer
            this.setState({ unlockPressed: false }); // after 10 seconds state updates
        }, 10 * 1000);

    }

    doUnlock(doorInfo) {
        this.props.writeLog('OperatorID: ' + this.props.operatorId + " MyDoorItem --> CurrentOperatingDoor:  " + JSON.stringify(this.props.currentOperatingDoor));
        this.props.sendUnlockMyDoor(doorInfo, this.props.baseURL);
    }


    render() {
        return (
            <div
                style={
                    this.props.currentIndex % 2 === 0
                        ? styles.alterBackgroundContainer
                        : styles.container
                }
            >
                <div style={styles.titleRow}>
                    <div style={styles.dot}>
                        <FontAwesomeIcon icon={faCircle} size="xs" color={Colors.Black} />
                    </div>
                    <p style={styles.doorName}>{this.props.doorName}</p>
                </div>
                <div />
                {this.props.tmpSelectedBadgeOfUser &&
                    this.props.tmpSelectedBadgeOfUser.Badge && (
                        <div style={styles.buttonRow}>
                            <Link style={styles.underlineless} to="/doors">
                                <ButtonView
                                    label="Unlock"
                                    instyle={styles.unlock}
                                     onPress={this.checkIfPINCommonCodeEnabled}
                                />
                            </Link>

                            {this.state.unlockPressed === false && (
                                <div style={doorStatusStyle(this.props.statusDescription)}>
                                    <p>{checkDoorStatus(this.props.statusDescription)}</p>
                                </div>
                            )}

                            {this.state.unlockPressed === true && (
                                <div style={styles.doorStatusBeingUnlocked}>
                                    <p>Door Being Unlocked...</p>
                                </div>
                            )}
                        </div>
                    )}
                <div style={styles.line} />

            </div>
        );
    }
}

const doorStatusStyle = statusDescription => {
    switch (statusDescription) {
        case 'DOOR is Secure': //2
        case 'Manual Locked': //6
        case 'Door Secure.':
            return styles.doorStatusLocked;

        case 'Forced Door': //3
        case 'Free Access': //4
        case 'Manual Unlocked': //5
        case 'Bypass Active': //7
        case 'DOOR is Open': //10
        case 'Free Access Open': //11
        case 'Disabled and Open': //22
        case 'Door Unlocked':
            return styles.doorStatusUnlocked;
        default:
            return styles.doorStatusUnknown;
    }
};

const checkDoorStatus = statusDescription => {
    //console.log('Status description ....', statusDescription);
    switch (statusDescription) {
        case 'DOOR is Secure': //2
        case 'Manual Locked': //6
        case 'Door Secure.':
            return 'Door Locked';

        case 'Forced Door': //3
        case 'Free Access': //4
        case 'Manual Unlocked': //5
        case 'Bypass Active': //7
        case 'DOOR is Open': //10
        case 'Free Access Open': //11
        case 'Disabled and Open': //22
        case 'Door Unlocked':
            return 'Door Unlocked';
        default:
            return 'Unknown';
    }
};

const styles = {
    container: {
        display: 'flex',
        flex: 1,
        height: 100,
        //marginBottom: 3,
        //marginTop: 3,
        flexDirection: 'column',
        backgroundColor: Colors.White,
        borderWidth: '0.0px',
        borderStyle: 'solid',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: 1,
        borderBottomLeftRadius: 1,
        borderColor: Colors.White
    },
    alterBackgroundContainer: {
        display: 'flex',
        flex: 1,
        height: 100,
        //marginBottom: 3,
        //marginTop: 3,
        flexDirection: 'column',
        backgroundColor: Colors.VeryPaleYellow,
        borderWidth: '0.0px',
        borderStyle: 'solid',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: 1,
        borderBottomLeftRadius: 1,
        borderColor: Colors.White
    },
    titleRow: {
        height: 35,
        flex: 1,
        paddingLeft: 10,
        paddingRight: 10,
        justifyContent: 'flex-start',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row'
    },
    dot: {
        width: 12,
        textAlign: 'center'
    },
    doorStatusUnlocked: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 3,
        marginBottom: 3,
        height: 'auto',
        alignSelf: 'flex-end',
        width: 120,
        textAlign: 'right',
        fontSize: 14,
        color: Colors.DeepGreen,
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold'
    },
    doorStatusUnknown: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 3,
        marginBottom: 3,
        height: 'auto',
        alignSelf: 'flex-end',
        width: 120,
        textAlign: 'right',
        fontSize: 14,
        color: Colors.Black,
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold'
    },

    doorStatusBeingUnlocked: {
        marginLeft: 8,
        marginRight: 8,
        marginTop: 3,
        marginBottom: 3,
        height: 'auto',
        alignSelf: 'flex-end',
        textAlign: 'right',
        width: 155,
        fontSize: 14,
        color: Colors.LightViolet,
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold'
    },
    doorStatusLocked: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 3,
        marginBottom: 3,
        height: 'auto',
        alignSelf: 'flex-end',
        width: 100,
        textAlign: 'right',
        fontSize: 14,
        color: Colors.Red,
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold'
    },
    unlock: {
        backgroundColor: Colors.White,
        color: Colors.DeepGreen,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.Green,
        borderRadius: 6,
        padding: 5,
        marginRight: 5,
        height: 35,
        width: 70,
        fontSize: 12,
        cursor: 'pointer',
        boxShadow: '1px 0.5px 1px #000000',
        ':active': {
            backgroundColor: Colors.Gray
        }
    },
    lock: {
        backgroundColor: Colors.White,
        color: Colors.Red,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.Red,
        borderRadius: 6,
        padding: 5,
        marginRight: 5,
        width: 70,
        height: 35,
        fontSize: 12,
        cursor: 'pointer',
        boxShadow: '0.5px 0.5px 1px #000000',
        ':active': {
            backgroundColor: Colors.Gray
        }
    },
    unlockhold: {
        backgroundColor: Colors.White,
        color: Colors.DeepGreen,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.Green,
        borderRadius: 6,
        padding: 5,
        marginRight: 5,
        fontSize: 12,
        height: 35,
        cursor: 'pointer',
        boxShadow: '1px 0.5px 1px #000000',
        ':active': {
            backgroundColor: Colors.Gray
        }
    },

    doorName: {
        flex: 1,
        alignSelf: 'strech',
        marginLeft: 5,
        marginRight: 2,
        fontSize: 14,
        fontWeight: 700,
        textAlign: 'bottom'
    },
    buttonRow: {
        paddingLeft: 10,
        paddingRight: 10,
        height: 50,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between'
    },
    dottedLine: {
        height: 1,
        width: '95%',
        borderBottom: '1px dotted black'
    },
    line: {
        height: 1,
        width: '100%',
        borderBottom: '1px solid',
        borderColor: Colors.LightGrayishViolet
    }
};

const mapStateToProps = state => ({
    accessToken: state.token.accessToken,
    baseURL: state.appConfig.baseURL,
    tmpSelectedBadgeOfUser: state.token.tmpSelectedBadgeOfUser,
    viewSelection: state.settings.viewSelection,
    currentOperatingDoor: state.door.currentOperatingDoor,
    requestStatus: state.page.refreshStatus,
    wlock: state.door.requestWirelessLockStatus,
    isPINCodeEnabled: state.door.isPINCodeEnabled,
    isCommonCodeEnabled: state.door.isCommonCodeEnabled,
    isPINCommonCodeNotScheduled: state.door.isPINCommonCodeNotScheduled,
    cancelUnlock: state.door.cancelUnlock,
    operatorId: state.token.operatorId
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            sendUnlockMyDoor: actions.sendUnlockMyDoor,
            setCurrentOperatingDoor: actions.setCurrentOperatingDoor,
            fetchAccessReaderListByBadge: actions.fetchAccessReaderListByBadge,
            checkIfPINCommonCodeEnabled: actions.checkIfPINCommonCodeEnabled,
            resetPINCodeEnabledStatus: actions.resetPINCodeEnabledStatus,
            setPINCodeEnabled: actions.setPINCodeEnabled,
            resetCommonCodeEnabledStatus: actions.resetCommonCodeEnabledStatus,
            resetPINCOmmonCodeNotSCheduled: actions.resetPINCOmmonCodeNotSCheduled,
            resetCancelUnlockPressed: actions.resetCancelUnlockPressed,
            writeLog: actions.writeLog
        },
        dispatch
    );

export default Radium(
    connect(mapStateToProps, mapDispatchToProps)(DoorItemView)
);

import React, { Component } from 'react';
import Radium from 'radium';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/index';

import FunctionItemLink from '../common/FunctionItemLink';
import {
    PAGE_NAMES,
    SORTING_ORDER,
    SECURITY_LEVEL_CODES,
    ADMIN_ROLE_ID,
    SCREEN_CODES
} from '../../consts/consts';


class BodyView extends Component {
    constructor(props) {
        super(props);
        const { tmpViewSelection, tmpKeepMyDoorExpanded } = this.props;
        this.props.checkPriviledges(this.props.privileges, this.props.roleId);
        //console.log('keepDoorExpanded', tmpKeepMyDoorExpanded);
        //if (tmpViewSelection[0].status && tmpKeepMyDoorExpanded) {
        //    return <Redirect to="/doors" />;
        //}

        const badgeHoldersInPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.BADGEHOLDERIN
        );

        let doorPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.MANUAL_DOORS
        );

        let relayPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.MANUAL_RELAY
        );

        if (typeof doorPrivilege === 'undefined') {
            doorPrivilege = null;
        }

        if (typeof relayPrivilege === 'undefined') {
            relayPrivilege = null;
        }
        const lockDownPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.LOCKDOWN
        );

        const personnelPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.PERSONNEL
        );

        const scheduleChangesPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.SCHEDULECHANGES
        );

        const statusPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.STATUS
        );

        const threatLevelPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.THREATLEVEL
        );

        this.state = { badgeHoldersInPrivilege, threatLevelPrivilege, statusPrivilege, scheduleChangesPrivilege, personnelPrivilege, lockDownPrivilege, doorPrivilege, relayPrivilege, tmpViewSelection, tmpKeepMyDoorExpanded };

    }


    render() {
        if (this.props.tmpViewSelection[0] && this.props.tmpViewSelection[0].status && this.props.tmpKeepMyDoorExpanded === true) {
            return <Redirect to="/doors" />;
        }
        return (
            <div
                onClick={() => {
                    this.props.resetInactivityTimer();
                }}
                style={styles.container}
            >
                {this.state.tmpViewSelection[0].status && !(this.props.roleId !== ADMIN_ROLE_ID && (this.state.doorPrivilege === null || typeof (this.state.doorPrivilege) === 'undefined')) && this.props.menuPriviledges.MyDoorMenuEnabled === true && <FunctionItemLink Title="My Doors" link="/doors" />}
                {this.state.tmpViewSelection[1].status && !(this.props.roleId !== ADMIN_ROLE_ID && (this.state.lockDownPrivilege === null || typeof (this.state.lockDownPrivilege) === 'undefined')) && this.props.menuPriviledges.LockDownAreaMenuEnabled === true && <FunctionItemLink Title="Lockdown" link="/lockdown" />}
                {this.state.tmpViewSelection[2].status && !(this.props.roleId !== ADMIN_ROLE_ID && (this.state.threatLevelPrivilege === null || typeof (this.state.threatLevelPrivilege) === 'undefined')) && this.props.menuPriviledges.ThreatLevelMenuEnabled === true && <FunctionItemLink Title="Threat Levels" link="/threatlevel" />}
                {this.state.tmpViewSelection[3].status && !(this.props.roleId !== ADMIN_ROLE_ID && (this.state.statusPrivilege === null || typeof (this.state.statusPrivilege) === 'undefined')) && this.props.menuPriviledges.StatusMenuEnabled === true &&<FunctionItemLink Title="Status" link="/status" />}
                {this.state.tmpViewSelection[4].status && !(this.props.roleId !== ADMIN_ROLE_ID && (this.state.doorPrivilege === null || typeof (this.state.doorPrivilege) === 'undefined')) && this.props.menuPriviledges.ControlMenuEnabled === true &&<FunctionItemLink Title="Control" link="/control" />}
                {this.state.tmpViewSelection[5].status && !(this.props.roleId !== ADMIN_ROLE_ID && (this.state.personnelPrivilege === null || typeof (this.state.personnelPrivilege) === 'undefined')) && this.props.menuPriviledges.PersonnelMenuEnabled === true && <FunctionItemLink Title="Personnel" link="/personnel" />}
                {this.state.tmpViewSelection[6].status && !(this.props.roleId !== ADMIN_ROLE_ID && (this.state.badgeHoldersInPrivilege === null || typeof (this.state.badgeHoldersInPrivilege) === 'undefined')) && this.props.menuPriviledges.BadgeHoldersInMenuEnabled === true &&(
                    <FunctionItemLink Title="Badge Holders In" link="/badgeholdersin" />
                )}
                {this.state.tmpViewSelection[7].status && !(this.props.roleId !== ADMIN_ROLE_ID && (this.state.scheduleChangesPrivilege === null || typeof (this.state.scheduleChangesPrivilege) === 'undefined')) && this.props.menuPriviledges.ScheduleChangesMenuEnabled === true &&(
                    <FunctionItemLink Title="Scheduled Changes" link="/scheduledchanges" />
                )}




            </div>
        );
    }

}

const styles = {
    container: {
        display: 'flex',
        padding: 5,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflow: 'auto'
    }
};

const mapStateToProps = state => ({
    tmpViewSelection: state.settings.tmpViewSelection,
    tmpKeepMyDoorExpanded: state.settings.tmpKeepMyDoorExpanded,
    roleId: state.token.roleId,
    privileges: state.token.privileges,
    menuPriviledges: state.priviledge

});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            //addMessage: actions.addMessage,
            resetInactivityTimer: actions.resetInactivityTimer,
            checkPriviledges: actions.checkPriviledges
        },
        dispatch
    );

export default Radium(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(BodyView)
);

import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link ,Redirect } from 'react-router-dom';
import * as actions from '../../actions/index';
import * as Colors from '../../consts/colors';
import ButtonView from '../common/ButtonView';

class LogInView extends Component {
    constructor(props) {
        super(props);
        const debugMode = false;
        this.state = {
            username: '',
            password: '',
            remember: false,
            entryPinChecked: true,
            baseURL: debugMode
                ? 'http://localhost:53078/'
                : `${window.location.href.split('/#/login')[0]}/api/`,
            appBaseURL: `${window.location.href.split('/#/login')[0]}/`
        };
        this.handleUserName = this.handleUserName.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRemember = this.handleRemember.bind(this);
        this.handleEntryPinChecked = this.handleEntryPinChecked.bind(this);
        this.props.resetErrorMessage();
        this.props.setBaseURL(this.state.baseURL);
        this.props.setAppBaseURL(this.state.appBaseURL);
        this.props.checkSessionTimeOutStatus();
        this.props.resetChangeOperatorPasswordStatus();
        this.props.writeLog("LogInView -->"+ "Initialize view");
    }

    UNSAFE_componentWillMount() {
        this.props.getRememberUserName();
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.accessToken.length > 0 && props.changePasswordPressedState === false) {
            this.props.setUserInfo(this.state.username, this.state.password);
        }

        if (props.user.rememberedUserName !== '' && !this.state.remember) {
            this.setState({
                ...this.state,
                username: props.user.rememberedUserName,
                remember: true
            });
        }

        if (props.error) {
            if (props.error.toString().includes('TypeError')) {
                alert('Wifi is turned off or Server is down!');
                props.resetErrorMessage();
                return;
            }

            alert(props.error);
            props.resetErrorMessage();
            return;
        }
    }
    handleUserName(event) {
        this.setState({ ...this.state, username: event.target.value });
        this.props.setUserInfo(event.target.value, this.state.password);
    }

    handlePassword(event) {
        this.setState({ ...this.state, password: event.target.value });
    }

    handleRemember(event) {
        console.log(event.target.checked);
        this.setState({ ...this.state, remember: event.target.checked });
    }

    handleEntryPinChecked(event) {
        event.preventDefault();
        this.setState({ entryPinChecked: !this.state.entryPinChecked });
    }

    handleSubmit(event) {
        const userInfo = {
            grant_type: 'password',
            userName: this.state.username,
            password: this.state.password,
            remember: this.state.remember
        };

        event.preventDefault();
        this.props.resetErrorMessage();

        if (this.state.username.length > 0 && this.state.password.length > 0) {
            this.props.logIN(userInfo, this.props.baseURL);
        } else {
            if (
                this.state.username.length === 0 &&
                this.state.password.length === 0
            ) {
                alert('Please provide username and password!');
                return;
            }
            if (this.state.username.length === 0) {
                alert('Please provide username!');
                return;
            }

            if (this.state.password.length === 0) {
                alert('Please provide password!');
                return;
            }
        }

        return false;
    }

    render() {
        if (this.props.accessToken.length > 0) {
            //    this.props.checkSessionTimeOutStatus();   
            if (this.props.passwordRequiresUpdate === true || this.props.changePasswordPressedState === true) {
                this.props.resetChangePasswordPressed();
                return <Redirect to="/changepassword" />;
            }

            if (
                this.props.appPinEntryChecked &&
                this.props.tmpAppPinSessionChecked &&
                this.props.settingsPin !== '####'
            ) {
                return <Redirect to="/pincheck" />;
            }
            return <Redirect to="/home" />;
        }

        return (
            <div style={styles.container}>
                <form onSubmit={this.handleSubmit}>
                    <input
                        type="text"
                        tabIndex={0}
                        placeholder="Username"
                        name="username"
                        value={this.state.username}
                        onChange={this.handleUserName}
                        style={styles.username}
                    />
                    <br />
                    <br />
                    <input
                        type="password"
                        tabIndex={0}
                        placeholder="Password"
                        name="password"
                        value={this.state.password}
                        onChange={this.handlePassword}
                        style={styles.password}
                    />
                    <br />
                    <Link onClick={() => {

                        this.props.setUserInfo(this.state.username, this.state.password)

                        //login
                        this.props.resetErrorMessage();
                        const userInfo = {
                            grant_type: 'password',
                            userName: this.state.username,
                            password: this.state.password,
                            remember: this.state.remember
                        };
                        if (this.state.username.length > 0 && this.state.password.length > 0) {
                            this.props.changePasswordPressed();    
                            this.props.logIN(userInfo, this.props.baseURL);
                          
                           
                        } else {
                         
                            if (
                                this.state.username.length === 0 &&
                                this.state.password.length === 0
                            ) {
                                alert('Please provide username and password!');
                                return;
                            }
                            if (this.state.username.length === 0) {
                                alert('Please provide username!');
                                return;
                            }

                            if (this.state.password.length === 0) {
                                alert('Please provide password!');
                                return;
                            }

                        }
                    }} style={styles.underlineless} to={this.state.username && this.state.password && this.state.username.length > 0 && this.state.password.length > 0 && this.props.accessToken.length > 0 ? "/changepassword" : ""}>
                        <label  style={{ color: Colors.White, fontSize: 12, marginTop: 1 }}> Change password </label>
                    </Link>
                    <div style={styles.remeberdiv}>
                        <input
                            type="checkbox"
                            name="remember"
                            checked={this.state.remember}
                            onChange={this.handleRemember}
                            style={styles.remeberCheck}
                        />
                        <label>Remember me</label>
           
                    </div>
                    <br />
                    <input
                        type="submit"
                        style={styles.submitButton}
                        value="Log In"
                        tabIndex={0}
                    />
                   </form>
            </div>
        );
    }
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 1000,
        justifyContent: 'center',
        flex: 1
    },
    username: {
        padding: 8,
        width: 200,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray
    },
    password: {
        padding: 8,
        width: 200,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray
    },
    remeberdiv: {
        padding: 2,
        paddingLeft: 2,
        display: 'flex',
        alignItems:'center',
        color: Colors.White,
    },

    remeberCheck: {
        marginRight: 5,
    },

    submitButton: {
        backgroundColor: Colors.Purple,
        borderColor: Colors.Black,
        borderStyle: 'solid',
        borderWidth: 0.5,
        color: Colors.Green,
        width: '100%',
        height: 40,
        padding: 3,
        fontSize: 16,
        alignSelf: 'center',
        fontWeight: 900,
        cursor: 'pointer',
        boxShadow: '1px 1px 2px #000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: 0.5,
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    changePassword: {
        backgroundColor: Colors.Purple,
        borderColor: Colors.Black,
        borderStyle: 'solid',
        borderWidth: 0.5,
        color: Colors.Green,
        width: '100%',
        height: 40,
        padding: 3,
        fontSize: 16,
        alignSelf: 'center',
        fontWeight: 900,
        cursor: 'pointer',
        marginTop: 10,
        boxShadow: '1px 1px 2px #000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: 0.5,
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },

    underlineless: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: 24,
        display: 'flex',
        ':link': {
            color: Colors.White
        },
        ':hover': {
            color: Colors.White
        }
    },
};

const mapStateToProps = state => ({
    loading: state.loading.login,
    accessToken: state.token.accessToken,
    baseURL: state.appConfig.baseURL,
    msg: state.message.msg,
    error: state.error.msg,
    user: state.user,
    appPinEntryChecked: state.pin.appPinEntryChecked,
    tmpAppPinSessionChecked: state.pin.tmpAppPinSessionChecked,
    appPinSessionChecked: state.pin.appPinSessionChecked,
    settingsPin: state.pin.settingsPin,
    passwordRequiresUpdate: state.token.passwordRequiresUpdate,
    changePasswordPressedState: state.user.changePasswordPressed
   
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            logIN: actions.logIN,
            setUserInfo: actions.setUserInfo,
            resetErrorMessage: actions.resetErrorMessage,
            getRememberUserName: actions.getRememberUserName,
            setBaseURL: actions.setBaseURL,
            setAppBaseURL: actions.setAppBaseURL,
            checkSessionTimeOutStatus: actions.checkSessionTimeOutStatus,
            changePasswordPressed: actions.changePasswordPressed,
            resetChangePasswordPressed: actions.resetChangePasswordPressed,
            resetChangeOperatorPasswordStatus: actions.resetChangeOperatorPasswordStatus,
            writeLog: actions.writeLog

        },
        dispatch
    );
export default Radium(connect(mapStateToProps, mapDispatchToProps)(LogInView));

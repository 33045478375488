import React from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ButtonView from './ButtonView';
import * as Colors from '../../consts/colors';
import * as actions from '../../actions/index';


class LogView extends React.Component {
  componentDidMount() {
    if (this.props.onClose) {
      window.addEventListener('keydown', this.listenKeyboard.bind(this), true);
    }
  }

  componentWillUnmount() {
    if (this.props.onClose) {
      window.removeEventListener('keydown', this.listenKeyboard.bind(this), true);
    }
  }

  onOverlayClick() {
    //if (this.props.shouldCloseOnOverlayClick && this.props.shouldCloseOnOverlayClick === true) {
    console.log(this.props);
      //  this.props.onClose();
      this.props.hideLog();
    //}
  }

  onDialogClick(event) {
    event.stopPropagation();
  }

  listenKeyboard(event) {
    if (event.key === 'Escape' || event.keyCode === 27) {
   //   this.props.onClose();
        this.props.hideLog();
    }
  }

  render() {
    const overlayStyle = this.props.overlayStyle ? this.props.overlayStyle : {};
    const contentStyle = this.props.contentStyle ? this.props.contentStyle : {};
      const dialogStyle = this.props.dialogStyle ? this.props.dialogStyle : {};
      const log = this.props.log.split('\n').map((item, i) => <p style={{ backgroundColor: Colors.Cream, alignItems: 'left', width: 320}} key={i}>{item}</p>); 
    return (
     
        <div className="modal-overlay-div" style={overlayStyle} >
        <div   className="modal-content-div"        style={contentStyle}  >
                <div className="modal-dialog-div" style={styles.dialogStyle}>
                    <div style={{ backgroundColor: Colors.DarkModerateViolet, display: 'flex', justifyContent: 'center', width: '100%', color: Colors.White , marginTop:10}}><p>Debug Log</p></div>
                    <div style={{ whiteSpace: 'pre-wrap', flexDirection: 'column', overflowX: "auto", display: 'flex', alignContent:'left', alignItems: 'left', paddingTop: 0, justifyContent: 'left', margin: 10, fontSize: 9 }}>
                                   {log}
                        </div>
                 
                    <div style={styles.buttonContainer}>
                        <ButtonView instyle={styles.cancel} label="Cancel" onPress={() => {
                            this.props.hideLog();
                           
                        }} />

                        <ButtonView instyle={styles.clearLog} label="clear log" onPress={() => { this.props.clearLog() }} />
                   
                </div>
                       
        </div> 
            </div>
        </div>
    );
  }
}

const styles = {
    underlineless: {
        textDecoration: 'none'
    },
    container: {
        display: 'flex',
        backgroundColor: Colors.Blue,
        height: 600,
        flexDirection: 'column'
    }
    ,
    dialogStyle: {
        backgroundColor: Colors.VeryPaleBlue,
        padding: 15,
        display: 'flex',
        flexDirection: 'column',
        justifyContent:'center',
        alignItems: 'center',
        width: 400,
        height : 520
    },
    clearLog: {
        margin: 2,
        width: 100,
        height: 32,
        fontSize: 15,
        color: Colors.White,
        textAlign: 'center',
        backgroundColor: Colors.MostPureBlue,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    cancel: {
        margin: 2,
        width: 80,
        height: 32,
        color: Colors.Black,
        fontSize: 15,
        textAlign: 'center',
        backgroundColor: Colors.LightGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    buttonContainer: {
        display: 'flex',
        flex: 1,
        height: 'auto',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        marginLeft: 30,
        marginBottom: 4,
        marginTop: 10,
        alignSelf: 'bottom'
    }
,
    saveDialog: {
        margin: 2,
        width: 80,
        borderRadius: 4,
        height: 32,
        color: Colors.Black,
        fontSize: 15,
        textAlign: 'center',
        backgroundColor: Colors.LightGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer'
    },
    cancelDialog: {
        margin: 2,
        width: 80,
        borderRadius: 4,
        height: 32,
        color: Colors.Black,
        fontSize: 15,
        textAlign: 'center',
        backgroundColor: Colors.LightGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer'
    },
    logout: {
        backgroundColor: Colors.LightGray,
        color: Colors.Black,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'black',
        padding: 4,
        fontSize: '.75em',
        marginRight: 5,
        borderRadius: 6,
        fontWeight: 300,
        width: 62,
        justifyContent: 'center',
        height: 28,
        alignItems: 'center',
        cursor: 'pointer',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },

    refresh: { width: 75, textAlign: 'center' },
    logoutRow: {
        display: 'flex',
        height: 48,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    userNameRow: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },
    logoRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    username: {
        width: 100,
        height: 'auto',
        justifyContent: 'center',
        alignSelf: 'left',
        marginRight: 10,
        textAlign: 'center',
        fontSize: 14,
        alignItems: "'center'",
        color: Colors.Purple
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: 115,
        backgroundColor: Colors.VeryPaleBlue,
        zIndex: 10
    },
    continentalText: {
        textAlign: 'left',
        color: Colors.Purple,
        height: 18,
        borderBottom: '1px solid',
        borderColor: Colors.Green,
        paddingBottom: 5,
        fontSize: '1em'
    },
    continentalTextContainer: {
        flex: 1,
        width: 250,
        display: 'flex',
        height: 48,
        flexDirection: 'row',
        justifyContent: 'left',
        marginLeft: 70
    },
    accessText: {
        textAlign: 'center',
        color: Colors.Green,
        marginLeft: 20,
        fontSize: '1em'
    },
    loggedInTimeText: {
        color: Colors.Black,
        marginRight: 8,
        fontSize: 10,
        marginTop: 2,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 'normal'
    },
    menuBackground: {
        padding: 10,
        backgroundColor: Colors.VeryPaleBlue
    },
    appName: {
        color: Colors.Purple,
        justifyContent: 'center',
        marginLeft: 40,
        fontSize: 20,
        fontWeight: 'bold'
    },

    menuAreaContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: 42,
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: 20,
        marginRight: 20
    },
    line: {
        height: 8,
        backgroundColor: Colors.Green,
        marginTop: 5
    }
};

const mapStateToProps = state => ({
    operatorId: state.token.operatorId,
    log: state.log.log
});


const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
         
            writeLog: actions.writeLog,
            hideLog: actions.hideLog,
            clearLog: actions.clearLog
        },
        dispatch
    );

export default Radium(connect(mapStateToProps, mapDispatchToProps)(LogView));

import React from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Colors from '../../consts/colors';
import * as actions from '../../actions/index';
import ErrorBoundary from '../common/ErrorBoundary';

class PINView extends React.Component {
  constructor(props) {
    super(props);
      try {
          console.log('PIN Props: ', props);

          let p1 = props.p1 || props.p1 > -1 ? props.p1 : '';
          let p2 = props.p2 || props.p2 > -1 ? props.p2 : '';
          let p3 = props.p3 || props.p3 > -1 ? props.p3 : '';
          let p4 = props.p4 || props.p4 > -1 ? props.p4 : '';
          let p5 = props.p5 || props.p5 > -1 ? props.p5 : '';
          let p6 = props.p6 || props.p6 > -1 ? props.p6 : '';
          let p7 = props.p7 || props.p7 > -1 ? props.p7 : '';
          let p8 = props.p8 || props.p8 > -1 ? props.p8 : '';
          let p9 = props.p9 || props.p9 > -1 ? props.p9 : '';

          let disabledP1 = props.disabledP1 ? props.disabledP1: false;
          let disabledP2 = props.disabledP2 ? props.disabledP2: false;
          let disabledP3 = props.disabledP3 ? props.disabledP3: false;
          let disabledP4 = props.disabledP4 ? props.disabledP4: false;
          let disabledP5 = props.disabledP5 ? props.disabledP5:false;
          let disabledP6 = props.disabledP6 ? props.disabledP6: false;
          let disabledP7 = props.disabledP7 ? props.disabledP7: false;
          let disabledP8 = props.disabledP8 ? props.disabledP8: false;
          let disabledP9 = props.disabledP9 ? props.disabledP9 : false;
          let hidePinCode = props.hidePinCode ? props.hidePinCode : false;

          this.state = {
            p1:p1,
            p2:p2,
            p3:p3,
            p4: p4,
            p5: p5,
            p6: p6,
            p7: p7,
            p8: p8,
            p9:p9,
            pin: `${p1}${p2}${p3}${p4}${p5}${p6}${p7}${p8}${p9}`,
            stateChanged: false,
          disabledP1: disabledP1,
          disabledP2: disabledP2,
          disabledP3: disabledP3,
          disabledP4: disabledP4,
          disabledP5: disabledP5,
          disabledP6: disabledP6,
          disabledP7: disabledP7,
          disabledP8: disabledP8,
          disabledP9: disabledP9,
          hidePinCode: hidePinCode
 
      };

      this.handleTextChangeP1 = this.handleTextChangeP1.bind(this);
      this.handleTextChangeP2 = this.handleTextChangeP2.bind(this);
      this.handleTextChangeP3 = this.handleTextChangeP3.bind(this);
      this.handleTextChangeP4 = this.handleTextChangeP4.bind(this);
      this.handleTextChangeP5 = this.handleTextChangeP5.bind(this);
      this.handleTextChangeP6 = this.handleTextChangeP6.bind(this);
      this.handleTextChangeP7 = this.handleTextChangeP7.bind(this);
      this.handleTextChangeP8 = this.handleTextChangeP8.bind(this);
      this.handleTextChangeP9 = this.handleTextChangeP9.bind(this);

      this.p1 = React.createRef();
      this.p2 = React.createRef();
      this.p3 = React.createRef();
      this.p4 = React.createRef();
      this.p5 = React.createRef();
      this.p6 = React.createRef();
      this.p7 = React.createRef();
      this.p8 = React.createRef();
      this.p9 = React.createRef();

      this.props.changePersonnelPIN(this.state.pin);
    } catch (error) {
      this.setState(() => {
        throw error;
      });
    }
    }

    static getDerivedStateFromProps(props, state) {
        console.log('PIN...Derived', state);
        if ((props.p1 !== state.p1 || props.p2 !== state.p2 || props.p3 !== state.p3 || props.p4 !== state.p4 || props.p5 !== state.p5 || props.p6 !== state.p6 || props.p7 !== state.p7 || props.p8 !== state.p8 || props.p9 !== state.p9) && state.stateChanged === false) { 

            let p1 = props.p1 || props.p1 > -1 ? props.p1 : '';
            let p2 = props.p2 || props.p2 > -1 ? props.p2 : '';
            let p3 = props.p3 || props.p3 > -1 ? props.p3 : '';
            let p4 = props.p4 || props.p4 > -1 ? props.p4 : '';
            let p5 = props.p5 || props.p5 > -1 ? props.p5 : '';
            let p6 = props.p6 || props.p6 > -1 ? props.p6 : '';
            let p7 = props.p7 || props.p7 > -1 ? props.p7 : '';
            let p8 = props.p8 || props.p8 > -1 ? props.p8 : '';
            let p9 = props.p9 || props.p9 > -1 ? props.p9 : '';

          return {
              p1: p1,
              p2: p2,
              p3: p3,
              p4: p4,
              p5: p5,
              p6: p6,
              p7: p7,
              p8: p8,
              p9: p9,
              pin: `${p1}${p2}${p3}${p4}${p5}${p6}${p7}${p8}${p9}`
            }                         
        }
        return null;
    }

  

  handleTextChangeP1(event) {
    try {
      if (
        (event.target.value >= 0 && event.target.value <= 9) ||
        event.target.value === ''
      ) {
        this.setState(
          {
            ...this.state,
                p1: event.target.value,
                pin: `${event.target.value}${this.state.p2}${this.state.p3}${this.state.p4}${this.state.p5}${this.state.p6}${this.state.p7}${this.state.p8}${this.state.p9}`,
                stateChanged: true
          },
          () => {
            this.props.changePersonnelPIN(this.state.pin);
            if (this.props.onChange) this.props.onChange(this.state.pin);
          }
        );
        this.p2.current.focus();
      } else {
        event.preventDefault();
      }
    } catch (error) {
      this.setState(() => {
        throw error;
      });
    }
  }

  handleTextChangeP2(event) {
    try {
      if (
        (event.target.value >= 0 && event.target.value <= 9) ||
        event.target.value === ''
      ) {
        this.setState(
          {
            ...this.state,
            p2: event.target.value,
                pin: `${this.state.p1}${event.target.value}${this.state.p3}${this.state.p4}${this.state.p5}${this.state.p6}${this.state.p7}${this.state.p8}${this.state.p9 }`,
                stateChanged: true
          },
          () => {
            this.props.changePersonnelPIN(this.state.pin);
            if (this.props.onChange) this.props.onChange(this.state.pin);
          }
        );
        this.p3.current.focus();
      } else {
        event.preventDefault();
      }
    } catch (error) {
      this.setState(() => {
        throw error;
      });
    }
  }

  handleTextChangeP3(event) {
    try {
      if (
        (event.target.value >= 0 && event.target.value <= 9) ||
        event.target.value === ''
      ) {
        this.setState(
          {
            ...this.state,
            p3: event.target.value,
                pin: `${this.state.p1}${this.state.p2}${event.target.value}${this.state.p4}${this.state.p5}${this.state.p6}${this.state.p7}${this.state.p8}${this.state.p9}`,
                stateChanged: true
          },
          () => {
            this.props.changePersonnelPIN(this.state.pin);
            if (this.props.onChange) this.props.onChange(this.state.pin);
          }
        );
        this.p4.current.focus();
      } else {
        event.preventDefault();
      }
    } catch (error) {
      this.setState(() => {
        throw error;
      });
    }
  }

  handleTextChangeP4(event) {
    try {
      if (
        (event.target.value >= 0 && event.target.value <= 9) ||
        event.target.value === ''
      ) {
        this.setState(
          {
            ...this.state,
            p4: event.target.value,
                pin: `${this.state.p1}${this.state.p2}${this.state.p3}${event.target.value}${event.target.value}${this.state.p6}${this.state.p7}${this.state.p8}${this.state.p9}`,
                stateChanged: true
          },
          () => {
            this.props.changePersonnelPIN(this.state.pin);
            if (this.props.onChange) this.props.onChange(this.state.pin);
          }
        );
        this.p5.current.focus();
      } else {
        event.preventDefault();
      }
    } catch (error) {
      this.setState(() => {
        throw error;
      });
    }
  }

  handleTextChangeP5(event) {
        try {
            if (
                (event.target.value >= 0 && event.target.value <= 9) ||
                event.target.value === ''
            ) {
                this.setState(
                    {
                        ...this.state,
                        p5: event.target.value,
                        pin: `${this.state.p1}${this.state.p2}${this.state.p3}${this.state.p4}${event.target.value}${this.state.p6}${this.state.p7}${this.state.p8}${this.state.p9}`,
                        stateChanged: true
                    },
                    () => {
                        this.props.changePersonnelPIN(this.state.pin);
                        if (this.props.onChange) this.props.onChange(this.state.pin);
                    }
                );
                this.p6.current.focus();
            } else {
                event.preventDefault();
            }
        } catch (error) {
            this.setState(() => {
                throw error;
            });
        }
    }

 handleTextChangeP6(event) {
        try {
            if (
                (event.target.value >= 0 && event.target.value <= 9) ||
                event.target.value === ''
            ) {
                this.setState(
                    {
                        ...this.state,
                        p6: event.target.value,
                        pin: `${this.state.p1}${this.state.p2}${this.state.p3}${this.state.p4}${this.state.p5}${event.target.value}${this.state.p7}${this.state.p8}${this.state.p9}`,
                        stateChanged: true
                    },
                    () => {
                        this.props.changePersonnelPIN(this.state.pin);
                        if (this.props.onChange) this.props.onChange(this.state.pin);
                    }
                );
                this.p7.current.focus();
            } else {
                event.preventDefault();
            }
        } catch (error) {
            this.setState(() => {
                throw error;
            });
        }
    }
 handleTextChangeP7(event) {
        try {
            if (
                (event.target.value >= 0 && event.target.value <= 9) ||
                event.target.value === ''
            ) {
                this.setState(
                    {
                        ...this.state,
                        p7: event.target.value,
                        pin: `${this.state.p1}${this.state.p2}${this.state.p3}${this.state.p4}${this.state.p5}${this.state.p6}${event.target.value}${this.state.p8}${this.state.p9}`,
                        stateChanged: true
                    },
                    () => {
                        this.props.changePersonnelPIN(this.state.pin);
                        if (this.props.onChange) this.props.onChange(this.state.pin);
                    }
                );
                this.p8.current.focus();
            } else {
                event.preventDefault();
            }
        } catch (error) {
            this.setState(() => {
                throw error;
            });
        }
    }
 handleTextChangeP8(event) {
        try {
            if (
                (event.target.value >= 0 && event.target.value <= 9) ||
                event.target.value === ''
            ) {
                this.setState(
                    {
                        ...this.state,
                        p8: event.target.value,
                        pin: `${this.state.p1}${this.state.p2}${this.state.p3}${this.state.p4}${this.state.p5}${this.state.p6}${this.state.p7}${this.state.p8}${this.state.p9}`,
                        stateChanged: true
                    },
                    () => {
                        this.props.changePersonnelPIN(this.state.pin);
                        if (this.props.onChange) this.props.onChange(this.state.pin);
                    }
                );
                this.p9.current.focus();
            } else {
                event.preventDefault();
            }
        } catch (error) {
            this.setState(() => {
                throw error;
            });
        }
    }
 handleTextChangeP9(event) {
        try {
            if (
                (event.target.value >= 0 && event.target.value <= 9) ||
                event.target.value === ''
            ) {
                this.setState(
                    {
                        ...this.state,
                        p9: event.target.value,
                        pin: `${this.state.p1}${this.state.p2}${this.state.p3}${this.state.p4}${this.state.p5}${this.state.p6}${this.state.p7}${this.state.p8}${event.target.value}`,
                        stateChanged: true
                    },
                    () => {
                        this.props.changePersonnelPIN(this.state.pin);
                        if (this.props.onChange) this.props.onChange(this.state.pin);
                    }
                );
                this.p1.current.focus();
            } else {
                event.preventDefault();
            }
        } catch (error) {
            this.setState(() => {
                throw error;
            });
        }
    }


    render() {
    console.log('PIN:', this.state.pin);
    console.log('pPIN:', this.props.personnelPIN);
    return (
      <ErrorBoundary>
        <div style={styles.container}>
          <div style={styles.p1Container}>
            <input
              ref={this.p1}
              type={this.state.hidePinCode===true ? "password":"text"}
              name="p1"
              disabled={this.state.disabledP1}
              style={styles.p1}
              pattern="[0-9]{1}"
              maxLength={1}
              onChange={this.handleTextChangeP1}
              value={this.state.p1}
              title="only digits are allowed"
            />
          </div>
          <div style={styles.p2Container}>
            <input
              ref={this.p2}
              type={this.state.hidePinCode === true ? "password" : "text"}
               name="p2"
              disabled={this.state.disabledP2}
              style={styles.p2}
              pattern="[0-9]{1}"
              maxLength={1}
              onChange={this.handleTextChangeP2}
              value={this.state.p2}
              title="only digits are allowed"
            />
          </div>
          <div style={styles.p3Container}>
            <input
              ref={this.p3}
              type={this.state.hidePinCode === true ? "password" : "text"}
              name="p3"
              disabled={this.state.disabledP3}
              style={styles.p3}
              pattern="[0-9]{1}"
              maxLength={1}
              onChange={this.handleTextChangeP3}
              value={this.state.p3}
              title="only digits are allowed"
            />
          </div>
          <div style={styles.p4Container}>
            <input
              ref={this.p4}
              type={this.state.hidePinCode === true ? "password" : "text"}
              name="p4"
              disabled={this.state.disabledP4}
              style={styles.p4}
              pattern="[0-9]{1}"
              maxLength={1}
              onChange={this.handleTextChangeP4}
              value={this.state.p4}
              title="only digits are allowed"
            />
                </div>
                <div style={styles.p5Container}>
                    <input
                        ref={this.p5}
                        type={this.state.hidePinCode === true ? "password" : "text"}
                        name="p5"
                        disabled={this.state.disabledP5}
                        style={styles.p5}
                        pattern="[0-9]{1}"
                        maxLength={1}
                        onChange={this.handleTextChangeP5}
                        value={this.state.p5}
                        title="only digits are allowed"
                    />
                </div>
                <div style={styles.p6Container}>
                    <input
                        ref={this.p6}
                        type={this.state.hidePinCode === true ? "password" : "text"}
                        name="p6"
                        disabled={this.state.disabledP6}
                        style={styles.p6}
                        pattern="[0-9]{1}"
                        maxLength={1}
                        onChange={this.handleTextChangeP6}
                        value={this.state.p6}
                        title="only digits are allowed"
                    />
                </div>
                <div style={styles.p7Container}>
                    <input
                        ref={this.p7}
                        type={this.state.hidePinCode === true ? "password" : "text"}
                        name="p7"
                        disabled={this.state.disabledP7}
                        style={styles.p7}
                        pattern="[0-9]{1}"
                        maxLength={1}
                        onChange={this.handleTextChangeP7}
                        value={this.state.p7}
                        title="only digits are allowed"
                    />
                </div>
                <div style={styles.p8Container}>
                    <input
                        ref={this.p8}
                        type={this.state.hidePinCode === true ? "password" : "text"}
                        name="p8"
                        disabled={this.state.disabledP8}
                        style={styles.p8}
                        pattern="[0-9]{1}"
                        maxLength={1}
                        onChange={this.handleTextChangeP8}
                        value={this.state.p8}
                        title="only digits are allowed"
                    />
                </div>
                <div style={styles.p9Container}>
                    <input
                        ref={this.p9}
                        type={this.state.hidePinCode === true ? "password" : "text"}
                        name="p9"
                        disabled={this.state.disabledP9}
                        style={styles.p9}
                        pattern="[0-9]{1}"
                        maxLength={1}
                        onChange={this.handleTextChangeP9}
                        value={this.state.p9}
                        title="only digits are allowed"
                    />
                </div>
        </div>
      </ErrorBoundary>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    flex: 1,
    padding: 10
  },
  p1: {
    width: 20,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 15,
    borderRadius: 8,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.LightGray
  },
  p2: {
    width: 20,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 15,
    borderRadius: 8,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.LightGray
  },
  p3: {
    width: 20,
    textAlign: 'center',
    fontWeight: 'bold',
      fontSize: 15,
    borderRadius: 8,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.LightGray
  },
  p4: {
    width: 20,
    textAlign: 'center',
    fontWeight: 'bold',
      fontSize: 15,
    borderRadius: 8,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.LightGray
    },
    p5: {
        width: 20,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 15,
        borderRadius: 8,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray
    },
    p5: {
        width: 20,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 15,
        borderRadius: 8,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray
    },
    p6: {
        width: 20,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 15,
        borderRadius: 8,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray
    },
    p7: {
        width: 20,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 15,
        borderRadius: 8,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray
    },
    p8: {
        width: 20,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 15,
        borderRadius: 8,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray
    },
    p9: {
        width: 20,
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 15,
        borderRadius: 8,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray
    },

  p1Container: {
    display: 'flex',
    border: '1px solid #f5f5f5',
    height: 26,
    width: 'auto',
    marginLeft: 1,
    marginRight: 2,
    borderRadius: 8
  },
  p2Container: {
    display: 'flex',
    border: '1px solid #f5f5f5',
    height: 26,
    width: 'auto',
    marginLeft: 1,
    marginRight: 2,
    borderRadius: 8
  },
  p3Container: {
    display: 'flex',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#f5f5f5',
    height: 26,
    width: 'auto',
    marginLeft: 1,
    marginRight: 2,
    borderRadius: 8
  },
  p4Container: {
    display: 'flex',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#f5f5f5',
    height: 26,
    width: 'auto',
    marginLeft: 1,
    marginRight: 2,
    borderRadius: 8
    },
    p5Container: {
        display: 'flex',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#f5f5f5',
        height: 26,
        width: 'auto',
        marginLeft: 1,
        marginRight: 2,
        borderRadius: 8
    },
    p6Container: {
        display: 'flex',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#f5f5f5',
        height: 26,
        width: 'auto',
        marginLeft: 1,
        marginRight: 2,
        borderRadius: 8
    },
    p7Container: {
        display: 'flex',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#f5f5f5',
        height: 26,
        width: 'auto',
        marginLeft: 1,
        marginRight: 2,
        borderRadius: 8
    },
    p8Container: {
        display: 'flex',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#f5f5f5',
        height: 26,
        width: 'auto',
        marginLeft: 1,
        marginRight: 2,
        borderRadius: 8
    },
    p9Container: {
        display: 'flex',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#f5f5f5',
        height: 26,
        width: 'auto',
        marginLeft: 1,
        marginRight: 2,
        borderRadius: 8
    },
  line: {
    height: 12,
    backgroundColor: Colors.Gray,
    marginTop: 5
  }
};

const mapStateToProps = state => ({
  personnelPIN: state.pin.personnelPIN
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePersonnelPIN: actions.changePersonnelPIN
    },
    dispatch
  );

export default Radium(connect(mapStateToProps, mapDispatchToProps)(PINView));

import throttle from 'lodash/throttle';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducer from '../reducers/index';
import { mainSaga } from '../sagas';

import { loadState, saveState } from './sessionStorage';

const initialState = loadState();

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, initialState, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(mainSaga);

//console.log('State:', store.getState());

store.subscribe(
  throttle(() => {
    saveState({
      appConfig: store.getState().appConfig,
      token: store.getState().token,
      loading: store.getState().loading,
      door: store.getState().door,
      status: store.getState().status,
      page: store.getState().page,
      lockdownArea: store.getState().lockdownArea,
      threat: store.getState().threat,
      relay: store.getState().relay,
      badgeholder: store.getState().badgeholder,
      scheduledChanges: store.getState().scheduledChanges,
      //personnel: store.getState().personnel,
      accessGroups: store.getState().accessGroups,
      message: store.getState().message,
      user: store.getState().user,
      settings: store.getState().settings,
      pin: store.getState().pin,
      error: store.getState().error,
      connectStatus: store.getState().connectStatus,
      generalMessage: store.getState().generalMessage
    });
  }, 1000)
);

export default store;

import * as ActionTypes from '../actions/types';

const defaultState = {
  msg: ''
};

export default (state = defaultState, action) => {
  switch (action.type) {
    //case ActionTypes.SEND_DOOR_LOCK_SUCCEEDED: {
    //  return {
    //    msg: 'Lock command is sent successfully'
    //      msg: '';
    //  };
    //}

    //case ActionTypes.SEND_DOOR_UNLOCK_SUCCEEDED: {
    //  return {
    //    msg: 'Unlock command is sent successfully'
    //      msg: '';
    //  };
    //}

    //case ActionTypes.SEND_DOOR_LOCK_HOLD_SUCCEEDED: {
    //  return {
    //    msg: 'Unlock and  Hold  command is sent successfully'
    //  };
    //}

    case ActionTypes.RESET_MESSAGE: {
      return {
        msg: ''
      };
      }

      case ActionTypes.SHOWW_ERROR_MESSAGE: {
          return {
              msg: action.payload
          };
      }

    default:
      return state;
  }
};

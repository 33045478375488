import { combineReducers } from 'redux';

import TokenReducer from './TokenReducer';
import LoadingReducer from './LoadingReducer';
import AppConfigReducer from './AppConfigReducer';
import DoorReducer from './DoorReducer';
import ThreatReducer from './ThreatReducer';
import MessageReducer from './MessageReducer';
import ErrorReducer from './ErrorReducer';
import UserReducer from './UserReducer';
import RelayReducer from './RelayReducer';
import LockDownReducer from './LockDownReducer';
import StatusReducer from './StatusReducer';
import PageReducer from './PageReducer';
import BadgeHolderReducer from './BadgeHolderReducer';
import ScheduleChangesReducer from './ScheduleChangesReducer';
import PersonnelReducer from './PersonnelReducer';
import AccessGroupReducer from './AccessGroupReducer';
import PINReducer from './PINReducer';
import SettingsReducer from './SettingsReducer';
import ConnectionStatusReducer from './ConnectionStatusReducer';
import GeneralMessageReducer from './GeneralMessageReducer';
import ExceptionReducer from './ExceptionReducer';
import PartitionReducer from './PartitionReducer';
import EventReducer from './EventReducer';
import LogReducer from './LogReducer';
import PriviledgeReducer from './PriviledgeReducer';

export default combineReducers({
    appConfig: AppConfigReducer,
    token: TokenReducer,
    loading: LoadingReducer,
    door: DoorReducer,
    status: StatusReducer,
    page: PageReducer,
    lockdownArea: LockDownReducer,
    threat: ThreatReducer,
    relay: RelayReducer,
    badgeholder: BadgeHolderReducer,
    scheduledChanges: ScheduleChangesReducer,
    personnel: PersonnelReducer,
    accessGroups: AccessGroupReducer,
    message: MessageReducer,
    user: UserReducer,
    settings: SettingsReducer,
    pin: PINReducer,
    error: ErrorReducer,
    connectStatus: ConnectionStatusReducer,
    generalMessage: GeneralMessageReducer,
    exception: ExceptionReducer,
    partition: PartitionReducer,
    event: EventReducer,
    log: LogReducer,
    priviledge: PriviledgeReducer
});


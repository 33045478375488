import * as ActionTypes from '../actions/types';

const defaultState = {
  username: '',
    rememberedUserName: '',
    password: '',
    changePasswordPressed: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USERINFO: {
      return {
          ...state,
          username: action.payload.username && action.payload.username,
          password: action.payload.password && action.payload.password
      };
    }

    case ActionTypes.LOAD_USERINFO: {
      return {
        ...state,
        username: action.payload
      };
    }

    case ActionTypes.RESET_USERINFO: {
      return {
        ...state,
          username: '',
          password:''
      };
    }

    case ActionTypes.REMEMBERED_USERNAME: {
      return {
        ...state,
        rememberedUserName: action.payload
      };
      }

      case ActionTypes.CHANGE_PASSWORD_PRESSED: {
          return {
              ...state,
              changePasswordPressed: true
          };
      }

      case ActionTypes.RESET_CHANGE_PASSWORD_PRESSED: {
          return {
              ...state,
              changePasswordPressed: false
          };
      }

    default:
      return state;
  }
};

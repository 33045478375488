import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import * as Colors from '../../consts/colors';
import StatusDetailView from './StatusDetailView';
import * as actions from '../../actions/index';
import {
  DEVICE_TYPES_NAME,
  DEVICE_TYPES,
  PAGE_NAMES,
  ADMIN_ROLE_ID,
  SCREEN_CODES,
  SECURITY_LEVEL_CODES
} from '../../consts/consts';

class StatusView extends Component {
  constructor(props) {
    super(props);

    const statusPrivilege = this.props.privileges.find(
      privilege => privilege.ScreenID === SCREEN_CODES.STATUS
    );

    this.state = {
      // selectedDeviceType: DEVICE_TYPES_NAME.DEVICE_TYPE_NONE,
      deviceTypeNameList: [
        DEVICE_TYPES_NAME.DEVICE_TYPE_NONE,
        DEVICE_TYPES_NAME.DEVICE_TYPE_PANEL,
        DEVICE_TYPES_NAME.DEVICE_TYPE_DOOR,
        DEVICE_TYPES_NAME.DEVICE_TYPE_RELAY,

        DEVICE_TYPES_NAME.DEVICE_TYPE_INPUT
      ],
      deviceTypeList: [
        DEVICE_TYPES.DEVICE_TYPE_NONE,
        DEVICE_TYPES.DEVICE_TYPE_PANEL,
        DEVICE_TYPES.DEVICE_TYPE_DOOR,
        DEVICE_TYPES.DEVICE_TYPE_RELAY,
        DEVICE_TYPES.DEVICE_TYPE_INPUT
      ],
      statusPrivilege
    };
    this.showDeviceTypeList = this.showDeviceTypeList.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.props.writeLog('OperatorID: ' + this.props.operatorId + "StatusView -->  Constructor() --> : StatusPriviledge: " + JSON.stringify(statusPrivilege));

  }

  UNSAFE_componentWillMount() {
    this.props.setCurrentPageName(PAGE_NAMES.STATUS);
    this.props.resetSelectedDeviceType();
  }

  // {this.state.selectedDeviceType === DEVICE_TYPES_NAME.DEVICE_TYPE_NAPCO_PANEL && (
  //   <StatusDetailView deviceType={DEVICE_TYPES.DEVICE_TYPE_NAPCO_PANEL} />
  // )}
  // {this.state.selectedDeviceType === DEVICE_TYPES_NAME.DEVICE_TYPE_NAPCO_AREA && (
  //   <StatusDetailView deviceType={DEVICE_TYPES.DEVICE_TYPE_NAPCO_AREA} />
  // )}
  // {this.state.selectedDeviceType === DEVICE_TYPES_NAME.DEVICE_TYPE_NAPCO_ZONE && (
  //   <StatusDetailView deviceType={DEVICE_TYPES.DEVICE_TYPE_NAPCO_ZONE} />
  // )}
  // {this.state.selectedDeviceType === DEVICE_TYPES_NAME.DEVICE_TYPE_NAPCO_RELAY && (
  //   <StatusDetailView deviceType={DEVICE_TYPES.DEVICE_TYPE_NAPCO_RELAY} />
  // )}

  handleSelectionChanged(event) {
    //this.setState({ ...this.state, selectedDeviceType: event.target.value });

    const index = this.state.deviceTypeNameList.indexOf(event.target.value);
    this.props.getSelectedDeviceType(this.state.deviceTypeList[index]);
    console.log('Device Type:', index, this.state.deviceTypeList[index]);
  }

  showDeviceTypeList(deviceTypeList) {
    const deviceTypeViewList = deviceTypeList.map(deviceType => (
      <option key={deviceType} value={deviceType} style={styles.option}>
        {deviceType}
      </option>
    ));
    return (
      <select className="select-css" onChange={this.handleSelectionChanged}>
        {deviceTypeViewList}
      </select>
    );
  }

  render() {
    console.log('currentPage :', this.props.currentPage);
    console.log('Selected Device Type :', this.props.selectedDeviceType);
    return (
      <div style={styles.container}>
        <div style={styles.expandContainer}>
          <Link style={styles.underlineless} to="/home">
            <div>
              <FontAwesomeIcon icon={faMinus} size="1x" color={Colors.White} />
            </div>
          </Link>
          <div style={styles.functionText}>Status</div>
        </div>
        {this.props.roleId === ADMIN_ROLE_ID && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={styles.selectDeviceType}>Select Device Type</div>
            <div style={styles.devcieTypeListContainer}>
              {this.showDeviceTypeList(this.state.deviceTypeNameList)}
            </div>
            <div style={styles.statusDetails}>
              {this.props.selectedDeviceType === DEVICE_TYPES.DEVICE_TYPE_PANEL && (
                <StatusDetailView deviceType={DEVICE_TYPES.DEVICE_TYPE_PANEL} />
              )}
              {this.props.selectedDeviceType === DEVICE_TYPES.DEVICE_TYPE_RELAY && (
                <StatusDetailView deviceType={DEVICE_TYPES.DEVICE_TYPE_RELAY} />
              )}
              {this.props.selectedDeviceType === DEVICE_TYPES.DEVICE_TYPE_INPUT && (
                <StatusDetailView deviceType={DEVICE_TYPES.DEVICE_TYPE_INPUT} />
              )}
              {this.props.selectedDeviceType === DEVICE_TYPES.DEVICE_TYPE_NONE && (
                <StatusDetailView deviceType={DEVICE_TYPES.DEVICE_TYPE_NONE} />
              )}

              {this.props.selectedDeviceType === DEVICE_TYPES.DEVICE_TYPE_DOOR && (
                <StatusDetailView deviceType={DEVICE_TYPES.DEVICE_TYPE_DOOR} />
              )}
            </div>
          </div>
        )}

        {this.props.roleId !== ADMIN_ROLE_ID &&
          this.state.statusPrivilege &&
          this.state.statusPrivilege.SecurityLevel === SECURITY_LEVEL_CODES.FULL_CONTROL && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={styles.selectDeviceType}>Select Device Type</div>
              <div style={styles.devcieTypeListContainer}>
                {this.showDeviceTypeList(this.state.deviceTypeNameList)}
              </div>
              <div style={styles.statusDetails}>
                {this.props.selectedDeviceType === DEVICE_TYPES.DEVICE_TYPE_PANEL && (
                  <StatusDetailView deviceType={DEVICE_TYPES.DEVICE_TYPE_PANEL} />
                )}
                {this.props.selectedDeviceType === DEVICE_TYPES.DEVICE_TYPE_RELAY && (
                  <StatusDetailView deviceType={DEVICE_TYPES.DEVICE_TYPE_RELAY} />
                )}
                {this.props.selectedDeviceType === DEVICE_TYPES.DEVICE_TYPE_INPUT && (
                  <StatusDetailView deviceType={DEVICE_TYPES.DEVICE_TYPE_INPUT} />
                )}
                {this.props.selectedDeviceType === DEVICE_TYPES.DEVICE_TYPE_NONE && (
                  <StatusDetailView deviceType={DEVICE_TYPES.DEVICE_TYPE_NONE} />
                )}

                {this.props.selectedDeviceType === DEVICE_TYPES.DEVICE_TYPE_DOOR && (
                  <StatusDetailView deviceType={DEVICE_TYPES.DEVICE_TYPE_DOOR} />
                )}
              </div>
            </div>
          )}
      </div>
    );
  }
}

const styles = {
  underlineless: {
    textDecoration: 'none'
  },
  container: {
    display: 'flex',
    flex: 1,
    height: 'auto',
    justifyContent: 'center',
    marginRight: 20,
    flexDirection: 'column',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 10,
    borderColor: Colors.Green,
    backgroundColor: Colors.White
  },
  expandContainer: {
    flex: 1,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 10,
    backgroundColor: Colors.Green,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10
  },
  devcieTypeListContainer: {
    height: 50,
    padding: 10
  },
  selectDeviceType: {
    paddingLeft: 12,
    justifyContent: 'center',
    marginTop: 10,
    fontSize: 15,
    fontWeight: 'bold'
  },
  functionText: {
    color: Colors.White,
    textAlign: 'left',
    marginLeft: 20,
    fontSize: 20,
    fontWeight: 800
  },
  horizontalLine: {
    height: 1,
    width: '100%',
    borderBottom: '1px solid',
    borderColor: Colors.Purple
  }
};

const mapStateToProps = state => ({
  accessToken: state.token.accessToken,
  deviceTypes: state.status.deviceTypes,
  selectedDeviceType: state.status.selectedDeviceType,
  currentPage: state.page.currentPage,
  privileges: state.token.privileges,
  operatorId: state.token.operatorId,
  roleId: state.token.roleId,
  operatorId: state.token.operatorId
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAllDeviceTypeList: actions.fetchAllDeviceTypeList,
      getSelectedDeviceType: actions.getSelectedDeviceType,
      resetSelectedDeviceType: actions.resetSelectedDeviceType,
      setCurrentPageName: actions.setCurrentPageName,
      writeLog: actions.writeLog
    },
    dispatch
  );

export default Radium(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StatusView)
);

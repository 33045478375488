import React from "react";
import Radium from "radium";
import moment from "moment";
import * as Colors from "../../consts/colors";
// <div style={styles.replace}>
//   <div style={styles.day}> {props.replace.day}</div>
//   <div style={styles.time}>{props.replace.time}</div>
// </div>

const ScheduleChangesListItemView = props => {
  const startTime = moment
    .utc(`${props.startDate} ${props.startTime}`)
    .local()
    .format("hh:mm:ss A");

  const endTime = moment
    .utc(`${props.endDate} ${props.endTime}`)
    .local()
    .format("hh:mm:ss A");

  console.log("Start Time:::EndTime::: ", startTime, endTime);

  if (props.currentIndex % 2 === 0) {
    return (
      <div
        style={
          props.isSelected === true
            ? props.instyle
            : styles.alterBackgroundContainer
        }
        onClick={() => {
          props.onPress(props.currentIndex);
        }}
      >
        <div style={styles.schedule}>
          <div style={styles.description}> {props.description}</div>
          <div style={styles.time}>
            {startTime} to {endTime}
          </div>
        </div>

        <div style={styles.fromToDate}>
          <div style={styles.fromDate}>F {props.startDate}</div>
          <div style={styles.toDate}>T {props.endDate}</div>
        </div>
      </div>
    );
  }
  return (
    <div
      style={props.isSelected === true ? props.instyle : styles.container}
      onClick={() => {
        props.onPress(props.currentIndex);
      }}
    >
      <div style={styles.schedule}>
        <div style={styles.description}> {props.description}</div>
        <div style={styles.time}>
          {startTime} to {endTime}
        </div>
      </div>

      <div style={styles.fromToDate}>
        <div style={styles.fromDate}>F {props.startDate}</div>
        <div style={styles.toDate}>T {props.endDate}</div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flex: 1,
    height: 60,
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: Colors.LightGrayishBlue
  },
  alterBackgroundContainer: {
    display: "flex",
    flex: 1,
    height: 60,
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: Colors.PaleBlue
  },

  description: {
    padding: 2,
    aligbSelf: "left",
    fontSize: 12,
    fontWeight: "600"

  },
  time: {
    padding: 2,
    aligbSelf: "left",
    fontSize: 12,
    fontWeight: "Normal"
  },

  fromDate: {
    padding: 2,
    aligbSelf: "left",
    fontSize: 12,
    fontWeight: "Normal"
  },
  toDate: {
    padding: 2,
    aligbSelf: "left",
    fontSize: 12,
    fontWeight: "Normal"
  },

  schedule: {
    display: "flex",
    flex: 1.2,
    height: 60,
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "center",
    paddingLeft: 5,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "black"
  },
  replace: {
    display: "flex",
    flex: 1,
    height: 50,
    flexDirection: "column",
    alignItems: "left",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "black",
    justifyContent: "center",
    paddingLeft: 5
  },
  fromToDate: {
    display: "flex",
    flex: 1,
    height: 60,
    flexDirection: "column",
    alignItems: "left",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "black",
    justifyContent: "center",
    paddingLeft: 5
  }
};

export default Radium(ScheduleChangesListItemView);

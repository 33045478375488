import * as ActionTypes from '../actions/types';

const defaultState = {
  msg: ''
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_SUCCEEDED:
      return {
        msg: ''
      };
    case ActionTypes.LOGIN_FAILED: {
      //console.log(action.payload);

      return {
        msg: action.payload
      };
    }

    case ActionTypes.SEND_DOOR_LOCK_FAILED: {
      return {
        msg: action.payload
      };
    }

    case ActionTypes.SEND_DOOR_UNLOCK_FAILED: {
      return {
        msg: action.payload
      };
    }

    //case ActionTypes.SEND_DOOR_LOCK_HOLD_FAILED: {
    //  return {
    //    msg: action.payload
    //  };
    //}

    case ActionTypes.RESET_ERROR_MESSAGE: {
      return {
        msg: ''
      };
      }


      case ActionTypes.CHANGE_OPERATOR_PASSWORD_SUCCEEDED: {
          return {
              msg: action.payload && action.payload == true ? '' : action.payload
          };
      }



    default:
      return state;
  }
};

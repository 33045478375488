import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Colors from '../../consts/colors';
import '../../style.css';
import * as actions from '../../actions/index';

class ScreenView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedSearchOption: 'firstName',
            searchText: ''
        };

        this.handleSearchText = this.handleSearchText.bind(this);
        this.handleSearchOption = this.handleSearchOption.bind(this);
    }

    handleSearchText(event) {
        this.props.resetInactivityTimer();
        this.setState({ searchText: event.target.value });
        this.props.onChangeText(event.target.value);
    }

    handleSearchOption(event) {
        //console.log('Seleced Option==>', event.target.value);
        this.setState({ selectedSearchOption: event.target.value });
        //console.log('Seleced Current==>', this.state.selectedSearchOption);
    }

    render() {
        return (
            <div
                onClick={() => {
                    this.props.resetInactivityTimer();
                }}
                style={styles.box}
            >
                <div style={styles.searchBox}>
                    <input
                        style={styles.inputType}
                        value={this.state.searchText}
                        onChange={this.handleSearchText}
                        placeholder={this.props.placeholder}
                        type="text"
                    />
                </div>
            </div>
        );
    }
}

const styles = {
    searchBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'stretch',
        alignContent: 'stretch'
    },
    box: {
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10
    },
    label: {
        paddingLeft: 20,
        paddingBottom: 5
    },
    buttonContainer: {
        flex: 1,
        height: 40,
        display: 'flex',
        backgroundColor: Colors.White,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    selectBox: {
        padding: 4,
        flex: 2
    },
    inputType: {
        padding: 3.8,
        marginLeft: 5,
        flex: 3
    }
};

const mapStateToProps = state => ({
    tmpViewSelection: state.settings.tmpViewSelection,
    connectStatus: state.connectStatus,
    generalMessage: state.generalMessage,
    loading: state.loading.doors
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            closeLoaderModal: actions.closeLoaderModal,
            resetInactivityTimer: actions.resetInactivityTimer
        },
        dispatch
    );

export default Radium(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ScreenView)
);

import * as ActionTypes from '../actions/types';

const defaultState = {
  currentPage: '',
  refreshStatus: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_PAGE_NAME:
      return {
        ...state,
        currentPage: action.payload
      };

    case ActionTypes.GET_CURRENT_PAGE_NAME:
      return state;

    case ActionTypes.REFRESH_CURRENT_PAGE:
      return { ...state, refreshStatus: true };

    case ActionTypes.RESET_REFRESH_STATUS:
      return { ...state, refreshStatus: false };

    default:
      return state;
  }
};

import * as ActionTypes from '../actions/types';

const defaultState = {
  baseURL: '',
  appBaseURL: ''
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.SET_BASE_URL: {
      return {
        ...state,
        baseURL: action.payload
      };
    }
    case ActionTypes.GET_BASE_URL: {
      return {
        ...state
      };
    }
    case ActionTypes.SET_APP_BASE_URL: {
      return {
        ...state,
        appBaseURL: action.payload
      };
    }
    case ActionTypes.GET_APP_BASE_URL: {
      return {
        ...state
      };
    }

    default:
      return state;
  }
};

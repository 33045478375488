import { put, call, takeLatest, select } from 'redux-saga/effects';
import moment from 'moment';
import * as workerTimers from 'worker-timers';
import * as ActionTypes from '../actions/types';
import * as Api from '../svc/api.js';
import {
    PAGE_NAMES,
    MSG_SYSTEM_OFFLINE,
    MSG_SYSTEM_ONLINE,
    MSG_MBL_STOPPED,
    MBL_STOPPED_ERROR_MESSAGE
} from '../consts/consts';
import { checkIfPINCommonCodeEnabled, updateBadgeInfoRequest } from '../actions';



export default function* httpSaga() {
    //yield console.log('running http saga');
    const  operatorID = yield select(getOperatorID);
    yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  + " --> Running http saga" });
    yield takeLatest(ActionTypes.LOGIN, logIn);
    yield takeLatest(ActionTypes.FETCH_ALL_DOORS, fetchAllDoors);
    yield takeLatest(
        ActionTypes.FETCH_ACCESS_READER_LIST_BY_BADGE,
        fetchAccessReaderListByBadge
    );
    yield takeLatest(ActionTypes.SEND_DOOR_LOCK, sendDoorLock);
    yield takeLatest(ActionTypes.SEND_DOOR_UNLOCK, sendDoorUnLock);
    yield takeLatest(ActionTypes.SEND_DOOR_UNLOCK_HOLD, sendDoorUnLockHold);
    yield takeLatest(ActionTypes.FETCH_ALL_THREAT_LEVELS, fetchAllThreatLevels);
    yield takeLatest(
        ActionTypes.SEND_ACTIVATE_ALL_THREAT_LEVELS,
        sendActivateAllThreatLevels
    );
    yield takeLatest(
        ActionTypes.SEND_DEACTIVATE_ALL_THREAT_LEVELS,
        sendDeActivateAllThreatLevels
    );
    yield takeLatest(
        ActionTypes.SEND_ACTIVATE_THE_THREAT_LEVEL,
        sendActivateTheThreatLevel
    );
    yield takeLatest(
        ActionTypes.SEND_DEACTIVATE_THE_THREAT_LEVEL,
        sendDeActivateTheThreatLevel
    );
    yield takeLatest(ActionTypes.FETCH_ALL_LOCKDOWN_AREA, fetchAllLockDownAreas);
    yield takeLatest(
        ActionTypes.SEND_ACTIVATE_ALL_LOCKDOWN_AREA,
        sendActivateAllLockdownArea
    );
    yield takeLatest(
        ActionTypes.SEND_DEACTIVATE_ALL_LOCKDOWN_AREA,
        sendDeactivateAllLockdownArea
    );
    yield takeLatest(
        ActionTypes.SEND_ACTIVATE_THE_LOCKDOWN_AREA,
        sendActivateTheLockdownArea
    );
    yield takeLatest(
        ActionTypes.SEND_DEACTIVATE_THE_LOCKDOWN_AREA,
        sendDeactivateTheLockdownArea
    );
    yield takeLatest(ActionTypes.FETCH_ALL_RELAYS, fetchAllRelays);
    yield takeLatest(ActionTypes.SEND_ACTIVATE_RELAY, sendActivateRelay);
    yield takeLatest(ActionTypes.SEND_DEACTIVATE_RELAY, sendDeactivateRelay);
    yield takeLatest(ActionTypes.SEND_ACTIVATE_HOLD_RELAY, sendActivateHoldRelay);
    yield takeLatest(ActionTypes.FETCH_ALL_DEVICE_TYPE, fetchAllDeviceTypeList);
    yield takeLatest(ActionTypes.FETCH_DEVICE_TYPE_LIST, fetchDeviceTypeList);
    yield takeLatest(ActionTypes.FETCH_DEVICE_STATUS_DETAIL, fetchStatusDetail);
    yield takeLatest(ActionTypes.FETCH_ALL_BADGEHOLDERIN, fetchAllBadgeHolderIn);
    yield takeLatest(ActionTypes.FETCH_TIME_SCHEDULES, fetchTimeScheduleList);
    yield takeLatest(
        ActionTypes.FETCH_SCHEDULE_CHANGES_LIST,
        fetchScheduleChangesList
    );
    yield takeLatest(ActionTypes.DELETE_SCHEDULE, deleteSchedule);
    yield takeLatest(
        ActionTypes.FETCH_DEVICE_LIST_BY_DEVICE_TYPE,
        fetchDeviceListByDeviceType
    );
    yield takeLatest(
        ActionTypes.FETCH_FIELD_LIST_BY_DEVICE_TYPE,
        fetchFieldListByDeviceType
    );
    yield takeLatest(
        ActionTypes.CREATE_NEW_SCHEDULE_CHANGES,
        createNewScheduleChanges
    );
    yield takeLatest(ActionTypes.UPDATE_SCHEDULE_CHANGES, updateScheduleChanges);
    yield takeLatest(ActionTypes.FETCH_ALL_PERSONNELS, fetchAllPersonnels);
    yield takeLatest(ActionTypes.FETCH_ACCESS_GROUP_LIST, fetchAccessGroupList);
    yield takeLatest(ActionTypes.DELETE_PERSONNEL, deletePersonnel);
    yield takeLatest(ActionTypes.CREATE_NEW_PERSONNEL, createNewPersonnel);
    yield takeLatest(ActionTypes.UPDATE_PERSONNEL, updatePersonnel);
    yield takeLatest(ActionTypes.FETCH_PERSONNEL, fetchPersonnel);
    //yield takeLatest(ActionTypes.SORT_PERSONNEL, sortPersonnels);
    yield takeLatest(ActionTypes.ADD_BADGE_TO_PERSONNEL, addBadgeToPersonnel);
    yield takeLatest(ActionTypes.POST_EVENT, postEvent);
    yield takeLatest(
        ActionTypes.FETCH_BADGES_BY_PERSON_NAME,
        fetchBadgesByPersonnelName
    );
    yield takeLatest(ActionTypes.FETCH_SYSTEM_SETTINGS, fetchSystemSettings);
    yield takeLatest(
        ActionTypes.CANCEL_ACTIVE_SCHEDULE_CHANGES,
        cancelActiveScheduleChanges
    );
    yield takeLatest(ActionTypes.CHECK_BADGE_EXISTS, checkIfBadgeExits);
    yield takeLatest(ActionTypes.FETCH_PERSONNELS_COUNT, fetchPersonnelsCount);
    yield takeLatest(ActionTypes.FETCH_PAGED_PERSONNELS, fetchPagedPersonnels);
    yield takeLatest(
        ActionTypes.FETCH_BADGE_LIST_BY_PERSONNEL_ID,
        fetchBadgeListByPersonnelId
    );
    yield takeLatest(ActionTypes.ADD_AND_SHOW_MESSAGE, addAndShowMessage2);
    yield takeLatest(
        ActionTypes.REFRESH_APP_CONFIG_SETTINGS,
        fetchBadgesByPersonnelName
    );
    yield takeLatest(
        ActionTypes.REFRESH_APP_CONFIG_SETTINGS,
        fetchSystemSettings
    );
    yield takeLatest(ActionTypes.FETCH_DOORS_COUNT, fetchDoorsCount);
    yield takeLatest(ActionTypes.FETCH_PAGED_DOORS, fetchPagedDoors);
    yield takeLatest(ActionTypes.LOG_ERROR, logError);
    yield takeLatest(ActionTypes.GET_CONNECTION_STATUS, getConnectionStatus);
    yield takeLatest(ActionTypes.CHECK_IF_PARTITION_EXISTS, checkIfPartitionExists);
    //yield takeLatest(ActionTypes.SEND_MY_DOOR_UNLOCK, sendMyDoorUnLock);
    //yield takeLatest(ActionTypes.CHECK_IF_PIN_COMMONCODE_ENABLED, checkIfPINCommonCodeEnabled);
    yield takeLatest(ActionTypes.UPDATE_BAGDE_INFO_REQUEST, updateBadgeInfoRequest);
    yield takeLatest(ActionTypes.CHANGE_OPERATOR_PASSWORD, changeOperatorPassword);
    yield takeLatest(ActionTypes.ENABLE_DEBUG_LOG, enableDebugLog);
    yield takeLatest(ActionTypes.WRITE_LOG, writeLog);
}

function* addAndShowMessage2(action) {
    yield call(addAndShowMessage, action.payload);
}

function* addAndShowMessage(payload) {
    const generalMessageBefore = yield select(getGeneralMessage);

    yield put({
        type: ActionTypes.ADD_MESSAGE,
        payload
    });
   
    const generalMessageAfter = yield select(getGeneralMessage);

    const lastMessageTime = generalMessageBefore.messageTimeList.slice(-1)[0];
    const nextMessageTime = generalMessageAfter.messageTimeList.slice(-1)[0];
    const newMessageArrive = nextMessageTime !== lastMessageTime;

    console.log('In add new message:');

   
    if (payload === MSG_SYSTEM_OFFLINE) {

        const offlineIndex = generalMessageBefore.messageList.lastIndexOf(MSG_SYSTEM_OFFLINE);
        const onlineIndex = generalMessageBefore.messageList.lastIndexOf(MSG_SYSTEM_ONLINE);
        if (offlineIndex > onlineIndex) {
            console.log('Offline condition applied:', newMessageArrive);
              return ;
        }
    }
    else if (payload === MSG_SYSTEM_ONLINE) {
        const offlineIndex = generalMessageBefore.messageList.lastIndexOf(MSG_SYSTEM_OFFLINE);
        const onlineIndex = generalMessageBefore.messageList.lastIndexOf(MSG_SYSTEM_ONLINE);

        if (offlineIndex < 0 || onlineIndex > offlineIndex) {
            console.log('Online condition applied:', newMessageArrive);
            return;
        }
    }

    if (newMessageArrive) {

     console.log('new message arrived is true');
        yield put({
            type: ActionTypes.SHOW_GENERAL_MESSAGE,
            payload: true
        });

        const settings = yield select(getSettings);

        if (!settings.messageOptionUntilCleared) {
           
            const delayTime = settings.tmpMsgDisplayTimeInSeconds * 1000;
             console.log('delayed', settings.tmpMsgDisplayTimeInSeconds);
            yield delay(delayTime);
           
            yield put({
                type: ActionTypes.SHOW_GENERAL_MESSAGE,
                payload: false
            });
            console.log('after delayed');

        }
    }
}

const delay = ms => new Promise(res => workerTimers.setTimeout(res, ms));

function* logIn(action) {
    const operatorID = yield select(getOperatorID);
    try {
        
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> Logging In.." });
        const baseURL = yield select(getBaseURL);
        const response = yield call(Api.logIN, action.payload.userInfo, baseURL);
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " --> httpSaga-> LogIn--> Http Response: " + JSON.stringify(response)});

        yield put({
            type: ActionTypes.SET_LOGIN_REMEMBER,
            payload: action.payload.userInfo
        });

        yield put({ type: ActionTypes.LOGIN_SUCCEEDED, payload: response.data });

        yield put({ type: ActionTypes.RESET_INACTIVITY_TIMER });

        yield call(
            addAndShowMessage,
            `Logged in at [${moment().format('DD-MMM-YYYY hh:mm A')}]`
        );

        yield put({
            type: ActionTypes.ADD_LOGGED_IN_TIME,
            payload: moment().format('DD-MMM hh:mm A')
        });

        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield put({ type: ActionTypes.FETCH_SYSTEM_SETTINGS });
    } catch (error) {
        //yield console.log('http>>error :', error);
        try {
            yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> LogIn--> Http>> Error : " + error.response ?  error.response: "Failed"});
            yield put({
                type: ActionTypes.LOGIN_FAILED,
                payload: error.response.data.error_description
                    ? error.response.data.error_description
                    : error.response.data
            });

            yield put({ type: ActionTypes.CONNECTION_OFFLINE });
        } catch (exception) {
            //console.log(exception);
            yield put({
                type: ActionTypes.LOGIN_FAILED,
                payload: exception
            });
            yield put({ type: ActionTypes.CONNECTION_OFFLINE });
            yield call(addAndShowMessage, MSG_SYSTEM_OFFLINE);
        }
    }
}

function* fetchAllDoors(action) {
    //yield console.log('fetching all doors');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);
    const requestWirelessLockStatus = yield select(getRequestWirelessLockStatus);

    try {
       
        const response = yield call(
            Api.fetchAllDoors,
            accessToken,
            baseURL,
            action.payload,
            requestWirelessLockStatus
        );
       
        yield put({
            type: ActionTypes.FETCH_ALL_DOORS_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> fetchAllDoors--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.FETCH_ALL_DOORS_FAILED);
    }
}

function* fetchAccessReaderListByBadge(action) {
    //yield console.log('fetching all doors by badge no, facilitiy', action.payload);
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);
    try {
       
        const response = yield call(
            Api.fetchAccessReaderListByBadge,
            accessToken,
            baseURL,
            action.payload.badgeNo ? action.payload.badgeNo : '',
            action.payload.facility,
            action.payload.requestStatus ? action.payload.requestStatus : false,
            action.payload.wlockRequestStatus
                ? action.payload.wlockRequestStatus
                : false
        );
       
        yield put({
            type: ActionTypes.FETCH_ACCESS_READER_LIST_BY_BADGE_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> fetchAccessReaderListByBadge--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.FETCH_ACCESS_READER_LIST_BY_BADGE_FAILED);
    }
}

function* sendDoorLock(action) {
    //yield console.log('sending door lock ....', action.payload);
    const operatorID = yield select(getOperatorID);
    try {
       
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendDoorLock-->" + JSON.stringify(action.payload)});
        const baseURL = yield select(getBaseURL);
        const response = yield call(Api.sendLockTheDoor, action.payload, baseURL);
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendDoorLock--> Http Response" + JSON.stringify(response)});
        yield put({
            type: ActionTypes.SEND_DOOR_LOCK_SUCCEEDED,
            payload: response.data
        });
        //  yield put({ type: ActionTypes.CONNECTION_ONLINE });
        //  yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga->sendDoorLock--> Http>> Error : " + error.response ? error.response : "Failed"});
        yield call(handleError, error, ActionTypes.SEND_DOOR_LOCK_FAILED);
    }
}

function* sendDoorUnLock(action) {
    const currentPage = yield select(getCurrentPage);
    const operatorID = yield select(getOperatorID);
    //yield console.log('sending door unlock ....', action.payload);
    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendDoorUnLock-->" + JSON.stringify(action.payload) });
        const baseURL = yield select(getBaseURL);
        const response = yield call(Api.sendUnLockTheDoor, action.payload, baseURL);
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendDoorUnLock--> Http Response" + JSON.stringify(response) });
        yield put({
            type: ActionTypes.SEND_DOOR_LOCK_SUCCEEDED,
            payload: response.data
        });
        //yield put({ type: ActionTypes.CONNECTION_ONLINE });
        // yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
        if (currentPage !== PAGE_NAMES.CONTROL) {
            yield put({ type: ActionTypes.GET_DOOR_STATUS });
        }
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga->sendDoorUnLock--> Http>> Error : " + error.response ? error.response : "Failed"});
        yield call(handleError, error, ActionTypes.SEND_DOOR_LOCK_FAILED);
    }
}



//function* sendMyDoorUnLock(action) {
//    const currentPage = yield select(getCurrentPage);
//    //yield console.log('sending door unlock ....', action.payload);
//    try {
//        const baseURL = yield select(getBaseURL);
//        const response = yield call(Api.sendUnLockMyDoor, action.payload, baseURL);
//        //yield console.log('http>response :', response);
//        yield put({
//            type: ActionTypes.SEND_MY_DOOR_UNLOCK_SUCCEEDED,
//            payload: response.data
//        });
//        //yield put({ type: ActionTypes.CONNECTION_ONLINE });
//        // yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
        
//    } catch (error) {
//        yield call(handleError, error, ActionTypes.SEND_MY_DOOR_UNLOCK_FAILED);
//    }
//}



function* sendDoorUnLockHold(action) {
    //yield console.log('sending door unlock hold ....');
    const operatorID = yield select(getOperatorID);
    try {
       
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendDoorUnLockHold-->" + JSON.stringify(action.payload) });
        const baseURL = yield select(getBaseURL);
        const response = yield call(
            Api.sendUnLockHoldTheDoor,
            action.payload,
            baseURL
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendDoorUnLockHold--> Http Response" + JSON.stringify(response) });
        yield put({
            type: ActionTypes.SEND_DOOR_UNLOCK_HOLD_SUCCEEDED,
            payload: response.data
        });
        //yield put({ type: ActionTypes.CONNECTION_ONLINE });
        //   yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga->sendDoorUnLock--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.SEND_DOOR_UNLOCK_HOLD_FAILED);
    }
}

function* fetchAllThreatLevels() {
    //yield console.log('fetching all threat levels');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);
    try {
       
        const response = yield call(Api.fetchAllThreatLevels, accessToken, baseURL);
       
        yield put({
            type: ActionTypes.FETCH_ALL_THREAT_LEVELS_SUCCEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> fetchAllThreatLevels--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.FETCH_ALL_THREAT_LEVELS_FAILED);
    }
}

function* sendActivateAllThreatLevels() {
    //yield console.log('sending activate all threat levels....');
    const operatorID = yield select(getOperatorID);
    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga-->sendActivateAllThreatLevels.." });
        const baseURL = yield select(getBaseURL);
        const accessToken = yield select(getAccessToken);

        const response = yield call(
            Api.sendActivateAllThreatLevels,
            accessToken,
            baseURL
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendActivateAllThreatLevels--> Http Response" + JSON.stringify(response) });
        //yield console.log('sending activate all threat levels....', response);
        yield put({
            type: ActionTypes.SEND_ACTIVATE_ALL_THREAT_LEVELS_SUCCEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> sendActivateAllThreatLevels--> Http>> Error : " + + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.SEND_ACTIVATE_ALL_THREAT_LEVELS_FAILED);
    }
}

function* sendDeActivateAllThreatLevels() {
    //yield console.log('sending deactivate all threat levels....');
    const operatorID = yield select(getOperatorID);
    try {
       
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga-->sendDeActivateAllThreatLevels.." });
        const baseURL = yield select(getBaseURL);
        const accessToken = yield select(getAccessToken);

        const response = yield call(
            Api.sendDeactivateAllThreatLevels,
            accessToken,
            baseURL
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendDeActivateAllThreatLevels--> Http Response" + JSON.stringify(response) });
        //yield console.log('sending deactivate all threat levels....', response);
        yield put({
            type: ActionTypes.SEND_DEACTIVATE_ALL_THREAT_LEVELS_SUCCEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> sendDeActivateAllThreatLevels--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.SEND_DEACTIVATE_ALL_THREAT_LEVELS_FAILED);
    }
}

function* sendActivateTheThreatLevel(action) {
    //yield console.log('sending activate the threat level....', action.payload);
    const operatorID = yield select(getOperatorID);
    try {
       
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga-->sendActivateTheThreatLevel--> " + JSON.stringify(action.payload)});
        const baseURL = yield select(getBaseURL);
        const accessToken = yield select(getAccessToken);

        const response = yield call(
            Api.sendActivateTheThreatLevel,
            accessToken,
            baseURL,
            action.payload
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendActivateTheThreatLevel--> Http Response" + JSON.stringify(response) });
        //yield console.log('sending activate the threat level....', response);
        yield put({
            type: ActionTypes.SEND_ACTIVATE_THE_THREAT_LEVEL_SUCCEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> sendActivateTheThreatLevel--> Http>> Error : " + error.response ? error.response : "Failed"});
        yield call(handleError, error, ActionTypes.SEND_ACTIVATE_THE_THREAT_LEVEL_FAILED);
    }
}

function* sendDeActivateTheThreatLevel(action) {
    //yield console.log('sending deactivate the threat level....', action.payload);
    const operatorID = yield select(getOperatorID);
    try {
       
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga-->sendDeActivateTheThreatLevel--> " + JSON.stringify(action.payload) });
        const baseURL = yield select(getBaseURL);
        const accessToken = yield select(getAccessToken);

        const response = yield call(
            Api.sendDeactivateTheThreatLevel,
            accessToken,
            baseURL,
            action.payload
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendDeActivateTheThreatLevel--> Http Response" + JSON.stringify(response) });
        //yield console.log('sending deactivate the threat level....', response);
        yield put({
            type: ActionTypes.SEND_DEACTIVATE_THE_THREAT_LEVEL_SUCCEDED,
            payload: response.datas
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> sendDeActivateTheThreatLevel--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.SEND_DEACTIVATE_THE_THREAT_LEVEL_FAILED);
    }
}

function* fetchAllLockDownAreas() {
    //yield console.log('fetching all lockdown areas');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);
    try {
      
       
        const response = yield call(Api.fetchAllLockDownArea, accessToken, baseURL);
       
        yield put({
            type: ActionTypes.FETCH_ALL_LOCKDOWN_AREA_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> fetchAllLockDownAreas--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.FETCH_ALL_LOCKDOWN_AREA_FAILED);
    }
}

function* sendActivateAllLockdownArea() {
    //yield console.log('sending activate all the lockdown area....');
    const operatorID = yield select(getOperatorID);
    try {
        
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga-->sendActivateAllLockdownArea... " });
        const baseURL = yield select(getBaseURL);
        const accessToken = yield select(getAccessToken);

        const response = yield call(
            Api.sendActivateAllLockdownArea,
            accessToken,
            baseURL
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendActivateAllLockdownArea --> Http Response" + JSON.stringify(response) });
        //yield console.log('sending activate all lock down area....', response);
        yield put({
            type: ActionTypes.SEND_ACTIVATE_ALL_LOCKDOWN_AREA_SUCCEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> sendActivateAllLockdownArea--> Http>> Error : " +  error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.SEND_ACTIVATE_ALL_LOCKDOWN_AREA_FAILED);
    }
}

function* sendDeactivateAllLockdownArea() {
    //yield console.log('sending deactivate all the lockdown area....');
    const operatorID = yield select(getOperatorID);
    try {
       
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga-->sendDeactivateAllLockdownArea... " });
        const baseURL = yield select(getBaseURL);
        const accessToken = yield select(getAccessToken);

        const response = yield call(
            Api.sendDeactivateAllLockdownArea,
            accessToken,
            baseURL
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendDeactivateAllLockdownArea --> Http Response" + JSON.stringify(response) });
        //yield console.log('sending deactivate all lock down area....', response);
        yield put({
            type: ActionTypes.SEND_DEACTIVATE_ALL_LOCKDOWN_AREA_SUCCEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> sendDeactivateAllLockdownArea--> Http>> Error : " + + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.SEND_DEACTIVATE_ALL_LOCKDOWN_AREA_FAILED);
    }
}

function* sendActivateTheLockdownArea(action) {
    //yield console.log('sending activate the lockdown area....', action.payload);
    const operatorID = yield select(getOperatorID);
    try {
       
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga-->sendActivateTheLockdownArea-->" + JSON.stringify(action.payload)});
        const baseURL = yield select(getBaseURL);
        const accessToken = yield select(getAccessToken);

        const response = yield call(
            Api.sendActivateTheLockdownArea,
            accessToken,
            baseURL,
            action.payload
        );

        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga-->sendActivateTheLockdownArea-->" + JSON.stringify(response) });
        //yield console.log('sending activate the lockdown area....', response);
        yield put({
            type: ActionTypes.SEND_ACTIVATE_THE_LOCKDOWN_AREA_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> sendActivateTheLockdownArea--> Http>> Error : " +error.response ? error.response : "Failed"});
        yield call(handleError, error, ActionTypes.SEND_ACTIVATE_THE_LOCKDOWN_AREA_FAILED);
    }
}

function* sendDeactivateTheLockdownArea(action) {
    //yield console.log('sending deactivate the lockdown area....', action.payload);
    const operatorID = yield select(getOperatorID);
    try {
        
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendDeactivateTheLockdownArea-->" + JSON.stringify(action.payload) });
        const baseURL = yield select(getBaseURL);
        const accessToken = yield select(getAccessToken);

        const response = yield call(
            Api.sendDeactivateTheLockdownArea,
            accessToken,
            baseURL,
            action.payload
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga-->sendDeactivateTheLockdownArea-->" + JSON.stringify(response) });
        //yield console.log('sending deactivate the lockdown area....', response);
        yield put({
            type: ActionTypes.SEND_DEACTIVATE_THE_LOCKDOWN_AREA_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> sendDeactivateTheLockdownArea--> Http>> Error : " + error.response ? error.response : "Failed"});
        yield call(handleError, error, ActionTypes.SEND_DEACTIVATE_THE_LOCKDOWN_AREA_FAILED);
    }
}

function* fetchAllRelays(action) {
    //yield console.log('fetching all relays', action.payload);
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);
    try {
        
        
        const response = yield call(
            Api.fetchAllRelays,
            accessToken,
            baseURL,
            action.payload
        );
       
        yield put({
            type: ActionTypes.FETCH_ALL_RELAYS_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> fetchAllRelays--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.FETCH_ALL_RELAYS_FAILED);
    }
}

function* sendActivateRelay(action) {
    //yield console.log('sending activate  relay....', action.payload);
    const operatorID = yield select(getOperatorID);
    try {
        const baseURL = yield select(getBaseURL);
        const accessToken = yield select(getAccessToken);
     
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendActivateRelay-->" + JSON.stringify(action.payload) });
        const response = yield call(
            Api.sendActivateRelay,
            accessToken,
            baseURL,
            action.payload.panelNo,
            action.payload.relayNo
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendActivateRelay-->" + JSON.stringify(response) });
        //yield console.log('sending activate relay....', response);
        yield put({
            type: ActionTypes.SEND_ACTIVATE_RELAY_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> fetchAllRelays--> Http>> Error : " + error.response ? error.response : "Failed"});
        yield call(handleError, error, ActionTypes.SEND_ACTIVATE_RELAY_FAILED);
    }
}

function* sendDeactivateRelay(action) {
    //yield console.log('sending deactivate  relay....', action.payload);
    const operatorID = yield select(getOperatorID);
    try {
      
        const baseURL = yield select(getBaseURL);
        const accessToken = yield select(getAccessToken);
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendDeactivateRelay -->" + JSON.stringify(action.payload) });

        const response = yield call(
            Api.sendDeactivateRelay,
            accessToken,
            baseURL,
            action.payload.panelNo,
            action.payload.relayNo
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendDeactivateRelay-->" + JSON.stringify(response) });
        //yield console.log('sending deactivate relay....', response);
        yield put({
            type: ActionTypes.SEND_DEACTIVATE_RELAY_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> sendDeactivateRelay--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.SEND_DEACTIVATE_RELAY_FAILED);
    }
}

function* sendActivateHoldRelay(action) {
    //yield console.log('sending activate & Hold relay.... activate hold', action.payload);
    const operatorID = yield select(getOperatorID);
    try {
      
        const baseURL = yield select(getBaseURL);
        const accessToken = yield select(getAccessToken);
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendActivateHoldRelay -->" + JSON.stringify(action.payload) });

        const response = yield call(
            Api.sendActivateHoldRelay,
            accessToken,
            baseURL,
            action.payload.panelNo,
            action.payload.relayNo
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> sendActivateHoldRelay-->" + JSON.stringify(response) });
        //yield console.log('sending activate & hold relay.... response', response);
        yield put({
            type: ActionTypes.SEND_ACTIVATE_HOLD_RELAY_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> sendActivateHoldRelay--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.SEND_ACTIVATE_HOLD_RELAY_FAILED);
    }
}

function* fetchAllDeviceTypeList(action) {
    yield console.log('getting all device type ', action.payload);
    const operatorID = yield select(getOperatorID);
    try {
     
      
        const baseURL = yield select(getBaseURL);
        const accessToken = yield select(getAccessToken);

        const response = yield call(
            Api.fetchAllDeviceTypeList,
            accessToken,
            baseURL
        );

        
        yield put({
            type: ActionTypes.FETCH_ALL_DEVICE_TYPE_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> fetchAllDeviceTypeList--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.FETCH_ALL_DEVICE_TYPE_FAILED);
    }
}

function* fetchDeviceTypeList(action) {
    //yield console.log('getting  device types ', action.payload);
    const operatorID = yield select(getOperatorID);
    try {
       
       
        const baseURL = yield select(getBaseURL);
        const accessToken = yield select(getAccessToken);

        const response = yield call(
            Api.fetchDeviceTypeList,
            accessToken,
            baseURL,
            action.payload
        );
       
        //yield console.log('getting  device type list.... response', response);
        yield put({
            type: ActionTypes.FETCH_DEVICE_TYPE_LIST_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> fetchDeviceTypeList--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.FETCH_DEVICE_TYPE_LIST_FAILED);
    }
}

function* fetchStatusDetail(action) {
    //yield console.log('getting status detail', action.payload);
    const operatorID = yield select(getOperatorID);
    try {
       
       
        const baseURL = yield select(getBaseURL);
        const accessToken = yield select(getAccessToken);

        const refreshStatus = yield select(getRefreshStatus);
        const requestWirelessLockStatus = yield select(getRequestWirelessLockStatus);


        const response = yield call(
            Api.fetchDeviceStatusDetail,
            accessToken,
            baseURL,
            action.payload,
            refreshStatus,
            requestWirelessLockStatus
        );
       
        //yield console.log('getting status detail.... response', response);
        yield put({
            type: ActionTypes.FETCH_DEVICE_STATUS_DETAIL_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> fetchStatusDetail--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.FETCH_DEVICE_STATUS_DETAIL_FAILED);
    }
}

function* fetchAllBadgeHolderIn() {
    //yield console.log('fetching all badgeholderin');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);
    try {
      
        const response = yield call(
            Api.fetchAllBadgeHolderIn,
            accessToken,
            baseURL
        );
        yield put({
            type: ActionTypes.FETCH_ALL_BADGEHOLDERIN_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> fetchAllBadgeHolderIn--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.FETCH_ALL_BADGEHOLDERIN_FAILED);
    }
}

function* fetchScheduleChangesList() {
    //yield console.log('fetching schedulechanges list');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        
        const response = yield call(
            Api.fetchScheduleChangesList,
            accessToken,
            baseURL
        );
       
        yield put({
            type: ActionTypes.FETCH_SCHEDULE_CHANGES_LIST_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> fetchScheduleChangesList--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.FETCH_SCHEDULE_CHANGES_LIST_FAILED);
    }
}

function* deleteSchedule(action) {
    //yield console.log('deleting schedule...');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> deleteSchedule -->" + JSON.stringify(action.payload)});
        const response = yield call(
            Api.deleteSchedule,
            accessToken,
            baseURL,
            action.payload
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> deleteSchedule-->" + JSON.stringify(response) });
        yield put({
            type: ActionTypes.DELETE_SCHEDULE_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> deleteSchedule--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.DELETE_SCHEDULE_FAILED);
    }
}

function* fetchTimeScheduleList() {
    //yield console.log('fetching time schedule list');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
      
        const response = yield call(
            Api.fetchTimeScheduleList,
            accessToken,
            baseURL
        );
       
        yield put({
            type: ActionTypes.FETCH_TIME_SCHEDULES_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> fetchTimeScheduleList--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.FETCH_TIME_SCHEDULES_FAILED);
    }
}

function* fetchDeviceListByDeviceType(action) {
    //yield console.log('fetching device list by device type..');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> fetchDeviceListByDeviceType -->" + JSON.stringify(action.payload)});
        const response = yield call(
            Api.fetchDeviceListByDeviceType,
            accessToken,
            baseURL,
            action.payload
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> fetchDeviceListByDeviceType -->" + JSON.stringify(response) });
        yield put({
            type: ActionTypes.FETCH_DEVICE_LIST_BY_DEVICE_TYPE_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> fetchDeviceListByDeviceType--> Http>> Error : " + error.response ? error.response : "Failed"});
        yield call(handleError, error, ActionTypes.FETCH_DEVICE_LIST_BY_DEVICE_TYPE_FAILED);
    }
}

function* fetchFieldListByDeviceType(action) {
    //yield console.log('fetching field list by device type..');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> fetchFieldListByDeviceType -->" + JSON.stringify(action.payload) });
        const response = yield call(
            Api.fetchFieldListByDeviceType,
            accessToken,
            baseURL,
            action.payload
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> fetchFieldListByDeviceType -->" + JSON.stringify(response) });
        yield put({
            type: ActionTypes.FETCH_FIELD_LIST_BY_DEVICE_TYPE_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> fetchFieldListByDeviceType--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.FETCH_FIELD_LIST_BY_DEVICE_TYPE_FAILED);
    }
}

function* createNewScheduleChanges(action) {
    //yield console.log('creating new schedule changes..');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> createNewScheduleChanges -->" + JSON.stringify(action.payload) });
        const response = yield call(
            Api.createNewScheduleChanges,
            accessToken,
            baseURL,
            action.payload
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> createNewScheduleChanges -->" + JSON.stringify(response)});
        yield put({
            type: ActionTypes.CREATE_NEW_SCHEDULE_CHANGES_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +" --> httpSaga-> createNewScheduleChanges--> Http>> Error : " + error.response ? error.response : "Failed" });
        yield call(handleError, error, ActionTypes.CREATE_NEW_SCHEDULE_CHANGES_FAILED);
    }
}

function* updateScheduleChanges(action) {
    //yield console.log('updating chedule changes..');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> updateScheduleChanges -->" + JSON.stringify(action.payload) });
        const response = yield call(
            Api.updateScheduleChanges,
            accessToken,
            baseURL,
            action.payload
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> updateScheduleChanges -->" + JSON.stringify(response) });
        yield put({
            type: ActionTypes.UPDATE_SCHEDULE_CHANGES_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> updateScheduleChanges--> Http>> Error : " + error.response ? error.response : "Failed"});
        yield call(handleError, error, ActionTypes.UPDATE_SCHEDULE_CHANGES_FAILED);
    }
}

function* fetchAllPersonnels() {
    //yield console.log('fetching all personnels ');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
       
        const response = yield call(Api.fetchAllPersonnels, accessToken, baseURL);
       
        yield put({
            type: ActionTypes.FETCH_ALL_PERSONNELS_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> fetchAllPersonnels--> Http>> Error : " + error.response ? error.response : "Failed"});
        yield call(handleError, error, ActionTypes.FETCH_ALL_PERSONNELS_FAILED);
    }
}

function* fetchAccessGroupList() {
    //yield console.log('fetching access group list ');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        
        const response = yield call(Api.fetchAccessGroupList, accessToken, baseURL);
       
        yield put({
            type: ActionTypes.FETCH_ACCESS_GROUP_LIST_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> fetchAccessGroupList--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.FETCH_ACCESS_GROUP_LIST_FAILED);
    }
}

function* deletePersonnel(action) {
    //yield console.log('delete personnel');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> deletePersonnel -->" + JSON.stringify(action.payload)});
        const response = yield call(
            Api.deletePersonnel,
            accessToken,
            baseURL,
            action.payload
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> deletePersonnel -->" + JSON.stringify(response) });
        yield put({
            type: ActionTypes.DELETE_PERSONNEL_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> deletePersonnel--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.DELETE_PERSONNEL_FAILED);
    }
}

function* createNewPersonnel(action) {
    //yield console.log('creating new personnel...');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> createNewPersonnel -->" + JSON.stringify(action.payload) });
        const response = yield call(
            Api.createNewPersonnel,
            accessToken,
            baseURL,
            action.payload
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> createNewPersonnel -->" + JSON.stringify(response) });
        yield put({
            type: ActionTypes.CREATE_NEW_PERSONNEL_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> createNewPersonnel--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.CREATE_NEW_PERSONNEL_FAILED);
    }
}

function* updatePersonnel(action) {
    //yield console.log('updating personnel...');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    //console.log('action---payload', action.payload);
    const operatorID = yield select(getOperatorID);
    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> updatePersonnel -->" + JSON.stringify(action.payload) });
        const response = yield call(
            Api.updatePersonnel,
            accessToken,
            baseURL,
            action.payload
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> updatePersonnel -->" + JSON.stringify(response) });
        yield put({
            type: ActionTypes.UPDATE_PERSONNEL_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> updatePersonnel--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.UPDATE_PERSONNEL_FAILED);
    }
}

function* fetchPersonnel(action) {
    //yield console.log('fetching personnel...');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> fetchPersonnel -->" + JSON.stringify(action.payload) });
        const response = yield call(
            Api.fetchPersonnel,
            accessToken,
            baseURL,
            action.payload.id,
            action.payload.badge,
            action.payload.facility
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> fetchPersonnel -->" + JSON.stringify(response) });
        yield put({
            type: ActionTypes.FETCH_PERSONNEL_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> fetchPersonnel--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.FETCH_PERSONNEL_FAILED);
    }
}

function* addBadgeToPersonnel(action) {
    //yield console.log('adding badge to personnel...');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> addBadgeToPersonnel -->" + JSON.stringify(action.payload) });
        const response = yield call(
            Api.addBadgeToPersonnel,
            accessToken,
            baseURL,
            action.payload
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> addBadgeToPersonnel -->" + JSON.stringify(response) });
        yield put({
            type: ActionTypes.ADD_BADGE_TO_PERSONNEL_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> addBadgeToPersonnel--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.ADD_BADGE_TO_PERSONNEL_FAILED);
    }
}

function* postEvent(action) {
    //yield console.log('posting event...');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> postEvent -->" + JSON.stringify(action.payload) });
        const response = yield call(
            Api.postEvent,
            accessToken,
            baseURL,
            action.payload
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> postEvent -->" + JSON.stringify(response) });
        yield put({
            type: ActionTypes.POST_EVENT_SUCCEEDED,
            payload: response.data
        });
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> postEvent--> Http>> Error : " + error.response ? error.response : "Failed"})
        yield call(handleError, error, ActionTypes.POST_EVENT_FAILED);
    }
}

function* fetchBadgesByPersonnelName(action) {
    //yield console.log('fetching badges by personnel name...', action.payload.firstName);

    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);
    const operatorFirstName = yield select(getOperatorFirstName);
    const operatorLastName = yield select(getOperatorLastName);

    const name = { firstName: operatorFirstName, lastName: operatorLastName };

    try {
      
        const response = yield call(
            Api.fetchBadgesByPersonnelName,
            accessToken,
            baseURL,
            name
        );
        
        yield put({
            type: ActionTypes.FETCH_BADGES_BY_PERSON_NAME_SUCCEEDED,
            payload: response.data
        });
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> fetchBadgesByPersonnelName--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.FETCH_BADGES_BY_PERSON_NAME_FAILED);
    }
}

function* fetchSystemSettings() {
    //yield console.log('fetching system settings');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        
        const response = yield call(Api.fetchSystemSettings, accessToken, baseURL);
        yield put({
            type: ActionTypes.FETCH_SYSTEM_SETTINGS_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> fetchSystemSettings--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.FETCH_SYSTEM_SETTINGS_FAILED);
    }
}

function* cancelActiveScheduleChanges(action) {
    //yield console.log('cancelling active schedule changes...');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {

        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> cancelActiveScheduleChanges ..." + JSON.stringify(action.payload)});
        const response = yield call(
            Api.cancelActiveScheduleChanges,
            accessToken,
            baseURL,
            action.payload.caObjectId,
            action.payload.keepSchedule
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> cancelActiveScheduleChanges -->" + JSON.stringify(response) });
        yield put({
            type: ActionTypes.CANCEL_ACTIVE_SCHEDULE_CHANGES_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.FETCH_SCHEDULE_CHANGES_LIST });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> cancelActiveScheduleChanges--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.CANCEL_ACTIVE_SCHEDULE_CHANGES_FAILED);
    }
}

function* checkIfBadgeExits(action) {
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> checkIfBadgeExits ..." + JSON.stringify(action.payload) });
        const response = yield call(
            Api.checkIfBadgeExists,
            accessToken,
            baseURL,
            action.payload.badgeNo ? action.payload.badgeNo : '',
            action.payload.facility ? action.payload.facility : ''
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> checkIfBadgeExits -->" + JSON.stringify(response) });
        yield put({
            type: ActionTypes.CHECK_BADGE_EXISTS_SUCCEEDED,
            payload: response.data
        });
        yield put({
            type: ActionTypes.CONNECTION_ONLINE,
            payload: response.data
        });
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> checkIfBadgeExits--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.CHECK_BADGE_EXISTS_FAILED);
    }
}

function* fetchPersonnelsCount(action) {
    //yield console.log('fetching personnels count');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        
        const response = yield call(
            Api.fetchPersonnelsCount,
            accessToken,
            baseURL,
            action.payload
        );
       
        yield put({
            type: ActionTypes.FETCH_PERSONNELS_COUNT_SUCCEEDED,
            payload: response.data
        });
        yield put({
            type: ActionTypes.CONNECTION_ONLINE
        });
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> fetchPersonnelsCount--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.FETCH_PERSONNELS_COUNT_FAILED);
    }
}

function* fetchPagedPersonnels(action) {
    //yield console.log('fetching paged personnels ');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        
        const response = yield call(
            Api.fetchPagedPersonnels,
            accessToken,
            baseURL,
            action.payload
        );
       

        yield put({
            type: ActionTypes.FETCH_PAGED_PERSONNELS_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> fetchPagedPersonnels--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.FETCH_PAGED_PERSONNELS_FAILED);
    }
}

function* fetchBadgeListByPersonnelId(action) {
    //yield console.log('fetching badges by personnel id');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> fetchBadgeListByPersonnelId ..." + JSON.stringify(action.payload) });
        const response = yield call(
            Api.fetchBadgeListByPersonnelId,
            accessToken,
            baseURL,
            action.payload
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> fetchPagedPersonnels -->" + JSON.stringify(response) });

        yield put({
            type: ActionTypes.FETCH_BADGE_LIST_BY_PERSONNEL_ID_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> fetchPagedPersonnels--> Http>> Error : " + error.response ? error.response : "Failed"})
        yield call(handleError, error, ActionTypes.FETCH_BADGE_LIST_BY_PERSONNEL_ID_FAILED);
    }
}

function* fetchDoorsCount(action) {
    //yield console.log('fetching doors count');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
       
        const response = yield call(
            Api.fetchDoorsCount,
            accessToken,
            baseURL,
            action.payload
        );
       
        yield put({
            type: ActionTypes.FETCH_DOORS_COUNT_SUCCEEDED,
            payload: response.data
        });
        yield put({
            type: ActionTypes.CONNECTION_ONLINE
        });
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> fetchDoorsCount--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.FETCH_DOORS_COUNT_FAILED);
    }
}

function* fetchPagedDoors(action) {
    //yield console.log('fetching paged doors ');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
       
        const response = yield call(
            Api.fetchPagedDoors,
            accessToken,
            baseURL,
            action.payload
        );
      

        yield put({
            type: ActionTypes.FETCH_PAGED_DOORS_SUCCEEDED,
            payload: response.data
        });
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + " --> httpSaga-> fetchPagedDoors--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.FETCH_PAGED_DOORS_FAILED);
    }
}

function* getConnectionStatus(action) {
    //yield console.log('fetching paged doors ');
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);

    try {
        const response = yield call(
            Api.getConnectionStatus,
            accessToken,
            baseURL
        );
       
        //yield put({
        //    type: ActionTypes.FETCH_PAGED_DOORS_SUCCEEDED,
        //    payload: response.data
        //});
        yield put({ type: ActionTypes.CONNECTION_ONLINE });
        yield call(addAndShowMessage, MSG_SYSTEM_ONLINE);
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> getConnectionStatus--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.FETCH_PAGED_DOORS_FAILED);
    }
}

function* handleError(error, actionType = ActionTypes.DATA_FETCH_ERROR) {
    yield console.error('http>>error :', error);

    window.errObject = error;

    yield put({
        type: actionType,
        payload: error
    });

    let errorMessage = "";
    const wifiDisconnectOrServerStopped = (error.message === 'Network Error' && typeof (error.response) === 'undefined');
    const mblStopped = error.response && error.response.data && error.response.data.message && error.response.data.message.includes(MBL_STOPPED_ERROR_MESSAGE);
    const tokenExpired = error.response && error.response.data && error.response.status === 500 && error.response.data.exceptionType === 'System.InvalidOperationException';
    if (error.response && (error.response.status === 303 || error.response.status === 400)) {
        errorMessage = error.response.data;
    }


    if (wifiDisconnectOrServerStopped || mblStopped) {
        yield put({ type: ActionTypes.CONNECTION_OFFLINE });
        if (mblStopped) {
            yield call(addAndShowMessage, MSG_MBL_STOPPED);
        } else {
            yield call(addAndShowMessage, MSG_SYSTEM_OFFLINE);
        }
    } else if (tokenExpired) {
        yield put({ type: ActionTypes.LOGOUT });
    } else if (errorMessage && errorMessage.length > 0) {
        console.log("Error Message: ", errorMessage);
        yield put({ type: ActionTypes.SHOWW_ERROR_MESSAGE, payload: errorMessage });
    }
    else {
        const action = {
            payload: error.message
        };
        yield call(logError, action);
    }
}

function* logError(action) {
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);

    try {
        yield call(
            Api.logError,
            accessToken,
            baseURL,
            action.payload
        );
    } catch (error) {
        console.log(error);
    }
}


function* checkIfPartitionExists(action) {
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);
    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> checkIfPartitionExists ..." + JSON.stringify(action.payload) });
      const response =  yield call(
            Api.checkIfPartitionExists,
            accessToken,
            baseURL,
            action.payload
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> checkIfPartitionExists -->" + JSON.stringify(response) });
        yield put({
            type: ActionTypes.CHECK_IF_PARTITION_EXISTS_SUCCEEDED,
            payload: response.data
        });
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> getConnectionStatus--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.CHECK_IF_PARTITION_EXISTS_FAILED);
    }
}

function* checkIfPINCoomonCodeEnabled(action) {
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);
    try {
        const response = yield call(
            Api.checkIfPINCommonCodeEnabled,
            accessToken,
            baseURL,
            action.payload
        );

        yield put({
            type: ActionTypes.CHECK_IF_PIN_COMMONCODE_ENABLED_SUCCEEDED,
            payload: response.data
        });
    } catch (error) {
        console.log(error);
        yield call(handleError, error, ActionTypes.CHECK_IF_PIN_COMMONCODE_ENABLED_FAILED);
    }
}


function* updateBagdeInfoRequest(action) {
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);
    try {
        const response = yield call(
            Api.updateBadgeInfoRequest,
            accessToken,
            baseURL,
            action.payload
        );

        yield put({
            type: ActionTypes.UPDATE_BAGDE_INFO_REQUEST_SUCCEEDED,
            payload: response.data
        });
    } catch (error) {
        console.log(error);
        yield call(handleError, error, ActionTypes.UPDATE_BAGDE_INFO_REQUEST_FAILED);
    }
}


function* changeOperatorPassword(action) {
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);
    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> changeOperatorPassword ..." + JSON.stringify(action.payload) });
        const response = yield call(
            Api.changeOperatorPassword,
            accessToken,
            baseURL,
            action.payload
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> changeOperatorPassword -->" + JSON.stringify(response) });
        yield put({
            type: ActionTypes.CHANGE_OPERATOR_PASSWORD_SUCCEEDED,
            payload: response.data
        });
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> changeOperatorPassword--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.CHANGE_OPERATOR_PASSWORD_FAILED);
    }
}


function* enableDebugLog(action) {
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const operatorID = yield select(getOperatorID);
    try {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> enableDebugLog ..." + JSON.stringify(action.payload) });
        const response = yield call(
            Api.enableDebugLog,
            accessToken,
            baseURL,
            action.payload
        );
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + + " httpSaga--> enableDebugLog -->" + JSON.stringify(response) });
        yield put({
            type: ActionTypes.ENABLE_DEBUG_LOG_SUCCEEDED,
            payload: true
        });
    } catch (error) {
        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID +  " --> httpSaga-> enableDebugLog--> Http>> Error : " + error.response ? error.response : "Failed" })
        yield call(handleError, error, ActionTypes.ENABLE_DEBUG_LOG_FAILED);
    }
}

function* writeLog(action) {
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);
    const isDebugLogEnabled = yield select(getIsDebugLogEnabled);
    try {
        console.log("isDebugEnabled:--->" + isDebugLogEnabled);
        if (isDebugLogEnabled === true) {
            const response = yield call(
                Api.writeLog,
                accessToken,
                baseURL,
                { logData: action.payload, isDebugEnabled: isDebugLogEnabled }
            );

            if (response.data && response.data === true) { 
                yield put({
                    type: ActionTypes.WRITE_LOG_SUCCEEDED,
                    payload: action.payload  // this is for logging in app debug log dialog
                });
            }

        } else {
            console.log(action.payload);
        }
    } catch (error) {
        console.log(error);
        yield call(handleError, error, ActionTypes.WRITE_LOG_FAILED);
        }

}
const getBaseURL = state => state.appConfig.baseURL;
const getAccessToken = state => state.token.accessToken;
const getCurrentPage = state => state.page.currentPage;
const getGeneralMessage = state => state.generalMessage;
const getSettings = state => state.settings;
const getOperatorFirstName = state => state.token.operatorFirstName;
const getOperatorLastName = state => state.token.operatorLastName;
const getRefreshStatus = state => state.page.refreshStatus;
const getRequestWirelessLockStatus = state => state.door.tmpRequestWirelessLockStatus;
const getIsDebugLogEnabled = state => state.settings.isDebugLogEnabled
const getOperatorID = state => state.token.operatorId;

export const SET_BASE_URL = 'ACTION_SET_BASE_URL';
export const GET_BASE_URL = 'ACTION_GET_BASE_URL';

export const RESET_MESSAGE = 'ACTION_RESET_MESSAGE';
export const RESET_ERROR_MESSAGE = 'ACTION_RESET_ERROR_MESSAGE';

export const LOGIN = 'ACTION_LOGIN';
export const LOGIN_SUCCEEDED = 'ACTION_LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'ACTION_LOGIN_FAILED';

export const LOGOUT = 'ACTION_LOGOUT';

export const RESET_ACCESSTOKEN = 'ACTION_RESET_ACCESSTOKEN';
export const LOAD_ACCESSTOKEN = 'ACTION_LOAD_ACCESSTOKEN';

export const LOAD_BASE_URL = 'ACTION_LOAD_BASE_URL';

export const SET_USERINFO = 'ACTION_SET_USERINFO';
export const LOAD_USERINFO = 'ACTION_LOAD_USERINFO';
export const RESET_USERINFO = 'ACTION_RESET_USERINFO';

export const GET_CURRENT_PAGE_NAME = 'ACTION_ GET_CURRENT_PAGE_NAME';
export const SET_CURRENT_PAGE_NAME = 'ACTION_SET_CURRENT_PAGE_NAME';

export const FETCH_ALL_DOORS = 'ACTION_FETCH_ALL_DOORS';
export const FETCH_ALL_DOORS_SUCCEEDED = 'ACTION_FETCH_ALL_DOORS_SUCCEEDED';
export const FETCH_ALL_DOORS_FAILED = 'ACTION_FETCH_ALL_DOORS_FAILED';

export const SEND_DOOR_LOCK = 'ACTION_SEND_DOOR_LOCK';
export const SEND_DOOR_LOCK_SUCCEEDED = 'ACTION_SEND_DOOR_LOCK_SUCCEEDED';
export const SEND_DOOR_LOCK_FAILED = 'ACTION_SEND_DOOR_LOCK_FAILED';

export const SEND_DOOR_UNLOCK = 'ACTION_UNLOCK_THE_DOOR';
export const SEND_DOOR_UNLOCK_SUCCEEDED = 'ACTION_UNLOCK_THE_DOOR_SUCCEEDED';
export const SEND_DOOR_UNLOCK_FAILED = 'ACTION_UNLOCK_THE_DOOR_FAILED';

export const SEND_DOOR_UNLOCK_HOLD = 'ACTION_SEND_DOOR_UNLOCK_HOLD';
export const SEND_DOOR_UNLOCK_HOLD_SUCCEEDED =
    'ACTION_SEND_DOOR_UNLOCK_HOLD_SUCCEEDED';
export const SEND_DOOR_UNLOCK_HOLD_FAILED =
    'ACTION_SEND_DOOR_UNLOCK_HOLD_FAILED';

export const FETCH_ALL_LOCKDOWN_AREA = 'ACTION_FETCH_ALL_LOCKDOWN_AREA';
export const FETCH_ALL_LOCKDOWN_AREA_SUCCEEDED =
    'ACTION_FETCH_ALL_LOCKDOWN_AREA_SUCCEEDED';
export const FETCH_ALL_LOCKDOWN_AREA_FAILED =
    'ACTION_FETCH_ALL_LOCKDOWN_AREA_FAILED';

export const SEND_ACTIVATE_ALL_LOCKDOWN_AREA =
    'SEND_ACTIVATE_ALL_LOCKDOWN_AREA';
export const SEND_ACTIVATE_ALL_LOCKDOWN_AREA_SUCCEDED =
    'ACTION_SEND_ACTIVATE_ALL_LOCKDOWN_AREA_SUCCEDED';
export const SEND_ACTIVATE_ALL_LOCKDOWN_AREA_FAILED =
    'ACTION_SEND_ACTIVATE_ALL_LOCKDOWN_AREA_FAILED';

export const SEND_DEACTIVATE_ALL_LOCKDOWN_AREA =
    'SEND_DEACTIVATE_ALL_LOCKDOWN_AREA';
export const SEND_DEACTIVATE_ALL_LOCKDOWN_AREA_SUCCEDED =
    'ACTION_SEND_DEACTIVATE_ALL_LOCKDOWN_AREA_SUCCEDED';
export const SEND_DEACTIVATE_ALL_LOCKDOWN_AREA_FAILED =
    'ACTION_SEND_DEACTIVATE_ALL_LOCKDOWN_AREA_FAILED';

export const SEND_ACTIVATE_THE_LOCKDOWN_AREA =
    'ACTION_SEND_ACTIVATE_THE_LOCKDOWN_AREA ';
export const SEND_ACTIVATE_THE_LOCKDOWN_AREA_SUCCEEDED =
    'ACTION_SEND_ACTIVATE_THE_LOCKDOWN_AREA_SUCCEEDED';
export const SEND_ACTIVATE_THE_LOCKDOWN_AREA_FAILED =
    'ACTION_SEND_ACTIVATE_THE_LOCKDOWN_AREA_FAILED';

export const SEND_DEACTIVATE_THE_LOCKDOWN_AREA =
    'ACTION_SEND_DEACTIVATE_THE_LOCKDOWN_AREA';
export const SEND_DEACTIVATE_THE_LOCKDOWN_AREA_SUCCEEDED =
    'ACTION_SEND_DEACTIVATE_THE_LOCKDOWN_AREA_SUCCEEDED';
export const SEND_DEACTIVATE_THE_LOCKDOWN_AREA_FAILED =
    'ACTION_SEND_DEACTIVATE_THE_LOCKDOWN_AREA_FAILED';

export const FETCH_ALL_THREAT_LEVELS = 'ACTION_FETCH_ALL_THREAT_LEVELS';
export const FETCH_ALL_THREAT_LEVELS_SUCCEDED =
    'ACTION_FETCH_ALL_THREAT_LEVELS_SUCCEDED';
export const FETCH_ALL_THREAT_LEVELS_FAILED =
    'ACTION_FETCH_ALL_THREAT_LEVELS_FAILED';

export const SEND_ACTIVATE_ALL_THREAT_LEVELS =
    'ACTION_SEND_ACTIVATE_ALL_THREAT_LEVELS';
export const SEND_ACTIVATE_ALL_THREAT_LEVELS_SUCCEDED =
    'ACTION_SEND_ACTIVATE_ALL_THREAT_LEVELS_SUCCEDED';
export const SEND_ACTIVATE_ALL_THREAT_LEVELS_FAILED =
    'ACTION_SEND_ACTIVATE_ALL_THREAT_LEVELS_FAILED';

export const SEND_DEACTIVATE_ALL_THREAT_LEVELS =
    'ACTION_SEND_DEACTIVATE_ALL_THREAT_LEVELS';
export const SEND_DEACTIVATE_ALL_THREAT_LEVELS_SUCCEDED =
    'ACTION_SEND_DEACTIVATE_ALL_THREAT_LEVELS_SUCCEDED';
export const SEND_DEACTIVATE_ALL_THREAT_LEVELS_FAILED =
    'ACTION_SEND_DEACTIVATE_ALL_THREAT_LEVELS_FAILED';

export const SEND_ACTIVATE_THE_THREAT_LEVEL =
    'ACTION_SEND_ACTIVATE_THE_THREAT_LEVEL';
export const SEND_ACTIVATE_THE_THREAT_LEVEL_SUCCEDED =
    'ACTION_SEND_ACTIVATE_THE_THREAT_LEVEL_SUCCEDED';
export const SEND_ACTIVATE_THE_THREAT_LEVEL_FAILED =
    'ACTION_SEND_ACTIVATE_THE_THREAT_LEVEL_FAILED';

export const SEND_DEACTIVATE_THE_THREAT_LEVEL =
    'ACTION_SEND_DEACTIVATE_THE_THREAT_LEVEL';
export const SEND_DEACTIVATE_THE_THREAT_LEVEL_SUCCEDED =
    'ACTION_SEND_DEACTIVATE_THE_THREAT_LEVEL_SUCCEDED';
export const SEND_DEACTIVATE_THE_THREAT_LEVEL_FAILED =
    'ACTION_SEND_DEACTIVATE_THE_THREAT_LEVEL_FAILED';

export const FETCH_ALL_RELAYS = 'ACTION_FETCH_ALL_RELAYS';
export const FETCH_ALL_RELAYS_SUCCEEDED = 'ACTION_FETCH_ALL_RELAYS_SUCCEEDED';
export const FETCH_ALL_RELAYS_FAILED = 'ACTION_FETCH_ALL_RELAYS_FAILED';

export const SEND_ACTIVATE_RELAY = 'ACTION_SEND_ACTIVATE_RELAY';
export const SEND_ACTIVATE_RELAY_SUCCEEDED =
    'ACTION_SEND_ACTIVATE_RELAY_SUCCEEDED';
export const SEND_ACTIVATE_RELAY_FAILED = 'ACTION_SEND_ACTIVATE_RELAY_FAILED';

export const SEND_DEACTIVATE_RELAY = 'ACTION_SEND_DEACTIVATE_RELAY';
export const SEND_DEACTIVATE_RELAY_SUCCEEDED =
    'ACTION_SEND_DEACTIVATE_RELAY_SUCCEEDED';
export const SEND_DEACTIVATE_RELAY_FAILED =
    'ACTION_SEND_DEACTIVATE_RELAY_FAILED';

export const SEND_ACTIVATE_HOLD_RELAY = 'ACTION_SEND_ACTIVATE_HOLD_RELAY';
export const SEND_ACTIVATE_HOLD_RELAY_SUCCEEDED =
    'ACTION_SEND_ACTIVATE_HOLD_RELAY_SUCCEEDED';
export const SEND_ACTIVATE_HOLD_RELAY_FAILED =
    'ACTION_SEND_ACTIVATE_HOLD_RELAY_FAILED';

export const FETCH_ALL_DEVICE_TYPE = 'ACTION_FETCH_ALL_DEVICE_TYPE';
export const FETCH_ALL_DEVICE_TYPE_SUCCEEDED =
    'ACTION_FETCH_ALL_DEVICE_TYPE_SUCCEEDED';
export const FETCH_ALL_DEVICE_TYPE_FAILED =
    'ACTION_FETCH_ALL_DEVICE_TYPE_FAILED';

export const FETCH_DEVICE_TYPE_LIST = 'ACTION_FETCH_DEVICE_TYPE_LIST';
export const FETCH_DEVICE_TYPE_LIST_SUCCEEDED =
    'ACTION_FETCH_DEVICE_TYPE_LIST_SUCCEEDED';
export const FETCH_DEVICE_TYPE_LIST_FAILED = 'ACTION_DEVICE_TYPE_LIST_FAILED';

export const FETCH_DEVICE_STATUS_DETAIL = 'ACTION_FETCH_DEVICE_STATUS_DETAIL';
export const FETCH_DEVICE_STATUS_DETAIL_SUCCEEDED =
    'ACTION_FETCH_DEVICE_STATUS_DETAIL_SUCCEEDED';
export const FETCH_DEVICE_STATUS_DETAIL_FAILED =
    'ACTION_FETCH_DEVICE_STATUS_DETAIL_FAILED';

export const FETCH_ALL_BADGEHOLDERIN = 'ACTION_FETCH_ALL_BADGEHOLDERIN';
export const FETCH_ALL_BADGEHOLDERIN_SUCCEEDED =
    'ACTION_FETCH_ALL_BADGEHOLDERIN_SUCCEEDED';
export const FETCH_ALL_BADGEHOLDERIN_FAILED =
    'ACTION_FETCH_ALL_BADGEHOLDERIN_FAILED';

export const GET_SELECTED_DEVICE_TYPE = 'ACTION_GET_SELECTED_DEVICE_TYPE';
export const GET_SELECTED_DEVICE = 'ACTION_GET_SELECTED_DEVICE';
export const GET_SELECTED_FIELD = 'ACTION_GET_SELECTED_FIELD';
export const RESET_SELECTED_DEVICE_TYPE = 'ACTION_RESET_SELECTED_DEVICE_TYPE';

export const FETCH_SCHEDULE_CHANGES_LIST = 'ACTION_FETCH_SCHEDULE_CHANGES_LIST';
export const FETCH_SCHEDULE_CHANGES_LIST_SUCCEEDED =
    'ACTION_FETCH_SCHEDULE_CHANGES_LIST_SUCCEEDED';
export const FETCH_SCHEDULE_CHANGES_LIST_FAILED =
    'ACTION_FETCH_SCHEDULE_CHANGES_LIST_FAILED';

export const DELETE_SCHEDULE = 'ACTION_DELETE_SCHEDULE';
export const DELETE_SCHEDULE_SUCCEEDED = 'ACTION_DELETE_SCHEDULE_SUCCEEDED';
export const DELETE_SCHEDULE_FAILED = 'ACTION_DELETE_SCHEDULE_FAILED';

export const FETCH_TIME_SCHEDULES = 'ACTION_FETCH_TIME_SCHEDULES';
export const FETCH_TIME_SCHEDULES_SUCCEEDED =
    'ACTION_FETCH_TIME_SCHEDULES_SUCCEEDED';
export const FETCH_TIME_SCHEDULES_FAILED = 'ACTION_FETCH_TIME_SCHEDULES_FAILED';

export const FETCH_DEVICE_LIST_BY_DEVICE_TYPE =
    'ACTION_FETCH_DEVICE_LIST_BY_DEVICE_TYPE';
export const FETCH_DEVICE_LIST_BY_DEVICE_TYPE_SUCCEEDED =
    'ACTION_FETCH_DEVICE_LIST_BY_DEVICE_TYPE_SUCCEEDED';
export const FETCH_DEVICE_LIST_BY_DEVICE_TYPE_FAILED =
    'ACTION_FETCH_DEVICE_LIST_BY_DEVICE_TYPE_FAILED';

export const FETCH_FIELD_LIST_BY_DEVICE_TYPE =
    'ACTION_FETCH_FIELD_LIST_BY_DEVICE_TYPE';
export const FETCH_FIELD_LIST_BY_DEVICE_TYPE_SUCCEEDED =
    'ACTION_FETCH_FIELD_LIST_BY_DEVICE_TYPE_SUCCEEDED';
export const FETCH_FIELD_LIST_BY_DEVICE_TYPE_FAILED =
    'ACTION_FETCH_FIELD_LIST_BY_DEVICE_TYPE_FAILED';

export const CREATE_NEW_SCHEDULE_CHANGES = 'ACTION_CREATE_NEW_SCHEDULE_CHANGES';
export const CREATE_NEW_SCHEDULE_CHANGES_SUCCEEDED =
    'ACTION_CREATE_NEW_SCHEDULE_CHANGES_SUCCEEDED';
export const CREATE_NEW_SCHEDULE_CHANGES_FAILED =
    'ACTION_CREATE_NEW_SCHEDULE_CHANGES_FAILED';

export const RESET_NEW_SCHEDULE_CHANGES_SUCCEEDED =
    'ACTION_RESET_NEW_SCHEDULE_CHANGES_SUCCEEDED';

export const UPDATE_SCHEDULE_CHANGES = 'ACTION_UPDATE_SCHEDULE_CHANGES';
export const UPDATE_SCHEDULE_CHANGES_SUCCEEDED =
    'ACTION_UPDATE_SCHEDULE_CHANGES_SUCCEEDED';
export const UPDATE_SCHEDULE_CHANGES_FAILED =
    'ACTION_UPDATE_SCHEDULE_CHANGES_FAILED';

export const RESET_UPDATE_SCHEDULE_CHANGES_SUCCEEDED =
    'ACTION_RESET_UPDATE_SCHEDULE_CHANGES_SUCCEEDED';

export const FETCH_ALL_PERSONNELS = 'ACTION_FETCH_ALL_PERSONNELS';
export const FETCH_ALL_PERSONNELS_SUCCEEDED =
    'ACTION_FETCH_ALL_PERSONNELS_SUCCEEDED';
export const FETCH_ALL_PERSONNELS_FAILED = 'ACTION_FETCH_ALL_PERSONNELS_FAILED';

export const FETCH_ACCESS_GROUP_LIST = 'ACTION_FETCH_ACCESS_GROUP_LIST';
export const FETCH_ACCESS_GROUP_LIST_SUCCEEDED =
    'ACTION_FETCH_ACCESS_GROUP_LIST_SUCCEEDED';
export const FETCH_ACCESS_GROUP_LIST_FAILED =
    'ACTION_FETCH_ACCESS_GROUP_LIST_FAILED';

export const DELETE_PERSONNEL = 'ACTION_DELETE_PERSONNEL';
export const DELETE_PERSONNEL_SUCCEEDED = 'ACTION_DELETE_PERSONNEL_SUCCEEDED';
export const DELETE_PERSONNEL_FAILED = 'ACTION_DELETE_PERSONNEL_FAILED';

export const CHANGE_PERSONNEL_PIN = 'ACTION_CHANGE_PERSONNEL_PIN';

export const CREATE_NEW_PERSONNEL = 'ACTION_CREATE_NEW_PERSONNEL';
export const CREATE_NEW_PERSONNEL_SUCCEEDED =
    'ACTION_CREATE_NEW_PERSONNEL_SUCCEEDED';
export const CREATE_NEW_PERSONNEL_FAILED = 'ACTION_CREATE_NEW_PERSONNEL_FAILED';

export const RESET_NEW_PERSONNEL_CREATED_STATUS =
    'ACTION_RESET_NEW_PERSONNEL_CREATED_STATUS';

export const UPDATE_PERSONNEL = 'ACTION_UPDATE_PERSONNEL';
export const UPDATE_PERSONNEL_SUCCEEDED = 'ACTION_UPDATE_PERSONNEL_SUCCEEDED';
export const UPDATE_PERSONNEL_FAILED = 'ACTION_UPDATE_PERSONNEL_FAILED';

export const RESET_PERSONNEL_UPDATED_STATUS =
    'ACTION_RESET_PERSONNEL_UPDATED_STATUS';

export const SET_APP_PIN = 'ACTION_SET_APP_PIN';
export const GET_APP_PIN = 'ACTION_GET_APP_PIN';

export const SET_SERVER_IP_ADDRESS = 'ACTION_SET_SERVER_IP_ADDRESS';
export const GET_SERVER_IP_ADDRESS = 'ACTION_GET_SERVER_IP_ADDRESS';

export const SET_PORT_NO = 'ACTION_SET_PORT_NO';
export const GET_PORT_NO = 'ACTION_GET_PORT_NO';

export const SET_USER = 'ACTION_SET_USER';
export const GET_USER = 'ACTION_GET_USER';

export const SET_PASSWORD = 'ACTION_SET_PASSWORD';
export const GET_PASSWORD = 'ACTION_GET_PASSWORD';

export const TOGGLE_MY_DOORS_MENU = 'ACTION_TOGGLE_MY_DOORS_MENU';
export const TOGGLE_LOCKDOWN_MENU = 'ACTION_TOGGLE_LOCKDOWN_MENU';
export const TOGGLE_THREAT_LEVELS_MENU = 'ACTION_TOGGLE_THREAT_LEVELS_MENU';
export const TOGGLE_STATUS_MENU = 'ACTION_TOGGLE_STATUS_MENU';
export const TOGGLE_CONTROL_MENU = 'ACTION_TOGGLE_CONTROL_MENU';
export const TOGGLE_PERSONNEL_MENU = 'ACTION_TOGGLE_PERSONNEL_MENU';
export const TOGGLE_SCHEDULED_CHANGES_MENU =
    'ACTION_TOGGLE_SCHEDULED_CHANGES_MENU';
export const TOGGLE_BADGEHOLDERSIN_MENU = 'ACTION_TOGGLE_BADGEHOLDERSIN_MENU';

export const SET_MSG_DISPLAY_TIME = 'ACTION_SET_MSG_DISPLAY_TIME';
export const SET_MSG_OPTION_UNTIL_CLEARED =
    'ACTION_SET_MSG_OPTION_UNTIL_CLEARED';
export const SET_INLIST_REFRESH_TIME = 'ACTION_SET_INLIST_REFRESH_TIME';

export const TOGGLE_KEEP_MYDOORS_EXPANDED =
    'ACTION_TOGGLE_KEEP_MYDOORS_EXPANDED';

export const FETCH_PERSONNEL = 'ACTION_FETCH_PERSONNEL';
export const FETCH_PERSONNEL_SUCCEEDED = 'ACTION_FETCH_PERSONNEL_SUCCEEDED';
export const FETCH_PERSONNEL_FAILED = 'ACTION_FETCH_PERSONNEL_FAILED';

//export const SORT_PERSONNEL = "ACTION_SORT_PERSONNEL";
//export const SET_SORT_FLAGS = "SET_SORT_PERSONNEL_FLAGS";
export const SET_SEARCH_CRITERIA = 'SET_SEARCH_CRITERIA';

export const ADD_BADGE_TO_PERSONNEL = 'ACTIONADD_BADGE_TO_PERSONNEL';
export const ADD_BADGE_TO_PERSONNEL_SUCCEEDED =
    'ACTION_ADD_BADGE_TO_PERSONNEL_SUCCEEDED';
export const ADD_BADGE_TO_PERSONNEL_FAILED =
    'ACTION_ADD_BADGE_TO_PERSONNEL_FAILED';

export const RESET_NEW_BADGE_ADDED_TO_PERSONNEL_STATUS =
    'ACTION_RESET_NEW_BADGE_ADDED_TO_PERSONNEL_STATUS';

export const TOGGLE_VIEW_SELECTION_SUBITEM =
    'ACTION_TOGGLE_VIEW_SELECTION_SUBITEM';

export const CONNECTION_ONLINE = 'ACTION_CONNECTION_ONLINE';

export const CONNECTION_OFFLINE = 'ACTION_CONNECTION_OFFLINE';
export const SET_BADGE_INFO = 'ACTION_SET_BADGE_INFO';
export const LOAD_BADGE_INFO = 'ACTION_LOAD_BADGE_INFO';

export const POST_EVENT = 'ACTION_POST_EVENT';
export const POST_EVENT_SUCCEEDED = 'ACTION_POST_EVENT_SUCCEEDED';
export const POST_EVENT_FAILED = 'ACTION_POST_EVENT_FAILED';

export const SET_CURRENT_OPERATING_DOOR = 'ACTION_SET_CURRENT_OPERATING_DOOR';
export const GET_DOOR_STATUS = 'ACTION_GET_DOOR_STATUS';

export const FETCH_BADGES_BY_PERSON_NAME = 'ACTION_FETCH_BADGES_BY_PERSON_NAME';
export const FETCH_BADGES_BY_PERSON_NAME_SUCCEEDED =
    'ACTION_FETCH_BADGES_BY_PERSON_NAME_SUCCEEDED';
export const FETCH_BADGES_BY_PERSON_NAME_FAILED =
    'ACTION_FETCH_BADGES_BY_PERSON_NAME_FAILED';

export const SET_SELECTED_BADGE_OF_USER = 'ACTION_SET_SELECTED_BADGE_OF_USER';

export const ADD_MESSAGE = 'ACTION_ADD_MESSAGE';
export const ADD_AND_SHOW_MESSAGE = 'ADD_AND_SHOW_MESSAGE';
export const CLEAR_MESSAGE = 'ACTION_CLEAR_MESSAGE';

export const SET_LOGIN_REMEMBER = 'ACTION_SET_LOGIN_REMEMBER';
export const GET_LOGIN_REMEMBER = 'ACTION_GET_LOGIN_REMEMBER';
export const REMEMBERED_USERNAME = 'ACTION_REMEMBERED_USERNAME';

export const CLOSE_LOADER_MODAL = 'ACTION_CLOSE_LOADER_MODAL';

export const CHANGE_TMP_SETTINGS_PIN = 'ACTION_CHANGE_TMP_SETTINGS_PIN';

export const LOAD_SETTINGS_PIN = 'ACTION_LOAD_SETTINGS_PIN';

export const GET_SETTINGS_PIN = 'ACTION_GET_SETTINGS_PIN';

export const CHANGE_TMP_APP_ENTRY_CHECKED =
    'ACTION_CHANGE_TMP_APP_ENTRY_CHECKED';

export const CHECK_APP_ENTRY_SESSION = 'ACTION_CHECK_APP_ENTRY_SESSION';
export const ADD_LOGGED_IN_TIME = 'ACTION_ADD_LOGGED_IN_TIME';

export const SAVE_CONFIG_SETTINGS = 'ACTION_SAVE_CONFIG_SETTINGS';
export const CANCEL_SAVE_CONFIG_SETTINGS = 'ACTION_CANCEL_SAVE_CONFIG_SETTINGS';
export const RESET_INACTIVITY_TIMER = 'ACTION_RESET_INACTIVITY_TIMER';

export const CHANGE_SESSION_TIMEOUT = 'ACTION_CHANGE_SESSION_TIMEOUT';

export const STORE_APP_CONFIG_SETTINGS_TO_LOCAL_STORAGE =
    'ACTION_STORE_APP_CONFIG_SETTINGS_TO_LOCAL_STORAGE';

export const LOAD_APP_CONFIG_SETTINGS_FROM_LOCAL_STORAGE =
    'ACTION_LOAD_APP_CONFIG_SETTINGS_FROM_LOCAL_STORAGE';
export const SHOW_GENERAL_MESSAGE = 'ACTION_SHOW_GENERAL_MESSAGE';
export const HIDE_GENERAL_MESSAGE = 'ACTION_HIDE_GENERAL_MESSAGE';
export const SET_APP_BASE_URL = 'ACTION_SET_APP_BASE_URL';
export const GET_APP_BASE_URL = 'ACTION_GET_APP_BASE_URL';

export const FETCH_SYSTEM_SETTINGS = 'ACTION_FETCH_SYSTEM_SETTINGS';
export const FETCH_SYSTEM_SETTINGS_SUCCEEDED =
    'ACTION_FETCH_SYSTEM_SETTINGS_SUCCEEDED';
export const FETCH_SYSTEM_SETTINGS_FAILED =
    'ACTION_FETCH_SYSTEM_SETTINGS_FAILED';

export const START_IN_LIST_REFRESH = 'ACTION_START_IN_LIST_REFRESH';
export const CANCEL_IN_LIST_REFRESH = 'ACTION_CANCEL_IN_LIST_REFRESH';

export const CANCEL_ACTIVE_SCHEDULE_CHANGES =
    'ACTION_CANCEL_ACTIVE_SCHEDULE_CHANGES';
export const CANCEL_ACTIVE_SCHEDULE_CHANGES_SUCCEEDED =
    'ACTION_CANCEL_ACTIVE_SCHEDULE_CHANGES_SUCCEEDED';
export const CANCEL_ACTIVE_SCHEDULE_CHANGES_FAILED =
    'ACTION_CANCEL_ACTIVE_SCHEDULE_CHANGES_FAILED';
export const RESET_CANCEL_ACTIVE_SCHEDULE_CHANGES =
    'ACTION_RESET_CANCEL_ACTIVE_SCHEDULE_CHANGES';

export const CHECK_BADGE_EXISTS = 'ACTION_CHECK_BADGE_EXISTS';
export const CHECK_BADGE_EXISTS_SUCCEEDED =
    'ACTION_CHECK_BADGE_EXISTS_SUCCEEDED';
export const CHECK_BADGE_EXISTS_FAILED = 'ACTION_CHECK_BADGE_EXISTS_FAILED';

export const RESET_BADGE_EXIST_FLAG = 'ACTION_RESET_BADGE_EXIST_FLAG';

export const RESET_LOADING_FLAG = 'ACTION_RESET_LOADING_FLAG';

export const SAVE_STATE_DATA = 'ACTION_SAVE_STATE_DATA';
export const CANCEL_STATE_DATA = 'ACTION_CANCEL_STATE_DATA';
export const RESET_SAVE_STATE_DATA = 'ACTION_RESET_SAVE_STATE_DATA';

export const CONFIGURATION_CHANGED = 'ACTION_CONFIG_CHANGED';
export const RESET_CONFIGURATION_CHANGED = 'ACTION_RESET_CONFIGURATION_CHANGED';

export const SET_REMAINING_SESSION_TIME = 'ACTION_SET_REMAINING_SESSION_TIME';
export const RESET_REMAINING_SESSION_TIME =
    'ACTION_RESET_REMAINING_SESSION_TIME';

export const FETCH_ACCESS_READER_LIST_BY_BADGE =
    'ACTION_FETCH_ACCESS_READER_LIST_BY_BADGE';
export const FETCH_ACCESS_READER_LIST_BY_BADGE_SUCCEEDED =
    'ACTION_FETCH_ACCESS_READER_LIST_BY_BADGE_SUCCEEDED';
export const FETCH_ACCESS_READER_LIST_BY_BADGE_FAILED =
    'ACTION_FETCH_ACCESS_READER_LIST_BY_BADGE_FAILED';

export const REFRESH_CURRENT_PAGE = 'ACTION_REFRESH_CURRENT_PAGE';
export const RESET_REFRESH_STATUS = 'ACTION_RESET_REFRESH_STATUS';

export const FETCH_BADGE_LIST_BY_PERSONNEL_ID =
    'ACTION_FETCH_BADGE_LIST_BY_PERSONNEL_ID';
export const FETCH_BADGE_LIST_BY_PERSONNEL_ID_SUCCEEDED =
    'ACTION_FETCH_BADGE_LIST_BY_PERSONNEL_ID_SUCCEEDED';
export const FETCH_BADGE_LIST_BY_PERSONNEL_ID_FAILED =
    'ACTION_FETCH_BADGE_LIST_BY_PERSONNEL_ID_FAILED';

export const HAS_MORE_ITEMS = 'HAS_MORE_ITEMS';

export const RESET_BADGE_LIST = 'ACTION_RESET_BADGE_LIST';

export const RESET_SINGLE_PERSONNEL = 'ACTION_RESET_SINGLE_PERSONNEL';

export const TOGGLE_REQUEST_WIRELESS_LOCK_STATUS =
    'ACTION_TOGGLE_REQUEST_WIRELESS_LOCK_STATUS';

export const SET_LAST_RESET_TIME = 'ACTION_SET_LAST_RESET_TIME';

export const REFRESH_APP_CONFIG_SETTINGS = 'ACTION_REFRESH_APP_CONFIG_SETTINGS';

export const FETCH_PERSONNELS_COUNT = 'FETCH_PERSONNELS_COUNT';
export const FETCH_PERSONNELS_COUNT_SUCCEEDED =
    'FETCH_PERSONNELS_COUNT_SUCCEEDED';
export const FETCH_PERSONNELS_COUNT_FAILED = 'FETCH_PERSONNELS_COUNT_FAILED';
export const FETCH_PAGED_PERSONNELS = 'ACTION_FETCH_PAGED_PERSONNELS';
export const FETCH_PAGED_PERSONNELS_SUCCEEDED =
    'ACTION_FETCH_PAGED_PERSONNELS_SUCCEEDED';
export const FETCH_PAGED_PERSONNELS_FAILED = 'FETCH_PAGED_PERSONNELS_FAILED';
export const RESET_PERSONNEL = 'RESET_PERSONNEL';

export const FETCH_DOORS_COUNT = 'ACTION_FETCH_DOORS_COUNT';
export const FETCH_DOORS_COUNT_SUCCEEDED = 'ACTION_FETCH_DOORS_COUNT_SUCCEEDED';
export const FETCH_DOORS_COUNT_FAILED = 'ACTION_FETCH_DOORS_COUNT_FAILED';
export const FETCH_PAGED_DOORS = 'ACTION_FETCH_PAGED_DOORS';
export const FETCH_PAGED_DOORS_SUCCEEDED = 'ACTION_FETCH_PAGED_DOORS_SUCCEEDED';
export const FETCH_PAGED_DOORS_FAILED = 'ACTION_FETCH_PAGED_DOORS_FAILED';
export const RESET_DOORS = 'ACTION_RESET_DOORS';

export const LOG_ERROR = 'LOG_ERROR';

export const DATA_FETCH_ERROR = 'DATA_FETCH_ERROR';

export const GET_CONNECTION_STATUS = 'ACTION_GET_CONNECTION_STATUS';

export const SHOWW_ERROR_MESSAGE = 'ACTION_SHOWW_ERROR_MESSAGE';

export const SHOW_ALERT_MESSAGE = 'ACTION_SHOW_ALERT_MESSAGE';

export const STORE_SESSION_TIMEOUT_INFO = 'ACTION_STORE_SESSION_TIMEOUT_INFO';
export const LOAD_SESSION_TIMEOUT_INFO = 'ACTION_LOAD_SESSION_TIMEOUT_INFO';

export const CHECK_SESSION_TIMEOUT_STATUS = 'ACTION_CHECK_SESSION_TIMEOUT_STATUS';

export const CHECK_IF_PARTITION_EXISTS = 'ACTION_CHECK_IF_PARTITION_EXISTS';
export const CHECK_IF_PARTITION_EXISTS_SUCCEEDED = 'ACTION_CHECK_IF_PARTITION_EXISTS_SUCCEEDED';
export const CHECK_IF_PARTITION_EXISTS_FAILED = 'ACTION_CHECK_IF_PARTITION_EXISTS_FAILED';
export const RESET_PARTITION_EXISTS_STATUS = 'ACTION_RESET_PARTITION_EXISTS_STATUS';


export const SEND_MY_DOOR_UNLOCK = 'ACTION_SEND_MY_DOOR_UNLOCK';
export const SEND_MY_DOOR_UNLOCK_SUCCEEDED = 'ACTION_MY_DOOR_UNLOCK_SUCCEEDED';
export const SEND_MY_DOOR_UNLOCK_FAILED = 'ACTION_MY_DOOR_UNLOCK_FAILED';


export const CHECK_IF_PINCODE_ENABLED = 'ACTION_CHECK_IF_PINCODE_ENABLED';
export const CHECK_IF_PINCODE_ENABLED_SUCCEEDED = 'ACTION_CHECK_IF_PINCODE_ENABLED_SUCCEEDED';
export const CHECK_IF_PINCODE_ENABLED_FAILED = 'ACTION_CHECK_IF_PINCODE_ENABLED_FAILED';
export const RESET_PINCODE_ENABLED_STATUS = 'ACTION_RESET_PINCODE_ENABLED_STATUS';
export const SET_PINCODE_ENABLED = 'ACTION_SET_PINCODE_ENABLED';

export const CHECK_IF_PIN_COMMONCODE_ENABLED = 'ACTION_CHECK_IF_PIN_COMMONCODE_ENABLED';
export const CHECK_IF_PIN_COMMONCODE_ENABLED_SUCCEEDED = 'ACTION_CHECK_IF_PIN_COMMONCODE_ENABLED_SUCCEEDED';
export const CHECK_IF_PIN_COMMONCODE_ENABLED_FAILED = 'ACTION_CHECK_IF__PIN_COMMONCODE_ENABLED_FAILED';
export const RESET_COMMONCODE_ENABLED_STATUS = 'ACTION_RESET_COMMONCODE_ENABLED_STATUS';


export const UPDATE_BAGDE_INFO_REQUEST = 'ACTION_UPDATE_BAGDE_REQUEST';
export const UPDATE_BAGDE_INFO_REQUEST_SUCCEEDED = 'ACTION_UPDATE_BAGDE_INFO_REQUEST_SUCCEEDED';
export const UPDATE_BAGDE_INFO_REQUEST_FAILED = 'ACTION_UPDATE_BAGDE_INFO_REQUEST_FAILED';

export const EDIT_BADGE_NO = 'ACTION_EDIT_BADGE_NO';
export const EDIT_FACILITY_NO = 'ACTION_EDIT_FACILITY_NO';
export const RESET_EDITED_BADG_INFO = 'ACTION_RESET_EDITED_BADG_INFO';
export const SET_EDITED_BADGE_INFO = 'ACTIONS_ET_EDITED_BADGE_INFO';


export const SET_BADGE_LINKED = 'ACTIONS_SET_BADGE_LINKED';
export const RESET_BADGE_LINKED_STATUS = 'ACTIONS_RESET_BADGE_LINKED_STATUS';

export const PERSONNEL_PINVIEW_DATA_CAHNGED = 'ACTIONS_PERSONNEL_PINVIEW_DATA_CAHNGED';
export const RESET_PERSONNEL_PINVIEW_DATA_CAHNGED = 'ACTIONS_RESET_PERSONNEL_PINVIEW_DATA_CAHNGED';


export const NEW_BADGE_ADDED_IN_DROPDOWN = 'ACTIONS_NEW_BADGE_ADDED_IN_DROPDOWN';
export const RESET_NEW_BADGE_ADDED_IN_DROPDOWN = 'ACTIONS_RESET_NEW_BADGE_ADDED_IN_DROPDOWN';

export const RESET_PIN_COOMONCOODE_NOT_SCHEDULED = 'ACTIONS_RESET_PIN_COOMONCOODE_NOT_SCHEDULED';
export const RESET_BADGE_VALID_STATUS = 'ACTIONS_RESET_BADGE_VALID_STATUS';

export const RE_ENTER_PINCODE = 'ACTIONS_RE_ENTER_PINCODE';
export const RESET_RE_ENTER_PINCODE = 'ACTIONS_RESET_RE_ENTER_PINCODE';

export const CHANGE_OPERATOR_PASSWORD = 'ACTIONS_CHANGE_OPERATOR_PASSWORD';
export const CHANGE_OPERATOR_PASSWORD_SUCCEEDED = 'ACTIONS_CHANGE_OPERATOR_PASSWORD_SUCCEEDED';
export const CHANGE_OPERATOR_PASSWORD_FAILED = 'ACTIONS_CHANGE_OPERATOR_PASSWORD_FAILED';
export const RESET_CHANGE_OPERATOR_PASSWORD = 'ACTIONS_RESET_CHANGE_OPERATOR_PASSWORD';
export const RESET_PASSWORD_REQUIRES_UPDATE = 'ACTIONS_RESET_PASSWORD_REQUIRES_UPDATE';

export const CANCEL_UNLOCK_PRESSED = 'ACTIONS_CANCEL_UNLOCK_PRESSED';
export const RESET_CANCEL_UNLOCK_PRESSED = 'ACTIONS_RESET_CANCEL_UNLOCK_PRESSED';

export const CHANGE_PASSWORD_PRESSED = 'ACTIONS_CHANGE_PASSWORD_PRESSED';
export const RESET_CHANGE_PASSWORD_PRESSED = 'ACTIONS_RESET_CHANGE_PASSWORD_PRESSED';


export const ENABLE_DEBUG_LOG = 'ACTIONS_ENABLE_DEBUG_LOG';
export const ENABLE_DEBUG_LOG_SUCCEEDED = 'ACTIONS_ENABLE_DEBUG_LOG_SUCCEEDED';
export const ENABLE_DEBUG_LOG_FAILED = 'ACTIONS_ENABLE_DEBUG_LOG_FAILED';


export const WRITE_LOG = 'ACTIONS_WRITE_LOG';
export const WRITE_LOG_SUCCEEDED = 'ACTIONS_WRITE_LOG_SUCCEEDED';
export const WRITE_LOG_FAILED = 'ACTIONS_WRITE_LOG_FAILED';

export const CLEAR_LOG = 'ACTIONS_CLEAR_LOG';

export const VIEW_LOG = 'ACTIONS_VIEW_LOG';
export const HIDE_LOG = 'ACTIONS_HIDE_LOG';

export const CHANGE_SEARCH_TEXT_AG1 = 'ACTIONS_CHANGE_SEARCH_TEXT_AG1';
export const CHANGE_SEARCH_TEXT_AG2 = 'ACTIONS_CHANGE_SEARCH_TEXT_AG2';
export const CHANGE_SEARCH_TEXT_AG3 = 'ACTIONS_CHANGE_SEARCH_TEXT_AG3';
export const CHANGE_SEARCH_TEXT_AG4 = 'ACTIONS_CHANGE_SEARCH_TEXT_AG4';
export const CHANGE_SEARCH_TEXT_AG5 = 'ACTIONS_CHANGE_SEARCH_TEXT_AG5';
export const CHANGE_SEARCH_TEXT_AG6 = 'ACTIONS_CHANGE_SEARCH_TEXT_AG6';
export const CHANGE_SEARCH_TEXT_AG7 = 'ACTIONS_CHANGE_SEARCH_TEXT_AG7';
export const CHANGE_SEARCH_TEXT_AG8 = 'ACTIONS_CHANGE_SEARCH_TEXT_AG8';
export const CHANGE_SEARCH_TEXT_AG9 = 'ACTIONS_CHANGE_SEARCH_TEXT_AG9';
export const CHANGE_SEARCH_TEXT_AG10 = 'ACTIONS_CHANGE_SEARCH_TEXT_AG10';
export const CHANGE_SEARCH_TEXT_AG11 = 'ACTIONS_CHANGE_SEARCH_TEXT_AG11';
export const CHANGE_SEARCH_TEXT_AG12 = 'ACTIONS_CHANGE_SEARCH_TEXT_AG12';
export const CHANGE_SEARCH_TEXT_AG13 = 'ACTIONS_CHANGE_SEARCH_TEXT_AG13';
export const CHANGE_SEARCH_TEXT_AG14 = 'ACTIONS_CHANGE_SEARCH_TEXT_AG14';
export const CHANGE_SEARCH_TEXT_AG15 = 'ACTIONS_CHANGE_SEARCH_TEXT_AG15';
export const CHANGE_SEARCH_TEXT_AG16 = 'ACTIONS_CHANGE_SEARCH_TEXT_AG16';
export const RESET_SEARCH_TEXT_ALL_AG = 'ACTIONS_RESET_SEARCH_TEXT_ALL_AG';

export const CHECK_PRIVILEDGES = 'ACTIONS_CHECK_PRIVILEDGES';


import * as ActionTypes from '../actions/types';
import { DEVICE_TYPES } from '../consts/consts';

const defaultState = {
  deviceTypes: [],
  deviceTypeListMap: {},
  deviceStatusDetail: [],
  selectedDeviceType: DEVICE_TYPES.DEVICE_TYPE_NONE
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_DEVICE_TYPE_SUCCEEDED: {
      return {
        ...state,
        deviceTypes: action.payload,
        deviceTypeListMap: action.payload
      };
    }

    case ActionTypes.FETCH_DEVICE_TYPE_LIST_SUCCEEDED: {
      return {
        ...state,
        deviceTypes: action.payload,
        deviceTypeListMap: action.payload
      };
    }

    case ActionTypes.FETCH_DEVICE_STATUS_DETAIL_SUCCEEDED: {
      return {
        ...state,
        deviceStatusDetail: action.payload
      };
    }

    case ActionTypes.RESET_SELECTED_DEVICE_TYPE: {
      return {
        ...state,
        selectedDeviceType: DEVICE_TYPES.DEVICE_TYPE_NONE
      };
    }

    case ActionTypes.GET_SELECTED_DEVICE_TYPE: {
      return {
        ...state,
        selectedDeviceType: action.payload
      };
      }

      case ActionTypes.LOGOUT: {
          return defaultState;
      }

    default:
      return state;
  }
};

import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import * as Colors from '../../consts/colors';
import ButtonView from '../common/ButtonView';
import * as actions from '../../actions/index';

class DoorItemView extends Component {
  constructor(props) {
    super(props);
    this.doUnlock = this.doUnlock.bind(this);
  }

  doUnlock() {
    try {
      const {
        accessToken,
        panelNo,
        doorNo,
        doorStatus,
        doorName,
        tmpSelectedBadgeOfUser
      } = this.props;

      const doorInfo = {
        accessToken,
        panelNo,
        doorNo,
        locked: doorStatus,
        doorName,
        badge:
          tmpSelectedBadgeOfUser && tmpSelectedBadgeOfUser.Badge
            ? tmpSelectedBadgeOfUser.Badge
            : 0,
        facility:
          tmpSelectedBadgeOfUser && tmpSelectedBadgeOfUser.Facility
            ? tmpSelectedBadgeOfUser.Facility
            : 0
      };

        this.props.writeLog("OperatorID: " + this.props.operatorId + "--> DoorInfo: " + JSON.stringify(doorInfo));

      this.props.sendUnLockTheDoor(doorInfo, this.props.baseURL);
    } catch (error) {
      this.setState(() => {
        throw error;
      });
    }
  }

  render() {
    return (
      <div
        style={
          this.props.currentIndex % 2 === 0
            ? styles.alterBackgroundContainer
            : styles.container
        }
      >
        <div style={styles.titleRow}>
          <div style={styles.dot}>
            <FontAwesomeIcon icon={faCircle} size="xs" color={Colors.Black} />
          </div>
          <p style={styles.doorName}>{this.props.doorName}</p>
          {
            <div style={doorStatusStyle(this.props.statusDescription)}>
              <p>{checkDoorStatus(this.props.statusDescription)}</p>
            </div>
          }
        </div>

        <div />
        {
          <div style={styles.buttonRow}>
            <Link style={styles.underlineless} to="/control">
              <ButtonView
                label="Unlock"
                instyle={styles.unlock}
                onPress={this.doUnlock}
              />
            </Link>

            <Link style={styles.underlineless} to="/control">
              <ButtonView
                label="Lock"
                instyle={styles.lock}
                onPress={() => {
                  try {
                    const doorInfo = {
                      accessToken: this.props.accessToken,
                      panelNo: this.props.panelNo,
                      doorNo: this.props.doorNo,
                      locked: this.props.doorStatus,
                      doorName: this.props.doorName
                    };
                    this.props.writeLog("OperatorID: " + this.props.operatorId + "--> DoorInfo: " + JSON.stringify(doorInfo));
                    this.props.sendLockTheDoor(doorInfo, this.props.baseURL);
                  } catch (error) {
                    this.setState(() => {
                      throw error;
                    });
                  }
                }}
              />
            </Link>
            <Link style={styles.underlineless} to="/control">
              <ButtonView
                label="Unlock & Hold"
                instyle={styles.unlockhold}
                onPress={() => {
                  try {
                    const doorInfo = {
                      accessToken: this.props.accessToken,
                      panelNo: this.props.panelNo,
                      doorNo: this.props.doorNo,
                      locked: this.props.doorStatus,
                      doorName: this.props.doorName
                    };
                    this.props.writeLog("OperatorID: " + this.props.operatorId + "--> DoorInfo: " + JSON.stringify(doorInfo));

                    this.props.sendUnlockHoldTheDoor(
                      doorInfo,
                      this.props.baseURL
                    );
                    // this.props.setCurrentOperatingDoor(doorInfo);
                  } catch (error) {
                    this.setState(() => {
                      throw error;
                    });
                  }
                }}
              />
            </Link>
          </div>
        }
        <div style={styles.line} />
      </div>
    );
  }
}

const doorStatusStyle = statusDescription => {
  switch (statusDescription) {
    case 'DOOR is Secure': //2
    case 'Manual Locked': //6
    case 'Door Secure.':
      return styles.doorStatusLocked;
    case 'Forced Door': //3
    case 'Free Access': //4
    case 'Manual Unlocked': //5
    case 'Bypass Active': //7
    case 'DOOR is Open': //10
    case 'Free Access Open': //11
      case 'Disabled and Open': //22
      case 'Door Unlocked':
      return styles.doorStatusUnlocked;
    default:
      return styles.doorStatusUnknown;
  }
};

const checkDoorStatus = statusDescription => {
  switch (statusDescription) {
    case 'DOOR is Secure': //2
      case 'Manual Locked': //6
      case 'Door Secure.':
      return 'Door Locked';
    case 'Forced Door': //3
    case 'Free Access': //4
    case 'Manual Unlocked': //5
    case 'Bypass Active': //7
    case 'DOOR is Open': //10
    case 'Free Access Open': //11
     case 'Disabled and Open': //22
      case 'Door Unlocked':
      return 'Door Unlocked';
    default:
      return 'Unknown';
  }
};

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    height: 100,
    marginBottom: 3,
    marginTop: 3,
    flexDirection: 'column',
    backgroundColor: Colors.White
  },
  alterBackgroundContainer: {
    display: 'flex',
    flex: 1,
    height: 100,
    marginBottom: 3,
    marginTop: 3,
    flexDirection: 'column',
    backgroundColor: Colors.VeryPaleYellow
  },
  titleRow: {
    height: 35,
    flex: 1,
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  dot: {
    width: 12,
    textAlign: 'center'
  },
  doorStatusUnlocked: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 3,
    marginBottom: 3,
    height: 'auto',
    alignSelf: 'flex-end',
    width: 120,
    textAlign: 'right',
    fontSize: 14,
    color: Colors.DeepGreen,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold'
  },
  doorStatusLocked: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 3,
    marginBottom: 3,
    height: 'auto',
    alignSelf: 'flex-end',
    width: 100,
    textAlign: 'right',
    fontSize: 14,
    color: Colors.Red,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold'
  },
  doorStatusUnknown: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 3,
    marginBottom: 3,
    height: 'auto',
    alignSelf: 'flex-end',
    width: 120,
    textAlign: 'right',
    fontSize: 14,
    color: Colors.Black,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold'
  },
  unlock: {
    backgroundColor: Colors.White,
    color: Colors.DeepGreen,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.Green,
    borderRadius: 6,
    padding: 5,
    marginRight: 5,
    height: 35,
    width: 70,
    fontSize: 12,
    cursor: 'pointer',
    boxShadow: '1px 0.5px 1px #000000',
    ':active': {
      backgroundColor: Colors.Gray
    }
  },
  lock: {
    backgroundColor: Colors.White,
    color: Colors.Red,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.Red,
    borderRadius: 6,
    padding: 5,
    marginRight: 5,
    width: 70,
    height: 35,
    fontSize: 12,
    cursor: 'pointer',
    boxShadow: '0.5px 0.5px 1px #000000',
    ':active': {
      backgroundColor: Colors.Gray
    }
  },
  unlockhold: {
    backgroundColor: Colors.White,
    color: Colors.DeepGreen,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.Green,
    borderRadius: 6,
    padding: 5,
    marginRight: 5,
    fontSize: 12,
    height: 35,
    cursor: 'pointer',
    boxShadow: '1px 0.5px 1px #000000',
    ':active': {
      backgroundColor: Colors.Gray
    }
  },
  doorName: {
    flex: 1,
    alignSelf: 'strech',
    marginLeft: 5,
    marginRight: 2,
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'bottom'
  },
  buttonRow: {
    paddingLeft: 10,
    paddingRight: 10,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between'
  },
  dottedLine: {
    height: 1,
    width: '95%',
    borderBottom: '1px dotted black'
  },
  line: {
    height: 1,
    width: '100%',
    borderBottom: '1px solid',
    borderColor: Colors.LightGrayishViolet
  }
};

const mapStateToProps = state => ({
  accessToken: state.token.accessToken,
  baseURL: state.appConfig.baseURL,
  tmpSelectedBadgeOfUser: state.token.tmpSelectedBadgeOfUser,
  operatorId: state.token.operatorId
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendLockTheDoor: actions.sendLockTheDoor,
      sendUnLockTheDoor: actions.sendUnlockTheDoor,
     sendUnlockHoldTheDoor: actions.sendUnlockHoldTheDoor,
      writeLog: actions.writeLog
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(DoorItemView)
);

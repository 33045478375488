import { put, call, takeLatest, select } from 'redux-saga/effects';
import moment from 'moment';
import * as workerTimers from 'worker-timers';
import * as ActionTypes from '../actions/types';
import * as Api from '../svc/api.js';
import * as Messages from '../consts/messages';
import { BADGE_VIOLET_VOID} from '..//consts/consts';

import {
    PAGE_NAMES,
    MSG_SYSTEM_OFFLINE,
    MSG_SYSTEM_ONLINE,
    MSG_MBL_STOPPED,
    MBL_STOPPED_ERROR_MESSAGE
} from '../consts/consts';


export default function* unlockMyDoorSaga() {
    yield console.log('running unlock my door saga');
    yield takeLatest(ActionTypes.SEND_MY_DOOR_UNLOCK, sendMyDoorUnLock);
    yield takeLatest(ActionTypes.CHECK_IF_PIN_COMMONCODE_ENABLED, checkIfPINCommonCodeEnabled);
}

function* sendMyDoorUnLock(action) {
    const currentPage = yield select(getCurrentPage);
    const operatorID = yield select(getOperatorID);

    yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + ' unlockSaga --> Send Unlock My Door: ' + JSON.stringify(action.payload) });
    try {
        const isPINCodeEnabled = yield select(getIfPINCodeEnabled);
        const isCommonCodeEnabled = yield select(getIfCommonCodeEnabled);

        const baseURL = yield select(getBaseURL);
        const response = yield call(Api.sendUnLockMyDoor, action.payload, baseURL);
       
        //yield console.log('http>response :', response);
        yield put({
            type: ActionTypes.SEND_MY_DOOR_UNLOCK_SUCCEEDED,
            payload: response.data
        });

        yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + ' unlockSaga --> Send Unlock My Door--> API Response Data: ' + JSON.stringify(response.data) });

        if ((isPINCodeEnabled === true || isCommonCodeEnabled === true) && response.data === 0) {
            
            const message = "Common Code or PIN is wrong.";
            yield put({ type: ActionTypes.ADD_AND_SHOW_MESSAGE, payload: message });
            return;
        }

        yield put({ type: ActionTypes.GET_DOOR_STATUS });
        if (response.data > 1 && response.data != 8 && response.data != 17 && !(response.data == 64 && (isPINCodeEnabled === true || isCommonCodeEnabled === true))) {
            yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + ' unlockSaga --> Send Unlock My Door--> API Response Data: ' + JSON.stringify(response.data) });

           
            let message = "Badge violate void";
            const panelStatus = response.data;
            
            switch (panelStatus) {
                //case 8: 
                //    message = "BADGE Duress";
                //    break;
                case 32:
                    message = "BADGE Violate Time of Day";
                    break;
                case 64:
                    message = "BADGE Violate Unauthorized";
                    break;
                case 16:
                    message = "Badge violate void";
                    break;
                case 54:
                    message = "Badge violate void at panel";
                    break;
                case 96:
                    message = "Badge Violate APB";
                    break;
                case 80:
                    message = "BADGE Violate PIN";
                    break;
                case 216:
                    message = "BADGE Violate Category No Match";
                    break;
                case 221:
                    message = "APB Area Occupied";
                    break;
                case 222:
                    message = "APB Area Vacant";
                    break;
                case 223:
                    message = "APB Area Count Down Reached";
                    break;
                case 224:
                    message = "APB Area Count Up Reached";
                    break;
                case 241:
                    message = "BADGE Lockout";
                    break;
            }
            yield put({ type: ActionTypes.SEND_MY_DOOR_UNLOCK_FAILED });
            yield put({ type: ActionTypes.ADD_AND_SHOW_MESSAGE, payload: message });
            yield put({ type: ActionTypes.WRITE_LOG, payload: 'OperatorID: ' + operatorID + ' unlockSaga --> Send Unlock My Door--> Message: ' + message });

        }
    } catch (error) {
        yield call(handleError, error, ActionTypes.SEND_MY_DOOR_UNLOCK_FAILED);
    }
}



function* handleError(error, actionType = ActionTypes.DATA_FETCH_ERROR) {
    yield console.error('http>>error :', error);

    window.errObject = error;

    yield put({
        type: actionType,
        payload: error
    });

    let errorMessage = "";
    const wifiDisconnectOrServerStopped = (error.message === 'Network Error' && typeof (error.response) === 'undefined');
    const mblStopped = error.response && error.response.data && error.response.data.message && error.response.data.message.includes(MBL_STOPPED_ERROR_MESSAGE);
    const tokenExpired = error.response && error.response.data && error.response.status === 500 && error.response.data.exceptionType === 'System.InvalidOperationException';
    if (error.response && (error.response.status === 303 || error.response.status === 400)) {
        errorMessage = error.response.data;
    }


    if (wifiDisconnectOrServerStopped || mblStopped) {
        yield put({ type: ActionTypes.CONNECTION_OFFLINE });
        if (mblStopped) {
            // yield call(addAndShowMessage, MSG_MBL_STOPPED);
            yield put({ type: ActionTypes.ADD_AND_SHOW_MESSAGE, payload: MSG_MBL_STOPPED });
        } else {
          //  yield call(addAndShowMessage, MSG_SYSTEM_OFFLINE);
            yield put({ type: ActionTypes.ADD_AND_SHOW_MESSAGE, payload: MSG_SYSTEM_OFFLINE });
        }
    } else if (tokenExpired) {
        yield put({ type: ActionTypes.LOGOUT });
    } else if (errorMessage && errorMessage.length > 0) {
        console.log("Error Message: ", errorMessage);
        yield put({ type: ActionTypes.SHOWW_ERROR_MESSAGE, payload: errorMessage });
    }
    else {
        const action = {
            payload: error.message
        };
        yield call(logError, action);
    }
}

function* logError(action) {
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);

    try {
        yield call(
            Api.logError,
            accessToken,
            baseURL,
            action.payload
        );
    } catch (error) {
        console.log(error);
    }
}

function* checkIfPINCommonCodeEnabled(action) {
    const baseURL = yield select(getBaseURL);
    const accessToken = yield select(getAccessToken);

    try {
        const response = yield call(
            Api.checkIfPINCommonCodeEnabled,
            accessToken,
            baseURL,
            action.payload
        );

        const doorInfo = {
            accessToken,
            panelNo: action.payload.panelNo,
            doorNo: action.payload.doorNo,
            doorName: action.payload.doorName,
            badge: action.payload.badge,
            facility: action.payload.facility,
            doorCAObjectID: action.payload.doorCAObjectID,
            locked: action.payload.locked,
            noTransactionOnValid: action.payload.noTransactionOnValid,
            isPINCodeEnabled: action.payload.isPINCodeEnabled,
            isCommonCodeEnabled: action.payload.isCommonCodeEnabled,
            requestStatus: action.payload.requestStatus,
            wlockRequestStatus: action.payload.wlockRequestStatus
        };

        console.log("PIN Response:", response.data, action.payload);


        if (response.data === 0 || response.data === '0') {
            yield put({ type: ActionTypes.SEND_MY_DOOR_UNLOCK, payload: doorInfo });
        }

        if (response.data === 3 || response.data === '3') {
            yield put({ type: ActionTypes.CHECK_IF_PIN_COMMONCODE_ENABLED_SUCCEEDED, payload: response.data });
            yield put({ type: ActionTypes.ADD_AND_SHOW_MESSAGE, payload: "Common Code or PIN schedule not Valid" }); 
            return;
        }

        if (response.data === 4 || response.data === '4') {
            yield put({ type: ActionTypes.CHECK_IF_PIN_COMMONCODE_ENABLED_SUCCEEDED, payload: response.data });
            yield put({ type: ActionTypes.ADD_AND_SHOW_MESSAGE, payload: "Door already in Free Access" });
            return;
        }

        yield put({ type: ActionTypes.SET_CURRENT_OPERATING_DOOR, payload: doorInfo });
        yield put({ type: ActionTypes.CHECK_IF_PIN_COMMONCODE_ENABLED_SUCCEEDED, payload: response.data });

      

    } catch (error) {
        console.log(error);
        yield call(handleError, error, ActionTypes.CHECK_IF_PIN_COMMONCODE_ENABLED_FAILED);
    }
}

const getBaseURL = state => state.appConfig.baseURL;
const getAccessToken = state => state.token.accessToken;
const getCurrentPage = state => state.page.currentPage;
const getGeneralMessage = state => state.generalMessage;
const getIfPINCodeEnabled = state => state.door.isPINCodeEnabled;
const getIfCommonCodeEnabled = state => state.door.isCommonCodeEnabled;
const getOperatorID = state => state.token.operatorId;

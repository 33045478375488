import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Colors from '../../consts/colors';
import PinCheckView from './PinCheckView';
import HeaderView from '../common/HeaderView';
import FooterView from '../common/FooterView';
import * as actions from '../../actions/index';
import ErrorBoundary from '../common/ErrorBoundary';

class PinScreen extends Component {
  render() {
    return (
      <div
        onClick={() => {
          this.props.resetInactivityTimer();
        }}
        style={styles.container}
      >
        <div style={styles.header}>
          <HeaderView NoLogInTime NoLogOut NoRefreshButton />
        </div>
        <div style={styles.bodyContainer}>
          <ErrorBoundary>
            <PinCheckView />
          </ErrorBoundary>
        </div>
        <div style={styles.footer} />
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: Colors.White
  },
  dialogStyle: {
    backgroundColor: Colors.VeryPaleBlue,
    padding: 15,
    width: 600,
    height: 'auto'
  },
  bodyContainer: {
    display: 'flex',
    flex: 1,
    //flexDirection: 'column',
    //overflow: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    //marginTop: 115,
    backgroundColor: Colors.DarkModerateViolet
  },
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    width: '100%'
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    padding: 5,
    height: 50,
    flexDirection: 'row',
    backgroundColor: Colors.LightViolet,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.Black,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  viewContainer: {
    display: 'flex',
    flex: 1,
    flexBasis: 200,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  save: {
    margin: 2,
    width: 80,
    height: 32,
    fontSize: 15,
    borderRadius: 10,
    color: Colors.White,
    textAlign: 'center',
    backgroundColor: Colors.MostPureBlue,
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'pointer'
  },
  cancel: {
    margin: 2,
    width: 80,
    borderRadius: 10,
    height: 32,
    color: Colors.White,
    fontSize: 15,
    textAlign: 'center',
    backgroundColor: Colors.LightGray,
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'pointer'
  },
  settings: {
    width: 20,
    height: 20,
    padding: 10
  },
  underlineless: {
    textDecoration: 'none'
  }
};

const mapStateToProps = state => ({
  baseURL: state.appConfig.baseURL
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setBaseURL: actions.setBaseURL,
      resetInactivityTimer: actions.resetInactivityTimer
    },
    dispatch
  );

export default Radium(connect(mapStateToProps, mapDispatchToProps)(PinScreen));

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LogInScreen from '../components/login/LogInScreen';
import MainScreen from '../components/home/MainScreen';
import ConfigScreen from '../components/config/ConfigScreen';
import MyDoorsScreen from '../components/doors/MyDoorsScreen';
import LockdownScreen from '../components/lockdown/LockdownScreen';
import ThreatLevelScreen from '../components/threats/ThreatLevelScreen';
import ControlScreen from '../components/control/ControlScreen';
import PersonnelScreen from '../components/personnel/PersonnelScreen';
import StatusScreen from '../components/status/StatusScreen';
import BadgeHoldersInTimeScreen from '../components/badgeholdersin/BadgeHoldersInTimeScreen';
import ScheduledChangesScreen from '../components/scheduledchanges/ScheduledChangesScreen';
import AddNewScheduleChangeScreen from '../components/scheduledchanges/AddNewScheduleChangeScreen';
import UpdateScheduleChangeScreen from '../components/scheduledchanges/UpdateScheduleChangeScreen';
import PersonnelAddScreen from '../components/personnel/PersonnelAddScreen';
import PersonnelEditScreen from '../components/personnel/PersonnelEditScreen';
import BaseURLConfigScreen from '../components/config/BaseURLConfigScreen';
import PinScreen from '../components/pincheck/PinScreen';
import ChangePasswordScreen from '../components/login/ChangePasswordScreen';

import RequireAuthHOC from '../components/common/RequireAuthHOC';

const Routes = (
  <Switch>
    <Route path="/login" component={LogInScreen} />
    <Route path="/pincheck" component={RequireAuthHOC(PinScreen)} />
    <Route path="/changepassword" component={ChangePasswordScreen} />
    <Route path="/home" component={RequireAuthHOC(MainScreen)} />
    <Route path="/config" component={RequireAuthHOC(ConfigScreen)} />
    <Route path="/doors" component={RequireAuthHOC(MyDoorsScreen)} />
    <Route path="/lockdown" component={RequireAuthHOC(LockdownScreen)} />
    <Route path="/threatlevel" component={RequireAuthHOC(ThreatLevelScreen)} />
    <Route path="/control" component={RequireAuthHOC(ControlScreen)} />
    <Route path="/personnel" component={RequireAuthHOC(PersonnelScreen)} />
    <Route path="/addpersonnel" component={RequireAuthHOC(PersonnelAddScreen)} />
    <Route path="/editpersonnel" component={RequireAuthHOC(PersonnelEditScreen)} />
    <Route path="/status" component={RequireAuthHOC(StatusScreen)} />
    <Route path="/badgeholdersin" component={RequireAuthHOC(BadgeHoldersInTimeScreen)} />
    <Route path="/scheduledchanges" component={RequireAuthHOC(ScheduledChangesScreen)} />
    <Route path="/addschedulechange" component={RequireAuthHOC(AddNewScheduleChangeScreen)} />
    <Route path="/updateschedulechange" component={RequireAuthHOC(UpdateScheduleChangeScreen)} />
    <Route path="/baseurl" component={BaseURLConfigScreen} />
    <Redirect from="/" exact to="/login" />
  </Switch>
);

export default Routes;

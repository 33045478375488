import React from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faAngleLeft, faSync } from '@fortawesome/free-solid-svg-icons';
import { Link, Redirect } from 'react-router-dom';

import * as Colors from '../../consts/colors';
import ButtonView from './ButtonView';
import * as actions from '../../actions/index';
import ModalView from '../common/ModalView';
import ErrorBoundary from '../common/ErrorBoundary';

class HeaderView extends React.Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                showModal: false,
                refreshPressed: false,
                holdTimer: false
            };
            this.showModal = this.showModal.bind(this);
            this.onCloseModal = this.onCloseModal.bind(this);
            this.saveStateSAVE = this.saveStateSAVE.bind(this);
            this.saveStateCancelled = this.saveStateCancelled.bind(this);
            this.onSaveAppConfigSaveSettings = this.onSaveAppConfigSaveSettings.bind(
                this
            );
            this.onCancelAppConfigSaveSetting = this.onCancelAppConfigSaveSettings.bind(
                this
            );
            this.refreshCurrentPage = this.refreshCurrentPage.bind(this);
        } catch (error) {
            this.setState(() => {
                throw error;
            });
        }
    }


    componentDidMount() {
        //this.checkConnectionStatus();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isConnected === true && this.props.isConnected === false) {
            this.checkConnectionStatus();
        } else if (prevProps.isConnected === false && this.props.isConnected === true) {
            this.stopCheckingConnectionStatus();
        }
    }

    componentWillUnmount() {
        this.props.resetSaveStateData();
        this.stopCheckingConnectionStatus();
    }

    onCloseModal() {
        this.setState({ ...this.state, showModal: false });
    }

    onSaveAppConfigSaveSettings() {
        try {
            if (this.props.tmpAppPinEntryChecked === true) {
                if (this.props.tmpSettingsPin.includes('#')) {
                    alert('Enter 4 digit PIN!');
                    return;
                }
            }

            this.props.saveConfigSettings({
                appPinEntryChecked: this.props.tmpAppPinEntryChecked,
                settingsPin: this.props.tmpAppPinEntryChecked
                    ? this.props.tmpSettingsPin
                    : '####'
            });
            alert('CA4K App configuration have been saved.');
            this.props.addAndShowMessage('App Config have been updated.');
            this.props.saveStateData();
        } catch (error) {
            this.setState(() => {
                throw error;
            });
        }
    }

    onCancelAppConfigSaveSettings() {
        try {
            this.props.cancelStateData();
            this.props.cancelSaveConfigSettings();
        } catch (error) {
            this.setState(() => {
                throw error;
            });
        }
    }

    refreshCurrentPage() {
        this.props.refreshCurrentPage();
    }

    showModal() {
        this.setState({ ...this.state, showModal: true });
    }

    saveStateSAVE() {
        this.props.saveStateData();
    }
    saveStateCancelled() {
        this.props.cancelStateData();
    }

    checkConnectionStatus() {
        this.connectionStatusTimer = window.setInterval(() => {
            if (!!this.props.accessToken) {
                this.props.checkConnectionStatus();
            }
        }, 5000);
    }

    stopCheckingConnectionStatus() {
        if (this.connectionStatusTimer) {
            clearInterval(this.connectionStatusTimer);
        }
    }

    render() {
        if (
            this.props.backPath &&
            (this.props.save === true || this.props.cancel === true)
        ) {
            return <Redirect to={this.props.backPath} />;
        }

        return (
            <ErrorBoundary>
                <div
                    onClick={() => {
                        this.props.resetInactivityTimer();
                    }}
                    style={styles.container}
                >
                    <div style={styles.logoRow}>
                        <div
                            style={styles.continentalTextContainer}
                            onTouchStart={() => {
                                try {
                                    if (this.state.holdTimer) {
                                        clearTimeout(this.state.holdTimer);
                                    }
                                    this.setState({
                                        ...this.state,
                                        holdTimer: false
                                    });
                                } catch (error) {
                                    this.setState(() => {
                                        throw error;
                                    });
                                }
                            }}
                            onTouchEnd={() => {
                                try {
                                    let timer = false;

                                    if (!this.state.holdTimer) {
                                        clearTimeout(this.state.holdTimer);
                                        timer = setTimeout(() => {
                                            alert(this.props.expMessageList);
                                        }, 3000);
                                    }
                                    this.setState({
                                        ...this.state,
                                        holdTimer: timer
                                    });
                                } catch (error) {
                                    this.setState(() => {
                                        throw error;
                                    });
                                }
                            }}
                            onMouseUp={() => {
                                try {
                                    if (this.state.holdTimer) {
                                        clearTimeout(this.state.holdTimer);
                                    }
                                    this.setState({
                                        ...this.state,
                                        holdTimer: false
                                    });
                                } catch (error) {
                                    this.setState(() => {
                                        throw error;
                                    });
                                }
                            }}
                            onMouseDown={() => {
                                try {
                                    let timer = false;

                                    if (!this.state.holdTimer) {
                                        clearTimeout(this.state.holdTimer);
                                        timer = setTimeout(() => {
                                            alert(this.props.expMessageList);
                                        }, 2000);
                                    }
                                    this.setState({
                                        ...this.state,
                                        holdTimer: timer
                                    });
                                } catch (error) {
                                    this.setState(() => {
                                        throw error;
                                    });
                                }
                            }}
                        >
                            <p style={styles.continentalText}>Continental</p>
                            <p style={styles.accessText}> Access </p>
                        </div>
                        <div style={styles.logoutRow}>
                            {!this.props.NoLogOut &&
                                this.props.username &&
                                this.props.username.length > 0 && (
                                    <div style={styles.username}>{this.props.username}</div>
                                )}

                            {!this.props.NoLogOut && (
                                <ButtonView
                                    instyle={styles.logout}
                                    label="Log Out"
                                    onPress={() => {
                                        this.props.logOut();
                                    }}
                                />
                            )}
                        </div>
                    </div>

                    <div style={styles.menuAreaContainer}>
                        {this.props.hasMenu && (
                            <Link style={styles.underlineless} to="/config">
                                <div style={styles.menuBackground}>
                                    <FontAwesomeIcon
                                        icon={faBars}
                                        size="1x"
                                        color={Colors.Purple}
                                    />
                                </div>
                            </Link>
                        )}
                        {this.props.hasBack && this.props.backPath && (
                            <div
                                style={styles.menuBackground}
                                onClick={() => {
                                    try {
                                        if (this.props.configChanged === true) {
                                            this.showModal();
                                        } else {
                                            // this.onCancelAppConfigSaveSettings();
                                            this.props.cancelStateData();
                                        }
                                    } catch (error) {
                                        this.setState(() => {
                                            throw error;
                                        });
                                    }
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faAngleLeft}
                                    size="1x"
                                    color={Colors.Purple}
                                />
                            </div>
                        )}

                        {!this.props.title && (
                            <p style={styles.appName}>
                                CA4K Access App
                {!this.props.NoLogInTime && this.props.loggedInTime && (
                                    <span style={styles.loggedInTimeText}>
                                        Logged in at {this.props.loggedInTime}
                                    </span>
                                )}
                            </p>
                        )}
                        {this.props.title && (
                            <p style={styles.appName}>
                                {' '}
                                {this.props.title}{' '}
                                {!this.props.NoLogInTime && this.props.loggedInTime && (
                                    <span style={styles.loggedInTimeText}>
                                        Logged in {this.props.loggedInTime}
                                    </span>
                                )}
                            </p>
                        )}
                        {!this.props.NoRefreshButton && (
                            <div
                                onClick={() => {
                                    try {
                                        this.refreshCurrentPage();
                                        this.setState({ ...this.state, refreshPressed: true });
                                        setTimeout(() => {
                                            //Start the timer
                                            this.setState({ ...this.state, refreshPressed: false }); // after 1 seconds state updates
                                        }, 0.2 * 1000);
                                    } catch (error) {
                                        this.setState(() => {
                                            throw error;
                                        });
                                    }
                                }}
                                style={styles.refresh}
                            >
                                {this.state.refreshPressed === false && (
                                    <FontAwesomeIcon
                                        icon={faSync}
                                        size="1x"
                                        color={Colors.Purple}
                                    />
                                )}
                                {this.state.refreshPressed === true && (
                                    <FontAwesomeIcon
                                        icon={faSync}
                                        size="1x"
                                        color={Colors.Purple}
                                        rotation={90}
                                    />
                                )}
                            </div>
                        )}
                        {/*below line is placeholder*/}
                        {this.props.NoRefreshButton && (
                            <div style={{ width: 75, textAlign: 'center' }} />
                        )}
                    </div>
                    <div style={styles.line} />

                    {this.state.showModal === true && (
                        <ModalView
                            onClose={this.onCloseModal}
                            dialogStyle={styles.dialogStyle}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                            >
                                <p
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        fontSize: 16,
                                        justifyContent: 'center'
                                    }}
                                >
                                    Are you sure about to save the App Config changes?
                </p>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    marginTop: '16px'
                                }}
                            >
                                <ButtonView
                                    label="No"
                                    onPress={() => {
                                        this.onCancelAppConfigSaveSettings();
                                        this.onCloseModal();
                                    }}
                                    instyle={styles.cancelDialog}
                                />

                                <ButtonView
                                    label="Yes"
                                    onPress={() => {
                                        this.onSaveAppConfigSaveSettings();
                                        this.onCloseModal();
                                    }}
                                    instyle={styles.saveDialog}
                                />
                            </div>
                        </ModalView>
                    )}
                </div>
            </ErrorBoundary>
        );
    }
}
const styles = {
    underlineless: {
        textDecoration: 'none'
    },
    dialogStyle: {
        backgroundColor: Colors.VeryPaleBlue,
        padding: 15,
        width: 300,
        height: 'auto'
    },
    saveDialog: {
        margin: 2,
        width: 80,
        borderRadius: 4,
        height: 32,
        color: Colors.Black,
        fontSize: 15,
        textAlign: 'center',
        backgroundColor: Colors.LightGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer'
    },
    cancelDialog: {
        margin: 2,
        width: 80,
        borderRadius: 4,
        height: 32,
        color: Colors.Black,
        fontSize: 15,
        textAlign: 'center',
        backgroundColor: Colors.LightGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer'
    },
    logout: {
        backgroundColor: Colors.LightGray,
        color: Colors.Black,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'black',
        padding: 4,
        fontSize: '.75em',
        marginRight: 5,
        borderRadius: 6,
        fontWeight: 300,
        width: 62,
        justifyContent: 'center',
        height: 28,
        alignItems: 'center',
        cursor: 'pointer',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },

    refresh: { width: 75, textAlign: 'center' },
    logoutRow: {
        display: 'flex',
        height: 48,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    userNameRow: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },
    logoRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    username: {
        width: 100,
        height: 'auto',
        justifyContent: 'center',
        alignSelf: 'left',
        marginRight: 10,
        textAlign: 'center',
        fontSize: 14,
        alignItems: "'center'",
        color: Colors.Purple
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: 115,
        backgroundColor: Colors.VeryPaleBlue,
        zIndex: 10
    },
    continentalText: {
        textAlign: 'left',
        color: Colors.Purple,
        height: 18,
        borderBottom: '1px solid',
        borderColor: Colors.Green,
        paddingBottom: 5,
        fontSize: '1em'
    },
    continentalTextContainer: {
        flex: 1,
        width: 250,
        display: 'flex',
        height: 48,
        flexDirection: 'row',
        justifyContent: 'left',
        marginLeft: 70
    },
    accessText: {
        textAlign: 'center',
        color: Colors.Green,
        marginLeft: 20,
        fontSize: '1em'
    },
    loggedInTimeText: {
        color: Colors.Black,
        marginRight: 8,
        fontSize: 10,
        marginTop: 2,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 'normal'
    },
    menuBackground: {
        padding: 10,
        backgroundColor: Colors.VeryPaleBlue
    },
    appName: {
        color: Colors.Purple,
        justifyContent: 'center',
        marginLeft: 40,
        fontSize: 20,
        fontWeight: 'bold'
    },

    menuAreaContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: 42,
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: 20,
        marginRight: 20
    },
    line: {
        height: 8,
        backgroundColor: Colors.Green,
        marginTop: 5
    }
};

const mapStateToProps = state => ({
    username: state.user.username,
    loggedInTime: state.token.loggedInTime,
    appPinEntryChecked: state.pin.appPinEntryChecked,
    tmpAppPinEntryChecked: state.pin.tmpAppPinEntryChecked,
    settingsPin: state.pin.settingsPin,
    tmpSettingsPin: state.pin.tmpSettingsPin,
    save: state.generalMessage.saveStateData,
    cancel: state.generalMessage.cancelStateData,
    configChanged: state.settings.configChanged,
    expMessageList: state.exception.msgList,
    isConnected: state.connectStatus.connected,
    accessToken: state.token.accessToken
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            logOut: actions.logOut,
            //showGeneralMessage: actions.showGeneralMessage,
            saveConfigSettings: actions.saveConfigSettings,
            cancelSaveConfigSettings: actions.cancelSaveConfigSettings,
            changeTmpSettingsPin: actions.changeTmpSettingsPin,
            changeTmpAppEntryChecked: actions.changeTmpAppEntryChecked,
            resetInactivityTimer: actions.resetInactivityTimer,
            //addMessage: actions.addMessage,
            addAndShowMessage: actions.addAndShowMessage,
            saveStateData: actions.saveStateData,
            cancelStateData: actions.cancelStateData,
            resetSaveStateData: actions.resetSaveStateData,
            resetConfigurationChanged: actions.resetConfigurationChanged,
            refreshCurrentPage: actions.refreshCurrentPage,
            checkConnectionStatus: actions.getConnectionStatus
        },
        dispatch
    );

export default Radium(connect(mapStateToProps, mapDispatchToProps)(HeaderView));

import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import * as Colors from '../../consts/colors.js';
import HeaderView from '../common/HeaderView';
import FooterView from '../common/FooterView';
import ButtonView from '../common/ButtonView';
import * as actions from '../../actions/index';
import ModalView from '../common/ModalView';
import { PAGE_NAMES } from '../../consts/consts';
import ErrorBoundary from '../common/ErrorBoundary';

class BaseURLConfigScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showProgressModal: true,
      url: this.props.baseURL ? this.props.baseURL : '',
      saved: false
    };
    this.setState({ saved: true });
    this.handeleBaseURLChanged = this.handeleBaseURLChanged.bind(this);
    this.handleSaveButtonInModalClicked = this.handleSaveButtonInModalClicked.bind(this);
    this.props.setCurrentPageName(PAGE_NAMES.BASE_URL_CONFIGURATION);
  }

  onCloseModal() {
    this.setState({ showModal: false });
  }

  showWarningDialog() {
    this.setState({ showModal: true });
  }

  handeleBaseURLChanged(event) {
    this.setState({ ...this.state, url: event.target.value });
  }

  handleSaveButtonInModalClicked() {
    // if (this.isValidURL(this.state.url)) {
    this.props.setBaseURL(this.state.url);
    this.setState({ saved: true });
    //} else {
    // this.showWarningDialog();
    //}
  }

  isValidURL(str) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return pattern.test(str);
  }

  render() {
    if (this.state.saved === true) {
      if (this.props.accessToken.length > 0) {
        this.props.logOut();
      }
      return <Redirect to="/login" />;
    }

    return (
      <div style={styles.container}>
        <div style={styles.header}>
          <HeaderView NoLogOut />
        </div>

        <div style={styles.bodyContainer}>
          <ErrorBoundary>
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                width: 350
              }}
            >
              <div style={styles.title}>Web Service IP Address</div>
              <div style={styles.baseURLContainer}>
                <p style={{ fontSize: 14, fontWeight: '600' }}> Enter Base URL</p>
                <input
                  type="url"
                  value={this.state.url}
                  name="txtBaseURL"
                  placeholder="http://ipaddress:port/"
                  onChange={this.handeleBaseURLChanged}
                  style={styles.baseURL}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <ButtonView
                  label="Save"
                  onPress={this.handleSaveButtonInModalClicked}
                  instyle={styles.save}
                />
              </div>
            </div>
          </ErrorBoundary>
        </div>
        <div style={styles.footer}>
          <FooterView />
        </div>
        <div>
          {this.state.showModal === true && (
            <ModalView
              onClose={() => this.onCloseModal}
              dialogStyle={styles.dialogStyle}
              shouldCloseOnOverlayClick
            >
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <p
                    style={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'row',
                      fontSize: 16,
                      justifyContent: 'center'
                    }}
                  >
                    Please enter valid URL
                  </p>
                </div>

                <div style={{ fontSize: 12, color: Colors.Red }}>[Should not use @ # $ ; * \]</div>

                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginTop: '16px'
                  }}
                >
                  <ButtonView
                    label="CLOSE"
                    onPress={() => {
                      this.onCloseModal();
                    }}
                    instyle={styles.cancel}
                  />
                </div>
              </div>
            </ModalView>
          )}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    height: '100%',
    backgroundColor: Colors.White,
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  baseURLContainer: {
    flex: 1,
    display: 'flex',
    backgroundColor: Colors.White,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  baseURL: {
    width: '90%',
    height: 24,
    marginLeft: 5,
    border: '1px solid',
    borderColor: Colors.LightGray
  },
  title: {
    fontSize: 18,
    alignItems: 'center',
    height: 40,
    backgroundColor: Colors.White,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    padding: 5,
    color: Colors.Green
  },
  blackLine: {
    height: 1,
    width: '98%',
    borderBottom: '1px solid black',
    marginLeft: 10
  },
  bodyContainer: {
    paddingTop: 20,
    display: 'flex',
    flex: 1,
    backgroundColor: Colors.White,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 115
  },
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    width: '100%'
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%'
  },
  save: {
    margin: 2,
    width: 80,
    height: 32,
    fontSize: 15,
    borderRadius: 10,
    color: Colors.White,
    textAlign: 'center',
    backgroundColor: Colors.MostPureBlue,
    border: '1px solid',
    cursor: 'pointer'
  },
  dialogStyle: {
    backgroundColor: Colors.VeryPaleBlue,
    padding: 15,
    width: 300,
    height: 'auto'
  },
  cancel: {
    margin: 2,
    width: 80,
    borderRadius: 10,
    height: 32,
    color: Colors.Black,
    fontSize: 15,
    textAlign: 'center',
    backgroundColor: Colors.LightGray,
    border: '1px solid',
    borderColor: Colors.LightGray,
    cursor: 'pointer'
  }
};
const mapStateToProps = state => ({
  baseURL: state.appConfig.baseURL,
  accessToken: state.token.accessToken
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setBaseURL: actions.setBaseURL,
      logOut: actions.logOut,
      setCurrentPageName: actions.setCurrentPageName
    },
    dispatch
  );

export default Radium(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseURLConfigScreen)
);

import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';
import { StyleRoot } from 'radium';

import 'normalize.css';
import './style.css';
import App from './App';

ReactDOM.render(
  <StyleRoot>
    <App />
  </StyleRoot>,
  document.getElementById('app')
);

import { put, select, takeLatest,takeEvery, fork, delay } from 'redux-saga/effects';
import * as ActionTypes from '../actions/types';
import { BADGE_VALID_EVENT, BADGE_VIOLET_VOID, VALID_ACCESS_TYPES } from '..//consts/consts';
const _ = require('lodash');


export default function* eventSaga() {
    //yield console.log('running event saga...');
    yield takeEvery(ActionTypes.GET_DOOR_STATUS, startStatusPulling);
}

function* startStatusPulling() {
    yield fork(statusPulling);
    //console.log('PostEvent:: start status pulling');
}

function* statusPulling() {
    try {
        const state = yield select(getState);
        while (true) {
            //const currentPage = yield select(getCurrentPage);

            const currentOperatingDoor = state.door.currentOperatingDoor;

            const badgeValidStatus = state.door.badgeValidStatus;
            const noTransactionOnValid = currentOperatingDoor.noTransactionOnValid ? currentOperatingDoor.noTransactionOnValid: false;

            console.log("BadgeValidStatus:::", badgeValidStatus, noTransactionOnValid, currentOperatingDoor, currentOperatingDoor.noTransactionOnValid, currentOperatingDoor.isPINCodeEnabled, currentOperatingDoor.isCommonCodeEnabled);
            yield put({ type: ActionTypes.WRITE_LOG, payload: 'BadgeValidStatus: ' + badgeValidStatus+",NoTransactionValid: "+ noTransactionOnValid + ",IsPINCodeEnabled: "+ currentOperatingDoor.isPINCodeEnabled+ ",IsCommonCodeEnabled: "+ currentOperatingDoor.isCommonCodeEnabled });
            if (!_.includes(VALID_ACCESS_TYPES, badgeValidStatus) && !(badgeValidStatus == 64 && (currentOperatingDoor.isPINCodeEnabled === true || currentOperatingDoor.isCommonCodeEnabled === true))) {

                console.log("BadgeValidStatus", badgeValidStatus);
                const badgeViolateVoidEvent = {
                    aCat: BADGE_VIOLET_VOID.aCat,
                    aClass: BADGE_VIOLET_VOID.aClass,
                    aDescription: 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA', //`${lastName}, ${firstName}`,
                    aName: currentOperatingDoor.doorName,
                    aPending: BADGE_VIOLET_VOID.aPending,
                    aPriority: BADGE_VIOLET_VOID.aPriority,
                    aResReq: BADGE_VIOLET_VOID.aResReq,
                    PanelNo: currentOperatingDoor.panelNo,
                    DeviceNo: currentOperatingDoor.doorNo,
                    DeviceCaObjectID: currentOperatingDoor.doorCAObjectID ? currentOperatingDoor.doorCAObjectID : '',
                    Badge: state.token.tmpSelectedBadgeOfUser.Badge,
                    Facility: state.token.tmpSelectedBadgeOfUser.Facility,
                    Status: badgeValidStatus
                };

                yield put({ type: ActionTypes.WRITE_LOG, payload: 'BadgeVioletVoid Event: -->' + JSON.stringify(badgeViolateVoidEvent) });

                yield put({
                    type: ActionTypes.POST_EVENT,
                    payload: badgeViolateVoidEvent
                });
                
                break;

            }
            
            yield put({ type: ActionTypes.WRITE_LOG, payload: 'Current Operating Door: --->' + JSON.stringify(currentOperatingDoor) });

            yield delay(2 * 1000);
            const firstName = state.token.tmpSelectedBadgeOfUser.FrstName
                ? state.token.tmpSelectedBadgeOfUser.FrstName
                : '';
            const lastName = state.token.tmpSelectedBadgeOfUser.LastName
                ? state.token.tmpSelectedBadgeOfUser.LastName
                : '';

            for (let index = 0; index < 3; index++) {
                // yield put({ type: ActionTypes.FETCH_ALL_DOORS });
                yield put({
                    type: ActionTypes.FETCH_ACCESS_READER_LIST_BY_BADGE,
                    payload: {
                        badgeNo: state.token.tmpSelectedBadgeOfUser.Badge,
                        facility: state.token.tmpSelectedBadgeOfUser.Facility,
                        requestStatus: true,
                        wlockRequestStatus: true
                    }
                });

                const doorStatusUpdated = yield isDoorStatusUpdated(
                    currentOperatingDoor
                );
                //  console.log('Door Status Updated:', doorStatusUpdated);
                if (doorStatusUpdated && doorStatusUpdated === true) {
                    //console.log(
                    //  'PostEvent:: Door Status updated for Door No',
                    //  state.door.currentOperatingDoor.doorNo
                    //);
                    console.log("Badge Valid Status:", badgeValidStatus, noTransactionOnValid);
                    if (badgeValidStatus === 8 || noTransactionOnValid === false) {
                        const badgeValidEvent = {
                            aCat: BADGE_VALID_EVENT.aCat,
                            aClass: BADGE_VALID_EVENT.aClass,
                            aDescription: 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA', //`${lastName}, ${firstName}`,
                            aName: currentOperatingDoor.doorName,
                            aPending: BADGE_VALID_EVENT.aPending,
                            aPriority: BADGE_VALID_EVENT.aPriority,
                            aResReq: BADGE_VALID_EVENT.aResReq,
                            PanelNo: currentOperatingDoor.panelNo,
                            DeviceNo: currentOperatingDoor.doorNo,
                            DeviceCaObjectID: currentOperatingDoor.doorCAObjectID ? currentOperatingDoor.doorCAObjectID : '',
                            Badge: state.token.tmpSelectedBadgeOfUser.Badge,
                            Facility: state.token.tmpSelectedBadgeOfUser.Facility,

                            Status: badgeValidStatus
                        };

                        yield put({ type: ActionTypes.WRITE_LOG, payload: 'BadgeValidEvent: --->' + JSON.stringify(badgeValidEvent)});
                      
                        yield put({ type: ActionTypes.POST_EVENT, payload: badgeValidEvent });
                        //console.log(
                        //  'PostEvent:: Sending Badge Valid Event......',
                        //  badgeValidEvent
                        //);
                        return;
                    } else if (noTransactionOnValid === true) {
                        return;
                    } 
                    
                }

                yield delay(5 * 1000);
            }

            const badgeViolateVoidEvent = {
                aCat: BADGE_VIOLET_VOID.aCat,
                aClass: BADGE_VIOLET_VOID.aClass,
                aDescription: 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA', //`${lastName}, ${firstName}`,
                aName: currentOperatingDoor.doorName,
                aPending: BADGE_VIOLET_VOID.aPending,
                aPriority: BADGE_VIOLET_VOID.aPriority,
                aResReq: BADGE_VIOLET_VOID.aResReq,
                PanelNo: currentOperatingDoor.panelNo,
                DeviceNo: currentOperatingDoor.doorNo,
                DeviceCaObjectID: currentOperatingDoor.doorCAObjectID ? currentOperatingDoor.doorCAObjectID : '' ,
                Badge: state.token.tmpSelectedBadgeOfUser.Badge,
                Facility: state.token.tmpSelectedBadgeOfUser.Facility,
                Status: BADGE_VIOLET_VOID.Status
            };
            yield put({ type: ActionTypes.WRITE_LOG, payload: 'BadgeVioletVoid Event: --->' + JSON.stringify(badgeViolateVoidEvent) });

            yield put({
                type: ActionTypes.POST_EVENT,
                payload: badgeViolateVoidEvent
            });
            //console.log(
            //  'PostEvent:: Sending Badge Violet Void......',
            //  badgeViolateVoidEvent
            //);
            break;
        }

    } catch (error) {
        console.log(error);
    }
}

function* isDoorStatusUpdated(doorInfo) {
    try {
        //console.log('PostEvent::DoorInfo', doorInfo);
        const state = yield select(getState);
        //console.log('PostEvent::DoorInfo', doorInfo, state);

        const doorInOperation = state.door.doors.find(
            door => door.doorNo === doorInfo.doorNo
        );
        //console.log('PostEvent:: Door In Operation :', doorInOperation);

        // if (doorInOperation.locked !== doorInfo.locked) {
        //   return true;
        // }

        if (!_.isEqual(doorInOperation.statusDef, doorInfo.statusDef)) {
            return true;
        }
    } catch (error) {
        //yield put({ type: ActionTypes.FETCH_ALL_DOORS_FAILED, payload: error });
        yield put({
            type: ActionTypes.FETCH_ACCESS_READER_LIST_BY_BADGE_FAILED,
            payload: error
        });
        return false;
    }
    return false;
}

//const getCurrentPage = state => state.page.currentPage;
//const getBaseURL = state => state.appConfig.baseURL;
const getState = state => state;

import React, { Component } from "react";
import Radium from "radium";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import * as Colors from "../../consts/colors";
import ThreatLevelItemView from "./ThreatLevelItemView.js";
import ButtonView from "../common/ButtonView";
import * as actions from "../../actions/index";
import {
    PAGE_NAMES,
    ADMIN_ROLE_ID,
    SCREEN_CODES,
    SECURITY_LEVEL_CODES
} from "../../consts/consts";

class ThreatLevelView extends Component {
    constructor(props) {
        super(props);

        const threatLevelPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.THREATLEVEL
        );
        this.state = { threatLevelPrivilege };
       // console.log("Threat level Privilege: ", threatLevelPrivilege);
        this.props.writeLog('OperatorID: ' + this.props.operatorId + "ThreatLevelView -->  Constructor() -->  ThreatLevelPriviledge : " + JSON.stringify(threatLevelPrivilege));

    }

    UNSAFE_componentWillMount() {
        this.props.setCurrentPageName(PAGE_NAMES.THREATLEVEL);
        this.props.writeLog('OperatorID: ' + this.props.operatorId + "ThreatLevelView -->  fetcing threat levels and lockdown areas() --> ");

        this.props.fetchAllThreatLevels();
        this.props.fetchAllLockDownArea();
    }

    showThreatList(threats) {
        const threatList = threats.map(threat => (
            <ThreatLevelItemView
                key={threat.level}
                threatLevel={threat.description}
                activated={threat.activated}
                levelNo={threat.level}
            />
        ));
        return <div>{threatList}</div>;
    }

    render() {
        return (
            <div style={styles.container}>
                <div>
                    <div style={styles.expandContainer}>
                        <Link style={styles.underlineless} to="/home">
                            <div>
                                <FontAwesomeIcon
                                    icon={faMinus}
                                    size="1x"
                                    color={Colors.White}
                                />
                            </div>
                        </Link>
                        <div style={styles.functionText}>Threat Levels</div>
                    </div>
                </div>
                <div style={styles.blackLine} />
                {this.props.threats.length === 0 && (
                    <div style={styles.emptyList}>No Threat Levels Found</div>
                )}
                {this.props.roleId === ADMIN_ROLE_ID &&
                    this.props.threats.length !== 0 && (
                        <div style={styles.ThreatLevelContainer}>
                            <div style={styles.threatLevelAllAreas}>All Threat Levels</div>
                            <div style={styles.threatLevelStatusButton}>
                                <Link style={styles.underlineless} to="/threatlevel">
                                    <ButtonView
                                        label="Activate"
                                        enabled={this.props.threats.some(
                                            threat => threat.activated === false
                                        )}
                                        instyle={
                                            this.props.threats.some(
                                                threat => threat.activated === false
                                            )
                                                ? styles.activate
                                                : styles.disabled
                                        }
                                        onPress={() => {
                                            const isConfirmed = window.confirm(
                                                "Confirm Activation for all threat levels?"
                                            );
                                            if (isConfirmed === true) {
                                                this.props.writeLog('OperatorID: ' + this.props.operatorId + "ThreatLevelView --> Activate all threat levels");
                                                this.props.sendActivateAllThreatLevels();
                                                alert(
                                                    "Activation command is sent for all threat levels."
                                                );
                                            }
                                        }}
                                    />
                                </Link>
                                <Link style={styles.underlineless} to="/threatlevel">
                                    <ButtonView
                                        label="Deactivate"
                                        enabled={this.props.threats.some(
                                            threat => threat.activated === true
                                        )}
                                        instyle={
                                            this.props.threats.some(
                                                threat => threat.activated === true
                                            )
                                                ? styles.deactivate
                                                : styles.disabled
                                        }
                                        onPress={() => {
                                            const isConfirmed = window.confirm(
                                                "Confirm Deactivation for all threat levels?"
                                            );
                                            if (isConfirmed === true) {
                                                this.props.writeLog('OperatorID: ' + this.props.operatorId + "ThreatLevelView --> Deactivate all threat levels");
                                                this.props.sendDeactivateAllThreatLevels();
                                                alert(
                                                    "Deactivation command is sent for all threat levels."
                                                );
                                            }
                                        }}
                                    />
                                </Link>
                            </div>
                        </div>
                    )}
                {this.props.roleId !== ADMIN_ROLE_ID &&
                    this.state.threatLevelPrivilege &&
                    this.state.threatLevelPrivilege.SecurityLevel ===
                    SECURITY_LEVEL_CODES.FULL_CONTROL &&
                    this.props.threats.length !== 0 && (
                        <div style={styles.ThreatLevelContainer}>
                            <div style={styles.threatLevelAllAreas}>All Threat Levels</div>
                            <div style={styles.threatLevelStatusButton}>
                                <Link style={styles.underlineless} to="/threatlevel">
                                    <ButtonView
                                        label="Activate"
                                        enabled={this.props.threats.some(
                                            threat => threat.activated === false
                                        )}
                                        instyle={
                                            this.props.threats.some(
                                                threat => threat.activated === false
                                            )
                                                ? styles.activate
                                                : styles.disabled
                                        }
                                        onPress={() => {
                                            const isConfirmed = window.confirm(
                                                "Confirm Activation for all threat levels?"
                                            );
                                            if (isConfirmed === true) {
                                                this.props.sendActivateAllThreatLevels();
                                                alert(
                                                    "Activation command is sent for all threat levels."
                                                );
                                            }
                                        }}
                                    />
                                </Link>
                                <Link style={styles.underlineless} to="/threatlevel">
                                    <ButtonView
                                        label="Deactivate"
                                        enabled={this.props.threats.some(
                                            threat => threat.activated === true
                                        )}
                                        instyle={
                                            this.props.threats.some(
                                                threat => threat.activated === true
                                            )
                                                ? styles.deactivate
                                                : styles.disabled
                                        }
                                        onPress={() => {
                                            const isConfirmed = window.confirm(
                                                "Confirm Deactivation for all threat levels?"
                                            );
                                            if (isConfirmed === true) {
                                                this.props.sendDeactivateAllThreatLevels();
                                                alert(
                                                    "Deactivation command is sent for all threat levels."
                                                );
                                            }
                                        }}
                                    />
                                </Link>
                            </div>
                        </div>
                    )}

                <div style={styles.listContainer}>
                    {this.props.roleId === ADMIN_ROLE_ID &&
                        this.showThreatList(this.props.threats)}
                    {this.props.roleId !== ADMIN_ROLE_ID &&
                        this.state.threatLevelPrivilege &&
                        this.state.threatLevelPrivilege.SecurityLevel ===
                        SECURITY_LEVEL_CODES.FULL_CONTROL &&
                        this.showThreatList(this.props.threats)}
                </div>
            </div>
        );
    }
}

const styles = {
    underlineless: {
        textDecoration: "none"
    },
    emptyList: {
        display: "flex",
        flex: 1,
        fontSize: 17,
        fontWeight: "600",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Colors.White,
        height: 100
    },
    disabled: {
        backgroundColor: Colors.Gray,
        color: Colors.White,
        borderWidth: "0px",
        borderStyle: "solid",
        borderColor: Colors.LightGray,
        padding: 5,
        fontSize: 13,
        marginRight: 5,
        borderRadius: 8,
        fontWeight: 600,
        width: 100,
        justifyContent: "center",
        height: 32,
        alignItems: "center"
    },
    activate: {
        backgroundColor: Colors.Red,
        color: Colors.White,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "black",
        padding: 5,
        fontSize: 13,
        marginRight: 5,
        borderRadius: 8,
        fontWeight: 600,
        width: 100,
        justifyContent: "center",
        height: 32,
        alignItems: "center",
        cursor: "pointer",
        boxShadow: "0.5px 0.5px 1px #000000",
        ":active": {
            backgroundColor: Colors.Gray,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "black"
        }
    },
    deactivate: {
        backgroundColor: Colors.DeepGreen,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "black",
        color: Colors.White,
        padding: 5,
        justifyContent: "center",
        height: 32,
        borderRadius: 8,
        fontWeight: 600,
        width: 100,
        fontSize: 13,
        alignItems: "center",
        cursor: "pointer",
        boxShadow: "0.5px 0.5px 1px #000000",
        ":active": {
            backgroundColor: Colors.Gray,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "black"
        }
    },
    container: {
        display: "flex",
        flex: 1,
        height: "auto",
        justifyContent: "center",
        marginRight: 20,
        flexDirection: "column",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: 10,
        borderColor: Colors.Green,
        backgroundColor: Colors.White
    },
    expandContainer: {
        flex: 1,
        height: 30,
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        paddingLeft: 10,
        backgroundColor: Colors.Green,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10
    },
    threatLevelAllAreas: {
        textAlign: "center",
        padding: 2,
        fontSize: 16,
        fontWeight: "700"
    },
    ThreatLevelContainer: {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "black",
        marginTop: 10,
        marginLeft: 20,
        marginRight: 20,
        height: "auto",
        borderRadius: 6,
        padding: 5,
        backgroundColor: Colors.LightGray
    },
    threatLevelStatusButton: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },

    listContainer: { marginLeft: 10, marginRight: 10, marginBottom: 20 },

    functionText: {
        color: Colors.White,
        textAlign: "left",
        marginLeft: 20,
        fontSize: 20,
        fontWeight: 800
    },
    horizontalLine: {
        height: 1,
        width: "100%",
        borderBottom: "1px solid",
        borderColor: Colors.Purple
    }
};

const mapStateToProps = state => ({
    accessToken: state.token.accessToken,
    threats: state.threat.threats,
    privileges: state.token.privileges,
    operatorId: state.token.operatorId,
    roleId: state.token.roleId,
    operatorId: state.token.operatorId
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchAllLockDownArea: actions.fetchAllLockDownArea,
            fetchAllThreatLevels: actions.fetchAllThreatLevels,
            sendActivateAllThreatLevels: actions.sendActivateAllThreatLevels,
            sendDeactivateAllThreatLevels: actions.sendDeactivateAllThreatLevels,
            sendActivateTheThreatLevel: actions.sendActivateTheThreatLevel,
            //sendDeactivateTheThreatLevel: actions.sendDeActivateTheThreatLevel,
            setCurrentPageName: actions.setCurrentPageName,
            writeLog: actions.writeLog
        },
        dispatch
    );

export default Radium(
    connect(mapStateToProps, mapDispatchToProps)(ThreatLevelView)
);

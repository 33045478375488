import * as ActionTypes from '../actions/types';

const defaultState = {
  badgeholdersin: [],
  badgeListOfThePersonnel: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_BADGEHOLDERIN_SUCCEEDED:
      return {
        ...state,
        badgeholdersin: action.payload
      };

    case ActionTypes.FETCH_BADGES_BY_PERSON_NAME_SUCCEEDED:
      return {
        ...state,
        badgeListOfThePersonnel: action.payload
      };

    case ActionTypes.LOGOUT: {
           return defaultState;
    }

    default:
      return state;
  }
};

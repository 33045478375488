export const DEVICE_TYPES = {
  DEVICE_TYPE_NONE: '0',
  DEVICE_TYPE_INPUT: '1',
  DEVICE_TYPE_RELAY: '2',
  DEVICE_TYPE_LINK: '3',
  DEVICE_TYPE_PANEL: '4',
  DEVICE_TYPE_DOOR: '5',
  DEVICE_TYPE_COM: '6',
  DEVICE_TYPE_MAP: '7',
  DEVICE_TYPE_CAT_COUNTER: '8',
  DEVICE_TYPE_SYSTEM: '9',
  DEVICE_TYPE_ALP: '10',
  DEVICE_TYPE_LOCKDOWN: '13',
  DEVICE_TYPE_THREATLEVEL: '15',
  DEVICE_TYPE_NAPCO_PANEL: '30',
  DEVICE_TYPE_NAPCO_AREA: '31',
  DEVICE_TYPE_NAPCO_ZONE: '32',
  DEVICE_TYPE_NAPCO_RELAY: '33',
  DEVICE_TYPE_WL_SERVER: '45',
  DEVICE_TYPE_LOCK: '47',
  DEVICE_TYPE_FACILITY_MAP: '50',
  DEVICE_TYPE_CAMERA: '51'
};

export const DEVICE_TYPES_NAME = {
  DEVICE_TYPE_NONE: 'All',
  DEVICE_TYPE_INPUT: 'Input',
  DEVICE_TYPE_RELAY: 'Relay',
  DEVICE_TYPE_LINK: 'Link',
  DEVICE_TYPE_PANEL: 'Panel',
  DEVICE_TYPE_DOOR: 'Door',
  DEVICE_TYPE_COM: 'Com',
  DEVICE_TYPE_MAP: 'Map',
  DEVICE_TYPE_CAT_COUNTER: 'Cat Counter',
  DEVICE_TYPE_SYSTEM: 'System',
  DEVICE_TYPE_ALP: 'ALP',
  DEVICE_TYPE_LOCKDOWN: 'Lockdown',
  DEVICE_TYPE_THREATLEVEL: 'Threat Level',
  DEVICE_TYPE_NAPCO_PANEL: 'Napco Panel',
  DEVICE_TYPE_NAPCO_AREA: 'Napco Area',
  DEVICE_TYPE_NAPCO_ZONE: 'Napco Zone',
  DEVICE_TYPE_NAPCO_RELAY: 'Napco Relay',
  DEVICE_TYPE_WL_SERVER: 'WL Server',
  DEVICE_TYPE_LOCK: 'Lock',
  DEVICE_TYPE_FACILITY_MAP: 'Facility Map',
  DEVICE_TYPE_CAMERA: 'Camera'
};

export const PAGE_NAMES = {
  CONFIG: 'Config',
  LOGIN: 'LogIn',
  HOME: 'Home',
  DOORS: 'Doors',
  LOCKDOWN: 'Lockdown',
  THREATLEVEL: 'ThreatLevel',
  PERSONNEL: 'Personnel',
  ADD_PERSONNEL: 'Add Personnel',
  EDIT_PERSONNEL: 'Edit Personnel',
  STATUS: 'Status',
  CONTROL: 'Control',
  BADGEHOLDERIN: 'BadgeHolderIn',
  SCHEDULEDCHANGES: 'Scheduled Changes',
  ADDSCHEDULECHANGES: 'Add New Schedule Changes',
  EDIT_SCHEDULECHANGES: 'Edit Schedule Changes',
  BASE_URL_CONFIGURATION: 'Base URl Configuration'
};

export const SERVER_MESSAGES = {
  PANEL_STATUS: 'CodedMessagePanelStatus',
  READER_STATUS: 'CodedMessageReaderStatus',
  RELAY_STATUS: 'CodedMessageRelayStatus',
  INPUT_STATUS: 'CodedMessageInputStatus',
  LOCKDOWN_CHANGED: 'CodedMessageLockdownChange',
  THREATLEVEL_CHANGED: 'CodedMessageThreatLevelChange',
  SCHEDULE_CHANGED: 'CodeMsgScheduleChanged',
  OTHERSTATUS_CHANGED: 'OtherStatusChanged'
};

export const SCREEN_CODES = {
  MANUAL_DOORS: 34,
  MANUAL_RELAY: 35,
  LOCKDOWN: 75,
  THREATLEVEL: 76,
  STATUS: 6,
  PERSONNEL: 15,
  BADGEHOLDERIN: 26,
  SCHEDULECHANGES: 23
};

export const SCREEN_DEVICE_TYPES = {
    PERSONNEL: 9,
    SCHEDULECHANGES: 37
};

export const SECURITY_LEVEL_CODES = {
  FULL_CONTROL: 3,
  VIEW_ONLY: 4,
  CREATE_ONLY: 5,
  EDIT_ONLY: 6,
  CREATE_EDIT_ONLY: 7,
  DISABLE: 8
};

export const SORTING_ORDER = {
  DEFAULT: 0,
  ASCENDING: 1,
  DESCENDING: 2
};

export const BADGE_VALID_EVENT = {
  aCat: 0,
  aClass: 'Badge Valid',
  aDescription: '',
  aName: '',
  aPending: false,
  aPriority: 40,
  aResReq: false,
  PanelNo: '',
  DeviceNo: '',
  Badge: '',
  Facility: '',
  Status: 1
};

export const BADGE_VIOLET_VOID = {
  aCat: 0,
  aClass: 'Badge Violet Void',
  aDescription: '',
  aName: '',
  aPending: true,
  aPriority: 40,
  aResReq: false,
  PanelNo: '',
  DeviceNo: '',
  Badge: '',
  Facility: '',
  Status: 16
};

export const  KEYPAD_OPTIONS = {
    KEYPADS_NOT_USED:0,
    FOUR_DIGIT_PIN :1,
    FOUR_DIGIT_PIN: 2
};

export const VALID_ACCESS_TYPES = [1, 17, 3, 19, 5, 21, 2, 18, 4, 20, 8, 144, 128, 160, 6, 242]; 

export const MSG_SYSTEM_ONLINE = 'System Online';
export const MSG_SYSTEM_OFFLINE = 'Status Request Failed';
export const MSG_MBL_STOPPED = 'MBL Stopped';
export const MAX_FACILITY_CODE = 10;
export const MBL_STOPPED_ERROR_MESSAGE = 'Cannot connect to CardAccess Main Business Layer or Database';

export const ADMIN_OPERATOR_ID = '1eeb696c-0e28-4702-8598-c379c95b45e1';
export const OPERATOR_ID = '1eeb696c-0e28-4702-8598-c379c95b45e1';
export const ADMIN_ROLE_ID = '82B19834-4EF0-405B-9AD1-23860AAF4139';

import { END, eventChannel } from "redux-saga";
import { call, take, takeEvery, select, put } from "redux-saga/effects";
import * as ActionTypes from "../actions/types";
import * as workerTimers from 'worker-timers';

let inactivityTimeoutChannel = null;

export default function* inactivitySaga() {
  yield takeEvery(ActionTypes.RESET_INACTIVITY_TIMER, resetInactivityTimer);
  yield takeEvery(ActionTypes.LOGOUT, logout);
  yield call(resetInactivityTimer);
}

function* resetInactivityTimer() {
  //yield console.log('resetInactivityTimer #####');

  const loginStatus = yield select(getLoginStatus);

  //console.log(`####${loginStatus}`);
  if (loginStatus) {
    if (inactivityTimeoutChannel) {
      inactivityTimeoutChannel.close();
    }

    return;
  }

  if (inactivityTimeoutChannel) {
    inactivityTimeoutChannel.close();
  }

  let inactivityTime = yield select(getInactivityTime);

  inactivityTime *= 60;

  inactivityTimeoutChannel = yield call(
    createInactivityTimeoutChannel,
    inactivityTime
  );
  try {
    while (true) {
      const data = yield take(inactivityTimeoutChannel);
      if (data) {
        if (data < inactivityTime) {
          const remaining = inactivityTime - data;
         // console.log('remaining sec', remaining);
          yield put({
            type: ActionTypes.SET_REMAINING_SESSION_TIME,
            remaining
            });

            yield put({
                type: ActionTypes.STORE_SESSION_TIMEOUT_INFO
            });
        } else if (data >= inactivityTime) {
          yield put({ type: ActionTypes.LOGOUT });
        }
      }
    }
  } finally {
    //yield console.log('inactivity timeout channel end');
  }
}

function* logout() {
  //console.log('EEEEE');
  if (inactivityTimeoutChannel) {
    yield inactivityTimeoutChannel.close();
  }
}

//function createInactivityTimeoutChannel(time) {
//  let tick = 0;
//  return eventChannel(emitter => {
//    const timer = setInterval(() => {
//      emitter(tick);
//      if (tick >= time) {
//        emitter(END);
//      }
//      tick++;
//    }, 1000);

//    return () => {
//      clearInterval(timer);
//    };
//  });
//}

function createInactivityTimeoutChannel(time) {
  let tick = 0;
  return eventChannel(emitter => {
      const timer = workerTimers.setInterval(() => {
      emitter(tick);
      if (tick >= time) {
        emitter(END);
      }
      tick++;
    }, 1000);

    return () => {
      workerTimers.clearInterval(timer);
    };
  });
}

const getInactivityTime = state => state.settings.tmpSessionTimeOut;

const getLoginStatus = state => state.loading.logout;

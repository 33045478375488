import React, { Component, useState } from 'react';
import Radium from 'radium';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Colors from '../../consts/colors';
import * as actions from '../../actions/index';
import ButtonView from '../common/ButtonView';
import * as AlertMessages from '../../consts/alert-messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

class ChangePasswordView extends Component{
      constructor(props) {
            super(props);
          this.state = {
                currentPassword: props.currentPassword,
                newPassword: '',
                confirmPassword: '',
                showCurrentPassword: false,
                showNewPassword: false,
                showConfirmPassword: false
          };

          this.handleChangeCurrentPassword = this.handleChangeCurrentPassword.bind(this);
          this.handleChangeNewPassword = this.handleChangeNewPassword.bind(this);
          this.handleChangeConfirmPassword = this.handleChangeConfirmPassword.bind(this);
          this.handleShowPassword = this.handleShowPassword.bind(this);
          this.handleSubmit = this.handleSubmit.bind(this);
          

    }


    handleChangeCurrentPassword = (event) => {
        this.setState({ currentPassword: event.target.value });
    }

    handleChangeNewPassword = (event) => {
        this.setState({ newPassword: event.target.value });
    }

    handleChangeConfirmPassword = (event) => {
        this.setState({ confirmPassword: event.target.value });
    }

    handleShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    }

     handleSubmit = (event) => {

      if (this.state.currentPassword.length === 0 && this.state.newPassword.length === 0 && this.state.confirmPassword.length === 0) {
          alert(AlertMessages.ALERT_PASSWARD_COMPLEXITY_REQUIREWMENT);
          event.preventDefault();
          return;
        }

        if (this.state.confirmPassword.length === 0) {
           alert(AlertMessages.ALERT_ENTER_VALID_PASSWORD);
           event.preventDefault();
           return;
        }

      if (this.state.newPassword !== this.state.confirmPassword) {
          alert(AlertMessages.ALERT_PASSWORD_DOES_NOT_MATCH);
          event.preventDefault();
          return;
         }

       if (this.state.newPassword === this.state.currentPassword || this.state.confirmPassword === this.state.currentPassword) {
             alert(AlertMessages.ALERT_NEW_PASSWORD_CAN_NOT_BE_SAME_OLD_PASSWORD);
             event.preventDefault();
             return;
         }

         this.props.writeLog('ChangePasswordView: --> changeOperatorPassword()');
         this.props.changeOperatorPassword(this.props.userName, this.state.currentPassword, this.state.newPassword, this.state.confirmPassword);

    }


    render() {

        if (this.props.passwordChanged === 1) // 0 -Default , 1- Password Changed, 2- Failed to change
        {
            alert(AlertMessages.ALERT_PASSWORD_CHANGED_SUCCESSFULLY);
            this.props.resetChangeOperatorPasswordStatus();
            this.props.resetPasswordRequiresUpdate();
            return <Redirect path="/login" />;
        } else if (this.props.passwordChanged === 2) {

            alert(this.props.errorMessage);
            this.props.resetChangeOperatorPasswordStatus();
            this.props.resetErrorMessage();
        }

        return (<div style={styles.container}>
       
            <div >
                <div style={styles.currentPasswordWrapper} >
                    <input
                        disabled
                    type={this.state.showCurrentPassword == true ? "text" : "password"}
                    tabIndex={0}
                    placeholder="Current Password"
                    onChange={this.handleChangeCurrentPassword}
                    name="txtCurrentPassword"
                    value={this.state.currentPassword}
                    style={styles.currentPassword}
                    />
                    <FontAwesomeIcon style={{ marginLeft: 8 }} onClick={() => { this.setState({ showCurrentPassword: !this.state.showCurrentPassword }); }} icon={this.state.currentPassword && ( this.state.showCurrentPassword === true ? faEyeSlash : faEye)} />
                </div>
            </div>
        
       
            <br />
            <div style={styles.newPasswordWrapper} >
                <input
                    type={this.state.showNewPassword == true ? "text" : "password"}
                    tabIndex={0}
                    placeholder="New Password"
                    onChange={this.handleChangeNewPassword}
                    name="txtNewPassword"
                    value={this.state.newPassword}
                    style={styles.newPassword}
                />
                <FontAwesomeIcon style={{ marginLeft: 8 }} onClick={() => { this.setState({ showNewPassword: !this.state.showNewPassword }); }} icon={this.state.newPassword && (this.state.showNewPassword === true ? faEyeSlash : faEye)} />
            </div>

            <br />
            <div style={styles.confirmPasswordWrapper} >
                <input
                    type={this.state.showConfirmPassword == true ? "text" : "password"}
                    tabIndex={0}
                    placeholder="Confirm Password"
                    onChange={this.handleChangeConfirmPassword}
                    name="txtConfirmPassword"
                    value={this.state.confirmPassword}
                    style={styles.newPassword}
                    
                />
                <FontAwesomeIcon style={{ marginLeft: 8 }} onClick={() => { this.setState({ showConfirmPassword: !this.state.showConfirmPassword }); }} icon={this.state.confirmPassword  && (this.state.showConfirmPassword === true ? faEyeSlash : faEye)} />

            </div>
            <div style={styles.boxContainer}>
            <ButtonView
                label="Change Password"
                instyle={styles.submitButton}
                onPress={this.handleSubmit}
                />
                <br/>
                <Link style={styles.underlineless} to="/login">
                    <ButtonView
                        label="Cancel"
                        instyle={styles.cancelButton}
                        onPress={() => {
                            this.props.resetPasswordRequiresUpdate();
                            this.props.logOut();
                        }}
                    />
                </Link>

            </div>
        </div>);

    }

}


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'auto',
        justifyContent: 'center'
     
    },
    boxContainer: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        width: 290,
        alignItems: 'center',
        height: 'auto',
        justifyContent: 'center'
    },
    currentPassword: {
        padding: 8,
        width: 190,
        borderWidth: '0px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray
    },
    newPassword: {
        marginTop: 4,
        padding: 8,
        width: 190,
        borderWidth: '0px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray
    },

    newPasswordWrapper: {
        marginTop: 4,
        padding: 4,
        width: 235,
        display: 'flex',
        alignItems: 'center',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray,
        background: Colors.White
    }
    ,
    currentPasswordWrapper: {
        marginTop: 4,
        padding: 4,
        width: 235,
        display: 'flex',
        alignItems: 'center',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray,
        background: Colors.White
    },

    confirmPasswordWrapper: {
        marginTop: 4,
        padding: 4,
        width: 235,
        display: 'flex',
        alignItems: 'center',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray,
        background: Colors.White
    }
    ,
    confirmPassword: {
        marginTop: 4,
        padding: 8,
        width: 190,
        borderWidth: '0px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray
    }, showPassword: {
    
    },
    submitButton: {
       marginLRight: 16,
        marginTop: 8,
        backgroundColor: Colors.Purple,
        borderColor: Colors.Black,
        borderStyle: 'solid',
        borderWidth: 0.5,
        color: Colors.Green,
        width: 250,
        height: 40,
        padding: 3,
        fontSize: 16,
        fontWeight: 700,
        cursor: 'pointer',
        boxShadow: '1px 1px 2px #000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: 0.5,
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },

    cancelButton: {
        marginLRight: 16,
        marginTop: 12,
        backgroundColor: Colors.Purple,
        borderColor: Colors.Black,
        borderStyle: 'solid',
        borderWidth: 0.5,
        color: Colors.Green,
        width: 250,
        height: 40,
        padding: 3,
        fontSize: 16,
        fontWeight: 700,
        cursor: 'pointer',
        marginTop: 2,
        boxShadow: '1px 1px 2px #000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: 0.5,
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    underlineless: {
        textDecoration: 'none'
    },

};


const mapStateToProps = state => ({
    baseURL: state.appConfig.baseURL,
    loading: state.loading.login,
    passwordChanged: state.token.passwordChanged,
    userName: state.user.username,
    currentPassword: state.user.password,
    errorMessage: state.error.msg
});


const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setBaseURL: actions.setBaseURL,
            closeLoaderModal: actions.closeLoaderModal,
            changeOperatorPassword: actions.changeOperatorPassword,
            resetChangeOperatorPasswordStatus: actions.resetChangeOperatorPasswordStatus,
            resetPasswordRequiresUpdate: actions.resetPasswordRequiresUpdate,
            logOut: actions.logOut,
            resetErrorMessage: actions.resetErrorMessage,
            writeLog: actions.writeLog

        },
        dispatch
    );


export default Radium(connect(mapStateToProps, mapDispatchToProps) (ChangePasswordView));

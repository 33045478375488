import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';

import * as Colors from '../../consts/colors';
import * as actions from '../../actions/index';
import {
    PAGE_NAMES,
    ADMIN_ROLE_ID,
    SCREEN_CODES,
    SECURITY_LEVEL_CODES
} from '../../consts/consts';
import SearchView from './SearchView';
import DoorItemView from './DoorItemView';
import ManualControlItemView from './ManualControlItemView';
import { retry } from 'redux-saga/effects';

const pageSize = 20;
const maximumAllowedRow = 500;
const searchInsteadMessage = 'Could not load more data, search instead.';

class ManualControlView extends Component {
    //static getDerivedStateFromProps(props, state) {
    //    if (state.showDoors) {
    //        console.log('show doors');
    //    }

    //    if (state.showRelays) {
    //        console.log('show relays');
    //    }
    //}

    constructor(props) {
        super(props);

        this.state = {
            hasMoreItems: true,
            filterTextDoors: '',
            resetNeeded: false
        };

        this.pageIndex = 0;
    }

    UNSAFE_componentWillMount() {
        let doorPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.MANUAL_DOORS
        );

        let relayPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.MANUAL_RELAY
        );

        if (typeof doorPrivilege === 'undefined') {
            doorPrivilege = null;
        }

        if (typeof relayPrivilege === 'undefined') {
            relayPrivilege = null;
        }

        this.props.writeLog('OperatorID: ' + this.props.operatorId + "--> Manual Control --> DoorPriviledge" + JSON.stringify(doorPrivilege)+", RelayPriviledge: "+  relayPrivilege);

        this.props.setCurrentPageName(PAGE_NAMES.CONTROL);
        this.props.fetchAllRelays(false);
        this.props.fetchAllDoors(false);

        this.setState({
            showDoors: false,
            showRelays: false,
            filterTextDoors: '',
            filterTextRelays: '',
            doorPrivilege,
            relayPrivilege
        });
    }

    componentDidMount() {
        this.props.fetchDoorsCount(false, this.state.filterTextDoors);
        this.props.writeLog('OperatorID: ' + this.props.operatorId + "--> Manual Control --> Door Count: " + (this.props.doorsCount!=null && this.props.doorsCount) + ", Relay count: " + (this.props.relays !=null && this.props.relays.length));

    }

    componentDidUpdate() {
        if (this.state.resetNeeded === true) {
            this.setState({
                hasMoreItems: true,
                resetNeeded: false
            });
            this.pageIndex = 0;
            this.props.resetDoors();
            this.props.fetchDoorsCount(false, this.state.filterTextDoors);
        }
    }

    componentWillUnmount() {
        this.props.resetDoors();
    }

    setSearchCriteriaDoors(text) {
        this.setState({
            filterTextDoors: text,
            resetNeeded: true
        });
    }

    setSearchCriteriaRelays(text) {
        this.setState({ filterTextRelays: text });
    }

    toggleDoors() {
        this.setState({ showDoors: !this.state.showDoors, filterTextDoors: '' });
    }

    toggleRelays() {
        this.setState({ showRelays: !this.state.showRelays });
    }

    loadMoreDoors() {
        if (!this.props.doorsCount) {
            if (this.props.finishFetchDoorsCount === true) {
                this.setState({
                    hasMoreItems: false
                });
            }

            return;
        }

        if (this.props.pagedDoors.length >= maximumAllowedRow) {
            alert(searchInsteadMessage);
            this.setState({
                hasMoreItems: false
            });
        } else if (this.props.pagedDoors.length >= this.props.doorsCount) {
            this.setState({ hasMoreItems: false });
        } else {
            this.props.fetchPagedDoors(
                false,
                this.pageIndex,
                this.state.filterTextDoors
            );
            const totalPage = Math.ceil(this.props.doorsCount / pageSize);
            if (this.pageIndex + 1 < totalPage) {
                console.log(this.pageIndex);
                this.pageIndex++;
            }
        }
    }

    showDoorList() {
        if (
            this.props.loading === false &&
            this.props.doorsCount === 0 &&
            this.props.finishFetchDoorsCount === true
        ) {
            console.log(this.props.doorsCount, this.props.finishFetchDoorsCount);
            return <div style={styles.emptyList}>No Doors Found</div>;
        }



        return this.props.pagedDoors.map((door, index) => {

            // Todo: Zaman Change this code after signalR issue change
            let doorStatus = door.locked;
            let statusDescription = door.statusDef;

            const element = this.props.allDoors.find(e => e.doorNo === door.doorNo && e.panelNo === door.panelNo);

            if (element) {
                doorStatus = element.locked;
                statusDescription = element.statusDef;
            }


            return (
                <DoorItemView
                    key={index + 1}
                    currentIndex={index}
                    doorName={door.doorName}
                    doorStatus={doorStatus}
                    statusDescription={statusDescription}
                    panelNo={door.panelNo}
                    doorNo={door.doorNo}
                />
            );
        });
    }

    showRelayList(relays) {
        const relayList = this.makeFilter(relays, 'Relays').map((relay, index) => (
            <ManualControlItemView
                key={index + 1}
                currentIndex={index}
                name={relay.relayName}
                isActivated={relay.activated}
                relayNo={relay.relayNo}
                panelNo={relay.panelNo}
            />
        ));
        return <div>{relayList}</div>;
    }

    makeFilter(data, listType) {
        try {
            if (
                this.state.filterTextDoors &&
                this.state.filterTextDoors.length > 0 &&
                listType === 'Doors'
            ) {
                return this.filter(
                    data,
                    'doorName',
                    this.state.filterTextDoors.toLowerCase()
                );
            } else if (
                this.state.filterTextRelays &&
                this.state.filterTextRelays.length > 0 &&
                listType === 'Relays'
            ) {
                return this.filter(
                    data,
                    'relayName',
                    this.state.filterTextRelays.toLowerCase()
                );
            }
        } catch (exception) {
            return data;
        }

        return data;
    }

    filter(list, searchOption, searchText) {
        if (searchOption === 'doorName') {
            return list.filter(item =>
                item.doorName.toLowerCase().includes(searchText)
            );
        } else if (searchOption === 'relayName') {
            return list.filter(person =>
                person.relayName.toLowerCase().includes(searchText)
            );
        }

        return list;
    }

    render() {
        return (

            <div style={styles.container}>
                <div>
                    <div style={styles.expandContainer}>
                        <Link style={styles.underlineless} to="/home">
                            <div>
                                <FontAwesomeIcon
                                    icon={faMinus}
                                    size="1x"
                                    color={Colors.White}
                                />
                            </div>
                        </Link>
                        <div style={styles.functionText}>Manual Control</div>
                    </div>
                </div>

                {this.props.roleId === ADMIN_ROLE_ID && (
                    <div>
                        <div
                            style={styles.subFunctionText}
                            onClick={() => {
                                //try {
                                this.toggleDoors();
                                // } catch (error) {
                                //   this.setState(() => {
                                //     throw error;
                                //   });
                                // }
                            }}
                        >
                            {this.state.showDoors === true
                                ? '\u002D Doors'
                                : '\u002B Doors'}
                        </div>
                        {this.state.showDoors === true && (
                            <div style={styles.subFunctionBorder}>
                                {this.props.doorsCount.length !== 0 && (
                                    <SearchView
                                        placeholder={'Search doors'}
                                        onChangeText={text => this.setSearchCriteriaDoors(text)}
                                    />
                                )}

                                <div style={{ ...styles.listContainer, overflow: 'auto' }}>
                                    <InfiniteScroll
                                        loadMore={this.loadMoreDoors.bind(this)}
                                        hasMore={this.state.hasMoreItems}
                                        loader={<p key="-5" className="loading" />}
                                        useWindow={false}
                                    >
                                        {this.showDoorList()}
                                    </InfiniteScroll>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {this.props.roleId === ADMIN_ROLE_ID && (
                    <div>
                        <div
                            style={styles.subFunctionText}
                            onClick={() => {
                                //   try {
                                this.toggleRelays();
                                // } catch (error) {
                                //   this.setState(() => {
                                //     throw error;
                                //   });
                                // }
                            }}
                        >
                            {this.state.showRelays === true
                                ? '\u002D Relays'
                                : '\u002B Relays'}
                        </div>

                        {this.state.showRelays === true && (
                            <div style={styles.subFunctionBorder}>
                               
                                {this.props.relays.length !== 0 && (
                                    <SearchView
                                        placeholder={'Search relays'}
                                        onChangeText={text => this.setSearchCriteriaRelays(text)}
                                    />
                                )}

                                {
                                    (this.props.relays.length === 0 || this.makeFilter(this.props.relays, 'Relays').length === 0) && (
                                        <div style={styles.emptyList}>No Relays Found</div>
                                    )}
                                <div style={{ ...styles.listContainer, overflow: 'auto' }}>
                                    {this.showRelayList(this.props.relays)}
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {/* different privileges here*/}
                {this.props.roleId !== ADMIN_ROLE_ID &&
                    this.state.doorPrivilege &&
                    this.state.doorPrivilege.SecurityLevel ===
                    SECURITY_LEVEL_CODES.FULL_CONTROL && (
                        <div>
                            <div
                                style={styles.subFunctionText}
                                onClick={() => {
                                    //  try {
                                    this.toggleDoors();
                                    // } catch (error) {
                                    //   this.setState(() => {
                                    //     throw error;
                                    //   });
                                    // }
                                }}
                            >
                                {this.state.showDoors === true
                                    ? '\u002D Doors'
                                    : '\u002B Doors'}
                            </div>
                            {this.state.showDoors === true && (
                                <div style={styles.subFunctionBorder}>
                                    {this.props.doorsCount.length !== 0 && (
                                        <SearchView
                                            placeholder={'Search doors'}
                                            onChangeText={text => this.setSearchCriteriaDoors(text)}
                                        />
                                    )}

                                    <div style={{ ...styles.listContainer, overflow: 'auto' }}>
                                        <InfiniteScroll
                                            loadMore={this.loadMoreDoors.bind(this)}
                                            hasMore={this.state.hasMoreItems}
                                            loader={<p key="-5" className="loading" />}
                                            useWindow={false}
                                        >
                                            {this.showDoorList()}
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                {this.props.roleId !== ADMIN_ROLE_ID &&
                    this.state.relayPrivilege &&
                    this.state.relayPrivilege.SecurityLevel ===
                    SECURITY_LEVEL_CODES.FULL_CONTROL && (
                        <div>
                            <div
                                style={styles.subFunctionText}
                                onClick={() => {
                                    //  try {
                                    this.toggleRelays();
                                    // } catch (error) {
                                    //   this.setState(() => {
                                    //     throw error;
                                    //   });
                                    // }
                                }}
                            >
                                {this.state.showRelays === true
                                    ? '\u002D Relays'
                                    : '\u002B Relays'}
                            </div>

                            {this.state.showRelays === true && (
                                <div style={styles.subFunctionBorder}>
                               
                                    {this.props.relays && this.props.relays.length !== 0 && (
                                        <SearchView
                                            placeholder={'Search relays'}
                                            onChangeText={text =>
                                                this.setSearchCriteriaRelays(text)
                                            }
                                        />
                                )}

                                {(this.props.relays && this.props.relays.length === 0 || this.makeFilter(this.props.relays, 'Relays').length === 0) && (
                                    <div style={styles.emptyList}>No Relays Found</div>
                                )}

                                <div style={{ ...styles.listContainer, overflow: 'auto' }}>
                                    {this.showRelayList(this.props.relays)}
                                </div>

                                </div>
                            )}
                        </div>
                    )}
            </div>

        );
    }
}

const styles = {
    underlineless: {
        textDecoration: 'none'
    },
    emptyList: {
        display: 'flex',
        flex: 1,
        fontSize: 17,
        fontWeight: '600',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.White,
        height: 100
    },
    container: {
        display: 'flex',
        flex: 1,
        height: 'auto',
        marginRight: 20,
        flexDirection: 'column',
        borderWidth: '1px',
        borderStyle: 'solid',
        paddingBottom: 16,
        borderRadius: 10,
        borderColor: Colors.Green,
        backgroundColor: Colors.White
    },
    expandContainer: {
        flex: 1,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        paddingLeft: 10,
        backgroundColor: Colors.Green,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10
    },

    functionText: {
        color: Colors.White,
        textAlign: 'left',
        marginLeft: 20,
        fontSize: 18,
        fontWeight: 800
    },

    subFunctionText: {
        color: Colors.Black,
        textAlign: 'left',
        marginLeft: 8,
        paddingTop: 22,
        fontSize: 20,
        fontWeight: 700
    },

    subFunctionBorder: {
        color: Colors.Black,
        textAlign: 'left',
        borderColor: Colors.Green,
        borderWidth: '1px',
        borderStyle: 'solid',
        marginTop: 8,
        marginBottom: 8,
        marginLeft: 8,
        marginRight: 8,
        padding: 4,
        borderRadius: 4,
        visibility: 'visible'
    },

    horizontalLine: {
        height: 1,
        width: '100%',
        borderBottom: '1px solid',
        borderColor: Colors.Purple
    },
    listContainer: {
        maxHeight: 510,
        //border: '1px solid black',
        overflowY: 'scroll',
        fontWeight: 'bold',
        //marginLeft: 8,
        fontSize: 14,
        //marginRight: 8,
        marginBottom: 10,
        marginTop: 5,
        backgroundColor: Colors.White,
        //paddingBottom: 20,
        //paddingTop: 10,
        borderRadius: 0
    },
    itemStyle: {
        display: 'flex',
        flex: 1,
        height: 35,
        margin: 1,
        fontSize: '0.9em',
        paddingLeft: 20,
        paddingRight: 20,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.White
    },
    selectedItemStyle: {
        display: 'flex',
        flex: 1,
        margin: 1,
        height: 35,
        fontSize: '0.91em',
        paddingLeft: 10,
        paddingRight: 10,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.Cream
    }
};

const mapStateToProps = state => ({
    accessToken: state.token.accessToken,
    tmpViewSelection: state.settings.tmpViewSelection,
    allDoors: state.door.allDoors,
    relays: state.relay.relays,
    privileges: state.token.privileges,
    operatorId: state.token.operatorId,
    roleId: state.token.roleId,
    doorsCount: state.door.doorsCount,
    pagedDoors: state.door.pagedDoors,
    loading: state.loading.loading,
    finishFetchDoorsCount: state.door.finishFetchDoorsCount,
    operatorId: state.token.operatorId
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchAllRelays: actions.fetchAllRelays,
            fetchAllDoors: actions.fetchAllDoors,
            setCurrentPageName: actions.setCurrentPageName,
            fetchPagedDoors: actions.fetchPagedDoors,
            fetchDoorsCount: actions.fetchDoorsCount,
            resetDoors: actions.resetDoors,
            writeLog: actions.writeLog
        },
        dispatch
    );

export default Radium(
    connect(mapStateToProps, mapDispatchToProps)(ManualControlView)
);

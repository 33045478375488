import React from 'react';
import Radium from 'radium';
import * as Colors from '../../consts/colors';

const PersonnelListItemView = props => {
    let rowStyle = styles.container;

    if (props.isSelected === true) {
        rowStyle = props.instyle;
    } else if (props.currentIndex % 2 === 0) {
        rowStyle = styles.alterBackgroundContainer;
    }

    return (
        <div
            className='tooltip'
            style={rowStyle}
            onClick={() => {
                props.onPress(props.currentIndex);
            }}
        >
            <div style={styles.lastName}>{props.lastName}</div>
            <div style={styles.firstName}>{props.firstName}</div>
            <div style={styles.facility}>{props.facility}</div>
            <div style={styles.totalBadges}>{getFormattedBadgeNo(props.defaultBadgeNo)}</div>
            <span className="tooltiptext">{props.defaultBadgeNo}</span>
        </div>
    );
};

const  getFormattedBadgeNo = (badgeNo) => {

    let formatedBadgeNo = badgeNo;

    if (badgeNo && badgeNo.length > 5) {

        formatedBadgeNo = badgeNo.slice(0,5)+"...";
    }

    return formatedBadgeNo;
}

const styles = {
    container: {
        display: 'flex',
        flex: 1,
        height: 35,
        margin: 1,
        fontSize: 12,
        paddingLeft: 10,
        paddingRight: 10,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.LightGrayishBlue
    },
    alterBackgroundContainer: {
        display: 'flex',
        flex: 1,
        height: 35,
        margin: 1,
        fontSize: 12,
        paddingLeft: 10,
        paddingRight: 10,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.PaleBlue
    },

    firstName: {
        display: 'flex',
        flex: 1,
        fontSize: '0.9em',
        width: 80,
        overflow: 'hidden',
        paddingRight: 1
    },
    lastName: {
        display: 'flex',
        flex: 1,
        fontSize: '0.9em',
        width: 80,
        paddingRight: 2,
        overflow: 'hidden'
    },
    facility: {
        flex: 1,
        width: 12,
        fontSize: '0.9em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: 4
    },
    totalBadges: {
        flex: 1,
        width: 90,
        display: 'flex',
        fontSize: '0.9em',
        justifyContent: 'flex-end'
    }
};

export default Radium(PersonnelListItemView);

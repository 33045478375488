import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import * as Colors from '../../consts/colors';
import * as actions from '../../actions/index';
import ButtonView from '../common/ButtonView';
import LogView from '../common/LogView';

class ViewOptionsView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={styles.container}>
        <div style={styles.titleRow}>
          <FontAwesomeIcon icon={faCircle} size="xs" color={Colors.Black} />
          <p style={styles.viewOptions}>View Options</p>
        </div>

        <div style={styles.keepDoorExpandedRow}>
          <p style={styles.label}> - Keep "My Doors" Expanded</p>
          <div>
            <input
              checked={this.props.tmpKeepMyDoorExpanded}
              type="checkbox"
              size="20"
              onChange={() => {
                this.props.toggleKeepMyDoorsExpanded(
                  this.props.tmpKeepMyDoorExpanded
                );
              }}
            />
          </div>
        </div>

        <div style={styles.keepDoorExpandedRow}>
          <p style={styles.label}> - Request Wireless Lock Status</p>
          <div>
            <input
              checked={this.props.tmpRequestWirelessLockStatus}
              type="checkbox"
              size="20"
              onChange={() => {
                this.props.toggleRequestWirelessLockStatus(
                  this.props.tmpRequestWirelessLockStatus
                  );
                  this.props.configurationChanged();
              }}
            />
          </div>
        </div>
        <div style={styles.keepDoorExpandedRow}>
                <p style={styles.label}> - Enable Debug Log</p>
                <div>
                    <input
                        checked={this.props.isDebugLogEnabled}
                        type="checkbox"
                        size="20"
                        onChange={() => {
                            console.log("Enabling Debug--->" + !this.props.isDebugLogEnabled);
                            this.props.writeLog("OperatorId: --->" + this.props.operatorId + "Enabling Debug--->" + !this.props.isDebugLogEnabled);
                            this.props.enableDebugLog(
                                !this.props.isDebugLogEnabled
                            );
                            this.props.configurationChanged();
                        }}
                    />
                </div>

             
            </div>
            {this.props.isDebugLogEnabled && <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', marginRight: 60 }} >
                <ButtonView instyle={styles.viewLog} label="View Log" onPress={
                    () => {
                        this.props.viewLog();
                    }

                } />
            </div>}
            {this.props.showLog == true && <LogView/>}
            <div style={styles.inListRefreshRow}>
          <p style={styles.label}> - In-List Refresh</p>
          <input
            style={styles.inListRefresh}
            value={this.props.tmpInListRefreshTimeInSeconds}
            type="number"
            size="20"
            onChange={event => {
              if (event.target.value > 0) {
                this.props.setInListRefreshTime(event.target.value);
                // this.props.addMessage('System Settings Updated [ In List Refresh Time ]');
              } else {
                alert('Please enter number greater than 0');
              }
            }}
          />
          Seconds
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    height: 220,
    justifyContent: 'flex-start',
    marginRight: 20,
    marginLeft: 20,
    flexDirection: 'column',
    backgroundColor: Colors.White
    },


    viewLog: {
        margin: 2,
        width: 100,
        height: 32,
        color: Colors.Black,
        fontSize: 15,
        textAlign: 'center',
        backgroundColor: Colors.LightGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
  viewOptions: { marginLeft: 10, fontSize: 17 },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    marginLeft: 10
  },
  keepDoorExpandedRow: {
    display: 'flex',
    flexDirection: 'row',
    height: 30,
    flex: 1,
    alignItems: 'center',
    marginLeft: 40,
    marginRight: 50,
    justifyContent: 'space-between'
  },
  keepDoorExpanded: {
    marginLeft: 10,
    marginRight: 10,
    width: 30
  },
  inListRefreshRow: {
    display: 'flex',
    flexDirection: 'row',
    height: 30,
    alignItems: 'center',
    marginLeft: 40,
    justifyContent: 'flex-start'
  },
  inListRefresh: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.LightGray,
    marginLeft: 10,
    marginRight: 10,
    width: 28,
    borderRadius: 6
  },
  myDoorsSelectionRow: {
    display: 'flex',
    flexDirection: 'row',
    height: 25,
    alignItems: 'center',
    marginLeft: 40,
    justifyContent: 'flex-start'
  },
  myDoorsSelection: {
    marginLeft: 40,
    marginRight: 10
  },
  label: { width: 'auto', fontSize: '0.90em' },
  blackLine: { height: 1, width: '100%', borderBottom: '2px solid black' }
};

const mapStateToProps = state => ({
  // inListRefreshTimeInSeconds: state.settings.inListRefreshTimeInSeconds,
  // keepMyDoorExpanded: state.settings.keepMyDoorExpanded,
  tmpInListRefreshTimeInSeconds: state.settings.tmpInListRefreshTimeInSeconds,
  tmpKeepMyDoorExpanded: state.settings.tmpKeepMyDoorExpanded,
    requestWirelessLockStatus: state.door.requestWirelessLockStatus,
    tmpRequestWirelessLockStatus: state.door.tmpRequestWirelessLockStatus,
    configChanged: state.settings.configChanged,
    isDebugLogEnabled: state.settings.isDebugLogEnabled,
    showLog: state.log.showLog,
    operatorId: state.token.operatorId
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setInListRefreshTime: actions.setInListRefreshTime,
      toggleKeepMyDoorsExpanded: actions.toggleKeepMyDoorsExpanded,
      toggleRequestWirelessLockStatus: actions.toggleRequestWirelessLockStatus,
      configurationChanged: actions.configurationChanged,
      resetConfigurationChanged: actions.resetConfigurationChanged,
      enableDebugLog: actions.enableDebugLog,
      viewLog: actions.viewLog,
      hideLog: actions.hideLog,
      writeLog: actions.writeLog
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(ViewOptionsView)
);

import * as ActionTypes from '../actions/types';

const defaultState = {
  scheduledChanges: [],
  scheduledChangedUpdated: false,
  newScheduleChangesCreated: false,
  cancelledActiveSchedule: false,
  fieldList: [],
  deviceList: [],
  timeSchedules: [],
  selectedDeviceType: '',
  selectedDevice: '',
  selectedField: '',
  msg: ''
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_SCHEDULE_CHANGES_LIST_SUCCEEDED: {
      return {
        ...state,
        scheduledChanges: action.payload
      };
    }

    case ActionTypes.FETCH_TIME_SCHEDULES_SUCCEEDED: {
      return {
        ...state,
        timeSchedules: action.payload
      };
    }

    case ActionTypes.GET_SELECTED_DEVICE_TYPE: {
      return {
        ...state,
        selectedDeviceType: action.payload
      };
    }

    case ActionTypes.FETCH_DEVICE_LIST_BY_DEVICE_TYPE_SUCCEEDED: {
      console.log('Device List::', action.payload);
      return {
        ...state,
        deviceList: action.payload
      };
    }

    case ActionTypes.FETCH_FIELD_LIST_BY_DEVICE_TYPE_SUCCEEDED: {
      console.log('Field List::', action.payload);
      const tmpFieldList = [
        { fieldName: '', valueMember: '', displayMember: '' }
      ];

      return {
        ...state,
        fieldList: [...tmpFieldList, ...action.payload]
      };
    }

    case ActionTypes.DELETE_SCHEDULE_SUCCEEDED: {
      return {
        scheduledChanges: action.payload,
        ...state
      };
    }

    case ActionTypes.CREATE_NEW_SCHEDULE_CHANGES_SUCCEEDED: {
      console.log('created: ', action.payload);

      return {
        ...state,
        newScheduleChangesCreated: action.payload.length === 0,
        msg: action.payload
      };
    }

    case ActionTypes.RESET_NEW_SCHEDULE_CHANGES_SUCCEEDED: {
      return {
        ...state,
        newScheduleChangesCreated: false,
        msg: ''
      };
    }

    case ActionTypes.UPDATE_SCHEDULE_CHANGES_SUCCEEDED: {
      console.log('updated: ', action.payload);

      return {
        ...state,
        scheduledChangedUpdated: action.payload.length === 0,
        msg: action.payload
      };
    }

    case ActionTypes.RESET_UPDATE_SCHEDULE_CHANGES_SUCCEEDED: {
      return {
        ...state,
        scheduledChangedUpdated: false,
        msg: ''
      };
    }

    case ActionTypes.CANCEL_ACTIVE_SCHEDULE_CHANGES_SUCCEEDED: {
      return {
        ...state,
        cancelledActiveSchedule: action.payload.length === 0,
        msg: ''
      };
    }

    case ActionTypes.RESET_CANCEL_ACTIVE_SCHEDULE_CHANGES: {
      return {
        ...state,
        cancelledActiveSchedule: false,
        msg: ''
      };
      }

   case ActionTypes.LOGOUT: {
          return defaultState;
    }

    default:
      return state;
  }
};

import React from 'react';
import Radium from 'radium';
import * as Colors from '../../consts/colors';
import '../../style.css';
import ModalView from '../common/ModalView';


const ModalProgressView = (props) => {

  const closeModal = () => { props.onCloseModal(); };

  return (
    <ModalView
      onClose={() => closeModal()}
      dialogStyle={styles.dialogStyle}
      shouldCloseOnOverlayClick
    >
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div className="loader" />
        <p style={{ color: Colors.White }}>{props.Message}</p>
      </div>
    </ModalView>);
};

const styles = {
  underlineless: {
    textDecoration: 'none'
  },

  container: {
    margin: 2,
    width: 80,
    height: 32,
    fontSize: 17,
    textAlign: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    border: '2px solid',
    borderColor: Colors.Black,
    cursor: 'pointer'
  },
  label: {
    color: Colors.Black,
    textAlign: 'left',
    marginLeft: 20,
    fontSize: 20,
    fontWeight: 800
  },
  dialogStyle: {
    padding: 15,
    width: 300,
    height: 'auto'
  },
  cancel: {
    margin: 2,
    width: 80,
    borderRadius: 10,
    height: 32,
    color: Colors.Black,
    fontSize: 15,
    textAlign: 'center',
    backgroundColor: Colors.LightGray,
    border: '1px solid',
    borderColor: Colors.LightGray,
    cursor: 'pointer'
  }
};

export default Radium(ModalProgressView);

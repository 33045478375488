import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Colors from '../../consts/colors';
import ErrorBoundary from '../common/ErrorBoundary';
import ChangePasswordView from './ChangePasswordView';
import HeaderView from '../common/HeaderView';
import * as actions from '../../actions/index';


const ChangePasswordScreen = () => {
   return  <div
        onClick={() => {
          
        }}
        style={styles.container}
    >
        <div style={styles.header}>
            <HeaderView NoLogInTime NoLogOut NoRefreshButton />
        </div>
        <div style={styles.bodyContainer}>
            <ErrorBoundary>

                <ChangePasswordView />

            </ErrorBoundary>
        </div>
        <div style={styles.footer} />
    </div>;
}


const styles = {
    container: {
        display: 'flex',
        flex: 1,
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: Colors.DarkModerateViolet
    },
    dialogStyle: {
        backgroundColor: Colors.VeryPaleBlue,
        padding: 15,
        width: 600,
        height: 'auto'
    },
    bodyContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
        justifyContent: 'center',
        marginTop: 115
    },

    header: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        width: '100%'
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%'
    },
}


const mapStateToProps = state => ({
    baseURL: state.appConfig.baseURL,
    loading: state.loading.login
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setBaseURL: actions.setBaseURL,
            closeLoaderModal: actions.closeLoaderModal
        },
        dispatch
    );

export default Radium(connect(mapStateToProps, mapDispatchToProps)(ChangePasswordScreen));
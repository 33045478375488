import * as ActionTypes from '../actions/types';

const defaultState = {
    allDoors: [],
    doors: [],
    currentOperatingDoor: {},
    tmpRequestWirelessLockStatus: false,
    requestWirelessLockStatus: false,
    doorsCount: 0,
    pagedDoors: [],
    loading: false,
    finishFetchDoorsCount: false,
    filterText: '',
    badgeValidStatus: -1,
    isPINCodeEnabled: false,
    isCommonCodeEnabled: false,
    isPINCommonCodeNotScheduled: false,
    cancelUnlock: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_ALL_DOORS_SUCCEEDED: {
            return {
                ...state,
                allDoors: action.payload
            };
        }

        case ActionTypes.FETCH_ACCESS_READER_LIST_BY_BADGE_SUCCEEDED: {
            return {
                ...state,
                doors: filteredDoors(action.payload)
            };
        }

        //case ActionTypes.SEND_DOOR_LOCK_COMMAND_SUCCEEDED: {
        //    return { ...state };
        //}

        //case ActionTypes.SEND_DOOR_UNLOCK_COMMAND_SUCCEEDED: {
        //    return { ...state };
        //}

        //case ActionTypes.SEND_DOOR_UNLOCK_HOLD_COMMAND_SUCCEEDED: {
        //    return { ...state };
        //}

        case ActionTypes.SET_CURRENT_OPERATING_DOOR: {
            console.log('PostEvent:: Door Reducer: ', action.payload);

            return {
                ...state,
                currentOperatingDoor: action.payload
            };
        }

      
        case ActionTypes.TOGGLE_REQUEST_WIRELESS_LOCK_STATUS: {
            console.log('TOGGLE', action.payload);
            return {
                ...state,
                tmpRequestWirelessLockStatus: action.payload
            };
        }

        case ActionTypes.SAVE_CONFIG_SETTINGS: {

            return {
                ...state,
                requestWirelessLockStatus: state.tmpRequestWirelessLockStatus
            };
        }

        case ActionTypes.CANCEL_SAVE_CONFIG_SETTINGS: {

            return {
                ...state,
                tmpRequestWirelessLockStatus: state.requestWirelessLockStatus
            };
        }

        case ActionTypes.FETCH_DOORS_COUNT: {
            return {
                ...state,
                loading: true,
                finishFetchDoorsCount: false
            };
        }

        case ActionTypes.FETCH_DOORS_COUNT_SUCCEEDED: {
            return {
                ...state,
                doorsCount: action.payload,
                loading: false,
                finishFetchDoorsCount: true
            };
        }

        case ActionTypes.FETCH_DOORS_COUNT_FAILED: {
            return {
                ...state,
                loading: false,
                finishFetchDoorsCount: true
            };
        }
        case ActionTypes.FETCH_PAGED_DOORS: {
            return {
                ...state,
                loading: true
            };
        }
        case ActionTypes.FETCH_PAGED_DOORS_SUCCEEDED: {
            return {
                ...state,
                pagedDoors: [...state.pagedDoors, ...action.payload],
                loading: false
            };
        }
        case ActionTypes.FETCH_PAGED_DOORS_FAILED: {
            return {
                ...state,
                loading: false
            };
        }

        case ActionTypes.RESET_DOORS: {
            return {
                ...state,
                pagedDoors: [],
                doorsCount: 0,
                finishFetchDoorsCount: false,
                filterText: ''
            };
        }

        case ActionTypes.SEND_MY_DOOR_UNLOCK: {
            return {
                ...state,
                badgeValidStatus: -1
            };
        }
        case ActionTypes.SEND_MY_DOOR_UNLOCK_SUCCEEDED: {
            return {
                ...state,
                badgeValidStatus: action.payload
            };
        }

        case ActionTypes.SEND_MY_DOOR_UNLOCK_FAILED: {
            return {
                ...state,
                badgeValidStatus: -1,
                cancelUnlock: true
            };
        }


        case ActionTypes.CHECK_IF_PINCODE_ENABLED: {
            return {
                ...state,
                isPINCodeEnabled: false
            };
        }
        case ActionTypes.CHECK_IF_PINCODE_ENABLED_SUCCEEDED: {
            return {
                ...state,
                isPINCodeEnabled: action.payload
            };
        }

        case ActionTypes.CHECK_IF_PINCODE_ENABLED_FAILED: {
            return {
                ...state,
                isPINCodeEnabled: false
            };
        }

        case ActionTypes.SET_PINCODE_ENABLED: {
            return {
                ...state,
                isPINCodeEnabled: true
            };
        }

        case ActionTypes.RESET_PINCODE_ENABLED_STATUS: {
            return {
                ...state,
                isPINCodeEnabled: false
            };
        }

        case ActionTypes.CHECK_IF_PIN_COMMONCODE_ENABLED: {
            return {
                ...state,
                isPINCodeEnabled: false,
                isCommonCodeEnabled: false
            };
        }


        case ActionTypes.CHECK_IF_PIN_COMMONCODE_ENABLED_SUCCEEDED: {

            console.log("PIN Check in Reducer:", action.payload);
            let isPINCodeEnabled = false;
            let isCommonCodeEnabled = false;
            let isPINCommonCodeNotScheduled = false;

            if (action.payload === 0) {
                isPINCodeEnabled = false;
                isCommonCodeEnabled = false;
            } else if (action.payload === 1) {
                isPINCodeEnabled = true;
                isCommonCodeEnabled = false;

            } else if (action.payload === 2) {
                isPINCodeEnabled = false;
                isCommonCodeEnabled = true;

            } else if (action.payload === 3) {
                isPINCommonCodeNotScheduled = true;
                isPINCodeEnabled = false;
                isCommonCodeEnabled = false;
            }
            else {
                isPINCodeEnabled = false;
                isCommonCodeEnabled = false;
            }

            return {
                ...state,
                isPINCodeEnabled,
                isCommonCodeEnabled,
                isPINCommonCodeNotScheduled
            };
        }

        case ActionTypes.CHECK_IF_PINCODE_ENABLED_FAILED: {
            return {
                ...state,
                isPINCodeEnabled: false,
                isCommonCodeEnabled: false
            };
        }



        case ActionTypes.RESET_COMMONCODE_ENABLED_STATUS: {
            return {
                ...state,
                isCommonCodeEnabled: false
            };
        }

        case ActionTypes.RESET_PIN_COOMONCOODE_NOT_SCHEDULED: {
            return {
                ...state,
                isPINCommonCodeNotScheduled: false
            };
        }

        case ActionTypes.RESET_BADGE_VALID_STATUS: {
            return {
                ...state,
                badgeValidStatus: -1
            };
        }

        case ActionTypes.CANCEL_UNLOCK_PRESSED: {
            return {
                ...state,
                cancelUnlock: true
            };
        }

        case ActionTypes.RESET_CANCEL_UNLOCK_PRESSED: {
            return {
                ...state,
                cancelUnlock: false
            };
        }



        default:
            return state;
    }
};

const filteredDoors = doors => {
    const updatedDoors = [];
    for (let i = 0; i < doors.length; ++i) {
        if (doors[i].statusDef && doors[i].statusDef === 'Not Configured') {
            continue;
        }

        updatedDoors.push(doors[i]);
    }
    return updatedDoors;
};

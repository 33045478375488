import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Colors from '../../consts/colors';
import LogInView from '../login/LogInView';
import HeaderView from '../common/HeaderView';
import ModalProgressView from '../common/ModalProgressView';
import * as actions from '../../actions/index';
import ErrorBoundary from '../common/ErrorBoundary';

class LogInScreen extends Component {
  render() {
    return (
      <div style={styles.container}>
        <div style={styles.header}>
          <HeaderView NoLogOut NoRefreshButton NoLogInTime />
        </div>
        <div style={styles.bodyContainer}>
          <ErrorBoundary>
            <div className="scrollable">
              <LogInView />
            </div>
          </ErrorBoundary>
        </div>
        {this.props.loading === true && (
          <div>
            <ModalProgressView
              Message="Please wait..."
              onCloseModal={() => {
                this.props.closeLoaderModal();
              }}
            />
          </div>
        )}
        <div style={styles.footer}></div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: Colors.DarkModerateViolet
  },
  dialogStyle: {
    backgroundColor: Colors.VeryPaleBlue,
    padding: 15,
    width: 600,
    height: 'auto'
  },
  bodyContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'auto',
    justifyContent: 'center',
    marginTop: 115
  },
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    width: '100%'
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%'
  },
  viewContainer: {
    display: 'flex',
    flex: 1,
    flexBasis: 200,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  save: {
    margin: 2,
    width: 80,
    height: 32,
    fontSize: 15,
    borderRadius: 10,
    color: Colors.White,
    textAlign: 'center',
    backgroundColor: Colors.MostPureBlue,
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'pointer'
  },
  cancel: {
    margin: 2,
    width: 80,
    borderRadius: 10,
    height: 32,
    color: Colors.White,
    fontSize: 15,
    textAlign: 'center',
    backgroundColor: Colors.LightGray,
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'pointer'
  }
};

const mapStateToProps = state => ({
  baseURL: state.appConfig.baseURL,
  loading: state.loading.login
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setBaseURL: actions.setBaseURL,
      closeLoaderModal: actions.closeLoaderModal
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(LogInScreen)
);

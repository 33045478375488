import moment from 'moment';
import * as ActionTypes from '../actions/types';

const defaultState = {
    accessToken: '',
    tokenType: '',
    expiresIn: '',
    loggedInTime: '',
    badgesOfTheUser: [],
    selectedBadgeOfUser: {},
    tmpSelectedBadgeOfUser: {},
    privileges: [],
    operatorId: '',
    passwordRequiresUpdate:false,
    roleId: '',
    operatorFirstName: '',
    operatorLastName: '',
    remainingSessionTime: 1,
    lastInactivityTime: moment.now(),
    passwordChanged: 0  /// 0- Default or Not Changed, 1 - Changed, 2- Fail to Change with a message   
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN_SUCCEEDED: {
            const zeroIndexedBadge =
                action.payload.badges && action.payload.badges.length >= 1
                    ? JSON.parse(action.payload.badges)[0]
                    : {};

            const tmpSelectedBadgeOfUser =
                state.tmpSelectedBadgeOfUser &&
                    action.payload.badges &&
                    includes(
                        JSON.parse(action.payload.badges),
                        state.tmpSelectedBadgeOfUser
                    )
                    ? state.tmpSelectedBadgeOfUser
                    : zeroIndexedBadge;
            
            const passwordRequiresUpdate = action.payload.passwordRequiresUpdate && (action.payload.passwordRequiresUpdate === 'True' || (action.payload.passwordRequiresUpdate === 'true' ));

            return {
                ...state,
                accessToken: action.payload.access_token,
                tokenType: action.payload.token_type,
                expiresIn: action.payload.expires_in,
                badgesOfTheUser: JSON.parse(action.payload.badges),
                selectedBadgeOfUser:
                    JSON.parse(action.payload.badges) &&
                        JSON.parse(action.payload.badges).length >= 1
                        ? JSON.parse(action.payload.badges)[0]
                        : {},

                tmpSelectedBadgeOfUser,

                privileges:
                    action.payload.privileges === null ||
                        action.payload.privileges.length === 0
                        ? []
                        : JSON.parse(action.payload.privileges),
                operatorId: action.payload.operatorId,
                roleId:
                    action.payload.roleId != null
                        ? action.payload.roleId.toString().toUpperCase()
                        : '',
                operatorFirstName: action.payload.operatorFirstName
                    ? action.payload.operatorFirstName
                    : '',
                operatorLastName: action.payload.operatorLastName
                    ? action.payload.operatorLastName
                    : '',
                passwordRequiresUpdate: passwordRequiresUpdate
            };
        }

        case ActionTypes.ADD_LOGGED_IN_TIME: {
            return {
                ...state,
                loggedInTime: action.payload
            };
        }

        case ActionTypes.LOAD_ACCESSTOKEN: {
            const zeroIndexedBadge =
                action.payload.badges && action.payload.badges.length >= 1
                    ? JSON.parse(action.payload.badges)[0]
                    : {};

            const tmpSelectedBadgeOfUser =
                state.tmpSelectedBadgeOfUser &&
                    action.payload.badges &&
                    includes(
                        JSON.parse(action.payload.badges),
                        state.tmpSelectedBadgeOfUser
                    )
                    ? state.tmpSelectedBadgeOfUser
                    : zeroIndexedBadge;

            return {
                ...state,
                accessToken: action.payload.access_token,
                tokenType: action.payload.token_type,
                expiresIn: action.payload.expires_in,
                badgesOfTheUser:
                    JSON.parse(action.payload.badges) &&
                        JSON.parse(action.payload.badges).length >= 0
                        ? JSON.parse(action.payload.badges)
                        : [],
                selectedBadgeOfUser:
                    JSON.parse(action.payload.badges) &&
                        JSON.parse(action.payload.badges).length >= 1
                        ? JSON.parse(action.payload.badges)[0]
                        : {},
                // tmpSelectedBadgeOfUser:
                //   JSON.parse(action.payload.badges) && JSON.parse(action.payload.badges).length >= 1
                //     ? JSON.parse(action.payload.badges)[0]
                //     : {},
                tmpSelectedBadgeOfUser,
                privileges:
                    action.payload.privileges === null ||
                        action.payload.privileges.length === 0
                        ? []
                        : JSON.parse(action.payload.privileges),
                operatorId: action.payload.operatorId,
                roleId:
                    action.payload.roleId != null
                        ? action.payload.roleId.toString().toUpperCase()
                        : ''
            };
        }

        case ActionTypes.SET_SELECTED_BADGE_OF_USER:
            return {
                ...state,
                tmpSelectedBadgeOfUser: action.payload
            };

        case ActionTypes.RESET_ACCESSTOKEN:
            return {
                ...defaultState
            };

        case ActionTypes.SAVE_CONFIG_SETTINGS: {
            return {
                ...state,
                selectedBadgeOfUser: { ...state.tmpSelectedBadgeOfUser }
            };
        }

        case ActionTypes.CANCEL_SAVE_CONFIG_SETTINGS: {
            return {
                ...state,
                tmpSelectedBadgeOfUser: { ...state.selectedBadgeOfUser }
            };
        }

        case ActionTypes.LOAD_BADGE_INFO: {
            //console.log('TMP:', action.payload, state);
            return {
                ...state,
                tmpSelectedBadgeOfUser: action.payload
            };
        }

        case ActionTypes.SET_REMAINING_SESSION_TIME: {
            return {
                ...state,
                remainingSessionTime: action.payload,
                lastInactivityTime: moment.now()
            };
        }

        case ActionTypes.RESET_REMAINING_SESSION_TIME: {
            return {
                ...state,
                remainingSessionTime: 0
            };
        }

        case ActionTypes.FETCH_BADGES_BY_PERSON_NAME_SUCCEEDED: {
            const payloads = action.payload;

            const zeroIndexedBadge =
                payloads && payloads.length >= 1 ? payloads[0] : {};

            const tmpSelectedBadgeOfUser =
                state.tmpSelectedBadgeOfUser &&
                    payloads &&
                    includes(payloads, state.tmpSelectedBadgeOfUser)
                    ? state.tmpSelectedBadgeOfUser
                    : zeroIndexedBadge;

            return {
                ...state,
                badgesOfTheUser: payloads || [],
                tmpSelectedBadgeOfUser,
                selectedBadgeOfUser: tmpSelectedBadgeOfUser
            };
        }

        case ActionTypes.CHANGE_OPERATOR_PASSWORD_SUCCEEDED: {
          

            return {
                ...state,
                passwordChanged: action.payload && action.payload === true ? 1 : 2
            };
        }

        case ActionTypes.RESET_CHANGE_OPERATOR_PASSWORD: {
            return {
                 ...state,
                passwordChanged: 0
            };
        }

        case ActionTypes.RESET_PASSWORD_REQUIRES_UPDATE: {
            return {
                ...state,
                passwordRequiresUpdate: false
            };
        }

        case ActionTypes.LOGOUT: {
            return {
                ...state,
                remainingSessionTime: 0
            };
        }

        default:
            return state;
    }
};

const includes = (badgeList, badgeToSearch) => {
    //console.log('BadgeList: ', badgeList.length, badgeToSearch);

    if (badgeList === null || badgeToSearch == null) return false;

    for (let i = 0; i < badgeList.length; ++i) {
        //console.log('BadgeList: ++', badgeList[i], badgeToSearch.Badge);
        if (
            badgeList[i].Badge &&
            badgeToSearch.Badge &&
            badgeList[i].Badge === badgeToSearch.Badge
        ) {
            return true;
        } else if (
            badgeList[i].badge &&
            badgeToSearch.badge &&
            badgeList[i].badge === badgeToSearch.badge
        ) {
            return true;
        }
    }

    return false;
};

import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { Link, Redirect } from 'react-router-dom';
import * as Colors from '../../consts/colors';
import ScheduleChangesListItemView from '../scheduledchanges/ScheduleChangesListItemView';
import ButtonView from '../common/ButtonView';
import * as actions from '../../actions/index';
import {
    PAGE_NAMES,
    SECURITY_LEVEL_CODES,
    ADMIN_ROLE_ID,
    SCREEN_CODES
} from '../../consts/consts';

import ModalProgressView from '../common/ModalProgressView';
import ModalView from '../common/ModalView';

class ScheduledChangesView extends Component {
    constructor(props) {
        super(props);
        this.props.fetchScheduleChangesList();

        const scheduleChangesPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.SCHEDULECHANGES
        );

        this.state = {
            selectedItemIndex: -1,
            showModal: false,
            showCancelActiveModal: false,
            caneActiveWithDeleted: false,
            scheduleChangesPrivilege
        };

        this.showScheduledChanges = this.showScheduledChanges.bind(this);
        this.warningDialog = this.warningDialog.bind(this);
        this.confirmDialog = this.confirmDialog.bind(this);
        this.props.setCurrentPageName(PAGE_NAMES.SCHEDULEDCHANGES);

        this.props.writeLog('OperatorID: ' + this.props.operatorId + "ScheduleChangesView -->  Constructor() --> ScheduleChangePriviledge:  " + JSON.stringify(scheduleChangesPrivilege));

    }
    onCloseModal() {
        this.setState({ showModal: false });
    }

    warningDialog() {
        this.setState({ showModal: true });
    }

    confirmDialog() {
        const isConfirmed = window.confirm(
            `Confirm delete ${
            this.props.scheduledChanges[this.state.selectedItemIndex].description
            }?`
        );

        if (isConfirmed === true) {
            this.props.deleteSchedule(
                this.props.scheduledChanges[this.state.selectedItemIndex]
            );
            this.setState({ selectedItemIndex: -1 });
            this.props.fetchScheduleChangesList();
        }
    }

    showScheduledChanges(scheduledchanges) {
        const scheduleChangesList = scheduledchanges.map(
            (schedulechange, index) => (
                <ScheduleChangesListItemView
                    currentIndex={index}
                    key={index}
                    isActive={schedulechange.active ? schedulechange.active : false}
                    description={schedulechange.description}
                    startTime={schedulechange.startTime}
                    endTime={schedulechange.endTime}
                    startDate={schedulechange.startDate}
                    endDate={schedulechange.endDate}
                    isSelected={this.state.selectedItemIndex === index}
                    instyle={
                        this.state.selectedItemIndex === index
                            ? styles.selectedItemStyle
                            : styles.itemStyle
                    }
                    onPress={indx => {
                        this.setState({ selectedItemIndex: indx });
                    }}
                />
            )
        );
        return <div>{scheduleChangesList}</div>;
    }

    render() {
        if (
            this.props.cancelledActiveSchedule === true &&
            this.state.caneActiveWithDeleted === true
        ) {
            alert('Device data has been restored.');
            this.props.resetCancelActiveScheduleChanges();
            this.setState({ ...this.state, caneActiveWithDeleted: false });
            return <Redirect to="/scheduledchanges" />;
        }

        return (
            <div style={styles.container}>
                <div>
                    <div style={styles.expandContainer}>
                        <Link style={styles.underlineless} to="/home">
                            <div>
                                <FontAwesomeIcon
                                    icon={faMinus}
                                    size="1x"
                                    color={Colors.White}
                                />
                            </div>
                        </Link>
                        <div style={styles.functionText}>Scheduled Changes</div>
                    </div>
                    <div style={styles.blackLine} />
                    {this.props.roleId === ADMIN_ROLE_ID &&
                        this.props.scheduledChanges &&
                        this.props.scheduledChanges.length > -1 && (
                            <div>
                                <div style={styles.buttonContainer}>
                                    <Link style={styles.underlineless} to="/addschedulechange">
                                        <ButtonView label="Add" instyle={styles.add} />
                                    </Link>
                                    <div>
                                        {this.props.scheduledChanges[
                                            this.state.selectedItemIndex === -1
                                                ? 0
                                                : this.state.selectedItemIndex
                                        ] &&
                                            !this.props.scheduledChanges[
                                                this.state.selectedItemIndex === -1
                                                    ? 0
                                                    : this.state.selectedItemIndex
                                            ].active &&
                                            this.props.scheduledChanges[
                                                this.state.selectedItemIndex === -1
                                                    ? 0
                                                    : this.state.selectedItemIndex
                                            ].active !== true && (
                                                <ButtonView
                                                    label="Delete"
                                                    disabled={
                                                        this.props.scheduledChanges[
                                                        this.state.selectedItemIndex === -1
                                                            ? 0
                                                            : this.state.selectedItemIndex
                                                        ] &&
                                                        this.props.scheduledChanges[
                                                            this.state.selectedItemIndex === -1
                                                                ? 0
                                                                : this.state.selectedItemIndex
                                                        ].active &&
                                                        this.props.scheduledChanges[
                                                            this.state.selectedItemIndex === -1
                                                                ? 0
                                                                : this.state.selectedItemIndex
                                                        ].active === true
                                                    }
                                            instyle={this.state.selectedItemIndex === -1 ? styles.deleteDisabled : styles.delete}
                                                    onPress={() => {
                                                        this.state.selectedItemIndex !== -1
                                                            ? this.confirmDialog()
                                                            : this.warningDialog();
                                                    }}
                                                />
                                            )}
                                    </div>

                                    {this.props.scheduledChanges[
                                        this.state.selectedItemIndex === -1
                                            ? 0
                                            : this.state.selectedItemIndex
                                    ] &&
                                        !this.props.scheduledChanges[
                                            this.state.selectedItemIndex === -1
                                                ? 0
                                                : this.state.selectedItemIndex
                                        ].active &&
                                        this.props.scheduledChanges[
                                            this.state.selectedItemIndex === -1
                                                ? 0
                                                : this.state.selectedItemIndex
                                        ].active !== true && (
                                            <Link
                                                style={styles.underlineless}
                                                to={
                                                    this.state.selectedItemIndex !== -1
                                                        ? {
                                                            pathname: '/updateschedulechange',
                                                            state: {
                                                                currentScheduledChanges: this.props
                                                                    .scheduledChanges[
                                                                    this.state.selectedItemIndex
                                                                ]
                                                            }
                                                        }
                                                        : {}
                                                }
                                            >
                                                <ButtonView
                                            label="Edit"
                                            onPress={() => {
                                                this.state.selectedItemIndex === -1 &&
                                                    this.warningDialog();
                                            }}
                                            instyle={this.state.selectedItemIndex === -1 ? styles.editDisable : styles.edit}
                                            enabled={this.state.selectedItem===-1? false: true}
                                                />
                                            </Link>
                                        )}

                                    {this.props.scheduledChanges[
                                        this.state.selectedItemIndex === -1
                                            ? 0
                                            : this.state.selectedItemIndex
                                    ] &&
                                        this.props.scheduledChanges[
                                            this.state.selectedItemIndex === -1
                                                ? 0
                                                : this.state.selectedItemIndex
                                        ].active &&
                                        this.props.scheduledChanges[
                                            this.state.selectedItemIndex === -1
                                                ? 0
                                                : this.state.selectedItemIndex
                                        ].active === true && (
                                            <ButtonView
                                                label="Cancel Active"
                                                onPress={() => {
                                                    this.setState({
                                                        ...this.state,
                                                        showCancelActiveModal: true
                                                    });
                                                }}
                                                instyle={styles.cancelActive}
                                            />
                                        )}
                                </div>
                                <div style={styles.titleContainer}>
                                    <div style={styles.listTitle}>Schedule </div>
                                    <div style={styles.ftListTitle}>From/To Date</div>
                                </div>

                                <div className="customScrollbar" style={styles.listContainer}>
                                    {this.showScheduledChanges(this.props.scheduledChanges)}
                                </div>
                            </div>
                        )}

                    {this.props.roleId !== ADMIN_ROLE_ID &&
                        this.state.scheduleChangesPrivilege &&
                        this.props.scheduledChanges &&
                        this.props.scheduledChanges.length > -1 &&
                        (this.state.scheduleChangesPrivilege.SecurityLevel ===
                            SECURITY_LEVEL_CODES.FULL_CONTROL ||
                            this.state.scheduleChangesPrivilege.SecurityLevel ===
                            SECURITY_LEVEL_CODES.VIEW_ONLY ||
                            this.state.scheduleChangesPrivilege.SecurityLevel ===
                            SECURITY_LEVEL_CODES.CREATE_ONLY ||
                            this.state.scheduleChangesPrivilege.SecurityLevel ===
                            SECURITY_LEVEL_CODES.EDIT_ONLY ||
                            this.state.scheduleChangesPrivilege.SecurityLevel ===
                            SECURITY_LEVEL_CODES.CREATE_EDIT_ONLY) && (
                            <div>
                                <div style={styles.buttonContainer}>
                                    {(this.state.scheduleChangesPrivilege.SecurityLevel ===
                                        SECURITY_LEVEL_CODES.FULL_CONTROL ||
                                        this.state.scheduleChangesPrivilege.SecurityLevel ===
                                        SECURITY_LEVEL_CODES.CREATE_ONLY ||
                                        this.state.scheduleChangesPrivilege.SecurityLevel ===
                                        SECURITY_LEVEL_CODES.CREATE_EDIT_ONLY) && (
                                            <Link style={styles.underlineless} to="/addschedulechange">
                                                <ButtonView label="Add" instyle={styles.add} />
                                            </Link>
                                        )}
                                    {this.state.scheduleChangesPrivilege.SecurityLevel ===
                                    SECURITY_LEVEL_CODES.FULL_CONTROL && this.props.scheduledChanges.length > 0 &&
                                        this.props.scheduledChanges[
                                        this.state.selectedItemIndex === -1
                                            ? 0
                                            : this.state.selectedItemIndex
                                        ] &&
                                        !this.props.scheduledChanges[
                                            this.state.selectedItemIndex === -1
                                                ? 0
                                                : this.state.selectedItemIndex
                                        ].active &&
                                        this.props.scheduledChanges[
                                            this.state.selectedItemIndex === -1
                                                ? 0
                                                : this.state.selectedItemIndex
                                        ].active !== true && (
                                            <div>
                                                <ButtonView
                                            label="Delete"
                                            enabled={this.state.selectedItem === -1 ? false : true}
                                            instyle={this.state.selectedItemIndex === -1 ? styles.deleteDisabled : styles.delete}
                                                    onPress={() => {
                                                        this.state.selectedItemIndex !== -1
                                                            ? this.confirmDialog()
                                                            : this.warningDialog();
                                                    }}
                                                />
                                            </div>
                                        )}
                                    {(this.state.scheduleChangesPrivilege.SecurityLevel ===
                                        SECURITY_LEVEL_CODES.FULL_CONTROL ||
                                        this.state.scheduleChangesPrivilege.SecurityLevel ===
                                        SECURITY_LEVEL_CODES.EDIT_ONLY ||
                                        this.state.scheduleChangesPrivilege.SecurityLevel ===
                                    SECURITY_LEVEL_CODES.CREATE_EDIT_ONLY) && this.props.scheduledChanges.length > 0 &&
                                        this.props.scheduledChanges[
                                        this.state.selectedItemIndex === -1
                                            ? 0
                                            : this.state.selectedItemIndex
                                        ] &&
                                        !this.props.scheduledChanges[
                                            this.state.selectedItemIndex === -1
                                                ? 0
                                                : this.state.selectedItemIndex
                                        ].active &&
                                        this.props.scheduledChanges[
                                            this.state.selectedItemIndex === -1
                                                ? 0
                                                : this.state.selectedItemIndex
                                        ].active !== true && (
                                            <Link
                                                style={styles.underlineless}
                                                to={
                                                    this.state.selectedItemIndex !== -1
                                                        ? {
                                                            pathname: '/updateschedulechange',
                                                            state: {
                                                                currentScheduledChanges: this.props
                                                                    .scheduledChanges[
                                                                    this.state.selectedItemIndex
                                                                ]
                                                            }
                                                        }
                                                        : {}
                                                }
                                            >
                                                <ButtonView
                                            label="Edit"
                                            enabled={this.state.selectedItem === -1 ? false : true}
                                                    onPress={() => {
                                                        this.state.selectedItemIndex === -1 &&
                                                            this.warningDialog();
                                                    }}
                                            instyle={this.state.selectedItemIndex === -1 ? styles.editDisable : styles.edit}
                                                />
                                            </Link>
                                        )}
                                { this.props.scheduledChanges.length > 0 && this.props.scheduledChanges[
                                        this.state.selectedItemIndex === -1
                                            ? 0
                                            : this.state.selectedItemIndex
                                    ] &&
                                        this.props.scheduledChanges[
                                            this.state.selectedItemIndex === -1
                                                ? 0
                                                : this.state.selectedItemIndex
                                        ].active &&
                                        this.props.scheduledChanges[
                                            this.state.selectedItemIndex === -1
                                                ? 0
                                                : this.state.selectedItemIndex
                                        ].active === true && (
                                            <ButtonView
                                                label="Cancel Active"
                                                onPress={() => {
                                                    this.setState({
                                                        ...this.state,
                                                        showCancelActiveModal: true
                                                    });
                                                }}
                                                instyle={styles.cancelActive}
                                            />
                                        )}
                                </div>
                                <div style={styles.titleContainer}>
                                    <div style={styles.listTitle}>Schedule </div>
                                    <div style={styles.fTlistTitle}>From/To Date</div>
                                </div>

                                <div className="customScrollbar" style={styles.listContainer}>
                                    {this.showScheduledChanges(this.props.scheduledChanges)}
                                </div>
                            </div>
                        )}
                </div>
                <div>
                    {this.state.showModal === true && (
                        <ModalView
                            onClose={this.onCloseModal}
                            dialogStyle={styles.dialogStyle}
                            shouldCloseOnOverlayClick
                        >
                            <div
                                style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                            >
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <p
                                        style={{
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            fontSize: 16,
                                            justifyContent: 'center'
                                        }}
                                    >
                                        {' '}
                                        Please select an item.
                  </p>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        marginTop: '16px'
                                    }}
                                >
                                    <ButtonView
                                        label="CLOSE"
                                        onPress={() => {
                                            this.onCloseModal();
                                        }}
                                        instyle={styles.close}
                                    />
                                </div>
                            </div>
                        </ModalView>
                    )}
                    {this.state.showCancelActiveModal === true && (
                        <ModalView
                            onClose={() => {
                                this.setState({ ...this.state, showCancelActiveModal: false });
                            }}
                            dialogStyle={styles.dialogStyle}
                            shouldCloseOnOverlayClick
                        >
                            <div style={styles.modalContainer}>
                                <div>
                                    <p>
                                        Click Yes to cancel the scheduled change and delete it(This
                                        will restore the device to its original state). Click No to
                                        cancel the scheduled change but keep it configured.
                  </p>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <ButtonView
                                        label="Yes"
                                        onPress={() => {
                                            const activeSchedule = this.props.scheduledChanges[
                                                this.state.selectedItemIndex
                                            ];

                                            this.props.cancelActiveScheduleChanges(
                                                activeSchedule.caObjectId,
                                                false
                                            );
                                            this.setState({
                                                ...this.state,
                                                showCancelActiveModal: false,
                                                caneActiveWithDeleted: true
                                            });
                                        }}
                                    />
                                    <ButtonView
                                        label="No"
                                        onPress={() => {
                                            const activeSchedule = this.props.scheduledChanges[
                                                this.state.selectedItemIndex
                                            ];

                                            this.props.cancelActiveScheduleChanges(
                                                activeSchedule.caObjectId,
                                                true
                                            );
                                            this.setState({
                                                ...this.state,
                                                showCancelActiveModal: false
                                            });
                                        }}
                                    />
                                    <ButtonView
                                        instyle={styles.cancel}
                                        label="Cancel"
                                        onPress={() => {
                                            this.setState({
                                                ...this.state,
                                                showCancelActiveModal: false
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </ModalView>
                    )}
                    {this.props.loading === true && (
                        <div>
                            <ModalProgressView
                                Message="Please wait...."
                                onCloseModal={() => {
                                    this.props.closeLoaderModal();
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const styles = {
    underlineless: {
        textDecoration: 'none'
    },
    modalContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        fontSize: '0.95em'
    },
    dialogStyle: {
        backgroundColor: Colors.VeryPaleBlue,
        padding: 15,
        width: 300,
        height: 'auto'
    },
    selectedItemStyle: {
        display: 'flex',
        flex: 1,
        height: 60,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.Cream
    },
    itemStyle: {
        display: 'flex',
        flex: 1,
        height: 50,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.White
    },
    close: {
        margin: 2,
        width: 80,
        borderRadius: 4,
        height: 32,
        color: Colors.Black,
        fontSize: 15,
        textAlign: 'center',
        backgroundColor: Colors.LightGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer'
    },
    cancelActive: {
        margin: 2,
        width: 130,
        height: 32,
        fontSize: 14,
        color: Colors.White,
        textAlign: 'center',
        backgroundColor: Colors.Red,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },

    edit: {
        margin: 2,
        width: 80,
        height: 32,
        fontSize: 14,
        color: Colors.White,
        textAlign: 'center',
        backgroundColor: Colors.Orange,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    editDisable: {
        margin: 2,
        width: 80,
        height: 32,
        fontSize: 14,
        color: Colors.White,
        textAlign: 'center',
        backgroundColor: Colors.LightGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.LightGray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    }
    ,
    add: {
        margin: 2,
        width: 80,
        height: 32,
        fontSize: 14,
        color: Colors.White,
        textAlign: 'center',
        backgroundColor: Colors.MostPureBlue,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    delete: {
        margin: 2,
        width: 80,
        height: 32,
        color: Colors.White,
        fontSize: 14,
        textAlign: 'center',
        backgroundColor: Colors.Red,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    deleteDisabled: {
        margin: 2,
        width: 80,
        height: 32,
        color: Colors.White,
        fontSize: 14,
        textAlign: 'center',
        backgroundColor: Colors.LightGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.LightGray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    }
    ,
    alldays: {
        display: 'flex',
        flex: 1,
        height: 50,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.LightGray
    },
    other: {
        display: 'flex',
        flex: 1,
        height: 50,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.White
    },

    listContainer: {
        height: 200,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'black',
        overflowY: 'scroll',
        fontWeight: 'bold',
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 20,
        borderRadius: 6
    },
    listTitle: { textAlign: 'left', display: 'flex', flex: 1.2, paddingLeft: 8 },
    ftListTitle: { textAlign: 'left', display: 'flex', flex: 1, paddingLeft: 8 },
    titleContainer: {
        marginLeft: 10,
        marginRight: 10,
        paddingLeft: 10,
        flex: 1,
        fontSize: 15,
        height: 28,
        display: 'flex',
        fontWeight: 'bold',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row'
    },
    container: {
        display: 'flex',
        flex: 1,
        height: 'auto',
        justifyContent: 'center',
        marginRight: 20,
        flexDirection: 'column',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: Colors.Green,
        backgroundColor: Colors.White
    },
    buttonContainer: {
        flex: 1,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Colors.White,
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 10,
        marginLeft: 30,
        marginRight: 30
    },
    expandContainer: {
        flex: 1,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        paddingLeft: 10,
        backgroundColor: Colors.Green,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10
    },

    functionText: {
        color: Colors.White,
        textAlign: 'left',
        marginLeft: 20,
        fontSize: 20,
        fontWeight: 800
    },
    horizontalLine: {
        height: 1,
        width: '100%',
        borderBottom: '1px solid',
        borderColor: Colors.Purple
    }
};

const mapStateToProps = state => ({
    accessToken: state.token.accessToken,
    scheduledChanges: state.scheduledChanges.scheduledChanges,
    privileges: state.token.privileges,
    operatorId: state.token.operatorId,
    roleId: state.token.roleId,
    cancelledActiveSchedule: state.scheduledChanges.cancelledActiveSchedule,
    loading: state.loading.loading,
    operatorId: state.token.operatorId
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setCurrentPageName: actions.setCurrentPageName,
            fetchScheduleChangesList: actions.fetchScheduleChangesList,
            deleteSchedule: actions.deleteSchedule,
            cancelActiveScheduleChanges: actions.cancelActiveScheduleChanges,
            closeLoaderModal: actions.closeLoaderModal,
            resetCancelActiveScheduleChanges: actions.resetCancelActiveScheduleChanges,
            writeLog: actions.writeLog
        },
        dispatch
    );

export default Radium(
    connect(mapStateToProps, mapDispatchToProps)(ScheduledChangesView)
);

import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Colors from '../../consts/colors';
import HeaderView from '../common/HeaderView';
import FooterView from '../common/FooterView';
import BodyView from '../home/BodyView';
import '../../style.css';
import * as actions from '../../actions/index';
import { PAGE_NAMES } from '../../consts/consts';
import ErrorBoundary from '../common/ErrorBoundary';

class MainScreen extends Component {
  UNSAFE_componentWillMount() {
    this.props.setCurrentPageName(PAGE_NAMES.HOME);
    //console.log('All props', this.props);
  }

  render() {
    //console.log('Privilages:', this.props.privileges);
    const connectionStatus = this.props.connectStatus.connected
      ? 'Online'
      : 'Offline';
    const statusMessage = this.props.connectStatus.connected
      ? this.props.connectStatus.onLineMessage
      : this.props.connectStatus.offLineMessage;

    //console.log('connection status: ', connectionStatus, statusMessage);
    return (
      <div style={styles.container}>
        <div styles={styles.header}>
          <HeaderView hasMenu NoRefreshButton />
        </div>
        <div style={styles.bodyContainer}>
          <ErrorBoundary>
            <div className="scrollable">
              <BodyView />
            </div>
          </ErrorBoundary>
        </div>
        <div style={styles.footer}>
          <FooterView
            messageList={this.props.generalMessage.messageList}
            status={connectionStatus}
            message={statusMessage}
            lockdown="Sys Normal"
          />
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: Colors.DarkModerateViolet
  },
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%'
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%'
  },
  bodyContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'auto',
    marginBottom: 65
  },
  appMessageArea: {},
  appBannerArea: {
    alignItems: 'center'
  },
  h1: {
    marginTop: 100,
    textAlign: 'center',
    color: Colors.Purple
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};
const mapStateToProps = state => ({
  accessToken: state.token.accessToken,
  connectStatus: state.connectStatus,
  generalMessage: state.generalMessage,
  privileges: state.token.privileges
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCurrentPageName: actions.setCurrentPageName
      //addMessage: actions.addMessage
    },
    dispatch
  );

export default Radium(connect(mapStateToProps, mapDispatchToProps)(MainScreen));

import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Colors from '../../consts/colors';
import * as actions from '../../actions/index';
import ErrorBoundary from '../common/ErrorBoundary';

const _ = require('lodash');

class ViewSelectionItemView extends Component {
  constructor(props) {
    super(props);
    try {
      this.showItemList = this.showItemList.bind(this);
      this.showUserBadgeList = this.showUserBadgeList.bind(this);
      this.handleBadgeSelectionChanged = this.handleBadgeSelectionChanged.bind(
        this
      );
    } catch (error) {
      this.setState(() => {
        throw error;
      });
    }
  }

  showItemList(itemList) {
    try {
      //console.log('ItemList::', itemList);
      const itemListView = itemList.map(item => (
        <div key={item.itemNo} style={styles.itemName}>
          <p> - {item.itemName}</p>
          <div style={styles.selection}>
            <input
              type="checkbox"
              size="20"
              checked={item.checked}
              onChange={() => {
                const itemInfo = {
                  //doorNo: item.itemNo,
                  itemNo: item.itemNo,
                  checked: !item.checked
                };
                //console.log('Current Item:', item);
                this.props.toggleViewSelectionSubItem(itemInfo);
                // this.props.addMessage('System Settings Updated [ My Doors ]');
              }}
            />
          </div>
        </div>
      ));

      return itemListView;
    } catch (error) {
      this.setState(() => {
        throw error;
      });
    }
  }

  handleBadgeSelectionChanged(event) {
      try {
 
      this.props.setSelectedBadgeOfUser(
          this.props.badgesOfTheUser.find(
              badge => ((badge.Badge + "/" + badge.Facility) === event.target.value )
        )
          );//badge.Badge === parseInt(event.target.value, 10) 

      this.props.fetchAccessReaderListByBadge(
        this.props.tmpSelectedBadgeOfUser.Badge,
        this.props.tmpSelectedBadgeOfUser.Facility,
        true,true            
        
      );
    } catch (error) {
      this.setState(() => {
        throw error;
      });
    }
  }

  showUserBadgeList(badgesOfTheUser) {
    try {
      const badgesListOfTheUser = badgesOfTheUser.map((badge, index) => {
        if (badge && badge.Badge && this.props.tmpSelectedBadgeOfUser) {
          return (
            <option
              key={index}
                  style={styles.option}
                  value={badge.Badge + "/" + badge.Facility}
                 selected={_.isEqual(
                badge.Badge,
                  this.props.tmpSelectedBadgeOfUser.Badge
              ) && _.isEqual(badge.Facility, this.props.tmpSelectedBadgeOfUser.Facility)}
            >
              {badge.Badge}
            </option>
          );
        }
      });

      return (
        <select
          style={styles.selectionBox}
              onChange={this.handleBadgeSelectionChanged}
              value={this.props.tmpSelectedBadgeOfUser.Badge + "/" + this.props.tmpSelectedBadgeOfUser.Facility}
        >
          {badgesListOfTheUser}
        </select>
      );
    } catch (error) {
      this.setState(() => {
        throw error;
      });
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <div style={styles.container}>
          {this.props.itemList && this.props.badgesOfTheUser && (
            <div style={styles.badgeHolderLink}>
              Badge Holder's Link
              <div style={styles.row}>
                <p style={{ marginRight: 5 }}>Badge</p>
                {this.props.badgesOfTheUser &&
                  this.props.badgesOfTheUser.length > 0 &&
                  this.showUserBadgeList(this.props.badgesOfTheUser)}
                {this.props.badgesOfTheUser.length === 0 && (
                  <div>
                    <p>No Badge</p>
                  </div>
                )}
              </div>
              {this.props.tmpSelectedBadgeOfUser &&
              this.props.tmpSelectedBadgeOfUser.Badge && ( // if badge exists then facility also exits
                  <div style={styles.row}>
                    <p> Facility</p>
                    <p>{this.props.tmpSelectedBadgeOfUser.Facility}</p>
                  </div>
                )}
              {this.props.tmpSelectedBadgeOfUser &&
                this.props.tmpSelectedBadgeOfUser.FrstName && (
                  <div style={styles.row}>
                    <p> First Name</p>
                    <p>{this.props.tmpSelectedBadgeOfUser.FrstName}</p>
                  </div>
                )}
              {this.props.tmpSelectedBadgeOfUser &&
                this.props.tmpSelectedBadgeOfUser.LastName && (
                  <div style={styles.row}>
                    <p> Last Name</p>
                    <p>{this.props.tmpSelectedBadgeOfUser.LastName}</p>
                  </div>
                )}
            </div>
          )}
          <div style={styles.viewSelectionItemRow}>
            <div style={styles.nameRow}>
              <p> - {this.props.name}</p>
            </div>
            <div style={styles.selection}>
              <input
                type="checkbox"
                size="20"
                checked={this.props.selected}
                onChange={this.props.handleViewSelection}
              />
            </div>
          </div>
          {this.props.selected &&
            this.props.itemList &&
            this.props.tmpSelectedBadgeOfUser &&
            this.props.tmpSelectedBadgeOfUser.Badge && (
              <div>{this.showItemList(this.props.itemList)}</div>
            )}
        </div>
      </ErrorBoundary>
    );
  }
}

const styles = {
  container: {
    marginRight: 20,
    paddingLeft: 20,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  row: {
    marginTop: 4,
    padding: 2,
    display: 'flex',
    height: 24,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  badgeHolderLink: {
    fontSize: 14,
    padding: 8,
    backgroundColor: Colors.VeryPaleBlue,
    height: 'auto',
    marginLeft: 40
  },
  selectionBox: {
    width: 180,
    height: 30
  },
  nameRow: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'left',
    flexDirection: 'column',
    fontSize: '0.90em'
  },
  viewSelectionItemRow: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    height: 'auto',
    justifyContent: 'space-between',
    marginRight: 20,
    paddingLeft: 40,
    flexDirection: 'row',
    backgroundColor: Colors.White
  },

  itemName: {
    display: 'flex',
    flex: 1,
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 20,
    margin: 2,
    marginLeft: 40,
    fontSize: 12,
    paddingLeft: 20,
    flexDirection: 'row',
    backgroundColor: Colors.VeryPaleBlue
  },
  selection: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 8,
    marginRight: 8,
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center'
  }
};

const mapStateToProps = state => ({
  tmpViewSelection: state.settings.tmpViewSelection,
  badgesOfTheUser: state.token.badgesOfTheUser,
  tmpSelectedBadgeOfUser: state.token.tmpSelectedBadgeOfUser,
  requestWirelessLockStatus: state.door.requestWirelessLockStatus
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleViewSelectionSubItem: actions.toggleViewSelectionSubItem,
      setSelectedBadgeOfUser: actions.setSelectedBadgeOfUser,
      //addMessage: actions.addMessage,
      fetchAccessReaderListByBadge: actions.fetchAccessReaderListByBadge,
      logError: actions.logError
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(ViewSelectionItemView)
);

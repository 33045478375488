import * as ActionTypes from './types';

export const logIN = (userInfo, baseURL) => ({
    type: ActionTypes.LOGIN,
    payload: { userInfo, baseURL }
});

export const setBaseURL = baseURL => ({
    type: ActionTypes.SET_BASE_URL,
    payload: baseURL
});

export const setUserInfo = (username,password) => ({
    type: ActionTypes.SET_USERINFO,
    payload: { username, password }
});

export const logOut = () => ({
    type: ActionTypes.LOGOUT
});

export const resetMessage = () => ({
    type: ActionTypes.RESET_MESSAGE
});

export const resetErrorMessage = () => ({
    type: ActionTypes.RESET_ERROR_MESSAGE
});

export const getCurrentPageName = () => ({
    type: ActionTypes.GET_CURRENT_PAGE_NAME
});

export const setCurrentPageName = currentPageName => ({
    type: ActionTypes.SET_CURRENT_PAGE_NAME,
    payload: currentPageName
});

export const fetchAllDoors = requestStatus => ({
    type: ActionTypes.FETCH_ALL_DOORS,
    payload: requestStatus
});

export const sendLockTheDoor = doorInfo => ({
    type: ActionTypes.SEND_DOOR_LOCK,
    payload: doorInfo
});

export const sendUnlockTheDoor = doorInfo => ({
    type: ActionTypes.SEND_DOOR_UNLOCK,
    payload: doorInfo
});

export const sendUnlockHoldTheDoor = doorInfo => ({
    type: ActionTypes.SEND_DOOR_UNLOCK_HOLD,
    payload: doorInfo
});

export const fetchAllThreatLevels = () => ({
    type: ActionTypes.FETCH_ALL_THREAT_LEVELS
});

export const sendActivateAllThreatLevels = () => ({
    type: ActionTypes.SEND_ACTIVATE_ALL_THREAT_LEVELS
});

export const sendDeactivateAllThreatLevels = () => ({
    type: ActionTypes.SEND_DEACTIVATE_ALL_THREAT_LEVELS
});

export const sendActivateTheThreatLevel = levelNo => ({
    type: ActionTypes.SEND_ACTIVATE_THE_THREAT_LEVEL,
    payload: levelNo
});

export const sendDeactivateTheThreatLevel = levelNo => ({
    type: ActionTypes.SEND_DEACTIVATE_THE_THREAT_LEVEL,
    payload: levelNo
});

export const fetchAllLockDownArea = () => ({
    type: ActionTypes.FETCH_ALL_LOCKDOWN_AREA
});

export const sendActivateAllLockdownAreas = () => ({
    type: ActionTypes.SEND_ACTIVATE_ALL_LOCKDOWN_AREA
});

export const sendDeactivateAllLockdownAreas = () => ({
    type: ActionTypes.SEND_DEACTIVATE_ALL_LOCKDOWN_AREA
});

export const sendActivateTheLockdownArea = areaNo => ({
    type: ActionTypes.SEND_ACTIVATE_THE_LOCKDOWN_AREA,
    payload: areaNo
});

export const sendDeactivateTheLockdownArea = areaNo => ({
    type: ActionTypes.SEND_DEACTIVATE_THE_LOCKDOWN_AREA,
    payload: areaNo
});

export const fetchAllRelays = requestStatus => ({
    type: ActionTypes.FETCH_ALL_RELAYS,
    payload: requestStatus
});

export const sendActivateRelay = relayInfo => ({
    type: ActionTypes.SEND_ACTIVATE_RELAY,
    payload: relayInfo
});

export const sendDeactivateRelay = relayInfo => ({
    type: ActionTypes.SEND_DEACTIVATE_RELAY,
    payload: relayInfo
});

export const sendActivateHoldRelay = relayInfo => ({
    type: ActionTypes.SEND_ACTIVATE_HOLD_RELAY,
    payload: relayInfo
});

export const fetchAllDeviceTypeList = () => ({
    type: ActionTypes.FETCH_ALL_DEVICE_TYPE
});

export const fetchDeviceTypeList = screenCode => ({
    type: ActionTypes.FETCH_DEVICE_TYPE_LIST,
    payload: screenCode
});

export const fetchDeviceStatusDetail = deviceType => ({
    type: ActionTypes.FETCH_DEVICE_STATUS_DETAIL,
    payload: deviceType
});

export const getSelectedDeviceType = selectedDeviceType => ({
    type: ActionTypes.GET_SELECTED_DEVICE_TYPE,
    payload: selectedDeviceType
});

export const resetSelectedDeviceType = () => ({
    type: ActionTypes.RESET_SELECTED_DEVICE_TYPE
});

export const getSelectedDevice = selectedDevice => ({
    type: ActionTypes.GET_SELECTED_DEVICE,
    payload: selectedDevice
});

export const getSelectedField = selectedField => ({
    type: ActionTypes.GET_SELECTED_FIELD,
    payload: selectedField
});

export const fetchAllBadgeHolderIn = () => ({
    type: ActionTypes.FETCH_ALL_BADGEHOLDERIN
});

export const fetchScheduleChangesList = () => ({
    type: ActionTypes.FETCH_SCHEDULE_CHANGES_LIST
});

export const deleteSchedule = schedule => ({
    type: ActionTypes.DELETE_SCHEDULE,
    payload: schedule
});

export const fetchTimeSchedules = () => ({
    type: ActionTypes.FETCH_TIME_SCHEDULES
});

export const fetchDeviceListByDeviceType = deviceType => ({
    type: ActionTypes.FETCH_DEVICE_LIST_BY_DEVICE_TYPE,
    payload: deviceType
});

export const fetchFieldListByDeviceType = deviceType => ({
    type: ActionTypes.FETCH_FIELD_LIST_BY_DEVICE_TYPE,
    payload: deviceType
});

export const createNewScheduleChanges = newScheduleChanges => ({
    type: ActionTypes.CREATE_NEW_SCHEDULE_CHANGES,
    payload: newScheduleChanges
});

export const resetNewScheduledChangesCreatedStatus = () => ({
    type: ActionTypes.RESET_NEW_SCHEDULE_CHANGES_SUCCEEDED
});

export const updateScheduleChanges = updatedScheduleChanges => ({
    type: ActionTypes.UPDATE_SCHEDULE_CHANGES,
    payload: updatedScheduleChanges
});

export const resetUpdateScheduledChangesCreatedStatus = () => ({
    type: ActionTypes.RESET_UPDATE_SCHEDULE_CHANGES_SUCCEEDED
});

export const fetchAllPersonnels = () => ({
    type: ActionTypes.FETCH_ALL_PERSONNELS
});

export const fetchAccessGroupList = () => ({
    type: ActionTypes.FETCH_ACCESS_GROUP_LIST
});

export const deletePersonnel = personnel => ({
    type: ActionTypes.DELETE_PERSONNEL,
    payload: personnel
});

export const changePersonnelPIN = PIN => ({
    type: ActionTypes.CHANGE_PERSONNEL_PIN,
    payload: PIN
});

export const createNewPersonnel = newPersonnel => ({
    type: ActionTypes.CREATE_NEW_PERSONNEL,
    payload: newPersonnel
});

export const resetNewPersonnalCreatedStatus = () => ({
    type: ActionTypes.RESET_NEW_PERSONNEL_CREATED_STATUS
});

export const updatePersonnel = updatedPersonnel => ({
    type: ActionTypes.UPDATE_PERSONNEL,
    payload: updatedPersonnel
});

export const resetPersonnalUpdatedStatus = () => ({
    type: ActionTypes.RESET_PERSONNEL_UPDATED_STATUS
});

export const setAppPIN = appPIN => ({
    type: ActionTypes.SET_APP_PIN,
    payload: appPIN
});

export const setServerIPAddress = ipAddress => ({
    type: ActionTypes.SET_SERVER_IP_ADDRESS,
    payload: ipAddress
});

export const setPortNo = portNo => ({
    type: ActionTypes.SET_PORT_NO,
    payload: portNo
});

export const setUser = username => ({
    type: ActionTypes.SET_USER,
    payload: username
});

export const setPassword = password => ({
    type: ActionTypes.SET_PASSWORD,
    payload: password
});

export const toggleMyDoorsMenu = toggle => ({
    type: ActionTypes.TOGGLE_MY_DOORS_MENU,
    payload: !toggle
});

export const toggleLockDownMenu = toggle => ({
    type: ActionTypes.TOGGLE_LOCKDOWN_MENU,
    payload: !toggle
});

export const toggleThreatLevelMenu = toggle => ({
    type: ActionTypes.TOGGLE_THREAT_LEVELS_MENU,
    payload: !toggle
});

export const toggleStatusMenu = toggle => ({
    type: ActionTypes.TOGGLE_STATUS_MENU,
    payload: !toggle
});

export const toggleControlMenu = toggle => ({
    type: ActionTypes.TOGGLE_CONTROL_MENU,
    payload: !toggle
});

export const togglePersonnelMenu = toggle => ({
    type: ActionTypes.TOGGLE_PERSONNEL_MENU,
    payload: !toggle
});

export const toggleScheduledChangesMenu = toggle => ({
    type: ActionTypes.TOGGLE_SCHEDULED_CHANGES_MENU,
    payload: !toggle
});

export const toggleBadgeHoldersInMenu = toggle => ({
    type: ActionTypes.TOGGLE_BADGEHOLDERSIN_MENU,
    payload: !toggle
});

export const setMsgDisplayTime = timeDuration => ({
    type: ActionTypes.SET_MSG_DISPLAY_TIME,
    payload: timeDuration
});

export const setMsgOptionUntilCleared = status => ({
    type: ActionTypes.SET_MSG_OPTION_UNTIL_CLEARED,
    payload: status
});

export const setInListRefreshTime = timeInSeconds => ({
    type: ActionTypes.SET_INLIST_REFRESH_TIME,
    payload: timeInSeconds
});

export const toggleKeepMyDoorsExpanded = toggle => ({
    type: ActionTypes.TOGGLE_KEEP_MYDOORS_EXPANDED,
    payload: !toggle
});

export const fetchPersonnel = personnelIdInfo => ({
    type: ActionTypes.FETCH_PERSONNEL,
    payload: personnelIdInfo
});

//export const sortPersonnels = personnels => ({
//    type: ActionTypes.SORT_PERSONNEL,
//    payload: personnels
//});

//export const sortFlagSetup = (sortBy, sortOrder) => ({
//    type: ActionTypes.SET_SORT_FLAGS,
//    sortOrder,
//    sortBy
//});

export const setSearchCriteria = (searchOption, searchText) => ({
    type: ActionTypes.SET_SEARCH_CRITERIA,
    payload: { searchOption, searchText }
});

export const addBadgeToPersonnel = badgeInfo => ({
    type: ActionTypes.ADD_BADGE_TO_PERSONNEL,
    payload: badgeInfo
});

export const resetNewBadgeAddedStatus = () => ({
    type: ActionTypes.RESET_NEW_BADGE_ADDED_TO_PERSONNEL_STATUS
});

export const toggleViewSelectionSubItem = itemInfo => ({
    type: ActionTypes.TOGGLE_VIEW_SELECTION_SUBITEM,
    payload: itemInfo
});

export const setBadgeInfo = badgeInfo => ({
    type: ActionTypes.SET_BADGE_INFO,
    payload: badgeInfo
});

export const postEvent = eventInfo => ({
    type: ActionTypes.POST_EVENT,
    payload: eventInfo
});

export const setCurrentOperatingDoor = doorInfo => ({
    type: ActionTypes.SET_CURRENT_OPERATING_DOOR,
    payload: doorInfo
});

export const fetchBadgesByPersonnelName = personnelName => ({
    type: ActionTypes.FETCH_BADGES_BY_PERSON_NAME,
    payload: personnelName
});

export const setSelectedBadgeOfUser = badgeInfo => ({
    type: ActionTypes.SET_SELECTED_BADGE_OF_USER,
    payload: badgeInfo
});

export const addMessage = message => ({
    type: ActionTypes.ADD_MESSAGE,
    payload: message
});

export const addAndShowMessage = message => ({
    type: ActionTypes.ADD_AND_SHOW_MESSAGE,
    payload: message
});

export const clearMessage = () => ({
    type: ActionTypes.CLEAR_MESSAGE
});

export const getRememberUserName = () => ({
    type: ActionTypes.GET_LOGIN_REMEMBER
});

export const closeLoaderModal = () => ({
    type: ActionTypes.CLOSE_LOADER_MODAL
});

export const changeTmpSettingsPin = val => ({
    type: ActionTypes.CHANGE_TMP_SETTINGS_PIN,
    payload: val
});

export const changeTmpAppEntryChecked = val => ({
    type: ActionTypes.CHANGE_TMP_APP_ENTRY_CHECKED,
    payload: val
});

export const changeAppEntrySession = val => ({
    type: ActionTypes.CHECK_APP_ENTRY_SESSION,
    payload: val
});

export const saveConfigSettings = val => ({
    type: ActionTypes.SAVE_CONFIG_SETTINGS,
    payload: val
});

export const cancelSaveConfigSettings = () => ({
    type: ActionTypes.CANCEL_SAVE_CONFIG_SETTINGS
});

export const resetInactivityTimer = () => ({
    type: ActionTypes.RESET_INACTIVITY_TIMER
});

export const changeSessionTimeOut = val => ({
    type: ActionTypes.CHANGE_SESSION_TIMEOUT,
    payload: val
});

export const storeAppConfigSettingsToLocalStorage = appConfigSettings => ({
    type: ActionTypes.STORE_APP_CONFIG_SETTINGS_TO_LOCAL_STORAGE,
    payload: appConfigSettings
});

export const loadAppConfigSettingsToLocalStorage = appConfigSettings => ({
    type: ActionTypes.LOAD_APP_CONFIG_SETTINGS_FROM_LOCAL_STORAGE,
    payload: appConfigSettings
});

export const setAppBaseURL = appBaseURL => ({
    type: ActionTypes.SET_APP_BASE_URL,
    payload: appBaseURL
});

export const fetchSystemSettings = () => ({
    type: ActionTypes.FETCH_SYSTEM_SETTINGS
});

export const setShowGeneralMessage = payload => ({
    type: ActionTypes.SHOW_GENERAL_MESSAGE,
    payload
});

export const hideGeneralMessage = () => ({
    type: ActionTypes.HIDE_GENERAL_MESSAGE
});

export const startInListRefresh = () => ({
    type: ActionTypes.START_IN_LIST_REFRESH
});

export const cancelInListRefresh = () => ({
    type: ActionTypes.CANCEL_IN_LIST_REFRESH
});

export const cancelActiveScheduleChanges = (caObjectId, keepSchedule) => ({
    type: ActionTypes.CANCEL_ACTIVE_SCHEDULE_CHANGES,
    payload: { caObjectId, keepSchedule }
});

export const checkIfBadgeExists = badgeInfo => ({
    type: ActionTypes.CHECK_BADGE_EXISTS,
    payload: badgeInfo
});

export const resetBadgeExistsFlag = () => ({
    type: ActionTypes.RESET_BADGE_EXIST_FLAG,
    payload: 0
});

export const resetLoadingFlag = () => ({
    type: ActionTypes.RESET_LOADING_FLAG
});

export const saveStateData = () => ({
    type: ActionTypes.SAVE_STATE_DATA
});

export const cancelStateData = () => ({
    type: ActionTypes.CANCEL_STATE_DATA
});

export const resetSaveStateData = () => ({
    type: ActionTypes.RESET_SAVE_STATE_DATA
});

export const configurationChanged = () => ({
    type: ActionTypes.CONFIGURATION_CHANGED
});

export const resetConfigurationChanged = () => ({
    type: ActionTypes.RESET_CONFIGURATION_CHANGED
});

export const resetCancelActiveScheduleChanges = () => ({
    type: ActionTypes.RESET_CANCEL_ACTIVE_SCHEDULE_CHANGES
});

export const fetchAccessReaderListByBadge = (
    badgeNo,
    facility,
    requestStatus,
    wlockRequestStatus
) => ({
    type: ActionTypes.FETCH_ACCESS_READER_LIST_BY_BADGE,
    payload: { badgeNo, facility, requestStatus, wlockRequestStatus }
});

export const refreshCurrentPage = () => ({
    type: ActionTypes.REFRESH_CURRENT_PAGE
});

export const hasMoreItems = () => ({
    type: ActionTypes.HAS_MORE_ITEMS
});

export const fetchBadgeListByPersonnelId = personnelId => ({
    type: ActionTypes.FETCH_BADGE_LIST_BY_PERSONNEL_ID,
    payload: personnelId
});

export const resetBadgeList = () => ({
    type: ActionTypes.RESET_BADGE_LIST
});

export const resetSinglePersonnel = () => ({
    type: ActionTypes.RESET_SINGLE_PERSONNEL
});

export const toggleRequestWirelessLockStatus = wlockRequestStatus => ({
    type: ActionTypes.TOGGLE_REQUEST_WIRELESS_LOCK_STATUS,
    payload: !wlockRequestStatus
});

export const setLastResetTime = () => ({
    type: ActionTypes.SET_LAST_RESET_TIME
});

export const refreshAppConfigSettings = () => ({
    type: ActionTypes.REFRESH_APP_CONFIG_SETTINGS
});

export const fetchPersonnelsCount = (filterOption, filterText) => ({
    type: ActionTypes.FETCH_PERSONNELS_COUNT,
    payload: { filterOption, filterText }
});

export const fetchPagedPersonnels = (
    pageIndex,
    sortBy,
    sortOrder,
    filterOption,
    filterText
) => ({
    type: ActionTypes.FETCH_PAGED_PERSONNELS,
    payload: { pageIndex, sortBy, sortOrder, filterOption, filterText }
});

export const resetPersonnel = () => ({
    type: ActionTypes.RESET_PERSONNEL
});

export const fetchDoorsCount = (requestStatus, filterText) => ({
    type: ActionTypes.FETCH_DOORS_COUNT,
    payload: { requestStatus, filterText }
});

export const fetchPagedDoors = (requestStatus, pageIndex, filterText) => ({
    type: ActionTypes.FETCH_PAGED_DOORS,
    payload: { requestStatus, pageIndex, filterText }
});

export const resetDoors = () => ({
    type: ActionTypes.RESET_DOORS
});

export const logError = (error) => ({
    type: ActionTypes.LOG_ERROR,
    payload: error
});

export const getConnectionStatus = () => ({
    type: ActionTypes.GET_CONNECTION_STATUS
});

export const showAlertMessage = () => ({
    type: ActionTypes.SHOW_ALERT_MESSAGE
});

export const checkSessionTimeOutStatus = () => ({
    type: ActionTypes.CHECK_SESSION_TIMEOUT_STATUS
});

export const checkIfPartitionExists = (currentOperatorId, screenDeviceType) => ({
    type: ActionTypes.CHECK_IF_PARTITION_EXISTS,
    payload: { currentOperatorId, screenDeviceType}
});

export const resetPartitionExistsStatus = () => ({
    type: ActionTypes.RESET_PARTITION_EXISTS_STATUS
});


export const sendUnlockMyDoor = doorInfo => ({
    type: ActionTypes.SEND_MY_DOOR_UNLOCK,
    payload: doorInfo
});


export const checkIfPINCodeEnabled = doorInfo => ({
    type: ActionTypes.CHECK_IF_PINCODE_ENABLED,
    payload: doorInfo
});

export const resetPINCodeEnabledStatus = () => ({
    type: ActionTypes.RESET_PINCODE_ENABLED_STATUS
});

export const setPINCodeEnabled = () => ({
    type: ActionTypes.SET_PINCODE_ENABLED
});

export const checkIfPINCommonCodeEnabled = (doorInfo) => ({
    type: ActionTypes.CHECK_IF_PIN_COMMONCODE_ENABLED,
    payload: doorInfo
});

export const resetCommonCodeEnabledStatus = () => ({
    type: ActionTypes.RESET_COMMONCODE_ENABLED_STATUS
});

export const updateBadgeInfoRequest = (updatedBadgeInfo) => ({
    type: ActionTypes.UPDATE_BAGDE_INFO_REQUEST,
    payload: updatedBadgeInfo
});


export const editBadgeNo = (oldBadgeNo, editedBadgeNo) => ({
    type: ActionTypes.EDIT_BADGE_NO,
    payload: { oldBadgeNo, editedBadgeNo}
});


export const editFacilityNo = (oldFacilityNo, editedFacilityNo) => ({
    type: ActionTypes.EDIT_FACILITY_NO,
    payload: { oldFacilityNo, editedFacilityNo }
});

export const setEditedBadgeInfo = (badgeInfo) => ({
    type: ActionTypes.SET_EDITED_BADGE_INFO,
    payload: badgeInfo
});

export const resetEditedBadgeInfo = () => ({
    type: ActionTypes.RESET_EDITED_BADG_INFO  
});


export const setBadgeLinked = (isBadgeLinked) => ({
    type: ActionTypes.SET_BADGE_LINKED,
    payload: isBadgeLinked === true ? true: false 
});

export const resetBadgeLinkedStatus = () => ({
    type: ActionTypes.RESET_BADGE_LINKED_STATUS
});


export const personnelPINViewDataChanged = () => ({
    type: ActionTypes.PERSONNEL_PINVIEW_DATA_CAHNGED
});

export const resetPersonnelPINViewDataChanged = () => ({
    type: ActionTypes.RESET_PERSONNEL_PINVIEW_DATA_CAHNGED
});


export const badgeAddedInDropDown = () => ({
    type: ActionTypes.NEW_BADGE_ADDED_IN_DROPDOWN
});


export const resetNewBadgeAddedInDropDown = () => ({
    type: ActionTypes.RESET_NEW_BADGE_ADDED_IN_DROPDOWN
})


export const resetPINCOmmonCodeNotSCheduled = () => ({
    type: ActionTypes.RESET_PIN_COOMONCOODE_NOT_SCHEDULED
})


export const resetBadgeValidStatus = () => ({
    type: ActionTypes.RESET_BADGE_VALID_STATUS
})

export const reEnterPIN = () => ({
    type: ActionTypes.RE_ENTER_PINCODE
})

export const resetReEnterPINCode = () => ({
    type: ActionTypes.RESET_RE_ENTER_PINCODE
})


export const changeOperatorPassword = (userName, oldPassword, newPassword, confirmPassword) => ({
    type: ActionTypes.CHANGE_OPERATOR_PASSWORD,
    payload: { userName, oldPassword, newPassword, confirmPassword}
})

export const resetChangeOperatorPasswordStatus = () => ({
    type: ActionTypes.RESET_CHANGE_OPERATOR_PASSWORD
})

export const cancelUnlockPressed = () => ({
    type: ActionTypes.CANCEL_UNLOCK_PRESSED
})

export const resetCancelUnlockPressed = () => ({
    type: ActionTypes.RESET_CANCEL_UNLOCK_PRESSED
})

export const resetPasswordRequiresUpdate = () => ({
    type: ActionTypes.RESET_PASSWORD_REQUIRES_UPDATE
})


export const changePasswordPressed = () => ({
    type: ActionTypes.CHANGE_PASSWORD_PRESSED
})

export const resetChangePasswordPressed = () => ({
    type: ActionTypes.RESET_CHANGE_PASSWORD_PRESSED
})

export const enableDebugLog = (isEnabled) => ({
    type: ActionTypes.ENABLE_DEBUG_LOG,
    payload: isEnabled
})

export const writeLog = (logData) => ({
    type: ActionTypes.WRITE_LOG,
    payload: logData
})

export const viewLog = () => ({
    type: ActionTypes.VIEW_LOG
})

export const hideLog = () => ({
    type: ActionTypes.HIDE_LOG
})

export const clearLog = () => ({
    type: ActionTypes.CLEAR_LOG
})


export const changeSearchTextAG1 = ( value) => ({
    type: ActionTypes.CHANGE_SEARCH_TEXT_AG1,
    payload: value
})
export const changeSearchTextAG2 = (value) => ({
    type: ActionTypes.CHANGE_SEARCH_TEXT_AG2,
    payload: value
})
export const changeSearchTextAG3 = (value) => ({
    type: ActionTypes.CHANGE_SEARCH_TEXT_AG3,
    payload: value
})
export const changeSearchTextAG4 = (value) => ({
    type: ActionTypes.CHANGE_SEARCH_TEXT_AG4,
    payload: value
})
export const changeSearchTextAG5 = (value) => ({
    type: ActionTypes.CHANGE_SEARCH_TEXT_AG5,
    payload: value
})
export const changeSearchTextAG6 = (value) => ({
    type: ActionTypes.CHANGE_SEARCH_TEXT_AG6,
    payload: value
})
export const changeSearchTextAG7 = (value) => ({
    type: ActionTypes.CHANGE_SEARCH_TEXT_AG7,
    payload: value
})
export const changeSearchTextAG8 = (value) => ({
    type: ActionTypes.CHANGE_SEARCH_TEXT_AG8,
    payload: value
})
export const changeSearchTextAG9 = (value) => ({
    type: ActionTypes.CHANGE_SEARCH_TEXT_AG9,
    payload: value
})
export const changeSearchTextAG10 = (value) => ({
    type: ActionTypes.CHANGE_SEARCH_TEXT_AG10,
    payload: value
})
export const changeSearchTextAG11 = (value) => ({
    type: ActionTypes.CHANGE_SEARCH_TEXT_AG11,
    payload: value
})
export const changeSearchTextAG12 = (value) => ({
    type: ActionTypes.CHANGE_SEARCH_TEXT_AG12,
    payload: value
})

export const changeSearchTextAG13 = (value) => ({
    type: ActionTypes.CHANGE_SEARCH_TEXT_AG13,
    payload: value
})

export const changeSearchTextAG14 = (value) => ({
    type: ActionTypes.CHANGE_SEARCH_TEXT_AG14,
    payload: value
})

export const changeSearchTextAG15 = (value) => ({
    type: ActionTypes.CHANGE_SEARCH_TEXT_AG15,
    payload: value
})

export const changeSearchTextAG16 = (value) => ({
    type: ActionTypes.CHANGE_SEARCH_TEXT_AG16,
    payload: value
})

export const resetSearchTextAllAG = () => ({
    type: ActionTypes.RESET_SEARCH_TEXT_ALL_AG
})

export const checkPriviledges = (priviledges, roleId) => ({
    type: ActionTypes.CHECK_PRIVILEDGES,
    payload: { priviledges, roleId}
})
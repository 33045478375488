import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { Link, Redirect } from 'react-router-dom';
import * as Colors from '../../consts/colors';
import ButtonView from '../common/ButtonView';
import PINView from '../common/PINView';
import ModalView from '../common/ModalView';
import ModalProgressView from '../common/ModalProgressView';
import * as actions from '../../actions/index';
import { PAGE_NAMES, MAX_FACILITY_CODE, SCREEN_DEVICE_TYPES } from '../../consts/consts';
import * as Messages from '../../consts/messages'

class PersonnelAddEditView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseButtonPressed: false,
            dataEntered: false,
            showModal: false,
            firstName: '',
            lastName: '',
            enable: true,
            pin: '',
            facilityNo: '',
            badgeNo: '',
            badgeList: [],
            selectedCredentialNo: '',
            selectedFacilityNo: '',
            selectedIndexOfBadge: 0,
            selectedAccessGroup1Value: 0,
            selectedAccessGroup2Value: 0,
            selectedAccessGroup3Value: 0,
            selectedAccessGroup4Value: 0,
            selectedAccessGroup5Value: 0,
            selectedAccessGroup6Value: 0,
            selectedAccessGroup7Value: 0,
            selectedAccessGroup8Value: 0,
            selectedAccessGroup9Value: 0,
            selectedAccessGroup10Value: 0,
            selectedAccessGroup11Value: 0,
            selectedAccessGroup12Value: 0,
            selectedAccessGroup13Value: 0,
            selectedAccessGroup14Value: 0,
            selectedAccessGroup15Value: 0,
            selectedAccessGroup16Value: 0,
            newAddedBadge: {}
        };

        this.txtPINCode = React.createRef();

        this.checkIfDuplicateAccessGroupSelected = this.checkIfDuplicateAccessGroupSelected.bind(
            this
        );

        this.handleCredentialFacilitySelectionChanged = this.handleCredentialFacilitySelectionChanged.bind(
            this
        );
        this.handlePINCodeEntryChanged = this.handlePINCodeEntryChanged.bind(this);
        this.handleCancelButtonPressed = this.handleCancelButtonPressed.bind(this);
        this.handleFirstNameChanged = this.handleFirstNameChanged.bind(this);
        this.handleLastNameChanged = this.handleLastNameChanged.bind(this);
        this.showAccessGroupsList1 = this.showAccessGroupsList1.bind(this);
        this.showBadgeList = this.showBadgeList.bind(this);
        this.showPartitionGroupList = this.showPartitionGroupList.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.handleAddButtonClicked = this.handleAddButtonClicked.bind(this);
        this.handleAddButtonInModalClicked = this.handleAddButtonInModalClicked.bind(
            this
        );
      

        this.handleCredentialNoChanged = this.handleCredentialNoChanged.bind(this);
        this.handleFacilityNoChanged = this.handleFacilityNoChanged.bind(this);

        this.refAG1 = React.createRef();
        this.refAG2 = React.createRef();
        this.refAG3 = React.createRef();
        this.refAG4 = React.createRef();
        this.refAG5 = React.createRef();
        this.refAG6 = React.createRef();
        this.refAG7 = React.createRef();
        this.refAG8 = React.createRef();
        this.refAG9 = React.createRef();
        this.refAG10 = React.createRef();
        this.refAG11 = React.createRef();
        this.refAG12 = React.createRef();
        this.refAG13 = React.createRef();
        this.refAG14 = React.createRef();
        this.refAG15 = React.createRef();
        this.refAG16 = React.createRef();

        this.handleAccessGroup1SelectionChanged = this.handleAccessGroup1SelectionChanged.bind(
            this
        );
        this.handleAccessGroup2SelectionChanged = this.handleAccessGroup2SelectionChanged.bind(
            this
        );

        this.handleAccessGroup3SelectionChanged = this.handleAccessGroup3SelectionChanged.bind(
            this
        );

        this.handleAccessGroup3SelectionChanged = this.handleAccessGroup3SelectionChanged.bind(
            this
        );

        this.handleAccessGroup4SelectionChanged = this.handleAccessGroup4SelectionChanged.bind(
            this
        );
        this.handleAccessGroup5SelectionChanged = this.handleAccessGroup5SelectionChanged.bind(
            this
        );
        this.handleAccessGroup6SelectionChanged = this.handleAccessGroup6SelectionChanged.bind(
            this
        );
        this.handleAccessGroup7SelectionChanged = this.handleAccessGroup7SelectionChanged.bind(
            this
        );
        this.handleAccessGroup8SelectionChanged = this.handleAccessGroup8SelectionChanged.bind(
            this
        );
        this.handleAccessGroup9SelectionChanged = this.handleAccessGroup9SelectionChanged.bind(
            this
        );
        this.handleAccessGroup10SelectionChanged = this.handleAccessGroup10SelectionChanged.bind(
            this
        );
        this.handleAccessGroup11SelectionChanged = this.handleAccessGroup11SelectionChanged.bind(
            this
        );
        this.handleAccessGroup12SelectionChanged = this.handleAccessGroup12SelectionChanged.bind(
            this
        );
        this.handleAccessGroup13SelectionChanged = this.handleAccessGroup13SelectionChanged.bind(
            this
        );
        this.handleAccessGroup14SelectionChanged = this.handleAccessGroup14SelectionChanged.bind(
            this
        );
        this.handleAccessGroup15SelectionChanged = this.handleAccessGroup15SelectionChanged.bind(
            this
        );
        this.handleAccessGroup16SelectionChanged = this.handleAccessGroup16SelectionChanged.bind(
            this
        );

        this.props.fetchSystemSettings();
        this.props.resetPartitionExistsStatus();
        this.props.fetchAccessGroupList();
        this.props.setCurrentPageName(PAGE_NAMES.ADD_PERSONNEL);
        this.props.changePersonnelPIN('');
        this.props.resetMessage();
        this.props.checkIfPartitionExists(this.props.operatorId, SCREEN_DEVICE_TYPES.PERSONNEL);
        this.props.resetSearchTextAllAG();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isHavingPartition === false) {
        alert("Sorry, you dont haveGeneric or this device specific partition");
          return { ...state, collapseButtonPressed: true, dataEntered: false };
        }

        return null;
    }

    componentWillUnmount() {
        this.setState({
            ...this.state,
            collapseButtonPressed: false,
            dataEntered: false
        });
    }


    handlePINCodeEntryChanged(event) {
        if (this.props.keypadOption === 2 && (event.target.value.length >= 9 || event.target.length === 0)) {
            event.preventDefault();
        }
        else if (this.props.keypadOption === 1 && (event.target.value.length >= 4 || event.target.length === 0)) {
            event.preventDefault();
        }

        this.props.changePersonnelPIN(event.target.value);
   
    }

    onCloseModal() {
        this.setState({ ...this.state, showModal: false });
    }

    checkIfDuplicateAccessGroupSelected(selectedAccessGroupValue) {
        if (selectedAccessGroupValue === 0) {
            return false;
        }

        if (
            selectedAccessGroupValue === this.state.selectedAccessGroup1Value ||
            selectedAccessGroupValue === this.state.selectedAccessGroup2Value ||
            selectedAccessGroupValue === this.state.selectedAccessGroup3Value ||
            selectedAccessGroupValue === this.state.selectedAccessGroup4Value ||
            selectedAccessGroupValue === this.state.selectedAccessGroup5Value ||
            selectedAccessGroupValue === this.state.selectedAccessGroup6Value ||
            selectedAccessGroupValue === this.state.selectedAccessGroup7Value ||
            selectedAccessGroupValue === this.state.selectedAccessGroup8Value ||
            selectedAccessGroupValue === this.state.selectedAccessGroup9Value ||
            selectedAccessGroupValue === this.state.selectedAccessGroup10Value ||
            selectedAccessGroupValue === this.state.selectedAccessGroup11Value ||
            selectedAccessGroupValue === this.state.selectedAccessGroup12Value ||
            selectedAccessGroupValue === this.state.selectedAccessGroup13Value ||
            selectedAccessGroupValue === this.state.selectedAccessGroup14Value ||
            selectedAccessGroupValue === this.state.selectedAccessGroup15Value ||
            selectedAccessGroupValue === this.state.selectedAccessGroup16Value
        ) {
            return true;
        }

        return false;
    }

    handleCancelButtonPressed() { }

    handleCredentialFacilitySelectionChanged(event) {
        const index = this.state.badgeList.findIndex(
            x =>
                x.credentialNo.trim() === event.target.value.split('/')[0].trim() &&
                x.facilityNo.trim() === event.target.value.split('/')[1].trim()
        );

        this.setState({
            ...this.state,
            selectedIndexOfBadge: index,
            selectedCredentialNo: this.state.badgeList[index].credentialNo,
            selectedFacilityNo: this.state.badgeList[index].facilityNo,
            badgeNo: this.state.badgeList[index].credentialNo,
            facilityNo: this.state.badgeList[index].facilityNo
        });
    }

    handleFirstNameChanged(event) {
        this.setState({
            ...this.state,
            firstName: event.target.value,
            dataEntered: true
        });
    }

    handleLastNameChanged(event) {
        this.setState({
            ...this.state,
            lastName: event.target.value,
            dataEntered: true
        });
    }

    showBadgeList(badgeList) {
        const badgeNoList = badgeList.map(badge => (
            <option style={styles.option}>
                {badge.credentialNo} / {badge.facilityNo}
            </option>
        ));
        return (
            <select
                className="select-css"
                onChange={this.handleCredentialFacilitySelectionChanged}
            >
                {badgeNoList}
            </select>
        );
    }

    showPartitionGroupList(partitionGroupList) {
        const partitionGroups = partitionGroupList.map(partitionGroup => (
            <option style={styles.option}>{partitionGroup.partitionName}</option>
        ));
        return <select className="select-css">{partitionGroups}</select>;
    }

    showAccessGroupsList1(accessGroupList) {

        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG1 !== 'undefined' && this.props.searchTextAG1 !== null && this.props.searchTextAG1.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG1));
           
        }

        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value} >{accessGroup.name}</option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG1}
                className="select-css"
                onChange={this.handleAccessGroup1SelectionChanged}

            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }
      
        return (
            <select
                ref={this.refAG1}
                className="select-css"
                onChange={this.handleAccessGroup1SelectionChanged}
                
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList2(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG2 !== 'undefined' && this.props.searchTextAG2 !== null && this.props.searchTextAG2.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG2));
        }
    
        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value} >{accessGroup.name}</option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG2}
                className="select-css"
                onChange={this.handleAccessGroup2SelectionChanged}

            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }

        return (
            <select
                ref={this.refAG2}
                className="select-css"
                onChange={this.handleAccessGroup2SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList3(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;

        if (typeof this.props.searchTextAG3 !== 'undefined' && this.props.searchTextAG3 !== null && this.props.searchTextAG3.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG3));
        }

        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>{accessGroup.name}</option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG3}
                className="select-css"
                onChange={this.handleAccessGroup3SelectionChanged}

            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }

        return (
            <select
                ref={this.refAG3}
                className="select-css"
                onChange={this.handleAccessGroup3SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList4(accessGroupList) {

        let filteredAccessGrpList = accessGroupList;

        if (typeof this.props.searchTextAG4 !== 'undefined' && this.props.searchTextAG4 !== null && this.props.searchTextAG4.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG4));
        }

        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>{accessGroup.name}</option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG4}
                className="select-css"
                onChange={this.handleAccessGroup4SelectionChanged}

            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }

        return (
            <select
                ref={this.refAG4}
                className="select-css"
                onChange={this.handleAccessGroup4SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList5(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;

        if (typeof this.props.searchTextAG5 !== 'undefined' && this.props.searchTextAG5 !== null && this.props.searchTextAG5.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG5));
        }
        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>{accessGroup.name}</option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG5}
                className="select-css"
                onChange={this.handleAccessGroup5SelectionChanged}

            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }

        return (
            <select
                ref={this.refAG5}
                className="select-css"
                onChange={this.handleAccessGroup5SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList6(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG6 !== 'undefined' && this.props.searchTextAG6 !== null && this.props.searchTextAG6.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG6));
        }

        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>{accessGroup.name}</option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG6}
                className="select-css"
                onChange={this.handleAccessGroup6SelectionChanged}

            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }

        return (
            <select
                ref={this.refAG6}
                className="select-css"
                onChange={this.handleAccessGroup6SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList7(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG7 !== 'undefined' && this.props.searchTextAG7 !== null && this.props.searchTextAG7.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG7));
        }

        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>{accessGroup.name}</option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG7}
                className="select-css"
                onChange={this.handleAccessGroup7SelectionChanged}

            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }

        return (
            <select
                ref={this.refAG7}
                className="select-css"
                onChange={this.handleAccessGroup7SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList8(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG8 !== 'undefined' && this.props.searchTextAG8 !== null && this.props.searchTextAG8.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG8));
        }
        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>{accessGroup.name}</option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG8}
                className="select-css"
                onChange={this.handleAccessGroup8SelectionChanged}

            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }

        return (
            <select
                ref={this.refAG8}
                className="select-css"
                onChange={this.handleAccessGroup8SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList9(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG9 !== 'undefined' && this.props.searchTextAG9 !== null && this.props.searchTextAG9.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG9));
        }
        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>{accessGroup.name}</option>
        ));


        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG9}
                className="select-css"
                onChange={this.handleAccessGroup9SelectionChanged}

            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }

        return (
            <select
                ref={this.refAG9}
                className="select-css"
                onChange={this.handleAccessGroup9SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList10(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG10 !== 'undefined' && this.props.searchTextAG10 !== null && this.props.searchTextAG10.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG10));
        }
        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>{accessGroup.name}</option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG10}
                className="select-css"
                onChange={this.handleAccessGroup10SelectionChanged}

            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }

        return (
            <select
                ref={this.refAG10}
                className="select-css"
                onChange={this.handleAccessGroup10SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList11(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG11 !== 'undefined' && this.props.searchTextAG11 !== null && this.props.searchTextAG11.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG11));
        }
        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>{accessGroup.name}</option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG11}
                className="select-css"
                onChange={this.handleAccessGroup11SelectionChanged}

            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }


        return (
            <select
                ref={this.refAG11}
                className="select-css"
                onChange={this.handleAccessGroup11SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList12(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG12 !== 'undefined' && this.props.searchTextAG12 !== null && this.props.searchTextAG12.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG12));
        }
        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>{accessGroup.name}</option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG12}
                className="select-css"
                onChange={this.handleAccessGroup12SelectionChanged}

            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }

        return (
            <select
                ref={this.refAG12}
                className="select-css"
                onChange={this.handleAccessGroup12SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList13(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG13 !== 'undefined' && this.props.searchTextAG13 !== null && this.props.searchTextAG13.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG13));
        }
        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>{accessGroup.name}</option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG13}
                className="select-css"
                onChange={this.handleAccessGroup13SelectionChanged}

            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }
        return (
            <select
                ref={this.refAG13}
                className="select-css"
                onChange={this.handleAccessGroup13SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList14(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG14 !== 'undefined' && this.props.searchTextAG14 !== null && this.props.searchTextAG14.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG14));
        }
        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>{accessGroup.name}</option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG14}
                className="select-css"
                onChange={this.handleAccessGroup14SelectionChanged}

            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }


        return (
            <select
                ref={this.refAG14}
                className="select-css"
                onChange={this.handleAccessGroup14SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList15(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG15 !== 'undefined' && this.props.searchTextAG15 !== null && this.props.searchTextAG15.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG15));
        }
        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>{accessGroup.name}</option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG15}
                className="select-css"
                onChange={this.handleAccessGroup15SelectionChanged}

            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }


        return (
            <select
                ref={this.refAG15}
                className="select-css"
                onChange={this.handleAccessGroup15SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList16(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG16 !== 'undefined' && this.props.searchTextAG16 !== null && this.props.searchTextAG16.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG16));
        }
        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>{accessGroup.name}</option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG16}
                className="select-css"
                onChange={this.handleAccessGroup16SelectionChanged}

            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }


        return (
            <select
                ref={this.refAG16}
                className="select-css"
                onChange={this.handleAccessGroup16SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }

    handleAddButtonClicked() {
        this.setState({
            ...this.state,
            showModal: true
        });
    }

    handleAddButtonInModalClicked() {
        if (
            this.state.newAddedBadge.credentialNo.length === 0 ||
            this.state.newAddedBadge.credentialNo <= 0
        ) {
            alert('Please enter Badge No.');
            return;
        }

        if (
            this.state.newAddedBadge.facilityNo.length === 0 ||
            this.state.newAddedBadge.facilityNo < 0 ||
            this.state.newAddedBadge.facilityNo > 10
        ) {
            alert('Please enter Facility No.');
            return;
        }

        const alreadyHaveBadge = this.props.personnels.find(
            personnel => personnel.badge === this.state.newAddedBadge.credentialNo
        );

        if (
            alreadyHaveBadge &&
            alreadyHaveBadge.badge &&
            alreadyHaveBadge.badge.length > 0
        ) {
            alert('Badge Already Exists!');
            this.setState({ ...this.state, newAddedBadge: {} });
            return;
        }

        let lastSerialNo;
        const badgeList = this.state.badgeList;
        if (badgeList.length === 0) lastSerialNo = 0;
        else lastSerialNo = badgeList[badgeList.length - 1].serialNo;
        badgeList.push({
            serialNo: parseInt(lastSerialNo, 10) + 1,
            ...this.state.newAddedBadge
        });

        console.log('badgelist', badgeList[0].credentialNo);

        if (badgeList.length === 1) {
            console.log('badgelist in', badgeList[0]);

            const badgeNo = badgeList[0].credentialNo;
            const facilityNo = badgeList[0].facilityNo;

            this.setState({
                ...this.state,
                showModal: false,
                newAddedBadge: {},
                badgeNo,
                facilityNo,
                badgeList
            });
            console.log('state:-', this.state);
        } else {
            this.setState({
                ...this.state,
                showModal: false,
                newAddedBadge: {},
                badgeList
            });
        }

        //  this.onCloseModal();
    }

    handleAccessGroup1SelectionChanged(event) {

       
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value)
        )[0];
       
        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  1 :',
                selectedAccessGroup.value
            );

            this.setState({
                ...this.state,
                selectedAccessGroup1Value: selectedAccessGroup.value,
                dataEntered: true
            });
        }
    }
    handleAccessGroup2SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  2 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup2Value: selectedAccessGroup.value,
                dataEntered: true
            });
        }
    }

    handleAccessGroup3SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value)
        )[0];
        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  3 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup3Value: selectedAccessGroup.value,
                dataEntered: true
            });
        }
    }

    handleAccessGroup4SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  4 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup4Value: selectedAccessGroup.value,
                dataEntered: true
            });
        }
    }

    handleAccessGroup5SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value)
        )[0];
        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  5 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup5Value: selectedAccessGroup.value,
                dataEntered: true
            });
        }
    }

    handleAccessGroup6SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  6 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup6Value: selectedAccessGroup.value,
                dataEntered: true
            });
        }
    }

    handleAccessGroup7SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  7 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup7Value: selectedAccessGroup.value,
                dataEntered: true
            });
        }
    }

    handleAccessGroup8SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value)
        )[0];
        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  8 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup8Value: selectedAccessGroup.value,
                dataEntered: true
            });
        }
    }

    handleAccessGroup9SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value)
        )[0];
        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  9 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup9Value: selectedAccessGroup.value,
                dataEntered: true
            });
        }
    }

    handleAccessGroup10SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt( event.target.value)
        )[0];
        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  10 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup10Value: selectedAccessGroup.value,
                dataEntered: true
            });
        }
    }

    handleAccessGroup11SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  11 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup11Value: selectedAccessGroup.value,
                dataEntered: true
            });
        }
    }

    handleAccessGroup12SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  12 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup12Value: selectedAccessGroup.value,
                dataEntered: true
            });
        }
    }

    handleAccessGroup13SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt( event.target.value)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  13 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup13Value: selectedAccessGroup.value,
                dataEntered: true
            });
        }
    }

    handleAccessGroup14SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  14 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup14Value: selectedAccessGroup.value,
                dataEntered: true
            });
        }
    }

    handleAccessGroup15SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt( event.target.value)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  15 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup15Value: selectedAccessGroup.value,
                dataEntered: true
            });
        }
    }

    handleAccessGroup16SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  16 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup16Value: selectedAccessGroup.value,
                dataEntered: true
            });
        }
    }

    handleCredentialNoChanged(event) {
        if (event.target.value.length <= this.props.sysBadgeLen) {
            this.setState({
                ...this.state,
                newAddedBadge: {
                    ...this.state.newAddedBadge,
                    credentialNo: event.target.value
                },
                dataEntered: true
            });
        } else {
            alert('Badge Number is too large or invalid.');
            event.target.value = '';
            return;
        }
    }

    handleFacilityNoChanged(event) {
        if (parseInt(event.target.value, 10) <= MAX_FACILITY_CODE) {
            this.setState({
                ...this.state,
                newAddedBadge: {
                    ...this.state.newAddedBadge,
                    facilityNo: event.target.value
                },
                dataEntered: true
            });
        } else {
            alert('Facility NO can not be more than 10.');
            event.target.value = '';
            return;
        }
    }

    render() {
        if (this.props.personnelCreated === true) {
            this.props.resetNewPersonnalCreatedStatus();
            this.setState({ ...this.state, dataEntered: false });
            return (
                <Redirect
                    to={{
                        pathname: '/personnel',
                        state: {
                            currentIndexPosition:
                                this.props && this.props.currentIndexPosition
                                    ? this.props.currentIndexPosition + 1
                                    : 0
                        }
                    }}
                />
            );
        }

        if (this.state.collapseButtonPressed === true) {
            if (this.state.dataEntered === true) {
                alert('Press Save button to save the changes or Cancel to discard.');
                this.setState({ ...this.state, collapseButtonPressed: false });
            } else return <Redirect to="/personnel" />;
        }

        if (this.props.msg && this.props.msg.length> 0) {
            alert(this.props.msg);
            this.props.resetMessage();
        }

        return (
            <div style={styles.container}>
                <div style={styles.expandContainer}>
                    <div
                        onClick={() => {
                            this.setState({ ...this.state, collapseButtonPressed: true });
                        }}
                    >
                        <FontAwesomeIcon icon={faMinus} size="1x" color={Colors.White} />
                    </div>

                    <div style={styles.functionText}>Personnel</div>
                </div>
               
                <div style={styles.blackLine} />
                <div style={styles.expandableArea}>
                 
                    <div style={styles.buttonContainer}>
                        
                        <ButtonView
                            label="Save"
                            instyle={styles.save}
                            onPress={event => {
                                event.preventDefault();
                                const selectedAG1Value = this.refAG1.current && this.refAG1.current.value ? this.refAG1.current.value : 0;
                                const selectedAG2Value = this.refAG2.current && this.refAG2.current.value ? this.refAG2.current.value : 0;
                                const selectedAG3Value = this.refAG3.current && this.refAG3.current.value ?this.refAG3.current.value:0 ;
                                const selectedAG4Value = this.refAG4.current && this.refAG4.current.value? this.refAG4.current.value : 0;
                                const selectedAG5Value = this.refAG5.current && this.refAG5.current.value ?this.refAG5.current.valu: 0;
                                const selectedAG6Value = this.refAG6.current && this.refAG6.current.value ? this.refAG6.current.value: 0;
                                const selectedAG7Value = this.refAG7.current && this.refAG7.current.value ?this.refAG7.current.value: 0;
                                const selectedAG8Value = this.refAG8.current && this.refAG8.current.value ?this.refAG8.current.value:0;
                                const selectedAG9Value = this.refAG9.current && this.refAG9.current.value ?this.refAG9.current.value:0;
                                const selectedAG10Value = this.refAG10.current && this.refAG10.current.value ?this.refAG10.current.value:0;
                                const selectedAG11Value = this.refAG11.current && this.refAG11.current.value ?this.refAG11.current.value:0;
                                const selectedAG12Value = this.refAG12.current && this.refAG12.current.value ?this.refAG12.current.value:0;
                                const selectedAG13Value = this.refAG13.current && this.refAG13.current.value ?this.refAG13.current.value:0;
                                const selectedAG14Value = this.refAG14.current && this.refAG14.current.value ?this.refAG14.current.value:0;
                                const selectedAG15Value = this.refAG15.current && this.refAG15.current.value ?this.refAG15.current.value:0;
                                const selectedAG16Value = this.refAG16.current && this.refAG16.current.value ?this.refAG16.current.value:0;
                              

                                this.setState(
                                    { ...this.state, pin: this.props.personnelPIN, selectedAccessGroup1Value: selectedAG1Value,
                                        selectedAccessGroup2Value: selectedAG2Value,
                                        selectedAccessGroup3Value: selectedAG3Value,
                                        selectedAccessGroup4Value: selectedAG4Value,
                                        selectedAccessGroup5Value: selectedAG5Value,
                                        selectedAccessGroup6Value: selectedAG6Value,
                                        selectedAccessGroup7Value: selectedAG7Value,
                                        selectedAccessGroup8Value: selectedAG8Value,
                                        selectedAccessGroup9Value: selectedAG9Value,
                                        selectedAccessGroup10Value: selectedAG10Value,
                                        selectedAccessGroup11Value: selectedAG11Value,
                                        selectedAccessGroup12Value: selectedAG12Value,
                                        selectedAccessGroup13Value: selectedAG13Value,
                                        selectedAccessGroup14Value: selectedAG14Value,
                                        selectedAccessGroup15Value: selectedAG15Value,
                                        selectedAccessGroup16Value: selectedAG16Value
                                    
                                    },
                                    () => {
                                        if (this.state.firstName.length === 0) {
                                            alert('Please enter First Name.');
                                            return;
                                        }

                                        if (this.state.lastName.length === 0) {
                                            alert('Please enter Last Name.');
                                            return;
                                        }

                                        if (this.state.badgeNo.length === 0) {
                                            alert('Please enter Badge No.');
                                            return;
                                        }

                                        if (this.state.facilityNo.length === 0) {
                                            alert('Please enter Facility No.');
                                            return;
                                        }

                                        
                                       
                                        
                                        console.log('SelectedAG1Value:', selectedAG1Value);

                                        const newPersonnel = {
                                            enable: this.state.enable,
                                            firstName: this.state.firstName,
                                            lastName: this.state.lastName,
                                            pin: this.state.pin,
                                            badge: this.state.badgeNo,
                                            facility: this.state.facilityNo,
                                            agNo1: this.state.selectedAccessGroup1Value,
                                            agNo2: this.state.selectedAccessGroup2Value,
                                            agNo3: this.state.selectedAccessGroup3Value,
                                            agNo4: this.state.selectedAccessGroup4Value,
                                            agNo5: this.state.selectedAccessGroup5Value,
                                            agNo6: this.state.selectedAccessGroup6Value,
                                            agNo7: this.state.selectedAccessGroup7Value,
                                            agNo8: this.state.selectedAccessGroup8Value,
                                            agNo9: this.state.selectedAccessGroup9Value,
                                            agNo10: this.state.selectedAccessGroup10Value,
                                            agNo11: this.state.selectedAccessGroup11Value,
                                            agNo12: this.state.selectedAccessGroup12Value,
                                            agNo13: this.state.selectedAccessGroup13Value,
                                            agNo14: this.state.selectedAccessGroup14Value,
                                            agNo15: this.state.selectedAccessGroup15Value,
                                            agNo16: this.state.selectedAccessGroup16Value
                                        };

                                        this.props.writeLog('OperatorID: ' + this.props.operatorId + "PersonnelAddView --> SaveNewPersonnel--> newPersonnel : " + JSON.stringify(newPersonnel));
                                        this.props.createNewPersonnel(newPersonnel);
                                    }
                                );
                            }}
                        />
                        <div style={{ marginLeft: 20 }}>
                            <Link
                                style={styles.underlineless}
                                to={{
                                    pathname: '/personnel',
                                    state: {
                                        currentIndexPosition:
                                            this.props && this.props.currentIndexPosition
                                                ? this.props.currentIndexPosition
                                                : 0
                                    }
                                }}
                            >
                                <ButtonView label="Cancel" instyle={styles.cancel} />
                            </Link>
                        </div>
                    </div>
                    <div style={styles.nameWithEnabled}>
                        <div style={styles.namePortion}>
                            <div style={styles.nameRow}>
                                <p> First:</p>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={this.state.firstName}
                                    onChange={this.handleFirstNameChanged}
                                    style={styles.firstName}
                                />
                            </div>

                            <div style={styles.nameRow}>
                                <p>Last:</p>
                                <input
                                    type="text"
                                    name="lasttName"
                                    value={this.state.lastName}
                                    onChange={this.handleLastNameChanged}
                                    style={styles.lastName}
                                />
                            </div>
                        </div>
                        <div style={styles.enabledRow}>
                            <p>Enabled</p>
                            <input
                                style={styles.enabled}
                                checked={this.state.enable}
                                value={this.state.enable}
                                type="checkbox"
                                onChange={() => {
                                    this.setState({
                                        ...this.state,
                                        enable: !this.state.enable,
                                        dataEntered: true
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div style={styles.badgeNoListLabel}>
                        <p>Badge No/Facilty No:</p>
                        {this.state.badgeList.length === 0 && (
                            <div
                                style={styles.addBadge}
                                onClick={this.handleAddButtonClicked}
                            >
                                Add Badge +
              </div>
                        )}
                    </div>
                    <div style={styles.badgeNoListContainer}>
                        {this.showBadgeList(this.state.badgeList)}
                    </div>

                    {this.state.showModal === true && (
                        <ModalView
                            onClose={this.onCloseModal}
                            dialogStyle={styles.dialogStyle}
                            shouldCloseOnOverlayClick
                        >
                            <div
                                style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                            >
                                <div
                                    style={{
                                        fontSize: 18,
                                        flex: 1,
                                        backgroundColor: Colors.Green,
                                        flexDirection: 'row',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        fontWeight: 'bold',
                                        padding: 5,
                                        color: Colors.White
                                    }}
                                >
                                    Add New Badge
                </div>
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <p style={{ fontSize: 14 }}> Badge No:</p>
                                    <input
                                        type="number"
                                        name="txtCredentialNo"
                                        onChange={this.handleCredentialNoChanged}
                                        style={{
                                            width: 140,
                                            height: 20,
                                            marginLeft: 5,
                                            backgroundColor: Colors.VeryPaleCyan
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <p style={{ fontSize: 14 }}>Facility No:</p>
                                    <input
                                        type="number"
                                        name="txtFacilityNo"
                                        onChange={this.handleFacilityNoChanged}
                                        style={{
                                            width: 140,
                                            height: 20,
                                            marginLeft: 5,
                                            backgroundColor: Colors.VeryPaleCyan
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <ButtonView
                                        label="Cancel"
                                        onPress={() => {
                                            this.onCloseModal();
                                        }}
                                        instyle={styles.cancel}
                                    />

                                    <ButtonView
                                        label="Add"
                                        onPress={this.handleAddButtonInModalClicked}
                                        instyle={styles.add}
                                    />
                                </div>
                            </div>
                        </ModalView>
                    )}

                    {this.props.keypadOption === 1 && <div style={styles.pinview} >
                        <p>PIN:</p>  <input
                            type={this.props.hidePinCode === true ? "password" : "number"}
                            ref={this.txtPINCode}
                            min="0"
                            step="1"
                            max="999999999"
                            name="txtPINCode"
                            inputmode="numeric"
                            style={{
                                paddingLeft: 2,
                                width: 70,
                                height: 20,
                                fontWeight: 'bold',
                                marginLeft: 5,
                                backgroundColor: Colors.VeryPaleCyan
                            }} 

                            onKeyPress={(e) => {

                                if (e.charCode >= 48 && e.charCode <= 57) {
                                    const pinInputted = e.target.value + String.fromCharCode(e.charCode);
                                    if (pinInputted >= 0 && pinInputted <= 9999) {
                                        this.props.changePersonnelPIN(pinInputted);
                                        
                                    } else {
                                        e.preventDefault();
                                    }
                                }
                                else {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }



                            }}
                        />
                            </div>}
                    {this.props.keypadOption === 2 && <div style={styles.pinview} >
                        <p>PIN:</p><input
                            type={this.props.hidePinCode === true ? "password" : "number"}
                            ref={this.txtPINCode}
                            min="0"
                            max="999999999"
                            name="txtPINCode"
                            step="1"
                            inputmode="numeric"
                            style={{
                                paddingLeft: 2,
                                width: 140,
                                height: 20,
                                fontWeight: 'bold',
                                marginLeft: 5,
                                backgroundColor: Colors.VeryPaleCyan
                            }}

                            onKeyPress={(e) => {

                                if (e.charCode >= 48 && e.charCode <= 57) {
                                    const pinInputted = e.target.value + String.fromCharCode(e.charCode);
                                    if (pinInputted >= 0 && pinInputted <= 999999999) {
                                        this.props.changePersonnelPIN(pinInputted);
                                    } else {
                                        e.preventDefault();
                                    }
                                }
                                else {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }



                            }}
                        />  </div>}
               
                    <div style={styles.accessGroupListContainer}>
                        <div style={styles.accessGroupContainer}>
                            {this.props.noOfAccessGroupsPerBadge >= 1 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 1

                                        <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG1(e.target.value);
                                           
                                            }}
                                        ></input>
                                     </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList1(this.props.accessGroups)}
                                    </div>{' '}
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 2 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 2
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG2(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList2(this.props.accessGroups)}
                                    </div>{' '}
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 3 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 3
                                          <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG3(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList3(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 4 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 4
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG4(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList4(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 5 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 5
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG5(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList5(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 6 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 6
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG6(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList6(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 7 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 7
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG7(e.target.value);

                                            }}
                                        ></input>

                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList7(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 8 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 8
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG8(e.target.value);

                                            }}
                                        ></input>

                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList8(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 9 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 9
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG9(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList9(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 10 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 10
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG10(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList10(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 11 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 11
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG11(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList11(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 12 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 12
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG12(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList12(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 13 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 13
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG13(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList13(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 14 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 14
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG14(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList14(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}
                            {this.props.noOfAccessGroupsPerBadge >= 15 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 15
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG15(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList15(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge === 16 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 16
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG16(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList16(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {this.props.loading === true && (
                    <div>
                        <ModalProgressView
                            Message="Please wait..."
                            onCloseModal={() => {
                                this.props.closeLoaderModal();
                            }}
                        />
                    </div>
                )}

            </div>
        );
    }
}

const styles = {
    dialogStyle: {
        backgroundColor: Colors.VeryPaleBlue,
        padding: 15,
        width: 300,
        height: 'auto'
    },
    add: {
        margin: 2,
        width: 80,
        height: 32,
        fontSize: 15,
        borderRadius: 10,
        color: Colors.White,
        textAlign: 'center',
        backgroundColor: Colors.MostPureBlue,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer'
    },
    accessGroupContainer: {
        flex: 1,
        borderColor: Colors.Gray,
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: 5,
        justifyContent: 'space-around',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: Colors.Cream
    },
    save: {
        margin: 2,
        width: 80,
        height: 32,
        fontSize: 15,
        color: Colors.White,
        textAlign: 'center',
        backgroundColor: Colors.MostPureBlue,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    cancel: {
        margin: 2,
        width: 80,
        height: 32,
        color: Colors.Black,
        fontSize: 15,
        textAlign: 'center',
        backgroundColor: Colors.LightGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    label: {
        fontWeight: 800,
        fontSize: 12,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 2
    },
    underlineless: {
        textDecoration: 'none'
    },
    select: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'black',
        borderRadius: 0,
        background: 'none',
        padding: 5,
        display: 'flex',
        flex: 1
    },
    firstName: {
        backgroundColor: Colors.VeryPaleCyan,
        flex: 1,
        height: 30,
        width: 70,
        paddingLeft: 8,
        display: 'flex',
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray,
        borderRadius: 6
    },
    lastName: {
        backgroundColor: Colors.VeryPaleCyan,
        flex: 1,
        width: 70,
        height: 30,
        marginRight: 10,
        paddingLeft: 8,
        display: 'flex',
        marginLeft: 10,
        marginBottom: 2,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray,
        borderRadius: 6
    },
    option: {
        flex: 1,
        display: 'flex'
    },
    checkbox: {
        backgroundColor: Colors.Black
    },
    addBadge: {
        backgroundColor: Colors.MostPureBlue,
        fontWeight: 'bold',
        width: 92,
        height: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: Colors.White,
        textDecoration: 'none',
        textAlign: 'center',
        padding: 3,
        cursor: 'pointer',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 5,
        borderColor: Colors.Black,
        marginBottom: 6,
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    buttonContainer: {
        display: 'flex',
        flex: 1,
        height: 80,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginLeft: 30,
        marginBottom: 10,
        marginTop: 10
    },
    nameWithEnabled: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        marginBottom: 8
    },
    nameRow: {
        display: 'flex',
        height: 45,
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'flex-start'
    },
    enabledRow: {
        display: 'flex',
        flexDirection: 'row',
        height: 40,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    enabled: {
        marginLeft: 10
    },
    namePortion: {
        display: 'flex',
        width: '90%',
        fontWeight: 'bold',
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    badgeNoListLabel: {
        paddingRight: 10,
        fontSize: 14,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        fontWeight: '700',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    badgeNoListContainer: {
        flex: 1,
        display: 'flex',
        height: 'auto',
        paddingRight: 10,
        justifyContent: 'center'
    },
    pinview: {
        flex: 1,
        justifyContent: 'flex-start',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        fontWeight: 'bold',
        fontSize: 14
    },
    searchAG: {
        fontSize: 10,
        marginBottom: 2
    },
    accessGroupListContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10,
        marginTop: 10
    },
    accessGroupsContainer: {
        flex: 1,
        display: 'flex',
        marginBottom: 10
    },
    partitionGroupsContainer: {
        flex: 1,
        display: 'flex',
        marginBottom: 10
    },
    listContainer: {
        height: 120,
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'black',
        overflowY: 'scroll',
        fontWeight: 'bold',
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        paddingTop: 10
    },
    container: {
        display: 'flex',
        flex: 1,
        height: 'auto',
        justifyContent: 'center',
        marginRight: 20,
        flexDirection: 'column',
        backgroundColor: Colors.White,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10
    },
    expandContainer: {
        flex: 1,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        paddingLeft: 10,
        backgroundColor: Colors.Green,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10
    },

    titleContainer: {
        marginLeft: 30,
        marginRight: 10,
        flex: 1,
        height: 45,
        display: 'flex',
        fontWeight: 'bold',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: Colors.White,
        flexDirection: 'row'
    },

    expandableArea: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
        paddingRight: 10
    },

    functionText: {
        color: Colors.White,
        textAlign: 'left',
        marginLeft: 20,
        fontSize: 20,
        fontWeight: 800
    },
    horizontalLine: {
        height: 1,
        width: '100%',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.Purple
    }
};

const mapStateToProps = state => ({
    accessToken: state.token.accessToken,
    operatorId: state.token.operatorId,
    personnelPIN: state.pin.personnelPIN,
    personnelCreated: state.personnel.personnelCreated,
    accessGroups: state.accessGroups.accessGroups,
    personnels: state.personnel.personnels,
    //noOfAccessGroupsPerBadge: state.settings.noOfAccessGroupsPerBadge
    noOfAccessGroupsPerBadge: state.settings.systemSettings.aGCountPerBadge,
    sysBadgeLen: state.settings.systemSettings.sysBadgeLen,
    loading: state.loading.loading,
    msg: state.message.msg,
    isHavingPartition: state.partition.isHavingPartition,
    keypadOption: state.settings.systemSettings.keypadOption,
    hidePinCode: state.settings.systemSettings.hidePinCode,
    operatorId: state.token.operatorId,
    searchTextAG1: state.accessGroups.searchTextAG1,
    searchTextAG2: state.accessGroups.searchTextAG2,
    searchTextAG3: state.accessGroups.searchTextAG3,
    searchTextAG4: state.accessGroups.searchTextAG4,
    searchTextAG5: state.accessGroups.searchTextAG5,
    searchTextAG6: state.accessGroups.searchTextAG6,
    searchTextAG7: state.accessGroups.searchTextAG7,
    searchTextAG8: state.accessGroups.searchTextAG8,
    searchTextAG9: state.accessGroups.searchTextAG9,
    searchTextAG10: state.accessGroups.searchTextAG10,
    searchTextAG11: state.accessGroups.searchTextAG11,
    searchTextAG12: state.accessGroups.searchTextAG12,
    searchTextAG13: state.accessGroups.searchTextAG13,
    searchTextAG14: state.accessGroups.searchTextAG14,
    searchTextAG15: state.accessGroups.searchTextAG15,
    searchTextAG16: state.accessGroups.searchTextAG16
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setCurrentPageName: actions.setCurrentPageName,
            createNewPersonnel: actions.createNewPersonnel,
            resetNewPersonnalCreatedStatus: actions.resetNewPersonnalCreatedStatus,
            fetchAccessGroupList: actions.fetchAccessGroupList,
            changePersonnelPIN: actions.changePersonnelPIN,
            resetMessage: actions.resetMessage,
            checkIfPartitionExists: actions.checkIfPartitionExists,
            resetPartitionExistsStatus: actions.resetPartitionExistsStatus,
            fetchSystemSettings: actions.fetchSystemSettings,
            writeLog: actions.writeLog,
            changeSearchTextAG1: actions.changeSearchTextAG1,
            changeSearchTextAG2: actions.changeSearchTextAG2,
            changeSearchTextAG3: actions.changeSearchTextAG3,
            changeSearchTextAG4: actions.changeSearchTextAG4,
            changeSearchTextAG5: actions.changeSearchTextAG5,
            changeSearchTextAG6: actions.changeSearchTextAG6,
            changeSearchTextAG7: actions.changeSearchTextAG7,
            changeSearchTextAG8: actions.changeSearchTextAG8,
            changeSearchTextAG9: actions.changeSearchTextAG9,
            changeSearchTextAG10: actions.changeSearchTextAG10,
            changeSearchTextAG11: actions.changeSearchTextAG11,
            changeSearchTextAG12: actions.changeSearchTextAG12,
            changeSearchTextAG13: actions.changeSearchTextAG13,
            changeSearchTextAG14: actions.changeSearchTextAG14,
            changeSearchTextAG15: actions.changeSearchTextAG15,
            changeSearchTextAG16: actions.changeSearchTextAG16,
            resetSearchTextAllAG: actions.resetSearchTextAllAG
        },
        dispatch
    );

export default Radium(
    connect(mapStateToProps, mapDispatchToProps)(PersonnelAddEditView)
);

import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, Redirect } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import * as Colors from '../../consts/colors';
import * as actions from '../../actions/index';
import { PAGE_NAMES } from '../../consts/consts';
import ButtonView from '../common/ButtonView.js';

class UpdateScheduleChangeView extends Component {
  constructor(props) {
    super(props);

    const tStartDate = moment
      .utc(
        `${props.currentScheduledChanges.startDate} ${props.currentScheduledChanges.startTime}`,
        'MM/DD/YYYY kk:mm A'
      )
      .toDate();

    const tEndDate = moment
      .utc(
        `${props.currentScheduledChanges.endDate} ${props.currentScheduledChanges.endTime}`,
        'MM/DD/YYYY kk:mm A'
      )
      .toDate();

    this.state = {
      collapseButtonPressed: false,
      dataChanged: false,
      currentScheduledChanges: { ...props.currentScheduledChanges },
      selectedDeviceTypeIndex: 0,
      selectedDeviceIndex: 0,
      selectedFieldIndex: 0,
      startDate: tStartDate,
      endDate: tEndDate,
      updatedSchedulechanges: {
        caObjectId: props.currentScheduledChanges.caObjectId,
        description: props.currentScheduledChanges.description,
        fromDate: moment(tStartDate, 'MM/DD/YYYY kk:mm A').toISOString(),
        toDate: moment(tEndDate, 'MM/DD/YYYY kk:mm A').toISOString(),
        deviceType: props.currentScheduledChanges.device,
        panelNo: props.currentScheduledChanges.pnlNo,
        deviceNo: props.currentScheduledChanges.deviceNo,
        fieldNo: props.currentScheduledChanges.fieldNo,
        scheduleNo: props.currentScheduledChanges.schedule,
        deviceText: props.currentScheduledChanges.fieldIn,
        fieldInText: props.currentScheduledChanges.valueIn
      }
    };

    console.log(
      'tStartDate: ',
      tStartDate,
      moment(tStartDate, 'MM/DD/YYYY HH:mm A').toISOString()
    );
    console.log(
      'tEndDate: ',
      tEndDate,
      moment(tEndDate, 'MM/DD/YYYY HH:mm A').toISOString()
    );

    this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
    this.handleEndDateChanged = this.handleEndDateChanged.bind(this);
    this.handleDescriptionChanged = this.handleDescriptionChanged.bind(this);
    this.props.setCurrentPageName(PAGE_NAMES.EDIT_SCHEDULECHANGES);
    this.props.fetchTimeSchedules();
    this.props.resetUpdateScheduledChangesCreatedStatus();
    this.props.writeLog('OperatorID: ' + this.props.operatorId + "UpdateScheduleChangeView -->  Constructor() --> currentScheduleChange: " + JSON.stringify(props.currentScheduledChanges));

  }

  componentWillUnmount() {
    this.setState({
      ...this.state,
      collapseButtonPressed: false,
      dataChanged: false
    });
  }

  handleDescriptionChanged(event) {
    console.log('description', event.target.value);
    this.setState({
      ...this.state,
      updatedSchedulechanges: {
        ...this.state.updatedSchedulechanges,
        description: event.target.value
      },
      dataChanged: true
    });
  }

  handleStartDateChanged(date) {
    const startDate = date;

    this.setState(
      {
        ...this.state,
        startDate: date,
        updatedSchedulechanges: {
          ...this.state.updatedSchedulechanges,
          fromDate: moment(startDate, 'MM/DD/YYYY kk:mm A').toISOString()
        },
        dataChanged: true
      },
      () => {
        console.log(
          'Start Date ::',
          startDate,
          this.state.updatedSchedulechanges.fromDate
        );
      }
    );
  }

  handleEndDateChanged(date) {
    const endDate = date;

    if (moment(date).isSameOrBefore(this.state.startDate)) {
      alert('End Date can not be same or before start date.');
      return;
    }

    this.setState(
      {
        ...this.state,
        endDate: date,
        updatedSchedulechanges: {
          ...this.state.updatedSchedulechanges,
          toDate: moment(endDate, 'MM/DD/YYYY kk:mm A').toISOString()
        },
        dataChanged: true
      },
      () => {
        console.log(
          'End Date ::',
          endDate,
          this.state.updatedSchedulechanges.toDate
        );
      }
    );
  }

  componentDidCatch(error, info) {
    console.log(error);
  }

  render() {
    console.log('current schedule changes', this.state.currentScheduledChanges);
    console.log('Updated schedule changes', this.state.updatedSchedulechanges);
    console.log('scheduledChangedUpdated', this.props.scheduledChangedUpdated);
    if (this.props.scheduledChangedUpdated === true) {
      this.props.resetUpdateScheduledChangesCreatedStatus();
      return <Redirect to="/scheduledchanges" />;
    }

    if (this.state.collapseButtonPressed === true) {
      if (this.state.dataChanged === true) {
        alert('Press Save button to save the changes or Cancel to discard.');
        this.setState({ ...this.state, collapseButtonPressed: false });
      } else return <Redirect to="/scheduledchanges" />;
    }

    return (
      <div style={styles.container}>
        <div>
          <div style={styles.expandContainer}>
            <div
              onClick={() => {
                this.setState({ ...this.state, collapseButtonPressed: true });
              }}
            >
              <FontAwesomeIcon icon={faMinus} size="1x" color={Colors.White} />
            </div>

            <div style={styles.functionText}>Update Scheduled Changes</div>
          </div>
          <div style={styles.blackLine} />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              marginLeft: 10,
              backgroundColor: Colors.White
            }}
          >
            <div style={styles.groupHeader}>Schedule Info</div>
            <div style={styles.descriptionRow}>
              Description
              <input
                type="text"
                name="description"
                value={this.state.updatedSchedulechanges.description}
                onChange={this.handleDescriptionChanged}
                style={styles.description}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              marginLeft: 10,
              backgroundColor: Colors.White
            }}
          >
            <div style={styles.groupHeader}>Change to be Made</div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                marginLeft: 10,
                backgroundColor: Colors.White
              }}
            >
              <p>Effective Date Range </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: 30,
                  flex: 1
                }}
              >
                <p style={{ width: 70, textAlign: 'left' }}>From</p>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.handleStartDateChanged}
                  showTimeSelect
                  timeFormat="hh a"
                  readonly
                  withPortal
                  timeIntervals={60}
                  disabledKeyboardNavigation
                  timeCaption="time"
                  // dateFormat="MMMM d, yyyy h:mm aa"
                  dateFormat="MM/dd/yyyy  h a"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: 30,
                  paddingTop: 5,
                  flex: 1
                }}
              >
                <p style={{ width: 70, display: 'flex', textAlign: 'left' }}>
                  To
                </p>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={this.handleEndDateChanged}
                  showTimeSelect
                  timeFormat="hh a"
                  readonly
                  withPortal
                  timeIntervals={60}
                  disabledKeyboardNavigation
                  timeCaption="time"
                  // dateFormat="MMMM d, yyyy h:mm aa"
                  dateFormat="MM/dd/yyyy  h a"
                />
              </div>
            </div>

            <div style={styles.buttonContainer}>
              <Link style={styles.underlineless} to="/scheduledchanges">
                <ButtonView label="Cancel" instyle={styles.cancel} />
              </Link>

              <Link style={styles.underlineless} to="/scheduledchanges">
                <ButtonView
                  label="Save"
                  instyle={styles.save}
                  onPress={event => {
                      event.preventDefault();
                      this.props.writeLog('OperatorID: ' + this.props.operatorId + "UpdateScheduleChangeView -->  UpdateScheduleChanges() --> updatedScheduleChanges: " + JSON.stringify(this.state.updatedSchedulechanges));
                      this.props.updateScheduleChanges(
                      this.state.updatedSchedulechanges
                    );
                  }}
                />
              </Link>
            </div>
          </div>
        </div>
        <div>
          <p style={styles.message}>{this.props.msg}</p>
        </div>
      </div>
    );
  }
}

const styles = {
  underlineless: {
    textDecoration: 'none'
  },
  message: {
    fontSize: 15,
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  groupHeader: {
    backgroundColor: Colors.VividBlue,
    color: Colors.White,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
    marginRight: 8,
    marginTop: 8
  },
  deviceTypeListContainer: {
    display: 'flex',
    flex: 1,
    height: 35,
    flexDirection: 'row',
    marginLeft: 8,
    marginRight: 8,
    marginTop: 5
  },
  deviceListContainer: {
    display: 'flex',
    flex: 1,
    height: 35,
    flexDirection: 'row',
    marginLeft: 8,
    marginRight: 8,
    marginTop: 5
  },
  fieldListContainer: {
    display: 'flex',
    flex: 1,
    height: 35,
    flexDirection: 'row',
    marginLeft: 8,
    marginRight: 8,
    marginTop: 5
  },
  timeScheduleListContainer: {
    display: 'flex',
    flex: 1,
    height: 80,
    justifyContent: 'flex-end',
    flexDirection: 'column',
    marginLeft: 8,
    marginRight: 8,
    marginTop: 5
  },
  description: {
    height: 30,
    paddingLeft: 6,
    marginLeft: 8,
    marginRight: 8,
    width: '95%',
    backgroundColor: Colors.VeryPaleCyan
  },
  descriptionRow: {
    height: 32,
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
    paddingRight: 1,
    marginTop: 5
  },
  selectedItemStyle: {
    display: 'flex',
    flex: 1,
    height: 50,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: Colors.Cream
  },
  itemStyle: {
    display: 'flex',
    flex: 1,
    height: 50,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: Colors.White
  },
  add: {
    margin: 2,
    width: 80,
    height: 32,
    fontSize: 15,
    color: Colors.White,
    textAlign: 'center',
    backgroundColor: Colors.MostPureBlue,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 10,
    cursor: 'pointer'
  },
  delete: {
    margin: 2,
    width: 80,
    height: 32,
    color: Colors.White,
    fontSize: 15,
    textAlign: 'center',
    backgroundColor: Colors.Red,
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'pointer',
    borderRadius: 10
  },
  alldays: {
    display: 'flex',
    flex: 1,
    height: 50,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: Colors.LightGray
  },
  other: {
    display: 'flex',
    flex: 1,
    height: 50,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: Colors.White
  },

  listContainer: {
    height: 180,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'black',
    overflowY: 'scroll',
    fontWeight: 'bold',
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 20,
    borderRadius: 6
  },
  listTitle: { textAlign: 'left', display: 'flex', flex: 1, paddingLeft: 8 },
  titleContainer: {
    marginLeft: 10,
    marginRight: 10,
    paddingL0eft: 10,
    flex: 1,
    fontSize: 15,
    height: 28,
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row'
  },
  container: {
    display: 'flex',
    flex: 1,
    height: 'auto',
    justifyContent: 'center',
    marginRight: 20,
    flexDirection: 'column',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: 10,
    borderColor: Colors.Green,
    backgroundColor: Colors.White
  },
  buttonContainer: {
    flex: 1,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 4,
    marginLeft: 30,
    marginRight: 6.5
  },
  save: {
    margin: 2,
    width: 80,
    height: 32,
    fontSize: 15,
    color: Colors.White,
    textAlign: 'center',
    backgroundColor: Colors.MostPureBlue,
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'pointer',
    borderRadius: 8,
    borderColor: Colors.Black,
    display: 'inline-block',
    textDecoration: 'none',
    boxShadow: '0.5px 0.5px 1px #00000000',
    ':active': {
      backgroundColor: Colors.Gray,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'black'
    }
  },
  cancel: {
    margin: 2,
    width: 80,
    height: 32,
    color: Colors.Black,
    fontSize: 15,
    textAlign: 'center',
    backgroundColor: Colors.LightGray,
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'pointer',
    borderRadius: 8,
    borderColor: Colors.Black,
    display: 'inline-block',
    textDecoration: 'none',
    boxShadow: '0.5px 0.5px 1px #00000000',
    ':active': {
      backgroundColor: Colors.Gray,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'black'
    }
  },
  expandContainer: {
    flex: 1,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 10,
    backgroundColor: Colors.Green,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10
  },

  functionText: {
    color: Colors.White,
    textAlign: 'left',
    marginLeft: 20,
    fontSize: 18,
    fontWeight: 800
  },
  horizontalLine: {
    height: 1,
    width: '100%',
    borderBottom: '1px solid',
    borderColor: Colors.Purple
  }
};

const mapStateToProps = state => ({
  accessToken: state.token.accessToken,
  scheduledChanges: state.scheduledChanges.scheduledChanges,
  timeSchedules: state.scheduledChanges.timeSchedules,
  msg: state.scheduledChanges.msg,
  deviceTypeListMap: state.status.deviceTypeListMap,
  selectedDeviceType: state.scheduledChanges.selectedDeviceType,
  selectedDevice: state.scheduledChanges.selectedDevice,
  selectedField: state.scheduledChanges.selectedField,
  deviceList: state.scheduledChanges.deviceList,
  fieldList: state.scheduledChanges.fieldList,
  newScheduleChangesCreated: state.scheduledChanges.newScheduleChangesCreated,
  scheduledChangedUpdated: state.scheduledChanges.scheduledChangedUpdated,
    operatorId: state.token.operatorId
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCurrentPageName: actions.setCurrentPageName,
      fetchDeviceTypeList: actions.fetchDeviceTypeList,
      fetchDeviceListByDeviceType: actions.fetchDeviceListByDeviceType,
      //fetchFieldListByDevice: actions.fetchFieldListByDevice,
      fetchTimeSchedules: actions.fetchTimeSchedules,
      createNewScheduleChanges: actions.createNewScheduleChanges,
      resetNewScheduledChangesCreatedStatus:
        actions.resetNewScheduledChangesCreatedStatus,
      updateScheduleChanges: actions.updateScheduleChanges,
      resetUpdateScheduledChangesCreatedStatus:
      actions.resetUpdateScheduledChangesCreatedStatus,
      writeLog: actions.writeLog
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(UpdateScheduleChangeView)
);

import * as ActionTypes from '../actions/types';
import {
    PAGE_NAMES,
    SORTING_ORDER,
    SECURITY_LEVEL_CODES,
    ADMIN_ROLE_ID,
    SCREEN_CODES
} from '../consts/consts';


const defaultState = {
    MyDoorMenuEnabled: true,
    LockDownAreaMenuEnabled: true,
    ThreatLevelMenuEnabled: true,
    StatusMenuEnabled: true,
    PersonnelMenuEnabled: true,
    ControlMenuEnabled: true,
    BadgeHoldersInMenuEnabled: true,
    ScheduleChangesMenuEnabled: true

};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.CHECK_PRIVILEDGES: {

            let MyDoorMenuEnabled = true;
            let LockDownAreaMenuEnabled = true;
            let ThreatLevelMenuEnabled = true;
            let StatusMenuEnabled = true;
            let PersonnelMenuEnabled = true;
            let ControlMenuEnabled = true;
            let BadgeHoldersInMenuEnabled = true;
            let ScheduleChangesMenuEnabled = true;

            const allPriviledges = action.payload.priviledges;
            const roleId = action.payload.roleId;

            const badgeHoldersInPrivilege = allPriviledges.find(
                privilege => privilege.ScreenID === SCREEN_CODES.BADGEHOLDERIN
            );

            let doorPrivilege = allPriviledges.find(
                privilege => privilege.ScreenID === SCREEN_CODES.MANUAL_DOORS
            );

            let relayPrivilege = allPriviledges.find(
                privilege => privilege.ScreenID === SCREEN_CODES.MANUAL_RELAY
            );

            if (typeof doorPrivilege === 'undefined') {
                doorPrivilege = null;
            }

            if (typeof relayPrivilege === 'undefined') {
                relayPrivilege = null;
            }
            const lockDownPrivilege = allPriviledges.find(
                privilege => privilege.ScreenID === SCREEN_CODES.LOCKDOWN
            );

            const personnelPrivilege = allPriviledges.find(
                privilege => privilege.ScreenID === SCREEN_CODES.PERSONNEL
            );

            const scheduleChangesPrivilege = allPriviledges.find(
                privilege => privilege.ScreenID === SCREEN_CODES.SCHEDULECHANGES
            );

            const statusPrivilege = allPriviledges.find(
                privilege => privilege.ScreenID === SCREEN_CODES.STATUS
            );

            const threatLevelPrivilege = allPriviledges.find(
                privilege => privilege.ScreenID === SCREEN_CODES.THREATLEVEL
            );


            if (roleId !== ADMIN_ROLE_ID && doorPrivilege && doorPrivilege.SecurityLevel && doorPrivilege.SecurityLevel === SECURITY_LEVEL_CODES.DISABLE) {
                MyDoorMenuEnabled = false;

            }
            if (roleId !== ADMIN_ROLE_ID && lockDownPrivilege && lockDownPrivilege.SecurityLevel && lockDownPrivilege.SecurityLevel === SECURITY_LEVEL_CODES.DISABLE) {
                LockDownAreaMenuEnabled = false;

            }
            if (roleId !== ADMIN_ROLE_ID && threatLevelPrivilege && threatLevelPrivilege.SecurityLevel && threatLevelPrivilege.SecurityLevel === SECURITY_LEVEL_CODES.DISABLE) {
                ThreatLevelMenuEnabled = false;

            }
            if (roleId !== ADMIN_ROLE_ID && statusPrivilege && statusPrivilege.SecurityLevel && statusPrivilege.SecurityLevel === SECURITY_LEVEL_CODES.DISABLE) {
                StatusMenuEnabled = false;

            }
            if (roleId !== ADMIN_ROLE_ID && personnelPrivilege && personnelPrivilege.SecurityLevel && personnelPrivilege.SecurityLevel === SECURITY_LEVEL_CODES.DISABLE) {
                PersonnelMenuEnabled = false;

            }

            if (roleId !== ADMIN_ROLE_ID && doorPrivilege && relayPrivilege && doorPrivilege.SecurityLevel && relayPrivilege.SecurityLevel && doorPrivilege.SecurityLevel === SECURITY_LEVEL_CODES.DISABLE && relayPrivilege.SecurityLevel === SECURITY_LEVEL_CODES.DISABLE) {
                ControlMenuEnabled = false;

            }

            if (roleId !== ADMIN_ROLE_ID && badgeHoldersInPrivilege && badgeHoldersInPrivilege.SecurityLevel && badgeHoldersInPrivilege.SecurityLevel === SECURITY_LEVEL_CODES.DISABLE) {
                BadgeHoldersInMenuEnabled = false;

            }
            if (roleId !== ADMIN_ROLE_ID && scheduleChangesPrivilege && scheduleChangesPrivilege.SecurityLevel && scheduleChangesPrivilege.SecurityLevel === SECURITY_LEVEL_CODES.DISABLE) {
                ScheduleChangesMenuEnabled = false;

            }
           

            return {
                MyDoorMenuEnabled,
                LockDownAreaMenuEnabled,
                ThreatLevelMenuEnabled,
                StatusMenuEnabled,
                PersonnelMenuEnabled,
                ControlMenuEnabled,
                BadgeHoldersInMenuEnabled,
                ScheduleChangesMenuEnabled

            };
        }

        default:
            return state;
    }
};

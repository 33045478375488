import React from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Colors from '../../consts/colors';
import * as actions from '../../actions/index';
import ErrorBoundary from '../common/ErrorBoundary';

function setCharAt(str, index, chr) {
  if (index > str.length - 1) return str;
  return str.substr(0, index) + chr + str.substr(index + 1);
}

function Pin(props) {
  return (
    <div style={styles.pinContainer}>
      <input
        ref={props.refInput}
        type="text"
        name={props.name}
        tabIndex={props.tabIndex}
        style={styles.p0}
        pattern="[0-9]{1}"
        maxLength={1}
        onChange={props.onChange}
        value={props.value}
        title="only digits are allowed"
      />
    </div>
  );
}

class SettingsPinView extends React.Component {
  constructor(props) {
    super(props);

    this.handleTextChange = this.handleTextChange.bind(this);

    this.p1 = React.createRef();
    this.p2 = React.createRef();
    this.p3 = React.createRef();
    this.p4 = React.createRef();
  }

  componentDidMount() {
    const settingsPin = this.props.settingsPin;
    const pin = settingsPin && settingsPin.length === 4 ? settingsPin : '####';

    this.props.changeTmpSettingsPin(pin);
  }

  getNewPin(oldPin, element) {
    const value = element.value;
    let index = 0;

    switch (element) {
      case this.p2.current:
        index = 1;
        break;
      case this.p3.current:
        index = 2;
        break;
      case this.p4.current:
        index = 3;
        break;
      default:
        index = 0;
        break;
    }

    return setCharAt(oldPin, index, this.formatValue(value));
  }

  handleTextChange(event) {
    try {
      const element = event.target;
      const value = element.value;

      const tmpSettingsPin = this.props.tmpSettingsPin;
      const oldPin =
        tmpSettingsPin && tmpSettingsPin.length === 4 ? tmpSettingsPin : '####';

      const newPin = this.getNewPin(oldPin, element);
      const isSingleDigit = value >= 0 && value <= 9;

      if (isSingleDigit) {
        this.props.changeTmpSettingsPin(newPin);
        this.props.configurationChanged();

        this.focusNextElement(element);
      } else {
        event.preventDefault();
      }
    } catch (error) {
      this.setState(() => {
        throw error;
      });
    }
  }

  formatValue(val) {
    return val !== '' ? val : '#';
  }

  focusNextElement(element) {
    let foucusedElement = null;

    switch (element) {
      case this.p1.current:
        foucusedElement = this.p2.current;
        break;
      case this.p2.current:
        foucusedElement = this.p3.current;
        break;
      default:
        foucusedElement = this.p4.current;
        break;
    }
    foucusedElement.focus();
  }

  render() {
    const tmpSettingsPin = this.props.tmpSettingsPin;

    const pin =
      tmpSettingsPin && tmpSettingsPin.length === 4 ? tmpSettingsPin : '####';

    const p1 = pin[0] !== '#' ? pin[0] : '';
    const p2 = pin[1] !== '#' ? pin[1] : '';
    const p3 = pin[2] !== '#' ? pin[2] : '';
    const p4 = pin[3] !== '#' ? pin[3] : '';

    return (
      <ErrorBoundary>
        <div style={styles.pin}>
          <p> PIN : </p>
          <div style={styles.container}>
            <Pin
              refInput={this.p1}
              name="p1"
              tabIndex={1}
              value={p1}
              onChange={this.handleTextChange}
            />
            <Pin
              refInput={this.p2}
              name="p2"
              tabIndex={2}
              value={p2}
              onChange={this.handleTextChange}
            />
            <Pin
              refInput={this.p3}
              name="p3"
              tabIndex={3}
              value={p3}
              onChange={this.handleTextChange}
            />
            <Pin
              refInput={this.p4}
              name="p4"
              tabIndex={4}
              value={p4}
              onChange={this.handleTextChange}
            />
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    padding: 10
  },
  p0: {
    width: 30,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    borderRadius: 8,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.LightGray
  },
  pinContainer: {
    display: 'flex',
    border: '1px solid #f5f5f5',
    height: 30,
    width: 'auto',
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 8
  },
  pin: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    marginLeft: 60,
    marginTop: 10,
    fontSize: '0.90em'
  }
};

const mapStateToProps = state => ({
  //settingsPin: state.pin.settingsPin
  settingsPin: state.pin.settingsPin,
  tmpSettingsPin: state.pin.tmpSettingsPin
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      configurationChanged: actions.configurationChanged,
      changeTmpSettingsPin: actions.changeTmpSettingsPin
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(SettingsPinView)
);

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import Radium from 'radium';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';

import PersonnelListItemView from './PersonnelListItemView.js';
import * as Colors from '../../consts/colors';
import * as actions from '../../actions/index';

const styles = {
    listContainer: {
        height: 180,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'black',
        overflowY: 'scroll',
        fontWeight: 'bold',
        marginLeft: 8,
        fontSize: 14,
        marginRight: 8,
        marginBottom: 20,
        backgroundColor: Colors.White,
        paddingBottom: 20,
        paddingTop: 10,
        borderRadius: 0
    },
    itemStyle: {
        display: 'flex',
        flex: 1,
        height: 35,
        margin: 1,
        fontSize: '0.9em',
        paddingLeft: 20,
        paddingRight: 20,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.White
    },
    selectedItemStyle: {
        display: 'flex',
        flex: 1,
        margin: 1,
        height: 35,
        fontSize: '0.91em',
        paddingLeft: 10,
        paddingRight: 10,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.Cream
    },

};

const pageSize = 20;
const maximumAllowedRow = 500;
const searchInsteadMessage = 'Could not load more data, search instead.';

class PersonnelListContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasMoreItems: true
        };

        this.pageIndex = 0;
    }

    componentDidMount() {
        this.props.fetchPersonnelsCount(this.props.filterOption, this.props.filterText);
    }

    componentDidUpdate() {
        if (this.props.personnelDeleted || this.props.reSetNeeded === true) {
            this.setState({
                hasMoreItems: true
            });
            this.pageIndex = 0;
        }
    }

    componentWillUnmount() {
        this.props.resetPersonnel();
    }

    loadMorePersonnel() {
        if (!this.props.personnelsCount) {
            if (this.props.finishFetchPersonnelsCount === true) {
                this.setState({
                    hasMoreItems: false
                });
            }
            return;
        }

        if (this.props.personnels.length >= maximumAllowedRow) {
            alert(searchInsteadMessage);
            this.setState({
                hasMoreItems: false
            });
        } else if (this.props.personnels.length >= this.props.personnelsCount) {
            this.setState({ hasMoreItems: false });
        } else {
            this.props.fetchPagedPersonnels(this.pageIndex, this.props.personalSortBy, this.props.personnelSortOrder, this.props.filterOption, this.props.filterText);
            const totalPage = Math.ceil(this.props.personnelsCount / pageSize);
            if ((this.pageIndex + 1) < totalPage) {
                console.log(this.pageIndex);
                this.pageIndex++;
            }
        }
    }

    showPersonnelList() {
        if (this.props.loading === false && this.props.personnelsCount === 0 && this.props.finishFetchPersonnelsCount === true) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>{<p>No Records Found</p>}</div>
            );
        }

        return this.props.personnels.map((personnel, index) => (
            <PersonnelListItemView
                key={index}
                currentIndex={index}
                lastName={personnel.lastName}
                firstName={personnel.firstName}
                defaultBadgeNo={personnel.badge}
                facility={personnel.facility}
                isSelected={this.props.selectedItemIndex === index}
                instyle={
                    this.props.selectedItemIndex === index ? styles.selectedItemStyle : styles.itemStyle
                }
                onPress={indx => {
                    this.props.selectListItem(indx);
                }}
            />
        ));
    }

    render() {
        return (<div style={{ ...styles.listContainer, overflow: 'auto' }}>
            <InfiniteScroll
                loadMore={this.loadMorePersonnel.bind(this)}
                hasMore={this.state.hasMoreItems}
                loader={<p key="-5" className="loading" />}
                useWindow={false}
            >
                {this.showPersonnelList()}
            </InfiniteScroll>
        </div>
        );
    }
}

const mapStateToProps = state => ({
    personnels: state.personnel.personnels,
    loading: state.loading.loading,
    personnelsCount: state.personnel.personnelsCount,
    personnelDeleted: state.personnel.personnelDeleted,
    finishFetchPersonnelsCount: state.personnel.finishFetchPersonnelsCount
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchPagedPersonnels: actions.fetchPagedPersonnels,
            fetchPersonnelsCount: actions.fetchPersonnelsCount,
            resetPersonnel: actions.resetPersonnel
        },
        dispatch
    );

export default Radium(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PersonnelListContainer)
);

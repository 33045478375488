import React from 'react';
import Radium from 'radium';

class ModalView extends React.Component {
  componentDidMount() {
    if (this.props.onClose) {
      window.addEventListener('keydown', this.listenKeyboard.bind(this), true);
    }
  }

  componentWillUnmount() {
    if (this.props.onClose) {
      window.removeEventListener('keydown', this.listenKeyboard.bind(this), true);
    }
  }

  onOverlayClick() {
    //if (this.props.shouldCloseOnOverlayClick && this.props.shouldCloseOnOverlayClick === true) {
    console.log(this.props);
    this.props.onClose();
    //}
  }

  onDialogClick(event) {
    event.stopPropagation();
  }

  listenKeyboard(event) {
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.props.onClose();
    }
  }

  render() {
    const overlayStyle = this.props.overlayStyle ? this.props.overlayStyle : {};
    const contentStyle = this.props.contentStyle ? this.props.contentStyle : {};
    const dialogStyle = this.props.dialogStyle ? this.props.dialogStyle : {};

    return (
      <div>
        <div className="modal-overlay-div" style={overlayStyle} />
        <div
          className="modal-content-div"
          style={contentStyle}
          onClick={this.onOverlayClick.bind(this)}
        >
          <div className="modal-dialog-div" style={dialogStyle} onClick={this.onDialogClick}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default Radium(ModalView);

import React from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Colors from '../../consts/colors';
import ButtonView from './ButtonView';
import * as actions from '../../actions/index';

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(error, errorInfo) {
    // Update state so the next render will show the fallback UI.
    return {
      error,
      errorInfo
    };
  }

  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    });

    this.props.logError(error);
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <h3
            style={{
              color: Colors.White,
              justifyContent: 'center',
              display: 'flex'
            }}
          >
            Something went wrong.
          </h3>
          {/* <details
            style={{
              alignItems: 'center',
              alignSelf: 'center',
              whiteSpace: 'pre-wrap',
              color: Colors.White,
              fontSize: '0.9em',
              display: 'flex',
              flex: 1,
              justifyContent: 'center'
            }}
          >
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details> */}

          <div style={styles.container}>
            <p style={styles.p}>Press Home Button to go to Home Screen</p>
            <Link style={styles.underlineless} to="/home">
              <ButtonView label="Home" instyle={styles.home} />
            </Link>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

const styles = {
  underlineless: {
    textDecoration: 'none'
  },
  p: { color: Colors.White },
  title: { color: Colors.White, justifyContent: 'center', display: 'flex' },
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  home: {
    margin: 2,
    width: 80,
    height: 32,
    fontSize: 15,
    color: Colors.White,
    textAlign: 'center',
    backgroundColor: Colors.MostPureBlue,
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'pointer',
    borderRadius: 8,
    borderColor: Colors.Black,
    display: 'inline-block',
    textDecoration: 'none',
    boxShadow: '0.5px 0.5px 1px #00000000',
    ':active': {
      backgroundColor: Colors.Gray,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'black'
    }
  }
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logError: actions.logError
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)
);

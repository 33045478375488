import * as ActionTypes from '../actions/types';
import { SORTING_ORDER } from '../consts/consts';

const defaultState = {
    badgeListPersonnelIdWise: [],
    personnels: [],
    personnel: {},
    personnelCreated: false,
    personnelDeleted: false,
    personnelUpdated: false,
    personnelFetched: false,
    selectedIndex: -1,
    sortFags: {},
    searchCriteria: {},
    newBadgeAdded: false,
    badgeExists: 0,
    filterOption: '',
    filterText: '',
    badgeInfoUpdatedStatus: -1,
    editedBadge: {},
    isBadgeLinked: false,
    pinViewDataChanged: false,
    newBadgeAddedInDropDownList: false,
    reEnterPINCode: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_ALL_PERSONNELS_SUCCEEDED: {
            return {
                ...state,
                personnels: makeSort(action.payload, state)
            };
        }
        case ActionTypes.CREATE_NEW_PERSONNEL_SUCCEEDED: {
            return {
                ...state,
                personnelCreated:
                    action.payload === 0 ||
                    action.payload === '0' ||
                    action.payload === ''
            };
        }

        case ActionTypes.UPDATE_PERSONNEL_SUCCEEDED: {
            return {
                ...state,
                personnelUpdated:
                    action.payload === 0 ||
                    action.payload === '0' ||
                    action.payload === ''
            };
        }

        case ActionTypes.DELETE_PERSONNEL_SUCCEEDED: {
            return {
                ...state,
                personnelDeleted:
                    action.payload === 0 ||
                    action.payload === '0' ||
                    action.payload === ''
            };
        }

        case ActionTypes.RESET_NEW_PERSONNEL_CREATED_STATUS: {
            return {
                ...state,
                personnelCreated: false
            };
        }

        case ActionTypes.RESET_PERSONNEL_UPDATED_STATUS: {
            return {
                ...state,
                personnelUpdated: false
            };
        }

        case ActionTypes.FETCH_PERSONNEL_SUCCEEDED: {
            return {
                ...state,
                personnel: action.payload,
                personnelFetched: true
            };
        }

        //case ActionTypes.SET_SORT_FLAGS: {
        //    return {
        //        ...state,
        //        sortFags: { sortOrder: action.sortOrder, sortBy: action.sortBy }
        //    };
        //}

        case ActionTypes.SET_SEARCH_CRITERIA: {
            return {
                ...state,
                searchCriteria: action.payload
            };
        }

        case ActionTypes.ADD_BADGE_TO_PERSONNEL_SUCCEEDED: {
            return {
                ...state,
                newBadgeAdded:
                    action.payload === 0 ||
                    action.payload === '0' ||
                    action.payload.length === 0
            };
        }

        case ActionTypes.RESET_NEW_BADGE_ADDED_TO_PERSONNEL_STATUS: {
            return {
                ...state,
                newBadgeAdded: false
            };
        }

        case ActionTypes.CHECK_BADGE_EXISTS: {
            return {
                ...state,
                badgeExists: 1
            };
        }

        case ActionTypes.CHECK_BADGE_EXISTS_SUCCEEDED: {
            return {
                ...state,
                badgeExists: action.payload === true ? 2 : 3
            };
        }

        case ActionTypes.RESET_BADGE_EXIST_FLAG: {
            return {
                ...state,
                badgeExists: 0
            };
        }
        case ActionTypes.FETCH_PERSONNELS_COUNT: {
            return {
                ...state,
                loading: true,
                finishFetchPersonnelsCount: false
            };
        }
        case ActionTypes.FETCH_PERSONNELS_COUNT_SUCCEEDED: {
            return {
                ...state,
                personnelsCount: action.payload,
                loading: false,
                finishFetchPersonnelsCount: true
            };
        }
        case ActionTypes.FETCH_PERSONNELS_COUNT_FAILED: {
            return {
                ...state,
                loading: false,
                finishFetchPersonnelsCount: true
            };
        }
        case ActionTypes.FETCH_PAGED_PERSONNELS: {
            return {
                ...state,
                loading: true
            };
        }
        case ActionTypes.FETCH_PAGED_PERSONNELS_SUCCEEDED: {
            return {
                ...state,
                personnels: makeSort([...state.personnels, ...action.payload], state),
                loading: false
            };
        }
        case ActionTypes.FETCH_PAGED_PERSONNELS_FAILED: {
            return {
                ...state,
                loading: false
            };
        }

        case ActionTypes.RESET_PERSONNEL: {
            return {
                ...state,
                personnels: [],
                personnelsCount: 0,
                finishFetchPersonnelsCount: false,
                personnelDeleted: false,
                filterOption: '',
                filterText: ''
            };
        }

        case ActionTypes.FETCH_BADGE_LIST_BY_PERSONNEL_ID_SUCCEEDED: {
            return {
                ...state,
                badgeListPersonnelIdWise: action.payload
            };
        }

        case ActionTypes.RESET_BADGE_LIST: {
            return {
                ...state,
                badgeListPersonnelIdWise: []
            };
        }

        case ActionTypes.RESET_SINGLE_PERSONNEL: {
            return {
                ...state,
                personnel: {}
            };
        }

        case ActionTypes.FETCH_PERSONNEL: {
            return {
                ...state,
                personnelFetched: false
            };
        }

        case ActionTypes.UPDATE_BAGDE_INFO_REQUEST: {
            return {
                ...state,
                badgeInfoUpdatedStatus: -1
            };
        }

        case ActionTypes.UPDATE_BAGDE_INFO_REQUEST_SUCCEEDED: {
            return {
                ...state,
                badgeInfoUpdatedStatus: action.payload
            };
        }

        case ActionTypes.UPDATE_BAGDE_INFO_REQUEST_FAILED: {
            return {
                ...state,
                badgeInfoUpdatedStatus: -1
            };
        }

        case ActionTypes.EDIT_BADGE_NO: {

            console.log("EDIT_BADGE_NO", action.payload);
            return {
                ...state,
                editedBadge: { ...state.editedBadge, oldBadgeNo: action.payload.oldBadgeNo, editedBadgeNo: action.payload.editedBadgeNo }
            };
        }


        case ActionTypes.EDIT_FACILITY_NO: {
            return {
                ...state,
                editedBadge: { ...state.editedBadge, oldFacilityNo: action.payload.oldFacilityNo, editedFacilityNo: action.payload.editedFacilityNo }
            };
        }

        case ActionTypes.SET_EDITED_BADGE_INFO: {
            console.log("Initial Edited Badge", action.payload);

            return {
                ...state,
                editedBadge: { oldBadgeNo: action.payload.badge, editedBadgeNo: action.payload.editedBadgeNo, oldFacilityNo: action.payload.facility, editedFacilityNo: action.payload.editedFacilityNo }
            };
        }

        case ActionTypes.RESET_EDITED_BADG_INFO: {
            return {
                ...state,
                editedBadge: {}
            };
        }

        case ActionTypes.SET_BADGE_LINKED: {
            return {
                ...state,
                isBadgLinked: action.payload === true ? true :false 
            };
        }

        case ActionTypes.RESET_BADGE_LINKED_STATUS: {
            return {
                ...state,
                isBadgLinked: false
            };
        }

        case ActionTypes.PERSONNEL_PINVIEW_DATA_CAHNGED: {
            return {
                ...state,
                pinViewDataChanged: true
            };
        }

        case ActionTypes.RESET_PERSONNEL_PINVIEW_DATA_CAHNGED: {
            return {
                ...state,
                pinViewDataChanged: false
            };
        }

        case ActionTypes.NEW_BADGE_ADDED_IN_DROPDOWN: {
            console.log("NewBadge:::", "sdsdsdsdsdsdd");
            return {
                ...state,
                newBadgeAddedInDropDownList: true
            };
        }

        case ActionTypes.RESET_NEW_BADGE_ADDED_IN_DROPDOWN: {
            return {
                ...state,
                newBadgeAddedInDropDownList: false
            };
        }

        case ActionTypes.RE_ENTER_PINCODE: {
            return {
                ...state,
                reEnterPINCode: true
            };
        }

        case ActionTypes.RESET_RE_ENTER_PINCODE: {
            return {
                ...state,
                reEnterPINCode: false
            };
        }
                
        default:
            return state;
    }
};

export const makeSort = (data, state) => {
    if (state.sortFags.sortOrder === SORTING_ORDER.ASCENDING) {
        return data.sort(selectSortType(state.sortFags.sortBy));
    } else if (state.sortFags.sortOrder === SORTING_ORDER.DESCENDING) {
        return data.sort(selectSortType(`-${state.sortFags.sortBy}`));
    }
    return data;
};

function selectSortType(property) {
    let sortOrder = 1;

    if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
    }

    if (property.includes('badge')) {
        return alphabeticallySort(sortOrder, property);
    }
    return numericallySort(sortOrder, property);
}

function alphabeticallySort(sortOrder, property) {
    return function (a, b) {
        if (sortOrder === -1) {
            return parseInt(b[property]) - parseInt(a[property]);
        }
        return parseInt(a[property]) - parseInt(b[property]);
    };
}

function numericallySort(sortOrder, property) {
    return function (a, b) {
        if (sortOrder === -1) {
            return b[property].localeCompare(a[property]);
        }
        return a[property].localeCompare(b[property]);
    };
}

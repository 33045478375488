import * as ActionTypes from '../actions/types';

const defaultState = {
  msgList: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.LOG_ERROR: {
      if (action.payload) {
        const messageList = [
          ...state.msgList,
          `===> ${action.payload}\n`
        ].slice(-10);
        return {
          msgList: messageList
        };
      }
      return state;
    }

    default:
      return state;
  }
};

import React from 'react';
//import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import * as actions from '../../actions/index';

const RequireAuthHOC = WrappedComponent => {
  class HOC extends React.Component {
    render() {
      if (this.props.accessToken.length === 0) {
        return <Redirect to="/login" />;
      }
      return (
        <WrappedComponent
          onClick={() => {
            this.props.resetInactivityTimer();
          }}
          {...this.props}
        />
      );
    }
  }

  const mapStateToProps = state => ({
    accessToken: state.token.accessToken,
    baseURL: state.appConfig.baseURL,
    remainingSessionTime: state.token.remainingSessionTime,
    lastInactivityTime: state.token.lastInactivityTime
  });

  const mapDispatchToProps = dispatch => bindActionCreators({ logOut: actions.logOut }, dispatch);

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(HOC);
};

export default RequireAuthHOC;

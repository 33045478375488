import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, Redirect } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import * as Colors from '../../consts/colors';
import * as actions from '../../actions/index';
import { PAGE_NAMES, SCREEN_CODES, SCREEN_DEVICE_TYPES } from '../../consts/consts';
import ButtonView from '../common/ButtonView.js';
import ModalProgressView from '../common/ModalProgressView';

const _ = require('lodash');

class AddScheduleChangeView extends Component {
    constructor(props) {
        super(props);
        const time = 60 * 60000; // 60 minute
        const startTime =
            new Date().getTime() + time - ((new Date().getTime() + time) % 3600000);

        const startDate = new Date().setTime(startTime);
        const endDate = new Date(new Date().setTime(startTime + 1 * 86400000));

        console.log('Start Time:', startDate);
        console.log('End Time:', endDate);

        this.state = {
            collapseButtonPressed: false,
            dataEntered: false,
            selectedDeviceTypeIndex: 0,
            selectedDeviceIndex: 0,
            selectedFieldIndex: 0,
            startDate,
            endDate,
            newScheduleChanges: {
                description: '',
                deviceType: 0,
                panelNo: '',
                deviceNo: '',
                fieldNo: '',
                scheduleNo: '',
                fromDate: moment(
                    new Date(startDate),
                    'MM/DD/YYYY kk:mm A'
                ).toISOString(),
                toDate: moment(new Date(endDate), 'MM/DD/YYYY kk:mm A').toISOString()
            }
        };

        this.loadInitialData = this.loadInitialData.bind(this);
        this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
        this.handleEndDateChanged = this.handleEndDateChanged.bind(this);
        this.handleDescriptionChanged = this.handleDescriptionChanged.bind(this);
        this.handleTimeScheduleSelectionChanged = this.handleTimeScheduleSelectionChanged.bind(
            this
        );
        this.handleDeviceTypeSelectionChanged = this.handleDeviceTypeSelectionChanged.bind(
            this
        );
        this.handleDeviceSelectionChanged = this.handleDeviceSelectionChanged.bind(
            this
        );
        this.handleFieldSelectionChanged = this.handleFieldSelectionChanged.bind(
            this
        );
        this.toCamelCase = this.toCamelCase.bind(this);
        this.props.setCurrentPageName(PAGE_NAMES.ADDSCHEDULECHANGES);
        this.props.fetchTimeSchedules();
        this.props.fetchDeviceTypeList(SCREEN_CODES.SCHEDULECHANGES);
        this.loadInitialData();
        this.props.resetPartitionExistsStatus();
        this.props.resetNewScheduledChangesCreatedStatus();
        this.props.resetMessage();
        this.props.checkIfPartitionExists(this.props.operatorId, SCREEN_DEVICE_TYPES.SCHEDULECHANGES);
        this.props.writeLog('OperatorID: ' + this.props.operatorId + "AddScheduleChangeView -->  Constructor() --> Start Date: " + startDate + ", End Date:" + endDate);

    }

    componentWillReceiveProps(nextProps) {
        if ((_.isEmpty(this.props.deviceTypeListMap)) && nextProps.deviceTypeListMap != null && !_.isEmpty(nextProps.deviceTypeListMap)) {
            const index = 0;
            const deviceTypeCode = Object.values(
                nextProps.deviceTypeListMap
            ).reverse()[index];

            this.setState({
                newScheduleChanges: {
                    ...this.state.newScheduleChanges,
                    deviceType: deviceTypeCode
                }
            }, () => {
                nextProps.fetchDeviceListByDeviceType(deviceTypeCode);
                nextProps.fetchFieldListByDeviceType(
                    deviceTypeCode === 5 ? 0 : deviceTypeCode
                );
            });

        }
        if (nextProps.isHavingPartition === false) {
            alert("Sorry, you dont haveGeneric or this device specific partition");
            this.setState({ collapseButtonPressed: true, dataEntered: false });
        }
    }

    componentWillUnmount() {
        this.setState({
            ...this.state,
            collapseButtonPressed: false,
            dataEntered: false
        });
    }
    toCamelCase(str) {
        if (str == null) return '';
        return str.substring(0, 1).toUpperCase() + str.substring(1);
    }

    loadInitialData() {
        // Device Type Selection for first time

        const index = 0;
        const deviceTypeCode = Object.values(
            this.props.deviceTypeListMap
        ).reverse()[index];

        this.setState({
            newScheduleChanges: {
                ...this.state.newScheduleChanges,
                deviceType: deviceTypeCode
            }
        });

        console.log('device type---', this.state.newScheduleChanges);
        this.props.fetchDeviceListByDeviceType(deviceTypeCode);

        this.props.fetchFieldListByDeviceType(
            deviceTypeCode === 5 ? 0 : deviceTypeCode
        );

        // field list selection for first time

        // device selection for first time
        if (this.props.deviceList.length === 1) {
            this.setState({
                newScheduleChanges: {
                    ...this.state.newScheduleChanges,
                    deviceNo: this.props.deviceList[0].valueMember,
                    deviceText: this.props.deviceList[0].displayMember,
                    panelNo: this.props.deviceList[0].pnlref
                }
            });
        }

        if (this.props.deviceList.length >= 1) {
            const deviceInfo = this.props.deviceList[0];

            console.log('device info', deviceInfo);

            this.state = {
                ...this.state,
                newScheduleChanges: {
                    ...this.state.newScheduleChanges,
                    deviceNo: deviceInfo.valueMember,
                    deviceText: this.props.deviceList[0].displayMember,
                    panelNo: deviceInfo.pnlref
                }
            };
        }
    }

    showDeviceTypeList(deviceTypeList) {
        const deviceTypes = deviceTypeList.map(deviceType => (
            <option key={deviceType} style={styles.option}>
                {this.toCamelCase(deviceType)}
            </option>
        ));
        return (
            <select
                style={styles.selectBox}
                onChange={this.handleDeviceTypeSelectionChanged}
            >
                {deviceTypes}
            </select>
        );
    }

    showDeviceList(deviceList) {
        const devices = deviceList.map((device, index) => (
            <option key={index} style={styles.option}>
                {device.displayMember}
            </option>
        ));
        return (
            <select
                style={styles.selectBox}
                onChange={this.handleDeviceSelectionChanged}
            >
                {devices}
            </select>
        );
    }

    showFieldList(fieldList) {
        const fields = fieldList.map(field => (
            <option key={field.valueMember} style={styles.option}>
                {field.displayMember}
            </option>
        ));
        return (
            <select
                name="selfieldList"
                style={styles.selectBox}
                onChange={this.handleFieldSelectionChanged}
            >
                {fields}
            </select>
        );
    }

    showTimeScheduleList(timeScheduleList) {
        const timeSchedules = timeScheduleList.map(timeSchedule => (
            <option key={timeSchedule.valueMember} style={styles.option}>
                {timeSchedule.displayMember}
            </option>
        ));
        return (
            <select
                style={styles.selectBox}
                onChange={this.handleTimeScheduleSelectionChanged}
            >
                {timeSchedules}
            </select>
        );
    }

    handleDescriptionChanged(event) {
        console.log('description', event.target.value);
        this.setState({
            ...this.state,
            newScheduleChanges: {
                ...this.state.newScheduleChanges,
                description: event.target.value
            },
            dataEntered: true
        });
    }

    handleDeviceTypeSelectionChanged(event) {
        const index = Object.keys(this.props.deviceTypeListMap)
            .reverse()
            .indexOf(event.target.value.toLowerCase());

        //const deviceTypeCode = Object.values(
        //    this.props.deviceTypeListMap
        //).reverse()[index];
      const deviceTypeCode= this.props.deviceTypeListMap[event.target.value.toLowerCase()];



        this.setState({
            ...this.state,
            selectedDeviceTypeIndex: index,
            newScheduleChanges: {
                ...this.state.newScheduleChanges,
                deviceType: deviceTypeCode
            },
            dataEntered: true
        }, () => {

            this.props.fetchDeviceListByDeviceType(deviceTypeCode);
            if (this.props.deviceList.length === 1) {
                this.setState({
                    ...this.state,
                    newScheduleChanges: {
                        ...this.state.newScheduleChanges,
                        deviceNo: this.props.deviceList[0].valueMember,
                        deviceText: this.props.deviceList[0].displayMember,
                        panelNo: this.props.deviceList[0].pnlref
                    },
                    dataEntered: true
                });
            }

            this.props.fetchFieldListByDeviceType(
                deviceTypeCode === 5 ? 0 : deviceTypeCode
            );

            if (this.props.fieldList.length === 1) {
                this.setState({
                    ...this.state,
                    newScheduleChanges: {
                        ...this.state.newScheduleChanges,
                        fieldNo: parseInt(this.props.fieldList[0].valueMember, 10),
                        fieldInText: this.props.fieldList[0].displayMember
                    },
                    dataEntered: true
                });
            }
        });

      
    }

    handleDeviceSelectionChanged(event) {
        const deviceInfo = this.props.deviceList.filter(device => {
            if (device.displayMember === event.target.value) return device;
            return null;
        })[0];

        console.log('device info', deviceInfo);

        this.setState({
            ...this.state,
            newScheduleChanges: {
                ...this.state.newScheduleChanges,
                deviceNo: deviceInfo.valueMember,
                deviceText: deviceInfo.displayMember,
                panelNo: deviceInfo.pnlref
            },
            dataEntered: true
        });
    }

    handleFieldSelectionChanged(event) {
        const fieldInfo = this.props.fieldList.filter(field => {
            if (field.displayMember === event.target.value) return field;
            return null;
        })[0];

        console.log('field info:', fieldInfo);

        this.setState({
            ...this.state,
            newScheduleChanges: {
                ...this.state.newScheduleChanges,
                fieldNo: parseInt(fieldInfo.valueMember, 10),
                fieldInText: fieldInfo.displayMember
            },
            dataEntered: true
        });

        console.log('New Schedule :', this.state.newScheduleChanges);
    }

    handleTimeScheduleSelectionChanged(event) {
        const timeScheduleInfo = this.props.timeSchedules.filter(timeSchedule => {
            if (timeSchedule.displayMember.trim() === event.target.value.trim()) {
                return timeSchedule;
            }
            return null;
        })[0];

        console.log('timeSchedule info:', timeScheduleInfo);

        this.setState({
            ...this.state,
            newScheduleChanges: {
                ...this.state.newScheduleChanges,
                scheduleNo: timeScheduleInfo.valueMember
            },
            dataEntered: true
        });

        console.log('New Schedule :', this.state.newScheduleChanges);
    }

    handleStartDateChanged(date) {
        console.log('Start Date: ', date);

        this.setState({
            ...this.state,
            startDate: date,
            newScheduleChanges: {
                ...this.state.newScheduleChanges,
                fromDate: moment(date, 'MM/DD/YYYY kk:mm A').toISOString()
            },
            dataEntered: true
        });
    }

    handleEndDateChanged(date) {
        console.log('End Date: ', date);
        const endDate = date;

        if (moment(date).isSameOrBefore(this.state.startDate)) {
            alert('End Date can not be same or before start date.');
            return;
        }

        this.setState({
            ...this.state,
            endDate: date,
            newScheduleChanges: {
                ...this.state.newScheduleChanges,
                toDate: moment(endDate, 'MM/DD/YYYY kk:mm A').toISOString()
            },
            dataEntered: true
        });
    }

    render() {
        console.log(
            'newScheduleChanges created:',
            this.props.newScheduleChangesCreated
        );
        if (this.props.newScheduleChangesCreated === true) {
            this.props.resetNewScheduledChangesCreatedStatus();
            return <Redirect to="/scheduledchanges" />;
        }

        if (this.state.collapseButtonPressed === true) {
            if (this.state.dataEntered === true) {
                alert('Press Save button to save the changes or Cancel to discard.');
                this.setState({ ...this.state, collapseButtonPressed: false });
            } else return <Redirect to="/scheduledchanges" />;
        }

        if (this.props.msg && this.props.msg.length > 0) {
            alert(this.props.msg);
            this.props.resetMessage();
        }

        return (
            <div style={styles.container}>
                <div>
                    <div style={styles.expandContainer}>
                        <div
                            onClick={() => {
                                this.setState({ ...this.state, collapseButtonPressed: true });
                            }}
                        >
                            <FontAwesomeIcon icon={faMinus} size="1x" color={Colors.White} />
                        </div>

                        <div style={styles.functionText}>Add New Scheduled Changes</div>
                    </div>
                    <div style={styles.blackLine} />

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            marginLeft: 10,
                            backgroundColor: Colors.White
                        }}
                    >
                        <div style={styles.groupHeader}>Devices To Change</div>
                        <div style={styles.descriptionRow}>
                            Description
              <input
                                type="text"
                                name="description"
                                value={this.state.newScheduleChanges.description}
                                onChange={this.handleDescriptionChanged}
                                style={styles.description}
                            />
                        </div>
                        <div style={styles.deviceTypeListContainer}>
                            <p style={{ width: 80 }}>Device </p>
                            {this.showDeviceTypeList(
                                Object.keys(this.props.deviceTypeListMap).reverse()
                            )}
                        </div>
                        <div style={styles.deviceListContainer}>
                            <p style={{ width: 80 }}>
                                {this.toCamelCase(
                                    _.invert(this.props.deviceTypeListMap) [this.state.newScheduleChanges.deviceType]
                                )}
                            </p>
                            {this.showDeviceList(
                                this.props.deviceList ? this.props.deviceList : []
                            )}
                        </div>
                        <div style={styles.fieldListContainer}>
                            <p style={{ width: 80 }}>Field </p>
                            {this.showFieldList(
                                this.props.fieldList ? this.props.fieldList : []
                            )}
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            marginLeft: 10,
                            backgroundColor: Colors.White
                        }}
                    >
                        <div style={styles.groupHeader}>Change To Be Made</div>
                        <div style={styles.timeScheduleListContainer}>
                            <p style={{ width: 200, fontSize: 15, textAlign: 'auto' }}>
                                New Time Schedule{' '}
                            </p>
                            {this.showTimeScheduleList(this.props.timeSchedules)}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                                marginLeft: 10,
                                backgroundColor: Colors.White,
                                marginTop: 10
                            }}
                        >
                            <p>Effective Date Range </p>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: 30,
                                    flex: 1
                                }}
                            >
                                <p style={{ width: 70, textAlign: 'left' }}>From</p>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.handleStartDateChanged}
                                    showTimeSelect
                                    timeFormat="hh a"
                                    readonly
                                    withPortal
                                    timeIntervals={60}
                                    disabledKeyboardNavigation
                                    timeCaption="time"
                                    // dateFormat="MMMM d, yyyy h:mm aa"
                                    dateFormat="MM/dd/yyyy  h a"
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: 30,
                                    flex: 1,
                                    paddingTop: 5
                                }}
                            >
                                <p style={{ width: 70, display: 'flex', textAlign: 'left' }}>
                                    To
                </p>
                                <DatePicker
                                    selected={this.state.endDate}
                                    onChange={this.handleEndDateChanged}
                                    showTimeSelect
                                    timeFormat="hh a"
                                    readonly
                                    withPortal
                                    disabledKeyboardNavigation
                                    timeIntervals={60}
                                    timeCaption="time"
                                    dateFormat="MM/dd/yyyy  h a"
                                />
                            </div>
                        </div>

                        <div style={styles.buttonContainer}>
                            <Link style={styles.underlineless} to="/scheduledchanges">
                                <ButtonView label="Cancel" instyle={styles.cancel} />
                            </Link>

                            <Link style={styles.underlineless} to="/scheduledchanges">
                                <ButtonView
                                    label="Save"
                                    instyle={styles.save}
                                    onPress={event => {
                                        event.preventDefault();

                                        if (
                                            this.state.newScheduleChanges.description.length === 0
                                        ) {
                                            alert('Please enter description.');
                                            return;
                                        }

                                        if (this.state.newScheduleChanges.deviceType.length === 0) {
                                            alert('Please select device type.');
                                            return;
                                        }

                                        if (this.state.newScheduleChanges.fieldNo.length === 0) {
                                            alert('Please select field.');
                                            return;
                                        }

                                        if (this.state.newScheduleChanges.deviceNo.length === 0) {
                                            alert('Please select device item.');
                                            return;
                                        }

                                        if (
                                            this.state.newScheduleChanges.deviceNo === 0 ||
                                            this.state.newScheduleChanges.deviceNo === '0'
                                        ) {
                                            alert('Please select device item.');
                                            return;
                                        }

                                        if (this.state.newScheduleChanges.fromDate.length === 0) {
                                            alert('Please select start date.');
                                            return;
                                        }
                                        if (this.state.newScheduleChanges.toDate.length === 0) {
                                            alert('Please select end date.');
                                            return;
                                        }

                                        this.props.writeLog('OperatorID: ' + this.props.operatorId + "AddScheduleChangeView --> SaveNewSchedule()--> NewSchedule : " + JSON.stringify(this.state.newScheduleChanges));

                                        this.props.createNewScheduleChanges(
                                            this.state.newScheduleChanges
                                        );
                                    }}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
                <div>
                    <p style={styles.message}>{this.props.msg}</p>
                </div>
                {this.props.loading === true && (
                    <div>
                        <ModalProgressView
                            Message="Please wait..."
                            onCloseModal={() => {
                                this.props.closeLoaderModal();
                            }}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const styles = {
    underlineless: {
        textDecoration: 'none'
    },
    message: {
        fontSize: 15,
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selectBox: {
        backgroundColor: Colors.VeryPaleCyan,
        padding: 4,
        height: 35,
        flex: 2,
        width: 120
    },
    groupHeader: {
        backgroundColor: Colors.VividBlue,
        color: Colors.White,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 8,
        marginRight: 8,
        marginTop: 8
    },
    deviceTypeListContainer: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        height: 25,
        flexDirection: 'row',
        marginLeft: 8,
        marginRight: 8,
        marginTop: 5,
        paddingTop: 5
    },
    deviceListContainer: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        height: 25,
        flexDirection: 'row',
        marginLeft: 8,
        marginRight: 8,
        marginTop: 8,
        paddingTop: 5
    },
    fieldListContainer: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        height: 25,
        flexDirection: 'row',
        marginLeft: 8,
        marginRight: 8,
        marginTop: 8,
        paddingTop: 5
    },
    timeScheduleListContainer: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        height: 76,
        justifyContent: 'flex-end',
        flexDirection: 'row',
        marginLeft: 8,
        marginRight: 8,
        marginTop: 5
    },
    description: {
        height: 30,
        marginLeft: 8,
        marginRight: 8,
        width: '95%',
        backgroundColor: Colors.VeryPaleCyan
    },
    descriptionRow: {
        height: 32,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        padding: 5,
        paddingRight: 1,
        marginTop: 5
    },
    selectedItemStyle: {
        display: 'flex',
        flex: 1,
        height: 50,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.Cream
    },
    itemStyle: {
        display: 'flex',
        flex: 1,
        height: 50,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.White
    },
    add: {
        margin: 2,
        width: 80,
        height: 32,
        fontSize: 15,
        color: Colors.White,
        textAlign: 'center',
        backgroundColor: Colors.MostPureBlue,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 10,
        cursor: 'pointer'
    },
    delete: {
        margin: 2,
        width: 80,
        height: 32,
        color: Colors.White,
        fontSize: 15,
        textAlign: 'center',
        backgroundColor: Colors.Red,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 10
    },
    alldays: {
        display: 'flex',
        flex: 1,
        height: 50,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.LightGray
    },
    other: {
        display: 'flex',
        flex: 1,
        height: 50,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.White
    },

    listContainer: {
        height: 180,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'black',
        overflowY: 'scroll',
        fontWeight: 'bold',
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 20,
        borderRadius: 6
    },
    listTitle: { textAlign: 'left', display: 'flex', flex: 1, paddingLeft: 8 },
    titleContainer: {
        marginLeft: 10,
        marginRight: 10,
        paddingLeft: 10,
        flex: 1,
        fontSize: 15,
        height: 28,
        display: 'flex',
        fontWeight: 'bold',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row'
    },
    container: {
        display: 'flex',
        flex: 1,
        height: 'auto',
        justifyContent: 'center',
        marginRight: 20,
        flexDirection: 'column',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: Colors.Green,
        backgroundColor: Colors.White
    },
    buttonContainer: {
        flex: 1,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginLeft: 30,
        marginRight: 6.5
    },
    save: {
        margin: 2,
        width: 80,
        height: 32,
        fontSize: 15,
        color: Colors.White,
        textAlign: 'center',
        backgroundColor: Colors.MostPureBlue,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    cancel: {
        margin: 2,
        width: 80,
        height: 32,
        color: Colors.Black,
        fontSize: 15,
        textAlign: 'center',
        backgroundColor: Colors.LightGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    expandContainer: {
        flex: 1,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        paddingLeft: 10,
        backgroundColor: Colors.Green,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10
    },

    functionText: {
        color: Colors.White,
        textAlign: 'left',
        marginLeft: 12,
        fontSize: 18,
        fontWeight: 800
    },
    horizontalLine: {
        height: 1,
        width: '100%',
        borderBottom: '1px solid',
        borderColor: Colors.Purple
    }
};

const mapStateToProps = state => ({
    accessToken: state.token.accessToken,
    operatorId: state.token.operatorId,
    scheduledChanges: state.scheduledChanges.scheduledChanges,
    msg: state.scheduledChanges.msg,
    timeSchedules: state.scheduledChanges.timeSchedules,
    deviceTypeListMap: state.status.deviceTypeListMap,
    selectedDeviceType: state.scheduledChanges.selectedDeviceType,
    selectedDevice: state.scheduledChanges.selectedDevice,
    selectedField: state.scheduledChanges.selectedField,
    deviceList: state.scheduledChanges.deviceList,
    fieldList: state.scheduledChanges.fieldList,
    newScheduleChangesCreated: state.scheduledChanges.newScheduleChangesCreated,
    loading: state.loading.loading,
    msg: state.message.msg,
    isHavingPartition: state.partition.isHavingPartition,
    operatorId: state.token.operatorId
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setCurrentPageName: actions.setCurrentPageName,
            fetchDeviceTypeList: actions.fetchDeviceTypeList,
            fetchDeviceListByDeviceType: actions.fetchDeviceListByDeviceType,
            fetchFieldListByDeviceType: actions.fetchFieldListByDeviceType,
            fetchTimeSchedules: actions.fetchTimeSchedules,
            createNewScheduleChanges: actions.createNewScheduleChanges,
            resetNewScheduledChangesCreatedStatus:
                actions.resetNewScheduledChangesCreatedStatus,
            resetMessage: actions.resetMessage,
            checkIfPartitionExists: actions.checkIfPartitionExists,
            resetPartitionExistsStatus: actions.resetPartitionExistsStatus,
            writeLog: actions.writeLog
        },
        dispatch
    );

export default Radium(
    connect(mapStateToProps, mapDispatchToProps)(AddScheduleChangeView)
);

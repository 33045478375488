import React, { Component } from 'react';
import Radium from 'radium';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import * as Colors from '../../consts/colors';
import PersonnelListContainer from './PersonnelListContainer.js';
import PersonnelSearchView from './PersonnelSearchView';
import ButtonView from '../common/ButtonView.js';
import * as actions from '../../actions/index';
import ModalView from '../common/ModalView';
import {
    PAGE_NAMES,
    SORTING_ORDER,
    SECURITY_LEVEL_CODES,
    ADMIN_ROLE_ID,
    SCREEN_CODES
} from '../../consts/consts';

class PersonnelView extends Component {
    constructor(props) {
        super(props);

        const personnelPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.PERSONNEL
        );

        this.state = {
            showModal: false,
            lastNameSortedAscending: false,
            firstNameSortedDescending: false,
            filteredPersonnels: [],
            selectedItemIndex: props.defaultSelectedIndex.selectedItemIndex,
            personalSortBy: 'lastName',
            personnelSortOrder: SORTING_ORDER.ASCENDING,
            filterOption: 'firstName',
            filterText: '',
            personnelPrivilege,
            reSetNeeded: false
        };

        this.props.setCurrentPageName(PAGE_NAMES.PERSONNEL);
        this.selectListItem = this.selectListItem.bind(this);
        this.setSearchCriteria = this.setSearchCriteria.bind(this);
        this.props.writeLog('OperatorID: ' + this.props.operatorId + "PersonnelView -->  Constructor()  Personnel Priviledge-->  " + JSON.stringify(personnelPrivilege));


    }

    componentDidUpdate(prevProps) {
        if (this.props.personnelDeleted || this.state.reSetNeeded === true || (prevProps.isConnected === true && this.props.isConnected === false) || (prevProps.isConnected === false && this.props.isConnected === true)) {
            this.props.resetPersonnel();
            this.props.fetchPersonnelsCount(
                this.state.filterOption,
                this.state.filterText
            );
            this.setState({
                reSetNeeded: false
            });
        }
        if (prevProps.isConnected === false && this.props.isConnected === true) {
             this.setState({
                reSetNeeded: true
            })
        }
    }

    onCloseModal() {
        this.setState({ showModal: false });
    }

    setSearchCriteria(option, text) {
        this.setState({
            filterText: text,
            filterOption: option,
            selectedItemIndex: -1
        });
    }

    selectListItem(indexNo) {
        this.setState({ selectedItemIndex: indexNo });
    }

    sortPersonnelData(sortBy, sortOrder) {
        if (sortOrder === SORTING_ORDER.ASCENDING) {
            sortOrder = SORTING_ORDER.DESCENDING;
        } else {
            sortOrder = SORTING_ORDER.ASCENDING;
        }

        if (this.state.personalSortBy !== sortBy) {
            sortOrder = SORTING_ORDER.ASCENDING;
        }

        this.setState({ personalSortBy: sortBy });
        this.setState({ personnelSortOrder: sortOrder });
        //this.props.sortFlagSetup(sortBy, sortOrder);
        //this.props.sortPersonnels(this.props.personnels);
        this.setState({ reSetNeeded: true });
    }

    filter(personnelList, searchOption, searchText) {
        if (searchOption === 'firstName') {
            return personnelList.filter(person =>
                person.firstName.toLowerCase().includes(searchText)
            );
        } else if (searchOption === 'lastName') {
            return personnelList.filter(person =>
                person.lastName.toLowerCase().includes(searchText)
            );
        } else if (searchOption === 'badge') {
            return personnelList.filter(person =>
                person.badge.toLowerCase().includes(searchText)
            );
        }

        return personnelList;
    }

    warningDialog() {
        this.setState({ showModal: true });
    }

    confirmDialog() {
        const firstName = this.props.personnels[this.state.selectedItemIndex]
            .firstName;

        const tmpSelectedBadgeOfUser = typeof (this.props.tmpSelectedBadgeOfUser) !== 'undefined' && this.props.tmpSelectedBadgeOfUser && this.props.tmpSelectedBadgeOfUser.Badge && this.props.tmpSelectedBadgeOfUser.Facility >= 0 && this.props.tmpSelectedBadgeOfUser;

        if (this.props.personnels[this.state.selectedItemIndex] && this.props.personnels[this.state.selectedItemIndex].badge && this.props.personnels[this.state.selectedItemIndex].facility && tmpSelectedBadgeOfUser && tmpSelectedBadgeOfUser.Badge.toString() === this.props.personnels[this.state.selectedItemIndex].badge.toString() && tmpSelectedBadgeOfUser.Facility.toString() === this.props.personnels[this.state.selectedItemIndex].facility.toString() ) {
              alert('Badge information cannot be modified which is currently being used in Badge Holder\'s Link for My Door.');
              return;
        }

        const isConfirmed = window.confirm(`Confirm delete ${firstName}?`);

        if (isConfirmed === true) {
            this.props.deletePersonnel(
                this.props.personnels[this.state.selectedItemIndex]
            );
            this.setState({ selectedItemIndex: -1 });
        }
    }

    render() {
        const connectedComponent = (<div>
            {this.props.roleId === ADMIN_ROLE_ID && (
                <div>
                    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <PersonnelSearchView
                            onChangeText={this.setSearchCriteria}
                            SearchButtonPressed={() => {
                                console.log(this.state);
                                this.setState({ reSetNeeded: true });
                            }}
                        />
                    </div>
                    <div style={styles.buttonContainer}>
                        <Link
                            style={styles.underlineless}
                            to={{
                                pathname: '/addpersonnel',
                                state: {
                                    currentIndexPosition: this.state.selectedItemIndex
                                }
                            }}
                        >
                            <ButtonView label="Add" instyle={styles.add} />
                        </Link>
                        <Link
                            style={styles.underlineless}
                            params={{ testvalue: 'hello' }}
                            to={
                                this.state.selectedItemIndex !== -1
                                    ? {
                                        pathname: '/editpersonnel',
                                        state: {
                                            currentPersonnel: this.props.personnels[
                                                this.state.selectedItemIndex
                                            ],
                                            currentIndexPosition: this.state.selectedItemIndex
                                        }
                                    }
                                    : {}
                            }
                        >
                            <ButtonView
                                onPress={() => {
                                    this.state.selectedItemIndex === -1 && this.warningDialog();
                                    
                                }}
                                label="Edit"
                                enabled={this.state.selectedItem === -1 ? false : true}
                                instyle={this.state.selectedItemIndex=== -1? styles.editDisabled: styles.edit}
                            />
                        </Link>
                        <ButtonView
                            label="Delete"
                            enabled={this.state.selectedItem === -1 ? false : true}
                            instyle={this.state.selectedItemIndex === -1 ? styles.deleteDisabled : styles.delete}
                            onPress={() => {
                                this.state.selectedItemIndex !== -1
                                    ? this.confirmDialog()
                                    : this.warningDialog();
                            }}
                        />
                    </div>
                    <div style={styles.titleContainer}>
                        <div
                            style={styles.lastName}
                            onClick={() =>
                                this.sortPersonnelData(
                                    'lastName',
                                    this.state.personnelSortOrder
                                )
                            }
                        >
                            Last Name
                {this.state.personnelSortOrder === 1 &&
                                this.state.personalSortBy === 'lastName' ? (
                                    <span style={styles.indicator}>&#8593;</span>
                                ) : (
                                    ''
                                )}
                            {this.state.personnelSortOrder === 2 &&
                                this.state.personalSortBy === 'lastName' ? (
                                    <span style={styles.indicator}>&#8595;</span>
                                ) : (
                                    ''
                                )}{' '}
                            &nbsp;
              </div>
                        <div
                            style={styles.firstName}
                            onClick={() =>
                                this.sortPersonnelData(
                                    'firstName',
                                    this.state.personnelSortOrder
                                )
                            }
                        >
                            First Name
                {this.state.personnelSortOrder === 1 &&
                                this.state.personalSortBy === 'firstName' ? (
                                    <span style={styles.indicator}>&#8593;</span>
                                ) : (
                                    ''
                                )}
                            {this.state.personnelSortOrder === 2 &&
                                this.state.personalSortBy === 'firstName' ? (
                                    <span style={styles.indicator}>&#8595;</span>
                                ) : (
                                    ''
                                )}
                        </div>
                        <div style={styles.facility}>Facility </div>
                        <div
                            onClick={() =>
                                this.sortPersonnelData('badge', this.state.personnelSortOrder)
                            }
                            style={styles.badge}
                        >
                            Badge
                {this.state.personnelSortOrder === 1 &&
                                this.state.personalSortBy === 'badge' ? (
                                    <span style={styles.indicator}>&#8593;</span>
                                ) : (
                                    ''
                                )}
                            {this.state.personnelSortOrder === 2 &&
                                this.state.personalSortBy === 'badge' ? (
                                    <span style={styles.indicator}>&#8595;</span>
                                ) : (
                                    ''
                                )}
                        </div>
                    </div>
                    <PersonnelListContainer
                        selectedItemIndex={this.state.selectedItemIndex}
                        selectListItem={this.selectListItem}
                        filterOption={this.state.filterOption}
                        filterText={this.state.filterText}
                        personalSortBy={this.state.personalSortBy}
                        personnelSortOrder={this.state.personnelSortOrder}
                        reSetNeeded={this.state.reSetNeeded}
                    />

                    <div style={styles.totalBadges}>
                        Total Badges : {this.props.personnelsCount}
                    </div>
                </div>
            )}

            {this.props.roleId !== ADMIN_ROLE_ID &&
                this.state.personnelPrivilege &&
                (this.state.personnelPrivilege.SecurityLevel ===
                    SECURITY_LEVEL_CODES.FULL_CONTROL ||
                    this.state.personnelPrivilege.SecurityLevel ===
                    SECURITY_LEVEL_CODES.VIEW_ONLY ||
                    this.state.personnelPrivilege.SecurityLevel ===
                    SECURITY_LEVEL_CODES.CREATE_ONLY ||
                    this.state.personnelPrivilege.SecurityLevel ===
                    SECURITY_LEVEL_CODES.EDIT_ONLY ||
                    this.state.personnelPrivilege.SecurityLevel ===
                    SECURITY_LEVEL_CODES.CREATE_EDIT_ONLY) && (
                    <div>
                        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                            <PersonnelSearchView
                                onChangeText={this.setSearchCriteria}
                                SearchButtonPressed={() => {
                                    console.log(this.state);
                                    this.setState({ reSetNeeded: true });
                                }}
                            />
                        </div>
                        {(this.state.personnelPrivilege.SecurityLevel ===
                            SECURITY_LEVEL_CODES.FULL_CONTROL ||
                            this.state.personnelPrivilege.SecurityLevel ===
                            SECURITY_LEVEL_CODES.CREATE_ONLY ||
                            this.state.personnelPrivilege.SecurityLevel ===
                            SECURITY_LEVEL_CODES.EDIT_ONLY ||
                            this.state.personnelPrivilege.SecurityLevel ===
                            SECURITY_LEVEL_CODES.CREATE_EDIT_ONLY) && (
                                <div style={styles.buttonContainer}>
                                    {(this.state.personnelPrivilege.SecurityLevel ===
                                        SECURITY_LEVEL_CODES.FULL_CONTROL ||
                                        this.state.personnelPrivilege.SecurityLevel ===
                                        SECURITY_LEVEL_CODES.CREATE_ONLY ||
                                        this.state.personnelPrivilege.SecurityLevel ===
                                        SECURITY_LEVEL_CODES.CREATE_EDIT_ONLY) && (
                                            <Link
                                                style={styles.underlineless}
                                                to={{
                                                    pathname: '/addpersonnel',
                                                    state: {
                                                        currentIndexPosition: this.state.selectedItemIndex
                                                    }
                                                }}
                                            >
                                                <ButtonView label="Add" instyle={styles.add} />
                                            </Link>
                                        )}

                                    {(this.state.personnelPrivilege.SecurityLevel ===
                                        SECURITY_LEVEL_CODES.FULL_CONTROL ||
                                        this.state.personnelPrivilege.SecurityLevel ===
                                        SECURITY_LEVEL_CODES.EDIT_ONLY ||
                                        this.state.personnelPrivilege.SecurityLevel ===
                                        SECURITY_LEVEL_CODES.CREATE_EDIT_ONLY) && (
                                            <Link
                                                style={styles.underlineless}
                                                params={{ testvalue: 'hello' }}
                                                to={
                                                    this.state.selectedItemIndex !== -1
                                                        ? {
                                                            pathname: '/editpersonnel',
                                                            state: {
                                                                currentPersonnel: this.props.personnels[
                                                                    this.state.selectedItemIndex
                                                                ],
                                                                currentIndexPosition: this.state
                                                                    .selectedItemIndex
                                                            }
                                                        }
                                                        : {}
                                                }
                                            >
                                                <ButtonView
                                                    onPress={() => {
                                                        this.state.selectedItemIndex === -1 &&
                                                            this.warningDialog();
                                                    }}
                                        label="Edit"
                                        enabled={this.state.selectedItem === -1 ? false :true}
                                        instyle={this.state.selectedItemIndex === -1 ? styles.editDisabled : styles.edit}
                                                />
                                            </Link>
                                        )}

                                    {this.state.personnelPrivilege.SecurityLevel ===
                                        SECURITY_LEVEL_CODES.FULL_CONTROL && (
                                            <ButtonView
                                    label="Delete"
                                    enabled={this.state.selectedItem === -1 ? false : true}
                                    instyle={this.state.selectedItemIndex === -1 ? styles.deleteDisabled : styles.delete}
                                                onPress={() => {
                                                    this.state.selectedItemIndex !== -1
                                                        ? this.confirmDialog()
                                                        : this.warningDialog();
                                                }}
                                            />
                                        )}
                                </div>
                            )}

                        {(this.state.personnelPrivilege.SecurityLevel ===
                            SECURITY_LEVEL_CODES.FULL_CONTROL ||
                            this.state.personnelPrivilege.SecurityLevel ===
                            SECURITY_LEVEL_CODES.CREATE_ONLY ||
                            this.state.personnelPrivilege.SecurityLevel ===
                            SECURITY_LEVEL_CODES.EDIT_ONLY ||
                            this.state.personnelPrivilege.SecurityLevel ===
                            SECURITY_LEVEL_CODES.CREATE_EDIT_ONLY ||
                            this.state.personnelPrivilege.SecurityLevel ===
                            SECURITY_LEVEL_CODES.VIEW_ONLY) && (
                                <div>
                                    <div style={styles.titleContainer}>
                                        <div
                                            style={styles.lastName}
                                            onClick={() =>
                                                this.sortPersonnelData(
                                                    'lastName',
                                                    this.state.personnelSortOrder
                                                )
                                            }
                                        >
                                            Last Name
                      {this.state.personnelSortOrder === 1 &&
                                                this.state.personalSortBy === 'lastName' ? (
                                                    <span style={styles.indicator}>&#8593;</span>
                                                ) : (
                                                    ''
                                                )}
                                            {this.state.personnelSortOrder === 2 &&
                                                this.state.personalSortBy === 'lastName' ? (
                                                    <span style={styles.indicator}>&#8595;</span>
                                                ) : (
                                                    ''
                                                )}{' '}
                                            &nbsp;
                    </div>
                                        <div
                                            style={styles.firstName}
                                            onClick={() =>
                                                this.sortPersonnelData(
                                                    'firstName',
                                                    this.state.personnelSortOrder
                                                )
                                            }
                                        >
                                            First Name
                      {this.state.personnelSortOrder === 1 &&
                                                this.state.personalSortBy === 'firstName' ? (
                                                    <span style={styles.indicator}>&#8593;</span>
                                                ) : (
                                                    ''
                                                )}
                                            {this.state.personnelSortOrder === 2 &&
                                                this.state.personalSortBy === 'firstName' ? (
                                                    <span style={styles.indicator}>&#8595;</span>
                                                ) : (
                                                    ''
                                                )}
                                        </div>

                                        <div style={styles.facility}>Facility </div>

                                        <div
                                            onClick={() =>
                                                this.sortPersonnelData(
                                                    'badge',
                                                    this.state.personnelSortOrder
                                                )
                                            }
                                            style={styles.badge}
                                        >
                                            Badge
                      {this.state.personnelSortOrder === 1 &&
                                                this.state.personalSortBy === 'badge' ? (
                                                    <span style={styles.indicator}>&#8593;</span>
                                                ) : (
                                                    ''
                                                )}
                                            {this.state.personnelSortOrder === 2 &&
                                                this.state.personalSortBy === 'badge' ? (
                                                    <span style={styles.indicator}>&#8595;</span>
                                                ) : (
                                                    ''
                                                )}
                                        </div>
                                    </div>
                                    <PersonnelListContainer
                                        selectedItemIndex={this.state.selectedItemIndex}
                                        selectListItem={this.selectListItem}
                                        filterOption={this.state.filterOption}
                                        filterText={this.state.filterText}
                                        personalSortBy={this.state.personalSortBy}
                                        personnelSortOrder={this.state.personnelSortOrder}
                                        reSetNeeded={this.state.reSetNeeded}
                                    />
                                    <div style={styles.totalBadges}>
                                        Total Badges : {this.props.personnelsCount}
                                    </div>
                                </div>
                            )}
                    </div>
                )}

            <div>
                {this.state.showModal === true && (
                    <ModalView
                        onClose={this.onCloseModal}
                        dialogStyle={styles.dialogStyle}
                        shouldCloseOnOverlayClick
                    >
                        <div
                            style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                            >
                                <p
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        fontSize: 16,
                                        justifyContent: 'center'
                                    }}
                                >
                                    {' '}
                                    Please select an item.
                  </p>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    marginTop: '16px'
                                }}
                            >
                                <ButtonView
                                    label="CLOSE"
                                    onPress={() => {
                                        this.onCloseModal();
                                    }}
                                    instyle={styles.cancel}
                                />
                            </div>
                        </div>
                    </ModalView>
                )}
            </div>

        </div>);
        const disConnectedComponent = <div style={{ display: 'flex', justifyContent: 'center', color: 'red', fontWeight: 'bold' }}>{<p>System Offline</p>}</div>;

        return (
            <div style={styles.container}>
                <div style={styles.expandContainer}>
                    <Link style={styles.underlineless} to="/home">
                        <div>
                            <FontAwesomeIcon icon={faMinus} size="1x" color={Colors.White} />
                        </div>
                    </Link>
                    <div style={styles.functionText}>Personnel</div>
                </div>
                <div style={styles.blackLine} />

                {this.props.isConnected ? connectedComponent : disConnectedComponent}

            </div>
        );
    }
}

const styles = {
    underlineless: {
        textDecoration: 'none'
    },
    firstName: {
        display: 'flex',
        flex: 1,
        fontSize: '0.9em',
        width: 80,
        overflow: 'hidden',
        paddingRight: 1
    },
    lastName: {
        display: 'flex',
        flex: 1,
        fontSize: '0.9em',
        width: 80,
        paddingRight: 2,
        overflow: 'hidden'
    },
    add: {
        margin: 2,
        width: 90,
        height: 32,
        fontSize: 14,
        color: Colors.White,
        textAlign: 'center',
        backgroundColor: Colors.MostPureBlue,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 4,
        borderColor: Colors.Black,
        cursor: 'pointer',
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    edit: {
        margin: 2,
        width: 90,
        height: 32,
        fontSize: 14,
        color: Colors.White,
        textAlign: 'center',
        display: 'inline-block',
        textDecoration: 'none',
        backgroundColor: Colors.Orange,
        borderWidth: '0.5px',
        borderStyle: 'solid',
        borderRadius: 4,
        borderColor: Colors.Black,
        cursor: 'pointer',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    editDisabled: {
        margin: 2,
        width: 90,
        height: 32,
        fontSize: 14,
        color: Colors.White,
        textAlign: 'center',
        display: 'inline-block',
        textDecoration: 'none',
        backgroundColor: Colors.LightGray,
        borderWidth: '0.5px',
        borderStyle: 'solid',
        borderRadius: 4,
        borderColor: Colors.Black,
        cursor: 'pointer',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.LightGray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    search: {
        margin: 2,
        width: 69,
        height: 32,
        color: Colors.White,
        fontSize: 14,
        textAlign: 'center',
        backgroundColor: Colors.DeepGreen,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 4,
        borderColor: Colors.Black,
        cursor: 'pointer',
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },

    delete: {
        margin: 2,
        width: 90,
        height: 32,
        color: Colors.White,
        fontSize: 14,
        textAlign: 'center',
        backgroundColor: Colors.Red,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 4,
        borderColor: Colors.Black,
        cursor: 'pointer',
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },

    deleteDisabled: {
        margin: 2,
        width: 90,
        height: 32,
        color: Colors.White,
        fontSize: 14,
        textAlign: 'center',
        backgroundColor: Colors.LightGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 4,
        borderColor: Colors.Black,
        cursor: 'pointer',
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.LightGray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    }
    ,
    listContainer: {
        height: 180,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'black',
        overflowY: 'scroll',
        fontWeight: 'bold',
        marginLeft: 8,
        fontSize: 14,
        marginRight: 8,
        marginBottom: 20,
        backgroundColor: Colors.White,
        paddingBottom: 20,
        paddingTop: 10,
        borderRadius: 0
    },
    container: {
        display: 'flex',
        flex: 1,
        height: 'auto',
        justifyContent: 'center',
        marginRight: 20,
        flexDirection: 'column',
        fontSize: 14,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 10,
        borderColor: Colors.Green,
        backgroundColor: Colors.White
    },
    expandContainer: {
        flex: 1,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        paddingLeft: 10,
        backgroundColor: Colors.Green,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10
    },
    facility: {
        flex: 1,
        width: 24,
        fontSize: 12,
        display: 'flex',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 5
    },
    badge: {
        flex: 1,
        width: 70,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight: 25
    },
    totalBadges: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight: 40,
        marginBottom: 10
    },
    titleContainer: {
        marginLeft: 20,
        //marginRight: 10,
        flex: 1,
        fontSize: 12,
        marginTop: 8,
        marginBottom: 8,
        height: 45,
        display: 'flex',
        fontWeight: 'bold',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: Colors.White,
        flexDirection: 'row'
    },
    buttonContainer: {
        flex: 1,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Colors.White,
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 10,
        marginLeft: 15,
        marginRight: 15
    },

    functionText: {
        color: Colors.White,
        textAlign: 'left',
        marginLeft: 20,
        fontSize: 20,
        fontWeight: 800
    },
    horizontalLine: {
        height: 1,
        width: '100%',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.Purple
    },
    indicator: {
        color: Colors.Blue,
        fontWeight: 'Bold'
    },
    dialogStyle: {
        backgroundColor: Colors.VeryPaleBlue,
        padding: 15,
        width: 300,
        height: 'auto'
    },
    cancel: {
        margin: 2,
        width: 80,
        borderRadius: 4,
        height: 32,
        color: Colors.Black,
        fontSize: 15,
        textAlign: 'center',
        backgroundColor: Colors.LightGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer'
    }
};

const mapStateToProps = state => ({
    accessToken: state.token.accessToken,
    personnels: state.personnel.personnels,
    privileges: state.token.privileges,
    operatorId: state.token.operatorId,
    roleId: state.token.roleId,
    loading: state.loading.loading,
    personnelsCount: state.personnel.personnelsCount,
    personnelDeleted: state.personnel.personnelDeleted,
    resetPersonnel: actions.resetPersonnel,
    hasMoreItems: state.personnel.hasMoreItems,
    isConnected: state.connectStatus.connected,
    tmpSelectedBadgeOfUser: state.token.tmpSelectedBadgeOfUser,
    operatorId: state.token.operatorId
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setCurrentPageName: actions.setCurrentPageName,
            fetchPersonnelsCount: actions.fetchPersonnelsCount,
            fetchAllPersonnels: actions.fetchAllPersonnels,
            deletePersonnel: actions.deletePersonnel,
            setSearchCriteria: actions.setSearchCriteria,
            closeLoaderModal: actions.closeLoaderModal,
            resetPersonnel: actions.resetPersonnel,
            fetchPagedPersonnels: actions.fetchPagedPersonnels,
            writeLog: actions.writeLog
        },
        dispatch
    );

export default Radium(
    connect(mapStateToProps, mapDispatchToProps)(PersonnelView)
);

import {
    put,
    select,
    takeLatest,
    call,
    fork,
    cancel,
    take,
    delay
} from 'redux-saga/effects';
import { createSignalrChannel } from './signalrHub';
import * as ActionTypes from '../actions/types';
import { PAGE_NAMES, SERVER_MESSAGES, DEVICE_TYPES } from '..//consts/consts';

let startRefreshTask = null;
let startInListRefreshTask = null;
let connection = null;

export default function* refreshSaga() {
    //yield console.log('running refresh saga');

    yield takeLatest(ActionTypes.LOGIN_SUCCEEDED, startRefresh);
    yield takeLatest(ActionTypes.REFRESH_CURRENT_PAGE, startRefresh);
    yield takeLatest(ActionTypes.LOAD_ACCESSTOKEN, startRefresh);
    yield takeLatest(ActionTypes.LOGOUT, cancelRefreshing);

    yield takeLatest(ActionTypes.LOGIN_SUCCEEDED, startSignalr);
    yield takeLatest(ActionTypes.LOAD_ACCESSTOKEN, startSignalr);
    yield takeLatest(ActionTypes.REFRESH_CURRENT_PAGE, startSignalr);
    yield takeLatest(ActionTypes.LOGOUT, stopSignalr);

    yield takeLatest(ActionTypes.START_IN_LIST_REFRESH, startInListRefresh);
    yield takeLatest(ActionTypes.CANCEL_IN_LIST_REFRESH, cancelInListRefreshing);
}

function* stopSignalr() {
    yield console.log('');
    if (connection) {
        //console.log('close signalr channel');
        connection.close();
    }
}

function* startSignalr() {
    //yield console.log('Hello ... signlr');

    const baseURL = yield select(getBaseURL);

    try {
        connection = yield call(createSignalrChannel, baseURL, 'liveEventsHub');

        while (true) {
            // take(END) will cause the saga to terminate by jumping to the finally block
            const msg = yield take(connection);
            //console.log(`ALERT From channel444:: ${msg}`);
            const msgJSON = JSON.parse(msg);

            const connectStatus = yield take(getConnectionStatus);

            const refreshStatus = yield select(getRefreshStatus);

            const requestWirelessLockStatus = yield select(getRequestWirelessLockStatus);

            console.log("requestWirelessLockStatus:", requestWirelessLockStatus);

            const tmpSelectedBadgeOfUser = yield select(getTmpSelectedBadgeOfUser);
            const selectedDeviceType = yield select(getSelectedDeviceType);
            const statusMessage = connectStatus.connected
                ? connectStatus.onLineMessage
                : connectStatus.offLineMessage;

            if (connectStatus.connected === connectStatus.offLineMessage) {
                yield put({ type: ActionTypes.ADD_MESSAGE, payload: statusMessage });
            } else if (connectStatus.connected === connectStatus.onLineMessage) {
                yield put({ type: ActionTypes.ADD_MESSAGE, payload: statusMessage });
            }

            if (msgJSON.StatusType === 0) {
                const details = msgJSON.Details;
                const type = details.type;
                //console.log(`Status Type,Type ${msgJSON.StatusType}, ${type}`);
                if (type === DEVICE_TYPES.DEVICE_TYPE_DOOR) {
                    //console.log('status updated:', 'fetching doors');
                    yield put({
                        type: ActionTypes.FETCH_ALL_DOORS,
                        payload: refreshStatus
                    });

                    if (tmpSelectedBadgeOfUser && tmpSelectedBadgeOfUser.Badge) {
                        yield put({
                            type: ActionTypes.FETCH_ACCESS_READER_LIST_BY_BADGE,
                            payload: {
                                badgeNo: tmpSelectedBadgeOfUser.Badge,
                                facility: tmpSelectedBadgeOfUser.Facility,
                                requestStatus: refreshStatus,
                                wlockRequestStatus: refreshStatus === true ? true : requestWirelessLockStatus
                            }
                        });
                    }
                    yield put({ type: ActionTypes.RESET_REFRESH_STATUS });
                } else if (type === DEVICE_TYPES.DEVICE_TYPE_THREATLEVEL) {
                    //console.log('status updated:', 'fetching all threat levels');
                    yield put({ type: ActionTypes.FETCH_ALL_THREAT_LEVELS });
                    yield put({ type: ActionTypes.FETCH_ALL_LOCKDOWN_AREA }); // If lockdown is associated with threatlevel....
                } else if (type === DEVICE_TYPES.DEVICE_TYPE_LOCKDOWN) {
                    //console.log('status updated:', 'fetching lockdown areas');
                    yield put({ type: ActionTypes.FETCH_ALL_LOCKDOWN_AREA });
                    yield put({ type: ActionTypes.FETCH_ALL_THREAT_LEVELS }); // If lockdown is associated with threatlevel....
                } else if (type === DEVICE_TYPES.DEVICE_TYPE_RELAY) {
                    //console.log('status updated:', 'fetching relays');
                    yield put({
                        type: ActionTypes.FETCH_ALL_RELAYS,
                        payload: refreshStatus
                    });

                    yield put({ type: ActionTypes.RESET_REFRESH_STATUS });
                }
            } else if (msgJSON.StatusType === 1) {
                const details = msgJSON.Details ? msgJSON.Details : '';
                const message = details.message ? details.message : '';

                if (message === SERVER_MESSAGES.PANEL_STATUS) {
                    yield put({
                        type: ActionTypes.FETCH_DEVICE_STATUS_DETAIL,
                        payload: selectedDeviceType
                    });
                }

                yield put({ type: ActionTypes.RESET_REFRESH_STATUS });
            }
        }
    } catch (ex) {
        console.log(ex);
    } finally {
        //console.log('signalr client terminated');
    }
}

function* cancelRefreshing() {
    if (startRefreshTask) {
        //console.log('canceling refreshing');
        yield cancel(startRefreshTask);
    }
}

function* startRefresh() {
    //yield console.log('refresh page...!!!');
    try {
        yield call(cancelRefreshing);
        startRefreshTask = yield fork(refreshingTask);
        //yield put({ type: ActionTypes.CONNECTION_ONLINE });
    } catch (error) {
        console.log('failed...!!!');
        //  yield put({ type: ActionTypes.CONNECTION_OFFLINE });
    }
}

function* refreshingTask() {
    while (true) {
        const currentPage = yield select(getCurrentPage);
        const selectedDeviceType = yield select(getSelectedDeviceType);
        const tmpSelectedBadgeOfUser = yield select(getTmpSelectedBadgeOfUser);
        const refreshStatus = yield select(getRefreshStatus);
        const requestWirelessLockStatus = yield select(getRequestWirelessLockStatus);
        console.log("requestWirelessLockStatus:", requestWirelessLockStatus);

        if (currentPage === PAGE_NAMES.DOORS) {
            yield put({ type: ActionTypes.REFRESH_APP_CONFIG_SETTINGS });
            if (tmpSelectedBadgeOfUser && tmpSelectedBadgeOfUser.Badge) {
                yield put({
                    type: ActionTypes.FETCH_ACCESS_READER_LIST_BY_BADGE,
                    payload: {
                        badgeNo: tmpSelectedBadgeOfUser.Badge,
                        facility: tmpSelectedBadgeOfUser.Facility,
                        requestStatus: refreshStatus, 
                        wlockRequestStatus: refreshStatus === true ? requestWirelessLockStatus : false 
                    }
                });
                yield put({ type: ActionTypes.RESET_REFRESH_STATUS });
            }
        } else if (currentPage === PAGE_NAMES.THREATLEVEL) {
            yield put({ type: ActionTypes.FETCH_ALL_THREAT_LEVELS }); // If lockdown is associated with threatlevel....
            yield put({ type: ActionTypes.FETCH_ALL_LOCKDOWN_AREA });
        } else if (currentPage === PAGE_NAMES.LOCKDOWN) {
            yield put({ type: ActionTypes.FETCH_ALL_LOCKDOWN_AREA });
            yield put({ type: ActionTypes.FETCH_ALL_THREAT_LEVELS });
        } else if (currentPage === PAGE_NAMES.CONTROL) {
            yield put({
                type: ActionTypes.FETCH_ALL_RELAYS,
                payload: refreshStatus
            });
            yield put({
                type: ActionTypes.FETCH_ALL_DOORS,
                payload: refreshStatus
            });

            yield put({ type: ActionTypes.RESET_REFRESH_STATUS });

        } else if (currentPage === PAGE_NAMES.STATUS) {
            yield put({
                type: ActionTypes.FETCH_DEVICE_STATUS_DETAIL,
                payload: selectedDeviceType
            });

            yield put({ type: ActionTypes.RESET_REFRESH_STATUS });
        } else if (currentPage === PAGE_NAMES.SCHEDULEDCHANGES) {
            yield put({ type: ActionTypes.FETCH_SCHEDULE_CHANGES_LIST });
        } else if (currentPage === PAGE_NAMES.PERSONNEL) {
            //yield put({ type: ActionTypes.FETCH_ALL_PERSONNELS });
            yield put({ type: ActionTypes.FETCH_SYSTEM_SETTINGS });
        } else if (currentPage === PAGE_NAMES.HOME) {
            // yield put({ type: ActionTypes.FETCH_ALL_DOORS });
            if (tmpSelectedBadgeOfUser && tmpSelectedBadgeOfUser.Badge) {
                yield put({
                    type: ActionTypes.FETCH_ACCESS_READER_LIST_BY_BADGE,
                    payload: {
                        badgeNo: tmpSelectedBadgeOfUser.Badge,
                        facility: tmpSelectedBadgeOfUser.Facility,
                        requestStatus: false,
                        wlockRequestStatus: false
                    }
                });

                yield put({ type: ActionTypes.RESET_REFRESH_STATUS });
            }
            yield put({ type: ActionTypes.FETCH_ALL_THREAT_LEVELS }); // If lockdown is associated with threatlevel....
            yield put({ type: ActionTypes.FETCH_ALL_LOCKDOWN_AREA });
        } else if (currentPage === PAGE_NAMES.CONFIG) {
            //yield put({ type: ActionTypes.FETCH_ALL_DOORS });
            yield put({ type: ActionTypes.REFRESH_APP_CONFIG_SETTINGS });
            if (tmpSelectedBadgeOfUser && tmpSelectedBadgeOfUser.Badge) {
                yield put({
                    type: ActionTypes.FETCH_ACCESS_READER_LIST_BY_BADGE,
                    payload: {
                        badgeNo: tmpSelectedBadgeOfUser.Badge,
                        facility: tmpSelectedBadgeOfUser.Facility,
                        requestStatus: false,
                        wlockRequestStatus: false

                    }
                });
                yield put({ type: ActionTypes.RESET_REFRESH_STATUS });
            }
           // yield put({ type: ActionTypes.FETCH_SYSTEM_SETTINGS });
        }

        // yield put({ type: ActionTypes.FETCH_SYSTEM_SETTINGS });

        const appDataRefreshInterval = 1; // General Refresh Time is 10 seconds
        yield delay(appDataRefreshInterval * 1000);
    }
}
// In-List Refresh

function* cancelInListRefreshing() {
    if (startInListRefreshTask) {
        //console.log('canceling In-List refreshing');
        yield cancel(startInListRefreshTask);
    }
}

function* startInListRefresh() {
    try {
        yield call(cancelInListRefreshing);
        startInListRefreshTask = yield fork(inListRefreshingTask);
    } catch (error) {
        //console.log('failed...!!!');
    }
}

function* inListRefreshingTask() {
    while (true) {
        const inListRefreshTimeInSeconds = yield select(
            getInListRefreshTimeInSeconds
        );
        yield delay(inListRefreshTimeInSeconds * 1000);

        const currentPage = yield select(getCurrentPage);
        //console.log('currentPage', currentPage);
        //console.log('refreshing In-List ....');
        if (currentPage === PAGE_NAMES.BADGEHOLDERIN) {
            yield put({ type: ActionTypes.FETCH_ALL_BADGEHOLDERIN });
        }
    }
}

const getTmpSelectedBadgeOfUser = state => state.token.tmpSelectedBadgeOfUser;
const getSelectedDeviceType = state => state.status.selectedDeviceType;
const getCurrentPage = state => state.page.currentPage;
const getBaseURL = state => state.appConfig.baseURL;
const getInListRefreshTimeInSeconds = state =>
    state.settings.inListRefreshTimeInSeconds;
const getConnectionStatus = state => state.connectStatus;
const getRefreshStatus = state => state.page.refreshStatus;
const getRequestWirelessLockStatus = state => state.door.tmpRequestWirelessLockStatus;
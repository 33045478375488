import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Colors from '../../consts/colors';
import StatusDetailsListItemView from './StatusDetailsListItemView';
import * as actions from '../../actions/index';
import { DEVICE_TYPES } from '../../consts/consts';
const _ = require('lodash');

class StatusDetailView extends Component {
    constructor(props) {
        super(props);
        this.state = { selectedDeviceType: props.deviceType };
        this.showStatusDetail = this.showStatusDetail.bind(this);
        this.props.fetchDeviceStatusDetail(props.deviceType);
        this.props.writeLog('OperatorID: ' + this.props.operatorId + "StatusDetailView -->  Constructor() --> :  Selected DeviceType : " + props.deviceType);
    }

    showStatusDetail(statusDetailList) {
        const sortedStatusDetailList = _.orderBy(statusDetailList, 'deviceType');
        const selectedDeviceType = this.state.selectedDeviceType;
        const statusDetail = sortedStatusDetailList.map((statusDetails, index) => {
            console.log('Description:', statusDetails.description);

            const version = statusDetails.version
                ? `Ver (${statusDetails.version})`
                : 'Not Responding';

            if (
                (this.state.selectedDeviceType === DEVICE_TYPES.DEVICE_TYPE_PANEL ||
                    this.state.selectedDeviceType === DEVICE_TYPES.DEVICE_TYPE_NONE) &&
                statusDetails.description.length > 20 &&
                statusDetails.cardCount === 0 &&
                statusDetails.version.length === 0
            ) {
                console.log('Description:+', statusDetails);

                const descriptionParts = statusDetails.description.split(',');

                //const state = descriptionParts[0];
                const cardCount = descriptionParts[3];

                const cardCountParts = cardCount.substring(13);
                //const stateParts = state.substring(6);

                const isLockDevice = statusDetails.deviceCategory > 100
                return (
                    <StatusDetailsListItemView
                        key={index}
                        currentIndex={index}
                        deviceType={statusDetails.deviceType}
                        deviceName={statusDetails.deviceName}
                        version={statusDetails.version}
                        cardCount={statusDetails.cardCount}
                        description={isLockDevice === true ? `${version}` : `Card Count (${cardCountParts}) : ${version}`}
                    />
                );
            }

            let newDescripition = '';
            if (statusDetails.deviceCategory > 100)
                newDescripition = `${version}`;
            else
                newDescripition = `Card Count (${statusDetails.cardCount}) : ${version}`;

            const description = (this.state.selectedDeviceType === DEVICE_TYPES.DEVICE_TYPE_PANEL ||
                this.state.selectedDeviceType ===
                DEVICE_TYPES.DEVICE_TYPE_NONE) &&
                statusDetails.description.length > 20
                ? newDescripition
                : statusDetails.description;


            return (
                <StatusDetailsListItemView
                    key={index}
                    currentIndex={index}
                    deviceType={statusDetails.deviceType}
                    deviceName={statusDetails.deviceName}
                    version={statusDetails.version}
                    cardCount={statusDetails.cardCount}
                    description={description}
                />
            );
        });
        return <div>{statusDetail}</div>;
    }

    render() {
        console.log('device status detail :', this.props.deviceStatusDetail);
        return (
            <div style={styles.container}>
                {this.props.deviceStatusDetail.length === 0 && (
                    <div style={styles.emptyList}>No Status Details Found</div>
                )}
                {this.props.deviceStatusDetail.length > 0 && (
                    <div style={styles.panelStatus}>Device Status Details:</div>
                )}
                {this.props.deviceStatusDetail.length > 0 && (
                    <div style={styles.listContainer}>
                        <div style={styles.titleRow}>
                            <div style={styles.deviceName}>Device Name</div>
                            <div style={styles.status}>Status</div>
                        </div>
                        {this.props.deviceStatusDetail &&
                            this.showStatusDetail(this.props.deviceStatusDetail)}
                    </div>
                )}
            </div>
        );
    }
}

const styles = {
    line: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: '100%',
        width: 1,
        backgroundColor: Colors.Black
    },
    underlineless: {
        textDecoration: 'none'
    },
    deviceName: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        height: 35,
        borderColor: Colors.Gray,
        justifyContent: 'center',
        fontSize: '0.9em',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    status: {
        display: 'flex',
        flex: 1,
        fontSize: '0.9em',
        height: 35,
        alignItems: 'center',
        borderColor: Colors.Gray,
        justifyContent: 'center',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    cardCount: {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        fontSize: 15,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    titleRow: {
        display: 'flex',
        flex: 1,
        backgroundColor: Colors.VeryPaleBlue,
        height: 35,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderWidth: '1px',
        borderStyle: 'solid',
        fontSize: 16,
        borderColor: Colors.Black
    },
    emptyList: {
        display: 'flex',
        flex: 1,
        fontSize: 16,
        fontWeight: '600',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: 50
    },

    listContainer: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.Gray
    },
    container: {
        display: 'flex',
        flex: 1,
        paddingLeft: 10,
        paddingRight: 0,
        height: 'auto',
        justifyContent: 'center',
        marginRight: 8,
        marginBottom: 10,
        flexDirection: 'column',
        backgroundColor: Colors.White
    },
    panelStatus: {
        fontWeight: 'bold',
        marginBottom: 10
    },
    expandContainer: {
        flex: 1,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Colors.White,
        flexDirection: 'row',
        marginTop: 10,
        marginLeft: 20,
        marginRight: 20
    },
    lockdownallareas: {
        textAlign: 'center',
        padding: 2,
        fontSize: 16,
        fontWeight: '700'
    },
    functionText: {
        color: Colors.Black,
        textAlign: 'left',
        marginLeft: 20,
        fontSize: 20,
        fontWeight: 800
    },
    blackLine: { height: '100%', width: 1, borderBottom: '2px solid black' }
};

const mapStateToProps = state => ({
    accessToken: state.token.accessToken,
    deviceTypes: state.status.deviceTypes,
    deviceStatusDetail: state.status.deviceStatusDetail,
    operatorId: state.token.operatorId
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchDeviceStatusDetail: actions.fetchDeviceStatusDetail,
            writeLog: actions.writeLog
        },
        dispatch
    );

export default Radium(
    connect(mapStateToProps, mapDispatchToProps)(StatusDetailView)
);

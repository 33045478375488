import React from "react";
import Radium from "radium";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Colors from "../../consts/colors";
import * as actions from "../../actions/index";

const ThreatLevelItemView = props => (
  <div
    style={styles.container}
    onClick={() => {
      if (props.activated === true) {
        const isConfirmed = window.confirm(
          `Confirm Threat Level Deactivation of ${props.threatLevel}?`
        );
        if (isConfirmed === true) {
          props.sendDeactivateTheThreatLevel(props.levelNo);
          alert("Deactivate command is sent.");
        }
      } else if (props.activated === false) {
        const isConfirmed = window.confirm(
          `Confirm Threat Level Activation of ${props.threatLevel}?`
        );
        if (isConfirmed === true) {
          props.sendActivateTheThreatLevel(props.levelNo);
          alert("Activate command is sent.");
        }
      }
    }}
  >
    <div style={styles.threatLevel}>{props.threatLevel}</div>
    {props.activated === true && (
      <div style={styles.threatLevelStatusActive}>Active</div>
    )}
    {/* {props.activated === false && <div style={styles.threatLevelStatusDeactive}>Deactive</div>} */}
  </div>
);

const styles = {
  container: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "black",
    borderRadius: 6,
    display: "flex",
    flex: 1,
    height: 36,
    marginTop: 15,
    marginRight: 10,
    marginLeft: 10,
    flexDirection: "row",
    backgroundColor: Colors.LightGrayishViolet,
    cursor: "pointer",
    boxShadow: "0.5px 0.5px 0.5px #000000",
    ":active": {
      backgroundColor: Colors.Gray,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "black"
    }
  },

  threatLevel: {
    fontSize: 14,
    height: 30,
    flex: 1,
    alignSelf: "center",
    alignItems: "center",
    paddingLeft: 10,
    display: "flex",
    fontWeight: "bold"
  },
  threatLevelStatusActive: {
    fontSize: 14,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: 5,
    paddingRight: 5,
    color: Colors.White,
    textAlign: "center",
    fontWeight: "600",
    width: 80,
    backgroundColor: Colors.Red
  },
  threatLevelStatusDeactive: {
    fontSize: 14,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: 5,
    paddingRight: 5,
    color: Colors.White,
    textAlign: "center",
    fontWeight: "600",
    width: 80,
    backgroundColor: Colors.DeepGreen
  }
};

const mapStateToProps = state => ({
  accessToken: state.token.accessToken,
  threats: state.threat.threats
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendActivateTheThreatLevel: actions.sendActivateTheThreatLevel,
      sendDeactivateTheThreatLevel: actions.sendDeactivateTheThreatLevel
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(ThreatLevelItemView)
);

import * as ActionTypes from '../actions/types';

const defaultState = {
    log: '',
    showLog: false
};

export default (state = defaultState, action) => {
    switch (action.type) {

        case ActionTypes.WRITE_LOG_SUCCEEDED: {

                return {
                    ...state,
                    log: state.log + action.payload + "\n"
                };
           
        }

        case ActionTypes.CLEAR_LOG: {
            return {
                ...state,
                log: ''
            };
        }

        case ActionTypes.VIEW_LOG: {
            return {
                ...state,
                showLog: true
            };
        }

      case ActionTypes.HIDE_LOG: {
            return {
                ...state,
                showLog: false
            };
      }
       
    default:
      return state;
  }
};

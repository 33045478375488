import * as ActionTypes from '../actions/types';

const defaultState = {
    accessGroups: [],
    searchTextAG1: '',
    searchTextAG2: '',
    searchTextAG3: '',
    searchTextAG4: '',
    searchTextAG6: '',
    searchTextAG7: '',
    searchTextAG8: '',
    searchTextAG9: '',
    searchTextAG10: '',
    searchTextAG11: '',
    searchTextAG12: '',
    searchTextAG13: '',
    searchTextAG14: '',
    searchTextAG15: '',
    searchTextAG16: ''
    
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ACCESS_GROUP_LIST_SUCCEEDED: {
      return {
        accessGroups: action.payload
      };
      }

      case ActionTypes.CHANGE_SEARCH_TEXT_AG1: {
          return {
              ...state,
              searchTextAG1: action.payload
          };
      }   

      case ActionTypes.CHANGE_SEARCH_TEXT_AG2: {
          return {
              ...state,
              searchTextAG2: action.payload
          };
      }   

      case ActionTypes.CHANGE_SEARCH_TEXT_AG3: {
          return {
              ...state,
              searchTextAG3: action.payload
          };
      } 

      case ActionTypes.CHANGE_SEARCH_TEXT_AG4: {
          return {
              ...state,
              searchTextAG4: action.payload
          };
      }

      case ActionTypes.CHANGE_SEARCH_TEXT_AG5: {
          return {
              ...state,
              searchTextAG5: action.payload
          };
      }

      case ActionTypes.CHANGE_SEARCH_TEXT_AG6: {
          return {
              ...state,
              searchTextAG6: action.payload
          };
      }

      case ActionTypes.CHANGE_SEARCH_TEXT_AG7: {
          return {
              ...state,
              searchTextAG7: action.payload
          };
      }

      case ActionTypes.CHANGE_SEARCH_TEXT_AG8: {
          return {
              ...state,
              searchTextAG8: action.payload
          };
      }

      case ActionTypes.CHANGE_SEARCH_TEXT_AG9: {
          return {
              ...state,
              searchTextAG9: action.payload
          };
      }

      case ActionTypes.CHANGE_SEARCH_TEXT_AG10: {
          return {
              ...state,
              searchTextAG10: action.payload
          };
      }

      case ActionTypes.CHANGE_SEARCH_TEXT_AG11: {
          return {
              ...state,
              searchTextAG11: action.payload
          };
      }

      case ActionTypes.CHANGE_SEARCH_TEXT_AG12: {
          return {
              ...state,
              searchTextAG12: action.payload
          };
      }

      case ActionTypes.CHANGE_SEARCH_TEXT_AG13: {
          return {
              ...state,
              searchTextAG13: action.payload
          };
      }

      case ActionTypes.CHANGE_SEARCH_TEXT_AG14: {
          return {
              ...state,
              searchTextAG14: action.payload
          };
      }

      case ActionTypes.CHANGE_SEARCH_TEXT_AG15: {
          return {
              ...state,
              searchTextAG15: action.payload
          };
      }

      case ActionTypes.CHANGE_SEARCH_TEXT_AG16: {
          return {
              ...state,
              searchTextAG16: action.payload
          };
      }

      case ActionTypes.RESET_SEARCH_TEXT_ALL_AG: {
          return {
              ...state,
              searchTextAG1: '',
              searchTextAG2: '',
              searchTextAG3: '',
              searchTextAG4: '',
              searchTextAG6: '',
              searchTextAG7: '',
              searchTextAG8: '',
              searchTextAG9: '',
              searchTextAG10: '',
              searchTextAG11: '',
              searchTextAG12: '',
              searchTextAG13: '',
              searchTextAG14: '',
              searchTextAG15: '',
              searchTextAG16: ''
          };
      }



    default:
      return state;
  }
};

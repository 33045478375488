export const Black = '#000000';
export const Purple = '#800080';
export const Green = '#99cc00';
export const LightGray = '#d3d3d3';
export const White = '#ffffff';
export const Blue = '#0000ff';
export const Gray = '#808080';

export const MostlyDesaturatedDarkBlue = '#557985';
export const MostlyDesaturatedDarkCyan = '#658f9c';

export const SlateGray = '#708090';
export const DeepGreen = '#008000';
export const Red = '#ff0000';
export const VeryPaleBlue = '#eef5ff';
export const VeryPaleCyan = '#e9faff';
export const PaleCyan = '#bcefff';
export const LightGrayishBlue = '#f4f7f8';
export const LightGrayishViolet = '#efecf7';
export const PaleBlue = '#bbd7ff';
export const VeryLightPaleBlue = '#d5e6ff';
export const VividBlue = '#1ea3ff';
export const MostPureBlue = '#008aea';
export const DarkBlue = '#005d9e';
export const Orange = '#ffa500';
export const Cream = '#fffdd0';
export const VeryPaleYellow = '#fffddf';
export const LimeGreen = '#a1e7cf';
export const DarkModerateViolet = '#592d86';
export const LightViolet = '#d9c6ec';

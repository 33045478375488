import * as ActionTypes from '../actions/types';

const defaultState = {
  lockdownAreas: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ALL_LOCKDOWN_AREA_SUCCEEDED: {
      return {
        lockdownAreas: action.payload
      };
      }

   case ActionTypes.LOGOUT: {
          return defaultState;
      }

    default:
      return state;
  }
};

import React from 'react';
import Radium from 'radium';
import * as Colors from '../../consts/colors';

const BadgeHoldersInListItemView = props => {
  if (props.currentIndex % 2 === 0) {
    return (
      <div style={props.instyle ? props.instyle : styles.alterBackgroundContainer}>
        <div style={styles.name}>
          {props.name}({props.badgeNo}){' '}
        </div>
        <div style={styles.reader}>{props.reader}</div>
        <div style={styles.timein}>{props.timein}</div>
      </div>
    );
  }

  return (
    <div style={props.instyle ? props.instyle : styles.container}>
      <div style={styles.name}>
        {props.name}({props.badgeNo}){' '}
      </div>
      <div style={styles.reader}>{props.reader}</div>
      <div style={styles.timein}>{props.timein}</div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    height: 25,
    alignItems: 'center',
    paddingRight: 20,
    paddingLeft: 20,
    flexDirection: 'row',
    marginBottom: 2,
    fontWeight: 'normal',
    fontSize: 12,
    backgroundColor: Colors.LightGrayishBlue
  },
  alterBackgroundContainer: {
    display: 'flex',
    flex: 1,
    height: 25,
    alignItems: 'center',
    paddingRight: 20,
    paddingLeft: 20,
    flexDirection: 'row',
    marginBottom: 2,
    fontWeight: 'normal',
    fontSize: 12,
    backgroundColor: Colors.PaleBlue
  },
  name: {
    flex: 1,
    alignItems: 'center',
    width: 150,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginLeft: 2,
    fontSize: '0.75em'
  },
  reader: {
    flex: 1,
    alignItems: 'center',
    width: 120,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginLeft: 2,
    fontSize: '0.75em',
    fontWeight: 'normal'
  },
  timein: {
    width: 120,
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginLeft: 5,
    fontWeight: 'normal',
    fontSize: '0.75em'
  }
};

export default Radium(BadgeHoldersInListItemView);

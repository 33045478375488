import * as ActionTypes from '../actions/types';

const defaultState = {
    isHavingPartition: true
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.CHECK_IF_PARTITION_EXISTS_SUCCEEDED: {
            console.log('sss', action.payload);
            return {
                isHavingPartition: action.payload 
      };
        }

        case ActionTypes.CHECK_IF_PARTITION_EXISTS_FAILED: {
            return {
                isHavingPartition: true
            };
        }

        case ActionTypes.LOGOUT: {
            return {
                isHavingPartition: true
            };
        }

        case ActionTypes.RESET_PARTITION_EXISTS_STATUS: {
            return {
                isHavingPartition: true
            };
        }



    default:
      return state;
  }
};

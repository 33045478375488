import axios from 'axios';
import qs from 'qs';

//const ca4kApiURL = 'http://172.16.205.197:8080/';

const generateDefaultHTTPHeaders = token => ({
    Authorization: `Bearer ${token}`
});

const logIN = (userInfo, baseURL) => {
    const logInURL = `${baseURL}token`;
    const data = qs.stringify(userInfo);
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
    };

    return axios.post(logInURL, data, headers);
};

const fetchAllDoors = (accessToken, baseURL, requestStatus, wlockRequetStatus) => {
    const reqType = 'doors';
    const url = `${baseURL}${reqType}/status/${requestStatus}/${wlockRequetStatus}`;
    console.log("URL:", url);
    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const fetchAccessReaderListByBadge = (
    accessToken,
    baseURL,
    badgeNo,
    facility,
    requestStatus,
    wlockRequestStatus
) => {
    //console.log('fetch access readers.....', badgeNo, facility, requestStatus);
    const reqType = `doors/${badgeNo}/${facility}/${requestStatus}/${wlockRequestStatus}`;
    const url = `${baseURL}${reqType}`;

    //console.log('url:', url);
    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: {}
    });
};

const sendLockTheDoor = (doorInfo, baseURL) => {
    console.log('sendDoorLock', doorInfo, baseURL);
    const reqType = `doors/${doorInfo.panelNo}/${doorInfo.doorNo}/operation`;
    const url = `${baseURL}${reqType}`;

    //console.log('url:', url);
    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(doorInfo.accessToken),
        data: {
            status: 'lock',
            requestStatus: true
        }
    });
};

const sendUnLockTheDoor = (doorInfo, baseURL) => {
    const reqType = `doors/${doorInfo.panelNo}/${doorInfo.doorNo}/operation`;
    const url = `${baseURL}${reqType}`;
    //console.log('reqtype:', reqType);
    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(doorInfo.accessToken),
        data: {
            status: 'unlock',
            badge: doorInfo.badge,
            facility: doorInfo.facility,
            requestStatus: true
        }
    });
};

const sendUnLockMyDoor = (doorInfo, baseURL) => {
    const reqType = `doors/${doorInfo.panelNo}/${doorInfo.doorNo}/unlockmydoor`;
    const url = `${baseURL}${reqType}`;

    const PIN = doorInfo.PIN;
    const CommonCode = doorInfo.CommonCode;
    let PIN_CommonCode = 0 ; 

    if (PIN > 0 || PIN < 0) {
        PIN_CommonCode = PIN;
    } else if (CommonCode > 0 || CommonCode < 0) {
        PIN_CommonCode = CommonCode;
    }

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(doorInfo.accessToken),
        data: {
            status: 'unlock',
            badge: doorInfo.badge,
            facility: doorInfo.facility,
            pin: PIN_CommonCode,
            requestStatus: true
        }
    });
};

const sendUnLockHoldTheDoor = (doorInfo, baseURL) => {
    const reqType = `doors/${doorInfo.panelNo}/${doorInfo.doorNo}/operation`;
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(doorInfo.accessToken),
        data: {
            status: 'unlockhold',
            requestStatus: true
        }
    });
};

const fetchAllThreatLevels = (accessToken, baseURL) => {
    const reqType = 'threatlevels';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const sendActivateAllThreatLevels = (accessToken, baseURL) => {
    const reqType = 'threatlevels/all-operation';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: { status: 'true' }
    });
};

const sendDeactivateAllThreatLevels = (accessToken, baseURL) => {
    const reqType = 'threatlevels/all-operation';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: { status: 'false' }
    });
};

const sendActivateTheThreatLevel = (accessToken, baseURL, levelNo) => {
    const reqType = `threatlevels/${levelNo}/operation`;
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: { status: 'true' }
    });
};

const sendDeactivateTheThreatLevel = (accessToken, baseURL, levelNo) => {
    const reqType = `threatlevels/${levelNo}/operation`;

    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: { status: 'false' }
    });
};

const fetchAllLockDownArea = (accessToken, baseURL) => {
    const reqType = 'lockdowns';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const sendActivateAllLockdownArea = (accessToken, baseURL) => {
    const reqType = 'lockdowns/all-operation';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: { status: 'true' }
    });
};

const sendDeactivateAllLockdownArea = (accessToken, baseURL) => {
    const reqType = 'lockdowns/all-operation';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: { status: 'false' }
    });
};

const sendActivateTheLockdownArea = (accessToken, baseURL, areaNo) => {
    const reqType = `lockdowns/${areaNo}/operation`;
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: { status: true }
    });
};

const sendDeactivateTheLockdownArea = (accessToken, baseURL, areaNo) => {
    const reqType = `lockdowns/${areaNo}/operation`;
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: { status: false }
    });
};

const fetchAllRelays = (accessToken, baseURL, requestStatus) => {
    const reqType = 'relays';
    const url = `${baseURL}${reqType}/${requestStatus}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const sendActivateRelay = (accessToken, baseURL, panelNo, relayNo) => {
    const reqType = `relays/${panelNo}/${relayNo}/operation`;
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: { status: 1 }
    });
};

const sendDeactivateRelay = (accessToken, baseURL, panelNo, relayNo) => {
    const reqType = `relays/${panelNo}/${relayNo}/operation`;
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: { status: 3 }
    });
};

const sendActivateHoldRelay = (accessToken, baseURL, panelNo, relayNo) => {
    const reqType = `relays/${panelNo}/${relayNo}/operation`;
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: { status: 2 }
    });
};

const fetchDeviceStatusDetail = (accessToken, baseURL, deviceType, requestStatus, wlockRequetStatus) => {
    const reqType = `status/${deviceType}/${requestStatus}/${wlockRequetStatus}`;
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const fetchAllDeviceTypeList = (accessToken, baseURL) => {
    const reqType = 'device-types';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const fetchDeviceTypeList = (accessToken, baseURL, screenCode) => {
    const reqType = `device-types/${screenCode}`;
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const fetchAllBadgeHolderIn = (accessToken, baseURL) => {
    const reqType = 'badgeholdersin';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const fetchScheduleChangesList = (accessToken, baseURL) => {
    const reqType = 'schedulechanges';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const deleteSchedule = (accessToken, baseURL, schedule) => {
    const reqType = 'schedulechanges';
    const url = `${baseURL}${reqType}`;

    return axios.delete(url, {
        data: {
            caObjectId: schedule.caObjectId,
            operation: 1
        },
        headers: generateDefaultHTTPHeaders(accessToken)
    });
    // return axios({
    //   method: 'DEL',
    //   url,
    //   headers: generateDefaultHTTPHeaders(accessToken),
    //   data: {
    //     caObjectId: schedule.caObjectId,
    //     operation: 1
    //   }
    // });
};

const fetchTimeScheduleList = (accessToken, baseURL) => {
    const reqType = 'schedulechanges/time-schedules';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const fetchDeviceListByDeviceType = (accessToken, baseURL, deviceType) => {
    const reqType = `devices-by-type/${deviceType}`;
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const fetchFieldListByDeviceType = (accessToken, baseURL, deviceType) => {
    const reqType = `schedulechanges/schedule-create-fields/${deviceType}`;
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const createNewScheduleChanges = (accessToken, baseURL, newScheduleChanges) => {
    const reqType = 'schedulechanges';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: {
            Operation: 0,
            Description: newScheduleChanges.description,
            DeviceType: newScheduleChanges.deviceType,
            PanelNo: newScheduleChanges.panelNo,
            DeviceNo: newScheduleChanges.deviceNo,
            FieldNo: newScheduleChanges.fieldNo,
            ScheduleNo: newScheduleChanges.scheduleNo,
            DateStart: newScheduleChanges.fromDate,
            DateEnd: newScheduleChanges.toDate,
            DeviceText: newScheduleChanges.deviceText
                ? newScheduleChanges.deviceText
                : '',
            FieldInText: newScheduleChanges.fieldInText
                ? newScheduleChanges.fieldInText
                : ''
        }
    });
};

const updateScheduleChanges = (
    accessToken,
    baseURL,
    updatedSchedulechanges
) => {
    const reqType = 'schedulechanges';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'put',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: {
            Operation: 2,
            caObjectId: updatedSchedulechanges.caObjectId,
            Description: updatedSchedulechanges.description,
            DateStart: updatedSchedulechanges.fromDate,
            DateEnd: updatedSchedulechanges.toDate,
            PanelNo: updatedSchedulechanges.panelNo,
            DeviceType: updatedSchedulechanges.deviceType,
            DeviceNo: updatedSchedulechanges.deviceNo,
            DeviceText: updatedSchedulechanges.deviceText,
            FieldNo: updatedSchedulechanges.fieldNo,
            FieldInText: updatedSchedulechanges.fieldInText,
            ScheduleNo: updatedSchedulechanges.scheduleNo
        }
    });
};

const fetchAllPersonnels = (accessToken, baseURL) => {
    const reqType = 'personnel';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const fetchAccessGroupList = (accessToken, baseURL) => {
    const reqType = 'access-group-list';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const deletePersonnel = (accessToken, baseURL, personnel) => {
    const reqType = 'personnel';
    const url = `${baseURL}${reqType}`;

    return axios.delete(url, {
        data: {
            facility: personnel.facility,
            badge: personnel.badge,
            firstName: personnel.firstName,
            lastName: personnel.lastName,
            operation: 1
        },
        headers: generateDefaultHTTPHeaders(accessToken)
    });
    // return axios({
    //   method: 'DEL',
    //   url,
    //   headers: generateDefaultHTTPHeaders(accessToken),
    //   data: {
    //     caObjectId: schedule.caObjectId,
    //     operation: 1
    //   }
    // });
};

const createNewPersonnel = (accessToken, baseURL, newPersonnel) => {
    const reqType = 'personnel';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: {
            Operation: 0,
            enable: newPersonnel.enable,
            facility: newPersonnel.facility,
            badge: newPersonnel.badge,
            firstName: newPersonnel.firstName,
            lastName: newPersonnel.lastName,
            pin: newPersonnel.pin,
            agNo1: newPersonnel.agNo1,
            agNo2: newPersonnel.agNo2,
            agNo3: newPersonnel.agNo3,
            agNo4: newPersonnel.agNo4,
            agNo5: newPersonnel.agNo5,
            agNo6: newPersonnel.agNo6,
            agNo7: newPersonnel.agNo7,
            agNo8: newPersonnel.agNo8,
            agNo9: newPersonnel.agNo9,
            agNo10: newPersonnel.agNo10,
            agNo11: newPersonnel.agNo11,
            agNo12: newPersonnel.agNo12,
            agNo13: newPersonnel.agNo13,
            agNo14: newPersonnel.agNo14,
            agNo15: newPersonnel.agNo15,
            agNo16: newPersonnel.agNo16
        }
    });
};

const updatePersonnel = (accessToken, baseURL, updatedPersonnel) => {
    const reqType = 'personnel';
    const url = `${baseURL}${reqType}`;
    console.log("update personnel->", updatedPersonnel, updatedPersonnel.newBadge, updatedPersonnel.newFacility);
    return axios({
        method: 'put',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: {
            Operation: updatedPersonnel.enable === false ? 4 : 3,
            enable: updatedPersonnel.enable,
            facility: updatedPersonnel.facility,
            badge: updatedPersonnel.badge,
            newBadge: updatedPersonnel.newBadge,
            newFacility: updatedPersonnel.newFacility,
            firstName: updatedPersonnel.firstName,
            lastName: updatedPersonnel.lastName,
            pin: updatedPersonnel.pin,
            agNo1: updatedPersonnel.agNo1,
            agNo2: updatedPersonnel.agNo2,
            agNo3: updatedPersonnel.agNo3,
            agNo4: updatedPersonnel.agNo4,
            agNo5: updatedPersonnel.agNo5,
            agNo6: updatedPersonnel.agNo6,
            agNo7: updatedPersonnel.agNo7,
            agNo8: updatedPersonnel.agNo8,
            agNo9: updatedPersonnel.agNo9,
            agNo10: updatedPersonnel.agNo10,
            agNo11: updatedPersonnel.agNo11,
            agNo12: updatedPersonnel.agNo12,
            agNo13: updatedPersonnel.agNo13,
            agNo14: updatedPersonnel.agNo14,
            agNo15: updatedPersonnel.agNo15,
            agNo16: updatedPersonnel.agNo16
        }
    });
};

const fetchPersonnel = (accessToken, baseURL, id, badge, facility) => {
    const reqType = 'personnel';
    const url = `${baseURL}${reqType}/${id}/${badge}/${facility}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const addBadgeToPersonnel = (accessToken, baseURL, badgeInfo) => {
    const reqType = 'personnel';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: {
            Operation: 0,
            personnelId: badgeInfo.personnelId,
            facility: badgeInfo.facility,
            badge: badgeInfo.badge,
            firstName: badgeInfo.firstName,
            lastName: badgeInfo.lastName,
            enable: badgeInfo.enable,
            pin: badgeInfo.pin,
            agNo1: badgeInfo.agNo1,
            agNo2: badgeInfo.agNo2,
            agNo3: badgeInfo.agNo3,
            agNo4: badgeInfo.agNo4,
            agNo5: badgeInfo.agNo5,
            agNo6: badgeInfo.agNo6,
            agNo7: badgeInfo.agNo7,
            agNo8: badgeInfo.agNo8,
            agNo9: badgeInfo.agNo9,
            agNo10: badgeInfo.agNo10,
            agNo11: badgeInfo.agNo11,
            agNo12: badgeInfo.agNo12,
            agNo13: badgeInfo.agNo13,
            agNo14: badgeInfo.agNo14,
            agNo15: badgeInfo.agNo15,
            agNo16: badgeInfo.agNo16
        }
    });
};

const postEvent = (accessToken, baseURL, eventInfo) => {
    const reqType = 'post-event';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: {
            aCat: eventInfo.aCat,
            aClass: eventInfo.aClass,
            aDescription: eventInfo.aDescription,
            aName: eventInfo.aName,
            aPending: eventInfo.aPending,
            aPriority: 40,
            aRespReq: false,
            PanelNo: eventInfo.PanelNo,
            DeviceNo: eventInfo.DeviceNo,
            DeviceCaObjectID: eventInfo.DeviceCaObjectID,
            Badge: eventInfo.Badge,
            Facility: eventInfo.Facility,
            Status: eventInfo.Status,
            guid: 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA'
        }
    });
};

const fetchBadgesByPersonnelName = (accessToken, baseURL, personnelName) => {
    const reqType = 'badges-by-perosn-names';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: {
            firstName: personnelName.firstName,
            lastName: personnelName.lastName
        }
    });
};

const fetchSystemSettings = (accessToken, baseURL) => {
    const reqType = 'system-settings';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const cancelActiveScheduleChanges = (
    accessToken,
    baseURL,
    caObjectId,
    keepSchedule
) => {
    const reqType = 'schedulechanges/schedule';
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: {
            caObjectId,
            keepSchedule
        }
    });
};

const checkIfBadgeExists = (accessToken, baseURL, badgeNo, facility) => {
    const reqType = 'personnel';
    const url = `${baseURL}${reqType}/${badgeNo}/${facility}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const fetchPersonnelsCount = (accessToken, baseURL, payload) => {
    const reqType = `personnel/count/${payload.filterOption}/${payload.filterText}`;
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const fetchPagedPersonnels = (accessToken, baseURL, payload) => {
    const reqType = `personnel/page/${payload.pageIndex}/${payload.sortBy}/${payload.sortOrder}/${payload.filterOption}/${payload.filterText}`;
    //console.log(reqType);
    const url = `${baseURL}${reqType}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const fetchDoorsCount = (accessToken, baseURL, payload) => {
    const { requestStatus, filterText } = payload;
    let fText = '';
    if (filterText) {
        fText = filterText.trim();
    }
    const reqType = `doors/count/${requestStatus}/${fText}`;
    const url = encodeURI(`${baseURL}${reqType}`);

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const fetchPagedDoors = (accessToken, baseURL, payload) => {
    const { pageIndex, requestStatus, filterText } = payload;
    let fText = '';
    if (filterText) {
        fText = filterText.trim();
    }
    const reqType = `doors/page/${pageIndex}/${requestStatus}/${fText}/`;
    //console.log(reqType);
    const url = encodeURI(`${baseURL}${reqType}`);

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const fetchBadgeListByPersonnelId = (accessToken, baseURL, personnelId) => {
    const reqType = 'personnel';
    const url = `${baseURL}${reqType}/persons/${personnelId}`;

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};

const logError = (accessToken, baseURL, message) => {
    const reqType = 'error';
    const url = encodeURI(`${baseURL}${reqType}`);

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: {
            message
        }
    });
};

const getConnectionStatus = (accessToken, baseURL) => {
    const reqType = 'connectionstatus/';
    //console.log(reqType);
    const url = encodeURI(`${baseURL}${reqType}`);

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};


const checkIfPartitionExists = (accessToken, baseURL, payload) => {
    const reqType = 'partition';
    const url = `${baseURL}${reqType}`;

    const currentOperatorId = payload.currentOperatorId;
    const screenDeviceType = payload.screenDeviceType;

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: {
            currentOperatorId,
            screenDeviceType
        }
    });
};


const checkIfPINCommonCodeEnabled = (accessToken, baseURL, payload) => {
    const reqType = 'doors/door';
    const url = `${baseURL}${reqType}/${payload.panelNo}/${payload.doorNo}`;
    console.log('URL:', url);

    return axios({
        method: 'get',
        url,
        headers: generateDefaultHTTPHeaders(accessToken)
    });
};


const updateBadgeInfoRequest = (accessToken, baseURL, payload) => {
    const reqType = 'personnel';
    const url = `${baseURL}${reqType}`;
    console.log('URL:', url);

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: {

            badge: payload.badgeNo,
            facility: payload.facilityNo,
            newBadge: payload.editedBadgeNo,
            newFacility: payload.editedFacilityNo
        }
    });
};


const changeOperatorPassword = (accessToken, baseURL, payload) => {
    const reqType = 'change-password';
    const url = `${baseURL}${reqType}`;
    console.log('URL:', url);

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: {
            userName: payload.userName,
            oldPassword: payload.oldPassword,
            newPassword: payload.newPassword,
            confirmPassword: payload.confirmPassword
        }
    });
};

const enableDebugLog = (accessToken, baseURL, payload) => {
    const reqType = 'enable-apilog';
    const url = `${baseURL}${reqType}`;
    console.log('URL:', url);

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: {
            isEnabled: payload
        }
    });
};

const writeLog = (accessToken, baseURL, payload) => {
    const reqType = 'webapi-log';
    const url = `${baseURL}${reqType}`;
    console.log('URL:', url);

    return axios({
        method: 'post',
        url,
        headers: generateDefaultHTTPHeaders(accessToken),
        data: {
            logData: payload.logData,
            isDebugEnabled: payload.isDebugEnabled
        }
    });
};

export {
    logIN,
    fetchAllDoors,
    fetchAccessReaderListByBadge,
    sendLockTheDoor,
    sendUnLockTheDoor,
    sendUnLockHoldTheDoor,
    fetchAllThreatLevels,
    sendActivateAllThreatLevels,
    sendDeactivateAllThreatLevels,
    sendActivateTheThreatLevel,
    sendDeactivateTheThreatLevel,
    fetchAllLockDownArea,
    sendActivateAllLockdownArea,
    sendDeactivateAllLockdownArea,
    sendActivateTheLockdownArea,
    sendDeactivateTheLockdownArea,
    fetchAllRelays,
    sendActivateRelay,
    sendDeactivateRelay,
    sendActivateHoldRelay,
    fetchAllDeviceTypeList,
    fetchDeviceTypeList,
    fetchDeviceStatusDetail,
    fetchAllBadgeHolderIn,
    fetchScheduleChangesList,
    deleteSchedule,
    fetchTimeScheduleList,
    fetchDeviceListByDeviceType,
    fetchFieldListByDeviceType,
    createNewScheduleChanges,
    updateScheduleChanges,
    fetchAllPersonnels,
    fetchAccessGroupList,
    deletePersonnel,
    createNewPersonnel,
    updatePersonnel,
    fetchPersonnel,
    addBadgeToPersonnel,
    postEvent,
    fetchBadgesByPersonnelName,
    fetchSystemSettings,
    cancelActiveScheduleChanges,
    checkIfBadgeExists,
    fetchBadgeListByPersonnelId,
    fetchPersonnelsCount,
    fetchPagedPersonnels,
    fetchDoorsCount,
    fetchPagedDoors,
    logError,
    getConnectionStatus,
    checkIfPartitionExists,
    sendUnLockMyDoor,
    checkIfPINCommonCodeEnabled,
    updateBadgeInfoRequest,
    changeOperatorPassword,
    enableDebugLog,
    writeLog
};

import moment from 'moment';
import * as ActionTypes from '../actions/types';
import { MSG_SYSTEM_OFFLINE, MSG_SYSTEM_ONLINE, MSG_MBL_STOPPED } from '../consts/consts';
import * as Colors from '../consts/colors';

const defaultState = {
    messageList: [],
    messageTimeList: [],
    currentMessage: '',
    currentMessageColor: Colors.Black,
    showGeneralMessage: false,
    saveStateData: false,
    cancelStateData: false
};
export default (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.ADD_MESSAGE: {
            let message = action.payload;

            if (message) {
                if (message === MSG_SYSTEM_OFFLINE || message === MSG_MBL_STOPPED) {
                    const offlineIndex = state.messageList.lastIndexOf(MSG_SYSTEM_OFFLINE);
                    const onlineIndex = state.messageList.lastIndexOf(MSG_SYSTEM_ONLINE);
                    if (offlineIndex > onlineIndex) {
                        return state;
                    }
                    if (message === MSG_MBL_STOPPED) {
                        message = MSG_MBL_STOPPED;
                    } else {
                        alert(message);
                        message = MSG_SYSTEM_OFFLINE;
                    }
                
                } else if (message === MSG_SYSTEM_ONLINE) {
                    const offlineIndex = state.messageList.lastIndexOf(MSG_SYSTEM_OFFLINE);
                    const onlineIndex = state.messageList.lastIndexOf(MSG_SYSTEM_ONLINE);

                    if (offlineIndex < 0 || onlineIndex > offlineIndex) {
                        return state;
                    }
                }

                const messageList = [...state.messageList, message].slice(-20);
                const messageTimeList = [
                    ...state.messageTimeList,
                    moment().format('DD-MMM-YYYY hh:mm:ss A')
                ].slice(-20);

                return {
                    ...state,
                    messageList,
                    messageTimeList,
                    currentMessage: message,
                    currentMessageColor: Colors.Black
                };
            }
            return state;
        }

        case ActionTypes.LOGOUT: {
            return {
                ...state,
                messageList: [],
                messageTimeList: [],
                currentMessage: ''
            };
        }

        case ActionTypes.SHOW_GENERAL_MESSAGE: {
            //console.log(action.payload);
            return {
                ...state,
                showGeneralMessage: action.payload
            };
        }

        case ActionTypes.SAVE_STATE_DATA: {
            return {
                ...state,
                saveStateData: true
            };
        }

        case ActionTypes.CANCEL_STATE_DATA: {
            return {
                ...state,
                cancelStateData: true
            };
        }

        case ActionTypes.RESET_SAVE_STATE_DATA: {
            return {
                ...state,
                saveStateData: false,
                cancelStateData: false
            };
        }
        default:
            return state;
    }
};

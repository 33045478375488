import React, { Component } from 'react';
import Radium from 'radium';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import Routes from './config/routes';
import store from './config/store.js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ErrorBoundary from './components/common/ErrorBoundary';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ErrorBoundary>
          <HashRouter>{Routes}</HashRouter>
        </ErrorBoundary>
      </Provider>
    );
  }
}

export default Radium(App);

import React from 'react';
import Radium from 'radium';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import * as Colors from '../../consts/colors';
import '../../style.css';

const FunctionItemLink = props => (
  <Link style={styles.underlineless} to={props.link}>
    <div style={styles.container}>
      <div style={styles.icon}>
        <FontAwesomeIcon icon={faPlus} size="1x" color={Colors.Black} />
      </div>
      <div style={styles.functionText}> {props.Title}</div>
    </div>
  </Link>
);

const styles = {
  underlineless: {
    textDecoration: 'none'
  },
  icon: {
    marginTop: 2,
    marginBottom: 2,
    marginLeft: 10,
    marginRight: 10
  },
  container: {
    flex: 1,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.Black,
    borderRadius: 10,
    backgroundColor: Colors.VeryPaleBlue,
    flexDirection: 'row',
    marginTop: 15,
    marginLeft: 20,
    marginRight: 20,
    boxShadow: '0 0 0.5px black',
    ':active': { backgroundColor: Colors.PaleBlue },
    ':hover': { backgroundColor: Colors.PaleBlue }
  },
  functionText: {
    color: Colors.Black,
    textAlign: 'left',
    marginLeft: 20,
    fontSize: 20,
    fontWeight: 800
  }
};

export default Radium(FunctionItemLink);

import React from 'react';
import Radium from 'radium';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/index';

import ButtonView from '../common/ButtonView';
import ModalView from '../common/ModalView';
import * as Colors from '../../consts/colors';
import { PAGE_NAMES, MSG_MBL_STOPPED } from '../../consts/consts';
import ErrorBoundary from '../common/ErrorBoundary';

class FooterView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            showMessage: false,
            buttonClick1: false,
            buttonClick2: false,
            buttonClick3: false,
            holdTimer: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.generalMessage.currentMessage && props.generalMessage.currentMessage === MSG_MBL_STOPPED) {
            alert(MSG_MBL_STOPPED);
             props.logOut();    
            return null;
        }

        return null;
    }

    onCloseModal() {
        this.setState({ ...this.state, visible: false });
    }

    getGeneralMessage() {
        const newMessage = this.props.generalMessage.currentMessage;
        const noNewMessage = 'Tap to see messages';

        return this.props.showGeneralMessage ? newMessage : noNewMessage;
    }

    getGeneralMessageList() {
        return this.props.generalMessage.messageList;
    }

    showMessage() {
        this.setState({
            showMessage: true
        });
        if (!this.props.messageOptionUntilCleared) {
            setTimeout(() => {
                this.setState({
                    showMessage: false
                });
            }, this.props.msgDisplayTimeInSeconds * 1000);
        }
    }

    statusMessage() {
        let ldstat = false;

        let tlstat = false;

        let message = 'Sys Normal';

        this.props.threat.threats.forEach(val => {
            if (val.activated) tlstat = true;
        });

        this.props.lockdownArea.lockdownAreas.forEach(val => {
            if (val.activated) ldstat = true;
        });

        if (ldstat && tlstat) {
            message = 'TL/LD Active';
        } else if (tlstat) {
            message = 'TL Active';
        } else if (ldstat) {
            message = 'LD Active';
        }

        return message;
    }

    hasNewMessage() {
        return this.state.showMessage === true;
    }

    sysNormal() {
        if (!this.state.buttonClick3 && this.statusMessage() !== 'Sys Normal') {
            return styles.lockdownMessageField_Red;
        }

        if (!this.state.buttonClick3) return styles.lockdownMessageField;

        return styles.lockdownMessageField_effect;
    }

    render() {
        const props = this.props;

        return (
            <ErrorBoundary>
                <div
                    onClick={() => {
                        props.resetInactivityTimer();
                    }}
                    style={styles.container}
                >
                    {this.state.visible && (
                        <ModalView
                            onClose={this.onCloseModal}
                            dialogStyle={styles.dialogStyle}
                            shouldCloseOnOverlayClick
                        >
                            <div
                                style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                            >
                                {this.props.generalMessage.messageList
                                    .slice(0) // this is for make copy of array
                                    .reverse() // this is for show last one top
                                    .map((value, index) => (
                                        <div key={index} style={styles.message}>
                                            <div style={styles.messageValue}> {value}</div>
                                            <div style={styles.time}>
                                                {
                                                    this.props.generalMessage.messageTimeList
                                                        .slice(0)
                                                        .reverse()[index]
                                                }
                                            </div>
                                        </div>
                                    ))}
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <ButtonView
                                        label="X"
                                        onPress={() => {
                                            this.onCloseModal();
                                        }}
                                        instyle={styles.cancel}
                                    />
                                </div>
                            </div>
                        </ModalView>
                    )}

                    {props.status && (
                        <button
                            style={
                                !this.state.buttonClick1
                                    ? styles.statusMessageField
                                    : styles.statusMessageField_effect
                            }
                            onTouchStart={e => {
                                this.setState({
                                    ...this.state,
                                    buttonClick1: !this.state.buttonClick1
                                }); // Touch Event for mobile device
                            }}
                            onTouchEnd={e => {
                                this.setState({
                                    ...this.state,
                                    buttonClick1: !this.state.buttonClick1
                                });
                            }}
                            onMouseDown={e => {
                                this.setState({
                                    ...this.state,
                                    buttonClick1: !this.state.buttonClick1
                                });
                            }}
                            onMouseUp={e => {
                                this.setState({
                                    ...this.state,
                                    buttonClick1: !this.state.buttonClick1
                                });
                            }}
                        >
                            <p
                                style={Object.assign({}, styles.p, {
                                    color: props.connected ? Colors.DeepGreen : Colors.Red
                                })}
                            >
                                {props.status}
                            </p>
                        </button>
                    )}

                    {props.accessToken &&
                        this.props.currentPage !== PAGE_NAMES.BASE_URL_CONFIGURATION && (
                            <button
                                style={
                                    !this.state.buttonClick2
                                        ? styles.generalMessageField
                                        : styles.generalMessageField_effect
                                }
                                onClick={e => {
                                    //this.props.addMessage(this.props.message);
                                    //this.setState({
                                    //    showMessage: false
                                    //});
                                    this.props.setShowGeneralMessage(false);
                                }}
                                onMouseDown={e => {
                                    try {
                                        let timer = false;

                                        if (!this.state.holdTimer) {
                                            clearTimeout(this.state.holdTimer);
                                            timer = setTimeout(() => {
                                                this.setState({ ...this.state, visible: true });
                                            }, 2000);
                                        }
                                        this.setState({
                                            ...this.state,
                                            holdTimer: timer,
                                            buttonClick2: !this.state.buttonClick2
                                        });
                                    } catch (error) {
                                        this.setState(() => {
                                            throw error;
                                        });
                                    }
                                }}
                                onMouseUp={e => {
                                    try {
                                        if (this.state.holdTimer) {
                                            clearTimeout(this.state.holdTimer);
                                        }
                                        this.setState({
                                            ...this.state,
                                            holdTimer: false,
                                            buttonClick2: !this.state.buttonClick2
                                        });
                                    } catch (error) {
                                        this.setState(() => {
                                            throw error;
                                        });
                                    }
                                }}
                                onTouchMove={e => {
                                    //this.props.addMessage(this.props.message);
                                    this.setState({
                                        showMessage: false
                                    });
                                }}
                                onTouchStart={e => {
                                    try {
                                        let timer = false;

                                        if (!this.state.holdTimer) {
                                            clearTimeout(this.state.holdTimer);
                                            timer = setTimeout(() => {
                                                this.setState({ ...this.state, visible: true });
                                            }, 2000);
                                        }
                                        this.setState({
                                            ...this.state,
                                            holdTimer: timer,
                                            buttonClick2: !this.state.buttonClick2
                                        });
                                    } catch (error) {
                                        this.setState(() => {
                                            throw error;
                                        });
                                    }
                                }}
                                onTouchEnd={e => {
                                    try {
                                        if (this.state.holdTimer) {
                                            clearTimeout(this.state.holdTimer);
                                        }
                                        this.setState({
                                            ...this.state,
                                            holdTimer: false,
                                            buttonClick2: !this.state.buttonClick2
                                        });
                                    } catch (error) {
                                        this.setState(() => {
                                            throw error;
                                        });
                                    }
                                }}
                            >
                                <p style={styles.generalMessage}>{this.getGeneralMessage()}</p>
                            </button>
                        )}

                    {props.accessToken &&
                        this.props.currentPage !== PAGE_NAMES.BASE_URL_CONFIGURATION && (
                            <button
                                onMouseDown={e => {
                                    this.setState({
                                        ...this.state,
                                        buttonClick3: !this.state.buttonClick3
                                    });
                                }}
                                onMouseUp={e => {
                                    this.setState({
                                        ...this.state,
                                        buttonClick3: !this.state.buttonClick3
                                    });
                                }}
                                onTouchStart={e => {
                                    this.setState({
                                        ...this.state,
                                        buttonClick3: !this.state.buttonClick3
                                    });
                                }}
                                onTouchEnd={e => {
                                    this.setState({
                                        ...this.state,
                                        buttonClick3: !this.state.buttonClick3
                                    });
                                }}
                                style={[this.sysNormal(), styles.p_lock]}
                            >
                                <span>{this.statusMessage()}</span>
                            </button>
                        )}
                </div>
            </ErrorBoundary>
        );
    }
}

const styles = {
    dialogStyle: {
        backgroundColor: Colors.VeryPaleBlue,
        padding: 15,
        width: 350,
        height: 'auto'
    },
    underlineless: {
        textDecoration: 'none'
    },
    messageValue: {
        fontSize: 11.5,
        alignItems: 'left'
    },
    message: {
        fontSize: 12,
        flex: 1,
        backgroundColor: Colors.Gray,
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 4,
        color: Colors.White,
        marginBottom: 1
    },
    time: {
        fontSize: 10,
        color: Colors.Cream
    },

    container: {
        display: 'flex',
        padding: 3,
        height: 50,
        flexDirection: 'row',
        backgroundColor: Colors.LightViolet,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.Black,
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    settings: {
        width: 20,
        height: 20,
        padding: 10
    },
    statusMessageField: {
        backgroundColor: Colors.VeryPaleCyan,
        height: 40,
        flexBasis: 72,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: '1px',
        borderStyle: 'solid',
        fontWeight: 600,
        borderColor: Colors.Black,
        borderRadius: 6,
        cursor: 'pointer',
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000'
    },

    statusMessageField_effect: {
        backgroundColor: Colors.LightGray,
        height: 40,
        flexBasis: 72,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: '1px',
        borderStyle: 'solid',
        fontWeight: 600,
        borderColor: Colors.Black,
        borderRadius: 6
    },

    generalMessageField: {
        backgroundColor: Colors.Cream,
        height: 40,
        flex: 1,
        marginLeft: 4,
        marginRight: 4,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: '1px',
        fontWeight: 600,
        borderStyle: 'solid',
        borderColor: Colors.Black,
        borderRadius: 6,
        cursor: 'pointer',
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000'
    },
    generalMessageField_effect: {
        backgroundColor: Colors.LightGray,
        height: 40,
        flex: 1,
        marginLeft: 4,
        marginRight: 4,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: '1px',
        fontWeight: 600,
        borderStyle: 'solid',
        borderColor: Colors.Black,
        borderRadius: 6
    },
    generalMessage: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        color: Colors.Black,
        fontSize: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    lockdownMessageField: {
        backgroundColor: Colors.VeryPaleCyan,
        height: 40,
        flexBasis: 76,
        justifyContent: 'center',
        alignItems: 'center',
        color: Colors.Black,
        fontWeight: 600,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.Black,
        borderRadius: 6,
        cursor: 'pointer',
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000'
    },

    lockdownMessageField_Red: {
        backgroundColor: Colors.Red,
        height: 40,
        flexBasis: 76,
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 600,
        color: Colors.White,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.Black,
        borderRadius: 6,
        cursor: 'pointer',
        boxShadow: '0.5px 0.5px 1px #000000'
    },
    p: {
        fontSize: 10,
        textAlign: 'center'
    },
    p_lock: {
        fontSize: 10,
        textAlign: 'center'
    },
    lockdownMessageField_effect: {
        backgroundColor: Colors.LightGray,
        height: 40,
        flexBasis: 78,
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 600,
        color: Colors.Black,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.Black,
        borderRadius: 6
    }
};

const mapStateToProps = state => ({
    lockdownArea: state.lockdownArea,
    threat: state.threat,
    accessToken: state.token.accessToken,
    connected: state.connectStatus.connected,
    msgDisplayTimeInSeconds: state.settings.msgDisplayTimeInSeconds,
    messageOptionUntilCleared: state.settings.messageOptionUntilCleared,
    configSettings: state.settings,
    generalMessage: state.generalMessage,
    currentPage: state.page.currentPage,
    showGeneralMessage: state.generalMessage.showGeneralMessage,
    currentMessageColor: state.generalMessage.currentMessageColor
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            //addMessage: actions.addMessage,
            resetInactivityTimer: actions.resetInactivityTimer,
            setShowGeneralMessage: actions.setShowGeneralMessage,
            showAlertMessage: actions.showAlertMessage,
            logOut: actions.logOut,
        },
        dispatch
    );

export default Radium(connect(mapStateToProps, mapDispatchToProps)(FooterView));

import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/index';
import * as Colors from '../../consts/colors';
import HeaderView from '../common/HeaderView';
import FooterView from '../common/FooterView';
import FunctionItemLink from '../common/FunctionItemLink';
import BadgeHoldersInTimeView from './BadgeHoldersInTimeView';
import ErrorBoundary from '../common/ErrorBoundary';
import {
    PAGE_NAMES,
    SORTING_ORDER,
    SECURITY_LEVEL_CODES,
    ADMIN_ROLE_ID,
    SCREEN_CODES
} from '../../consts/consts';


class BadgeHoldersInTimeScreen extends Component {
    constructor(props) {
        super(props);

        const badgeHoldersInPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.BADGEHOLDERIN
        );

        let doorPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.MANUAL_DOORS
        );

        let relayPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.MANUAL_RELAY
        );

        if (typeof doorPrivilege === 'undefined') {
            doorPrivilege = null;
        }

        if (typeof relayPrivilege === 'undefined') {
            relayPrivilege = null;
        }
        const lockDownPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.LOCKDOWN
        );

        const personnelPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.PERSONNEL
        );

        const scheduleChangesPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.SCHEDULECHANGES
        );

        const statusPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.STATUS
        );

        const threatLevelPrivilege = this.props.privileges.find(
            privilege => privilege.ScreenID === SCREEN_CODES.THREATLEVEL
        );

        this.state = { badgeHoldersInPrivilege, threatLevelPrivilege, statusPrivilege, scheduleChangesPrivilege, personnelPrivilege, lockDownPrivilege, doorPrivilege, relayPrivilege };

    }


    render() {
        const connectionStatus = this.props.connectStatus.connected ? 'Online' : 'Offline';
        const statusMessage = this.props.connectStatus.connected
            ? this.props.connectStatus.onLineMessage
            : this.props.connectStatus.offLineMessage;
        return (
            <div
                onClick={() => {
                    this.props.resetInactivityTimer();
                }}
                style={styles.container}
            >
                <div styles={styles.header}>
                    <HeaderView hasMenu />
                </div>
                <div style={styles.bodyContainer}>
                    <ErrorBoundary>
                        <div style={{ marginLeft: 20, marginTop: 10 }}>
                            <BadgeHoldersInTimeView />
                        </div>

                        {this.props.tmpViewSelection[0].status && !(this.props.roleId !== ADMIN_ROLE_ID && (this.state.doorPrivilege === null || typeof (this.state.doorPrivilege) === 'undefined')) && this.props.menuPriviledges.MyDoorMenuEnabled === true &&(
                            <FunctionItemLink Title="My Doors" link="/doors" />
                        )}
                        {this.props.tmpViewSelection[1].status && !(this.props.roleId !== ADMIN_ROLE_ID && (this.state.lockDownPrivilege === null || typeof (this.state.lockDownPrivilege) === 'undefined')) && this.props.menuPriviledges.LockDownAreaMenuEnabled === true && (
                            <FunctionItemLink Title="Lockdown" link="/lockdown" />
                        )}
                        {this.props.tmpViewSelection[2].status && !(this.props.roleId !== ADMIN_ROLE_ID && (this.state.threatLevelPrivilege === null || typeof (this.state.threatLevelPrivilege) === 'undefined')) && this.props.menuPriviledges.ThreatLevelMenuEnabled === true &&(
                            <FunctionItemLink Title="Threat Levels" link="/threatlevel" />
                        )}
                        {this.props.tmpViewSelection[5].status && !(this.props.roleId !== ADMIN_ROLE_ID && (this.state.personnelPrivilege === null || typeof (this.state.personnelPrivilege) === 'undefined')) && this.props.menuPriviledges.PersonnelMenuEnabled === true &&
                            (
                                <FunctionItemLink Title="Personnel" link="/personnel" />
                            )}
                        {this.props.tmpViewSelection[3].status && !(this.props.roleId !== ADMIN_ROLE_ID && (this.state.statusPrivilege === null || typeof (this.state.statusPrivilege) === 'undefined')) && this.props.menuPriviledges.StatusMenuEnabled === true && (
                            <FunctionItemLink Title="Status" link="/status" />
                        )}

                        {this.props.tmpViewSelection[4].status && !(this.props.roleId !== ADMIN_ROLE_ID && (this.state.doorPrivilege === null || typeof (this.state.doorPrivilege) === 'undefined')) && !(this.props.roleId !== ADMIN_ROLE_ID && (this.state.relayPrivilege === null || typeof (this.state.relayPrivilege) === 'undefined')) && this.props.menuPriviledges.ControlMenuEnabled === true && (
                            <FunctionItemLink Title="Control" link="/control" />
                        )}
                        {this.props.tmpViewSelection[7].status && !(this.props.roleId !== ADMIN_ROLE_ID && (this.state.scheduleChangesPrivilege === null || typeof (this.state.scheduleChangesPrivilege) === 'undefined')) && this.props.menuPriviledges.ScheduleChangesMenuEnabled === true &&(
                            <FunctionItemLink Title="Scheduled Changes" link="/scheduledchanges" />
                        )}
                    </ErrorBoundary>
                </div>
                <div style={styles.footer}>
                    <FooterView
                        messageList={this.props.generalMessage.messageList}
                        status={connectionStatus}
                        message={statusMessage}
                        lockdown="Sys Normal"
                    />
                </div>
            </div>
        );
    }
}

const styles = {
    container: {
        display: 'flex',
        flex: 1,
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: Colors.DarkModerateViolet
    },
    header: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%'
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%'
    },
    bodyContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
        marginBottom: 65
    },
    appMessageArea: {},
    appBannerArea: {
        alignItems: 'center'
    },
    h1: {
        marginTop: 100,
        textAlign: 'center',
        color: Colors.Purple
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    icon: {
        marginTop: 2,
        marginBottom: 2,
        marginLeft: 10,
        marginRight: 10
    }
};

const mapStateToProps = state => ({
    tmpViewSelection: state.settings.tmpViewSelection,
    connectStatus: state.connectStatus,
    generalMessage: state.generalMessage,
    roleId: state.token.roleId,
    privileges: state.token.privileges,
    menuPriviledges: state.priviledge
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            resetInactivityTimer: actions.resetInactivityTimer
        },
        dispatch
    );

export default Radium(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(BadgeHoldersInTimeScreen)
);

import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { Link, Redirect } from 'react-router-dom';
import * as Colors from '../../consts/colors';
import ButtonView from '../common/ButtonView';
import PINView from '../common/PINView';
import ModalView from '../common/ModalView';
import ModalProgressView from '../common/ModalProgressView';
import * as actions from '../../actions/index';
import { PAGE_NAMES, MAX_FACILITY_CODE } from '../../consts/consts';
import * as Messages from '../../consts/messages'
const _ = require('lodash');

class PersonnelEditView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isBadgeLinked: false,
            collapseButtonPressed: false,
            dataChanged: false,
            showModal: false,
            showEditNodal: false,
            firstName: '',
            lastName: '',
            pin: props.currentPersonnel && props.currentPersonnel.pin ? props.currentPersonnel.pin:'',
            facilityNo: props.currentPersonnel.facility,
            badgeNo: props.currentPersonnel.badge,
            badgeList: [],
            selectedCredentialNo: '',
            selectedFacilityNo: '',
            selectedIndexOfBadge: 0,
            newAddedBadge: {},
            selectedIndexOfAccessGroups: 0,
            currentPersonnel: props.currentPersonnel,
            selectedAccessGroup1Value: props.currentPersonnel.agNo1,
            selectedAccessGroup2Value: props.currentPersonnel.agNo2,
            selectedAccessGroup3Value: props.currentPersonnel.agNo3,
            selectedAccessGroup4Value: props.currentPersonnel.agNo4,
            selectedAccessGroup5Value: props.currentPersonnel.agNo5,
            selectedAccessGroup6Value: props.currentPersonnel.agNo6,
            selectedAccessGroup7Value: props.currentPersonnel.agNo7,
            selectedAccessGroup8Value: props.currentPersonnel.agNo8,
            selectedAccessGroup9Value: props.currentPersonnel.agNo9,
            selectedAccessGroup10Value: props.currentPersonnel.agNo10,
            selectedAccessGroup11Value: props.currentPersonnel.agNo11,
            selectedAccessGroup12Value: props.currentPersonnel.agNo12,
            selectedAccessGroup13Value: props.currentPersonnel.agNo13,
            selectedAccessGroup14Value: props.currentPersonnel.agNo14,
            selectedAccessGroup15Value: props.currentPersonnel.agNo15,
            selectedAccessGroup16Value: props.currentPersonnel.agNo16
        };

        this.refAG1 = React.createRef();
        this.refAG2 = React.createRef();
        this.refAG3 = React.createRef();
        this.refAG4 = React.createRef();
        this.refAG5 = React.createRef();
        this.refAG6 = React.createRef();
        this.refAG7 = React.createRef();
        this.refAG8 = React.createRef();
        this.refAG9 = React.createRef();
        this.refAG10 = React.createRef();
        this.refAG11 = React.createRef();
        this.refAG12 = React.createRef();
        this.refAG13 = React.createRef();
        this.refAG14 = React.createRef();
        this.refAG15 = React.createRef();
        this.refAG16 = React.createRef();

        this.txtPINCode = React.createRef();
        this.txtConfirmPINCode = React.createRef();
        this.refBadgeList = React.createRef();
        this.refAddBadge = React.createRef();
        this.refEditBadge = React.createRef();
        this.refSavePersonnel = React.createRef();

        this.props.setCurrentPageName(PAGE_NAMES.EDIT_PERSONNEL);

        /// initial badgeInfo set for edited badge
        const  badgeInfo = { badge: props.currentPersonnel.badge, editedBadgeNo: props.currentPersonnel.badge,facility: props.currentPersonnel.facility, editedFacilityNo: props.currentPersonnel.facility };
        this.props.setEditedBadgeInfo(badgeInfo);

        this.handleAccessGroup1SelectionChanged = this.handleAccessGroup1SelectionChanged.bind(
            this
        );
        this.handleAccessGroup2SelectionChanged = this.handleAccessGroup2SelectionChanged.bind(
            this
        );

        this.handleAccessGroup3SelectionChanged = this.handleAccessGroup3SelectionChanged.bind(
            this
        );

        this.handleAccessGroup3SelectionChanged = this.handleAccessGroup3SelectionChanged.bind(
            this
        );

        this.handleAccessGroup4SelectionChanged = this.handleAccessGroup4SelectionChanged.bind(
            this
        );
        this.handleAccessGroup5SelectionChanged = this.handleAccessGroup5SelectionChanged.bind(
            this
        );
        this.handleAccessGroup6SelectionChanged = this.handleAccessGroup6SelectionChanged.bind(
            this
        );
        this.handleAccessGroup7SelectionChanged = this.handleAccessGroup7SelectionChanged.bind(
            this
        );
        this.handleAccessGroup8SelectionChanged = this.handleAccessGroup8SelectionChanged.bind(
            this
        );
        this.handleAccessGroup9SelectionChanged = this.handleAccessGroup9SelectionChanged.bind(
            this
        );
        this.handleAccessGroup10SelectionChanged = this.handleAccessGroup10SelectionChanged.bind(
            this
        );
        this.handleAccessGroup11SelectionChanged = this.handleAccessGroup11SelectionChanged.bind(
            this
        );
        this.handleAccessGroup12SelectionChanged = this.handleAccessGroup12SelectionChanged.bind(
            this
        );
        this.handleAccessGroup13SelectionChanged = this.handleAccessGroup13SelectionChanged.bind(
            this
        );
        this.handleAccessGroup14SelectionChanged = this.handleAccessGroup14SelectionChanged.bind(
            this
        );
        this.handleAccessGroup15SelectionChanged = this.handleAccessGroup15SelectionChanged.bind(
            this
        );
        this.handleAccessGroup16SelectionChanged = this.handleAccessGroup16SelectionChanged.bind(
            this
        );
        this.handleCredentialFacilitySelectionChanged = this.handleCredentialFacilitySelectionChanged.bind(
            this
        );

        this.isTheSelectedBadgeNewlyAdded = this.isTheSelectedBadgeNewlyAdded.bind(
            this
        );
        this.checkIfDuplicateAccessGroupSelected = this.checkIfDuplicateAccessGroupSelected.bind(
            this
        );
        this.convertToFullEntityList = this.convertToFullEntityList.bind(this);
        this.handleEditButtonClicked = this.handleEditButtonClicked.bind(this);
        this.handleEditButtonInModalClicked = this.handleEditButtonInModalClicked.bind(this);
        this.handleEditCredentialNoChanged = this.handleEditCredentialNoChanged.bind(this);
        this.handleEditFacilityNoChanged = this.handleEditFacilityNoChanged.bind(this);
        this.handleCancelButtonPressed = this.handleCancelButtonPressed.bind(this);
        this.handleFirstNameChanged = this.handleFirstNameChanged.bind(this);
        this.handleLastNameChanged = this.handleLastNameChanged.bind(this);
        this.showBadgeList = this.showBadgeList.bind(this);
        this.handlePINCodeEntryChanged = this.handlePINCodeEntryChanged.bind(this);

        //    this.showPartitionGroupList = this.showPartitionGroupList.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.handleAddButtonClicked = this.handleAddButtonClicked.bind(this);
        this.handleAddButtonInModalClicked = this.handleAddButtonInModalClicked.bind(
            this
        );
        this.handleCredentialNoChanged = this.handleCredentialNoChanged.bind(this);
        this.handleFacilityNoChanged = this.handleFacilityNoChanged.bind(this);
        this.discardChanges = this.discardChanges.bind(this);
        this.savePersonnel = this.savePersonnel.bind(this);
        this.props.fetchSystemSettings();
        this.props.fetchAccessGroupList();
        this.props.fetchBadgeListByPersonnelId(this.props.currentPersonnel.id);
        this.props.resetLoadingFlag();
        this.props.resetPersonnelPINViewDataChanged();
        this.props.resetNewBadgeAddedInDropDown();
        this.props.resetReEnterPINCode();
        this.props.changePersonnelPIN(props.currentPersonnel && props.currentPersonnel.pin ? props.currentPersonnel.pin : '');
        if (this.props.tmpSelectedBadgeOfUser && this.props.tmpSelectedBadgeOfUser.Badge ) {
            this.props.setBadgeLinked(true);
        }
        else {
            this.props.setBadgeLinked(false);
        }

        this.props.writeLog('OperatorID: ' + this.props.operatorId + "PersonnelEditView -->  loader personnel -->  " + JSON.stringify(props.currentPersonnel));
        this.props.resetSearchTextAllAG();

    }

    handlePINCodeEntryChanged(event) {

        const pinInputted = event.target.value;
        const regexp4 = /^[0-9\b]{0,4}$/; // 0-9 digits only regex
        const regexp9 = /^[0-9\b]{0,9}$/; // 0-9 digits only regex
        let regexp = /^[0-9\b]{0,4}$/;  // default

        if (this.props.keypadOption === 2 && (event.target.value.length >= 9 || event.target.value.length === 0)) {
            event.preventDefault();
        }
        else if (this.props.keypadOption === 1 && (event.target.value.length >= 4 || event.target.value.length === 0))
        {
            event.preventDefault();
        }

        if (this.props.keypadOption === 2) 
            regexp = regexp9;
        else
             regexp = regexp4;

        if (regexp.test(pinInputted)) {
            console.log('regex passed.');
            this.props.changePersonnelPIN(event.target.value);
            this.props.personnelPINViewDataChanged();
        } else {
            console.log('regex failed.');
            event.preventDefault();
            
          
        }
    }


    discardChanges() {
        const props = this.props;
        // Initial Badge 
    
        // reseting edited badge info
        console.log("------",this.convertToFullEntityList(this.props.badgeListPersonnelIdWise));

        this.setState({
            collapseButtonPressed: false,
            dataChanged: false,
            showModal: false,
            showEditNodal: false,
            firstName: '',
            lastName: '',
            pin: props.currentPersonnel && props.currentPersonnel.pin ? props.currentPersonnel.pin : '',
            facilityNo: props.currentPersonnel.facility,
            badgeNo: props.currentPersonnel.badge,
            badgeList:this.convertToFullEntityList(this.props.badgeListPersonnelIdWise),
            selectedCredentialNo: `${this.props.currentPersonnel.badge}/${this.props.currentPersonnel.facility}`,
            selectedFacilityNo: '',
            selectedIndexOfBadge: 0,
            newAddedBadge: {},
            selectedIndexOfAccessGroups: 0,
            currentPersonnel: props.currentPersonnel,
            selectedAccessGroup1Value: props.currentPersonnel.agNo1,
            selectedAccessGroup2Value: props.currentPersonnel.agNo2,
            selectedAccessGroup3Value: props.currentPersonnel.agNo3,
            selectedAccessGroup4Value: props.currentPersonnel.agNo4,
            selectedAccessGroup5Value: props.currentPersonnel.agNo5,
            selectedAccessGroup6Value: props.currentPersonnel.agNo6,
            selectedAccessGroup7Value: props.currentPersonnel.agNo7,
            selectedAccessGroup8Value: props.currentPersonnel.agNo8,
            selectedAccessGroup9Value: props.currentPersonnel.agNo9,
            selectedAccessGroup10Value: props.currentPersonnel.agNo10,
            selectedAccessGroup11Value: props.currentPersonnel.agNo11,
            selectedAccessGroup12Value: props.currentPersonnel.agNo12,
            selectedAccessGroup13Value: props.currentPersonnel.agNo13,
            selectedAccessGroup14Value: props.currentPersonnel.agNo14,
            selectedAccessGroup15Value: props.currentPersonnel.agNo15,
            selectedAccessGroup16Value: props.currentPersonnel.agNo16
        }, () => {
            const personnelIdInfo = {
                id: this.props.currentPersonnel.id,
                badge: this.state.badgeNo,
                facility: this.state.facilityNo
            };
        
            console.log("pin--->",this.state.currentPersonnel.pin);
            this.props.fetchPersonnel(personnelIdInfo);
            });
     
    }

    convertToFullEntityList(badgeListPersonnelIDWise) {
        let badgeList = [];

        for (let i = 0; i < badgeListPersonnelIDWise.length; ++i) {
            const badgeInfo = badgeListPersonnelIDWise[i];
           
            const badge = { ...badgeInfo, editedBadgeNo: badgeInfo.badge, editedFacilityNo: badgeInfo.facility }
            badgeList.push(badge);
            console.log("BadgeInfo---", badgeInfo, badge,badgeList);
        }

        return badgeList;
    }


    UNSAFE_componentWillMount() {
        let badgelist = [];
       // badgelist = [...this.props.badgeListPersonnelIdWise];
        let isBadgeLinked = false;
        badgelist = this.convertToFullEntityList(this.props.badgeListPersonnelIdWise);

        const tmpSelectedBadgeOfUser= this.props.tmpSelectedBadgeOfUser;

     
        if (typeof (tmpSelectedBadgeOfUser) !== 'undefined' && tmpSelectedBadgeOfUser && tmpSelectedBadgeOfUser.Badge && tmpSelectedBadgeOfUser.Facility >= 0 && (this.state.badgeNo.toString() === tmpSelectedBadgeOfUser.Badge.toString()) && (this.state.facilityNo.toString() === tmpSelectedBadgeOfUser.Facility.toString())) {
            isBadgeLinked = true; // freedge this badge
            console.log("IsBadgeLinked-->Mount", isBadgeLinked);
        }
        this.props.setBadgeLinked(isBadgeLinked);

        this.setState(
            (prevState, props) => ({
                ...prevState,
                badgeList: [...badgelist],
                selectedCredentialNo: `${this.props.currentPersonnel.badge}/${this.props.currentPersonnel.facility}`,
                badgeNo: this.props.currentPersonnel.badge
            }),
            () => {
                const personnelIdInfo = {
                    id: this.props.currentPersonnel.id,
                    badge: this.state.badgeNo,
                    facility: this.state.facilityNo
                };
                this.props.fetchPersonnel(personnelIdInfo);
               }
        );


    }


    UNSAFE_componentWillReceiveProps(updatedProps) {
        if (this.props !== updatedProps) {
           const {
                badgeExists,
                loading,
                personnel,
                badgeListPersonnelIdWise,
                badgeInfoUpdatedStatus,
               isBadgeLinked,
               pinViewDataChanged

            } = updatedProps;

            if (
                badgeListPersonnelIdWise != null &&
                this.props.badgeListPersonnelIdWise !== badgeListPersonnelIdWise
            ) {
                let badgeList = this.convertToFullEntityList(badgeListPersonnelIdWise);

                console.log("BadgeList :", badgeList);

                this.setState(
                    {
                        badgeList: [...badgeList],
                        selectedCredentialNo: `${updatedProps.currentPersonnel.badge}/${updatedProps.currentPersonnel.facility}`,
                        badgeNo: updatedProps.currentPersonnel.badge,
                        facilityNo: updatedProps.currentPersonnel.facility
                    },
                    () => {
                        console.log('fetch personnel info....');
                        const personnelIdInfo = {
                            id: updatedProps.currentPersonnel.id,
                            badge: this.state.badgeNo,
                            facility: this.state.facilityNo
                        };
                        this.props.fetchPersonnel(personnelIdInfo);
                    }
                );
                return;
            }

            //if (updatedProps.personnelPIN !== this.props.personnelPIN) {
            //    this.setState((state) => ({ ...state, dataChanged: true }), () => { console.log(this.state); })
            //}


            if (personnel != null && !_.isEmpty(personnel) ) {
                console.log('personnel-----', personnel);
               // this.props.changePersonnelPIN(updatedProps.personnel.pin);

                this.setState(
                    {
                        ...this.state,
                        badgeNo: updatedProps.personnel.badge,
                        facilityNo: updatedProps.personnel.facility,
                        selectedCredentialNo: `${updatedProps.personnel.badge}/${updatedProps.personnel.facility}`,
                        pin: updatedProps.personnel.pin,
                        currentPersonnel: {
                            ...this.state.currentPersonnel,
                            ...updatedProps.personnel,
                            pin: updatedProps.personnel.pin,
                            firstName: this.state.currentPersonnel.firstName,
                            lastName: this.state.currentPersonnel.lastName,
                            agNo1: updatedProps.personnel.agNo1,
                            agNo2: updatedProps.personnel.agNo2,
                            agNo3: updatedProps.personnel.agNo3,
                            agNo4: updatedProps.personnel.agNo4,
                            agNo5: updatedProps.personnel.agNo5,
                            agNo6: updatedProps.personnel.agNo6,
                            agNo8: updatedProps.personnel.agNo8,
                            agNo7: updatedProps.personnel.agNo7,
                            agNo9: updatedProps.personnel.agNo9,
                            agNo10: updatedProps.personnel.agNo10,
                            agNo11: updatedProps.personnel.agNo11,
                            agNo12: updatedProps.personnel.agNo12,
                            agNo13: updatedProps.personnel.agNo13,
                            agNo14: updatedProps.personnel.agNo14,
                            agNo15: updatedProps.personnel.agNo15,
                            agNo16: updatedProps.personnel.agNo16,
                            enable: updatedProps.personnel.enable
                        },

                        selectedAccessGroup1Value: updatedProps.personnel.agNo1,
                        selectedAccessGroup2Value: updatedProps.personnel.agNo2,
                        selectedAccessGroup3Value: updatedProps.personnel.agNo3,
                        selectedAccessGroup4Value: updatedProps.personnel.agNo4,
                        selectedAccessGroup5Value: updatedProps.personnel.agNo5,
                        selectedAccessGroup6Value: updatedProps.personnel.agNo6,
                        selectedAccessGroup7Value: updatedProps.personnel.agNo7,
                        selectedAccessGroup8Value: updatedProps.personnel.agNo8,
                        selectedAccessGroup9Value: updatedProps.personnel.agNo9,
                        selectedAccessGroup10Value: updatedProps.personnel.agNo10,
                        selectedAccessGroup11Value: updatedProps.personnel.agNo11,
                        selectedAccessGroup12Value: updatedProps.personnel.agNo12,
                        selectedAccessGroup13Value: updatedProps.personnel.agNo13,
                        selectedAccessGroup14Value: updatedProps.personnel.agNo14,
                        selectedAccessGroup15Value: updatedProps.personnel.agNo15,
                        selectedAccessGroup16Value: updatedProps.personnel.agNo16
                    },
                    () => {
                        //this.props.resetSinglePersonnel();
                        console.log('currentPersonnel>', this.state);
                      
                       if (this.txtPINCode && this.txtPINCode.current != null && this.txtPINCode.current.value && this.state.currentPersonnel.pin !== this.txtPINCode.current.value) {
                           // if (this.txtPINCode.current.value !== updatedProps.personnelPIN) {
                           if (pinViewDataChanged !== true) {
                               this.txtPINCode.current.value = this.state.currentPersonnel.pin;
                           }
                          //  }
                        }
                      
                        
                    }
                );
             //   return;
            } else {

                const badgeList = this.state.badgeList;

                const lastlyAddedBadge = badgeList && badgeList.length > 0 ? badgeList[badgeList.length - 1]: null;
                const lastLyAddedBadgeNo = lastlyAddedBadge && lastlyAddedBadge.badge ? lastlyAddedBadge.badge :null;
                const lastlyAddedFacility = lastlyAddedBadge && lastlyAddedBadge.facility ? lastlyAddedBadge.facility : null;
                

                this.setState(
                    {
                        ...this.state,
                        badgeNo: lastLyAddedBadgeNo,
                        facilityNo: lastlyAddedFacility,
                        selectedCredentialNo: `${lastLyAddedBadgeNo}/${lastlyAddedFacility}`,
                        currentPersonnel: {
                            ...this.state.currentPersonnel,
                            firstName: this.state.currentPersonnel.firstName,
                            lastName: this.state.currentPersonnel.lastName,
                            agNo1: 0,
                            agNo2: 0,
                            agNo3: 0,
                            agNo4: 0,
                            agNo5: 0,
                            agNo6: 0,
                            agNo8: 0,
                            agNo7: 0,
                            agNo9: 0,
                            agNo10: 0,
                            agNo11: 0,
                            agNo12: 0,
                            agNo13: 0,
                            agNo14: 0,
                            agNo15: 0,
                            agNo16: 0
                        },

                        selectedAccessGroup1Value: 0,
                        selectedAccessGroup2Value: 0,
                        selectedAccessGroup3Value: 0,
                        selectedAccessGroup4Value: 0,
                        selectedAccessGroup5Value: 0,
                        selectedAccessGroup6Value: 0,
                        selectedAccessGroup7Value: 0,
                        selectedAccessGroup8Value: 0,
                        selectedAccessGroup9Value: 0,
                        selectedAccessGroup10Value: 0,
                        selectedAccessGroup11Value: 0,
                        selectedAccessGroup12Value: 0,
                        selectedAccessGroup13Value: 0,
                        selectedAccessGroup14Value: 0,
                        selectedAccessGroup15Value: 0,
                        selectedAccessGroup16Value: 0
                    },
                    () => {
                       // this.props.resetSinglePersonnel();
                        console.log('currentPersonnel>>', this.state);
                    }
                    
                );
              //  return;
            }

        

            if (loading === false && badgeExists === 3) {
                let lastSerialNo;
                const badgeList = this.state.badgeList;

                badgeList.push({
                    serialNo: parseInt(lastSerialNo, 10) + 1,
                    editedBadgeNo: this.state.newAddedBadge.badge,
                    editedFacilityNo: this.state.newAddedBadge.facility,
                    ...this.state.newAddedBadge
                });

                console.log('Badge List:', badgeList.length);
                if (badgeList.length >= 1) {
                  //  const badgeNo = badgeList[0].badge;
                 //   const facilityNo = badgeList[0].facility;
                  const badgeNo = badgeList[badgeList.length - 1].badge;
                  const facilityNo = badgeList[badgeList.length - 1].facility;

                    this.setState(
                        (prevState, props) => ({
                            ...prevState,
                            showModal: false,
                            newAddedBadge: {},
                            badgeNo,
                            facilityNo,
                            badgeList,
                            selectedCredentialNo: `${badgeList[badgeList.length - 1].badge}/${
                                badgeList[badgeList.length - 1].facility
                                }`,
                            currentPersonnel: {
                                ...prevState.currentPersonnel,
                                firstName: prevState.currentPersonnel.firstName,
                                lastName: prevState.currentPersonnel.lastName,
                                pin:'',
                                agNo1: 0,
                                agNo2: 0,
                                agNo3: 0,
                                agNo4: 0,
                                agNo5: 0,
                                agNo6: 0,
                                agNo8: 0,
                                agNo7: 0,
                                agNo9: 0,
                                agNo10: 0,
                                agNo11: 0,
                                agNo12: 0,
                                agNo13: 0,
                                agNo14: 0,
                                agNo15: 0,
                                agNo16: 0
                            },

                            selectedAccessGroup1Value: 0,
                            selectedAccessGroup2Value: 0,
                            selectedAccessGroup3Value: 0,
                            selectedAccessGroup4Value: 0,
                            selectedAccessGroup5Value: 0,
                            selectedAccessGroup6Value: 0,
                            selectedAccessGroup7Value: 0,
                            selectedAccessGroup8Value: 0,
                            selectedAccessGroup9Value: 0,
                            selectedAccessGroup10Value: 0,
                            selectedAccessGroup11Value: 0,
                            selectedAccessGroup12Value: 0,
                            selectedAccessGroup13Value: 0,
                            selectedAccessGroup14Value: 0,
                            selectedAccessGroup15Value: 0,
                            selectedAccessGroup16Value: 0
                        }),
                        () => {
                            this.props.resetSinglePersonnel();
                            console.log('selectedAccessGroup:', this.state);
                        }
                    );
                } else {
                    this.setState((prevState, props) => ({
                        ...prevState,
                        showModal: false,
                        newAddedBadge: {},
                        badgeList,
                        currentPersonnel: {
                            ...prevState.currentPersonnel,
                            firstName: prevState.currentPersonnel.firstName,
                            lastName: prevState.currentPersonnel.lastName,
                            pin: '',
                            agNo1: 0,
                            agNo2: 0,
                            agNo3: 0,
                            agNo4: 0,
                            agNo5: 0,
                            agNo6: 0,
                            agNo8: 0,
                            agNo7: 0,
                            agNo9: 0,
                            agNo10: 0,
                            agNo11: 0,
                            agNo12: 0,
                            agNo13: 0,
                            agNo14: 0,
                            agNo15: 0,
                            agNo16: 0
                        },

                        selectedAccessGroup1Value: 0,
                        selectedAccessGroup2Value: 0,
                        selectedAccessGroup3Value: 0,
                        selectedAccessGroup4Value: 0,
                        selectedAccessGroup5Value: 0,
                        selectedAccessGroup6Value: 0,
                        selectedAccessGroup7Value: 0,
                        selectedAccessGroup8Value: 0,
                        selectedAccessGroup9Value: 0,
                        selectedAccessGroup10Value: 0,
                        selectedAccessGroup11Value: 0,
                        selectedAccessGroup12Value: 0,
                        selectedAccessGroup13Value: 0,
                        selectedAccessGroup14Value: 0,
                        selectedAccessGroup15Value: 0,
                        selectedAccessGroup16Value: 0
                    }));
                }

                this.props.resetBadgeExistsFlag();
           } else if (loading === false && badgeExists === 2) {
                alert('Badge Exists!');
                this.props.resetBadgeExistsFlag();
            }

            if (badgeInfoUpdatedStatus === 1) {  /// Badge Info updated successfully

                this.location.reload(false);
            } else if (badgeInfoUpdatedStatus === 2) {  /// Badge exists for updating badges

                alert('Badge already exists, please enter another badgo no again');

            } else if (badgeInfoUpdatedStatus === 0) { // nothing happens may be info wrong format..

                alert('please enter badgo info again');
            }

        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.badgeListPersonnelIdWise !== this.props.badgeListPersonnelIdWise
        ) {

            let badgeList = this.convertToFullEntityList(this.props.badgeListPersonnelIdWise);
            console.log("++badgeList++", badgeList);
            
            this.setState({
                ...prevState,
                badgeList: [...badgeList],
                selectedCredentialNo: `${this.props.currentPersonnel.badge}/${this.props.currentPersonnel.facility}`,
                badgeNo: this.props.currentPersonnel.badge
            });

            console.log('ComponentDidUpdate.....');

            const personnelIdInfo = {
                id: this.props.currentPersonnel.id,
                badge: this.state.badgeNo,
                facility: this.state.facilityNo
            };
            this.props.fetchPersonnel(personnelIdInfo);
        }

        if (prevProps.isBadgeLinked !== this.props.isBadgeLinked) {
            this.setState({ isBadgeLinked: prevProps.isBadgeLinked });
        }

    }

    componentWillUnmount() {
        this.props.resetBadgeList();
        this.setState({
            ...this.state,
            collapseButtonPressed: false,
            dataChanged: false
        });
    }
    onCloseModal() {
        this.setState({ ...this.state, showModal: false });
    }

    onCloseEditModal() {
        this.setState({ ...this.state, showEditModal: false });
    }


    checkIfDuplicateAccessGroupSelected(selectedAccessGroupValue) {
        if (selectedAccessGroupValue === 0) {
            return false;
        }

        if (
            selectedAccessGroupValue ===
            parseInt(this.state.selectedAccessGroup1Value, 10) ||
            selectedAccessGroupValue ===
            parseInt(this.state.selectedAccessGroup2Value, 10) ||
            selectedAccessGroupValue ===
            parseInt(this.state.selectedAccessGroup3Value, 10) ||
            selectedAccessGroupValue ===
            parseInt(this.state.selectedAccessGroup4Value, 10) ||
            selectedAccessGroupValue ===
            parseInt(this.state.selectedAccessGroup5Value, 10) ||
            selectedAccessGroupValue ===
            parseInt(this.state.selectedAccessGroup6Value, 10) ||
            selectedAccessGroupValue ===
            parseInt(this.state.selectedAccessGroup7Value, 10) ||
            selectedAccessGroupValue ===
            parseInt(this.state.selectedAccessGroup8Value, 10) ||
            selectedAccessGroupValue ===
            parseInt(this.state.selectedAccessGroup9Value, 10) ||
            selectedAccessGroupValue ===
            parseInt(this.state.selectedAccessGroup10Value, 10) ||
            selectedAccessGroupValue ===
            parseInt(this.state.selectedAccessGroup11Value, 10) ||
            selectedAccessGroupValue ===
            parseInt(this.state.selectedAccessGroup12Value, 10) ||
            selectedAccessGroupValue ===
            parseInt(this.state.selectedAccessGroup13Value, 10) ||
            selectedAccessGroupValue ===
            parseInt(this.state.selectedAccessGroup14Value, 10) ||
            selectedAccessGroupValue ===
            parseInt(this.state.selectedAccessGroup15Value, 10) ||
            selectedAccessGroupValue ===
            parseInt(this.state.selectedAccessGroup16Value, 10)
        ) {
            console.log('Duplicate:', this.state.selectedAccessGroup1Value);
            return true;
        }

        return false;
    }

    handleCancelButtonPressed() { }

    handleCredentialFacilitySelectionChanged({ target }) {
        const { value } = target;

        let isBadgeLinked = false;
   
                
        const index = this.state.badgeList.findIndex(
            x => `${x.badge}/${x.facility}` === value
        );

        if (typeof (this.props.tmpSelectedBadgeOfUser) !== 'undefined' && this.props.tmpSelectedBadgeOfUser && this.props.tmpSelectedBadgeOfUser.Badge && this.props.tmpSelectedBadgeOfUser.Facility >= 0 && (this.state.badgeList[index].badge.toString() === this.props.tmpSelectedBadgeOfUser.Badge.toString()) && (this.state.badgeList[index].facility.toString() === this.props.tmpSelectedBadgeOfUser.Facility.toString())) {
            isBadgeLinked = true; // freedge this badge
  
        }

        console.log("dataChanges---", this.props.newBadgeAddedInDropDownList);
        this.props.writeLog('OperatorID: ' + this.props.operatorId + "PersonnelEditView -->  BadgeFacilityDropDownSelectionChanged-->");

        if (this.state.dataChanged === true || this.props.pinViewDataChanged === true || this.props.newBadgeAddedInDropDownList === true) {
            alert('Selected badge already being modified. Please save the changes or cancel.');
            return;
        }

        if (index != null) {
            const personnelIdInfo = {
                id: this.props.currentPersonnel.id,
                badge: this.state.badgeList[index].badge,
                facility: this.state.badgeList[index].facility,
                
            };

            // updating editedBadgeInfo in reducer
            const   badgeInfo = { badge: personnelIdInfo.badge, editedBadgeNo: personnelIdInfo.badge, facility: personnelIdInfo.facility, editedFacilityNo: personnelIdInfo.facility };
            this.props.setEditedBadgeInfo(badgeInfo);
            this.props.fetchPersonnel(personnelIdInfo);
            this.props.writeLog('OperatorID: ' + this.props.operatorId + "PersonnelEditView -->  isBadgeLinked-->" + isBadgeLinked);
            this.props.setBadgeLinked(isBadgeLinked);
        }

       

        if (this.isTheSelectedBadgeNewlyAdded(value)) {
            this.props.setBadgeLinked(false);
            this.setState(state => ({
                ...state,
                dataChanged: true,
                selectedIndexOfBadge: index,
                selectedCredentialNo: value,
                selectedFacilityNo: state.badgeList[index].facility,
                badgeNo: state.badgeList[index].badge,
                facilityNo: state.badgeList[index].facility,
                selectedAccessGroup1Value: 0,
                selectedAccessGroup2Value: 0,
                selectedAccessGroup3Value: 0,
                selectedAccessGroup4Value: 0,
                selectedAccessGroup5Value: 0,
                selectedAccessGroup6Value: 0,
                selectedAccessGroup7Value: 0,
                selectedAccessGroup8Value: 0,
                selectedAccessGroup9Value: 0,
                selectedAccessGroup10Value: 0,
                selectedAccessGroup11Value: 0,
                selectedAccessGroup12Value: 0,
                selectedAccessGroup13Value: 0,
                selectedAccessGroup14Value: 0,
                selectedAccessGroup15Value: 0,
                selectedAccessGroup16Value: 0
            }));
        } else {
            console.log("-----");
            //this.props.resetNewBadgeAddedInDropDown();    
            this.setState(state => ({
                ...state,
                selectedIndexOfBadge: index,
                selectedCredentialNo: value,
                selectedFacilityNo: state.badgeList[index].facility,
                badgeNo: state.badgeList[index].badge,
                facilityNo: state.badgeList[index].facility
            }), () => {
                console.log('SelecyedCredentialNo:', this.state.selectedCredentialNo, value, this.props.isBadgeLinked)
                this.props.writeLog('OperatorID: ' + this.props.operatorId + "PersonnelEditView -->  BadgeFacilityDropDownSelectionChanged--> Selected BadgeNo: " + this.state.selectedCredentialNo);
                });
       
        }

        

    }

    isTheSelectedBadgeNewlyAdded(selectedBadge) {
        //const index = this.props.badgeListPersonnelIdWise.findIndex(
        //    x => `${x.badge}/${x.facility}` === selectedBadge
        //);

        const index = this.props.badgeListPersonnelIdWise.findIndex(
            x => `${x.editedBadgeNo}/${x.editedFacilityNo}` === selectedBadge
        );

        if (index === null) return true;
        return false;
    }

    handleFirstNameChanged(event) {
        this.setState({
            ...this.state,
            currentPersonnel: {
                ...this.state.currentPersonnel,
                firstName: event.target.value
            },
            dataChanged: true
        });
    }

    handleLastNameChanged(event) {
        this.setState({
            ...this.state,
            currentPersonnel: {
                ...this.state.currentPersonnel,
                lastName: event.target.value
            },
            dataChanged: true
        });
    }

    showBadgeList(badgeList) {
        if (badgeList) {
            //const badgeNoList = badgeList.map(badge => (
            //    <option
            //        style={styles.option}
            //        value={`${badge.badge}/${badge.facility}`}
            //    >
            //        {badge.badge} / {badge.facility}
            //    </option>
            console.log("[badgeList]", badgeList);
            const badgeNoList = badgeList.map(badge => (
                <option
                    style={styles.option}
                    value={`${badge.badge}/${badge.facility}`}
                >
                    {badge.editedBadgeNo} / {badge.editedFacilityNo}
                </option>

            ));

            return (
                <select
                    name="BadgeList"
                    ref={this.refBadgeList}
                    className="select-css"
                    onChange={this.handleCredentialFacilitySelectionChanged}
                    value={this.state.selectedCredentialNo}
                >
                    {badgeNoList}
                </select>
            );
        }
    }

    showAccessGroupsList1(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;

        if (typeof this.props.searchTextAG1 !== 'undefined' && this.props.searchTextAG1 !== null && this.props.searchTextAG1.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG1));

        }

        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>
                {accessGroup.name}
            </option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG1}
                className="select-css"
                onChange={this.handleAccessGroup1SelectionChanged}

            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }


        return (
            <select
                ref={this.refAG1}
                value={this.state.selectedAccessGroup1Value}
                className="select-css"
                onChange={this.handleAccessGroup1SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList2(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;

        if (typeof this.props.searchTextAG2 !== 'undefined' && this.props.searchTextAG2 !== null && this.props.searchTextAG2.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG2));

        }

        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>
                {accessGroup.name}
            </option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG2}
                value={this.state.selectedAccessGroup2Value}
                className="select-css"
                onChange={this.handleAccessGroup2SelectionChanged}
            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }


        return (
            <select
                ref={this.refAG2}
                value={this.state.selectedAccessGroup2Value}
                className="select-css"
                onChange={this.handleAccessGroup2SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList3(accessGroupList) {

        let filteredAccessGrpList = accessGroupList;

        if (typeof this.props.searchTextAG3 !== 'undefined' && this.props.searchTextAG3 !== null && this.props.searchTextAG3.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG3));

        }

        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>
                {accessGroup.name}
            </option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG3}
                value={this.state.selectedAccessGroup3Value}
                className="select-css"
                onChange={this.handleAccessGroup3SelectionChanged}
            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }



        return (
            <select
                ref={this.refAG3}
                value={this.state.selectedAccessGroup3Value}
                className="select-css"
                onChange={this.handleAccessGroup3SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList4(accessGroupList) {

        let filteredAccessGrpList = accessGroupList;

        if (typeof this.props.searchTextAG4 !== 'undefined' && this.props.searchTextAG4 !== null && this.props.searchTextAG4.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG4));

        }

        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>
                {accessGroup.name}
            </option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG4}
                value={this.state.selectedAccessGroup4Value}
                className="select-css"
                onChange={this.handleAccessGroup4SelectionChanged}
            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }

        return (
            <select
                ref={this.refAG4}
                value={this.state.selectedAccessGroup4Value}
                className="select-css"
                onChange={this.handleAccessGroup4SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }

    showAccessGroupsList5(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG5 !== 'undefined' && this.props.searchTextAG5 !== null && this.props.searchTextAG5.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG5));
        }
        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>
                {accessGroup.name}
            </option>
        ));
        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG5}
                value={this.state.selectedAccessGroup5Value}
                className="select-css"
                onChange={this.handleAccessGroup5SelectionChanged}
            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }

        return (
            <select
                ref={this.refAG5}
                value={this.state.selectedAccessGroup5Value}
                className="select-css"
                onChange={this.handleAccessGroup5SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList6(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG6 !== 'undefined' && this.props.searchTextAG6 !== null && this.props.searchTextAG6.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG6));
        }

        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>
                {accessGroup.name}
            </option>
        ));
        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG6}
                value={this.state.selectedAccessGroup6Value}
                className="select-css"
                onChange={this.handleAccessGroup6SelectionChanged}
            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }
        return (
            <select
                ref={this.refAG6}
                value={this.state.selectedAccessGroup6Value}
                className="select-css"
                onChange={this.handleAccessGroup6SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList7(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG7 !== 'undefined' && this.props.searchTextAG7 !== null && this.props.searchTextAG7.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG7));
        }
        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>
                {accessGroup.name}
            </option>
        ));

        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG7}
                value={this.state.selectedAccessGroup7Value}
                className="select-css"
                onChange={this.handleAccessGroup7SelectionChanged}
            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }
        return (
            <select
                ref={this.refAG7}
                value={this.state.selectedAccessGroup7Value}
                className="select-css"
                onChange={this.handleAccessGroup7SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList8(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG8 !== 'undefined' && this.props.searchTextAG8 !== null && this.props.searchTextAG8.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG8));
        }

        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>
                {accessGroup.name}
            </option>
        ));
        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG8}
                value={this.state.selectedAccessGroup8Value}
                className="select-css"
                onChange={this.handleAccessGroup8SelectionChanged}
            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }
        return (
            <select
                ref={this.refAG8}
                value={this.state.selectedAccessGroup8Value}
                className="select-css"
                onChange={this.handleAccessGroup8SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList9(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG9 !== 'undefined' && this.props.searchTextAG9 !== null && this.props.searchTextAG9.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG9));
        }
        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>
                {accessGroup.name}
            </option>
        ));
        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG9}
                value={this.state.selectedAccessGroup9Value}
                className="select-css"
                onChange={this.handleAccessGroup9SelectionChanged}
            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }
        return (
            <select
                ref={this.refAG9}
                value={this.state.selectedAccessGroup9Value}
                className="select-css"
                onChange={this.handleAccessGroup9SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList10(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG10 !== 'undefined' && this.props.searchTextAG10 !== null && this.props.searchTextAG10.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG10));
        }

        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>
                {accessGroup.name}
            </option>
        ));

          if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG10}
                value={this.state.selectedAccessGroup10Value}
                className="select-css"
                onChange={this.handleAccessGroup10SelectionChanged}
            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }

        return (
            <select
                ref={this.refAG10}
                value={this.state.selectedAccessGroup10Value}
                className="select-css"
                onChange={this.handleAccessGroup10SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList11(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG11 !== 'undefined' && this.props.searchTextAG11 !== null && this.props.searchTextAG11.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG11));
        }
        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>
                {accessGroup.name}
            </option>
        ));
        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG11}
                value={this.state.selectedAccessGroup11Value}
                className="select-css"
                onChange={this.handleAccessGroup11SelectionChanged}
            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }

        return (
            <select
                ref={this.refAG11}
                value={this.state.selectedAccessGroup11Value}
                className="select-css"
                onChange={this.handleAccessGroup11SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList12(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG12 !== 'undefined' && this.props.searchTextAG12 !== null && this.props.searchTextAG12.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG12));
        }
        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>
                {accessGroup.name}
            </option>
        ));
        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG12}
                value={this.state.selectedAccessGroup12Value}
                className="select-css"
                onChange={this.handleAccessGroup12SelectionChanged}
            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }
        return (
            <select
                ref={this.refAG12}
                value={this.state.selectedAccessGroup12Value}
                className="select-css"
                onChange={this.handleAccessGroup12SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList13(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG13 !== 'undefined' && this.props.searchTextAG13 !== null && this.props.searchTextAG13.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG13));
        }
        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>
                {accessGroup.name}
            </option>
        ));
        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG13}
                value={this.state.selectedAccessGroup13Value}
                className="select-css"
                onChange={this.handleAccessGroup13SelectionChanged}
            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }
        return (
            <select
                ref={this.refAG13}
                value={this.state.selectedAccessGroup13Value}
                className="select-css"
                onChange={this.handleAccessGroup13SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList14(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG14 !== 'undefined' && this.props.searchTextAG14 !== null && this.props.searchTextAG14.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG14));
        }

        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>
                {accessGroup.name}
            </option>
        ));
        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG14}
                value={this.state.selectedAccessGroup14Value}
                className="select-css"
                onChange={this.handleAccessGroup14SelectionChanged}
            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }
        return (
            <select
                ref={this.refAG14}
                value={this.state.selectedAccessGroup14Value}
                className="select-css"
                onChange={this.handleAccessGroup14SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList15(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG15 !== 'undefined' && this.props.searchTextAG15 !== null && this.props.searchTextAG15.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG15));
        }

        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>
                {accessGroup.name}
            </option>
        ));
        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG15}
                value={this.state.selectedAccessGroup15Value}
                className="select-css"
                onChange={this.handleAccessGroup15SelectionChanged}
            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }
        return (
            <select
                ref={this.refAG15}
                value={this.state.selectedAccessGroup15Value}
                className="select-css"
                onChange={this.handleAccessGroup15SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }
    showAccessGroupsList16(accessGroupList) {
        let filteredAccessGrpList = accessGroupList;
        if (typeof this.props.searchTextAG16 !== 'undefined' && this.props.searchTextAG16 !== null && this.props.searchTextAG16.length !== 0) {
            filteredAccessGrpList = accessGroupList.filter(accGrp => accGrp.name.includes(this.props.searchTextAG16));
        }

        const accessGroups = filteredAccessGrpList.map(accessGroup => (
            <option style={styles.option} value={accessGroup.value}>
                {accessGroup.name}
            </option>
        ));
        if (accessGroups.length === 1) {

            return (<select
                ref={this.refAG16}
                value={this.state.selectedAccessGroup16Value}
                className="select-css"
                onChange={this.handleAccessGroup16SelectionChanged}
            >
                <option disabled > </option>
                {accessGroups}
            </select>);
        }
        return (
            <select
                ref={this.refAG16}
                value={this.state.selectedAccessGroup16Value}
                className="select-css"
                onChange={this.handleAccessGroup16SelectionChanged}
            >
                {accessGroups}
            </select>
        );
    }

    handleAccessGroup1SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value, 10)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  1 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup1Value: selectedAccessGroup.value,
                dataChanged: true
            });
        }
    }
    handleAccessGroup2SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value, 10)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  2 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup2Value: selectedAccessGroup.value,
                dataChanged: true
            });
        }
    }

    handleAccessGroup3SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value, 10)
        )[0];
        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  3 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup3Value: selectedAccessGroup.value,
                dataChanged: true
            });
        }
    }
    handleAccessGroup4SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value, 10)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  4 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup4Value: selectedAccessGroup.value,
                dataChanged: true
            });
        }
    }
    handleAccessGroup5SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value, 10)
        )[0];
        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  5 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup5Value: selectedAccessGroup.value,
                dataChanged: true
            });
        }
    }
    handleAccessGroup6SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value, 10)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  6 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup6Value: selectedAccessGroup.value,
                dataChanged: true
            });
        }
    }
    handleAccessGroup7SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value, 10)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  7 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup7Value: selectedAccessGroup.value,
                dataChanged: true
            });
        }
    }
    handleAccessGroup8SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value, 10)
        )[0];
        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  8 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup8Value: selectedAccessGroup.value,
                dataChanged: true
            });
        }
    }
    handleAccessGroup9SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value, 10)
        )[0];
        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  9 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup9Value: selectedAccessGroup.value,
                dataChanged: true
            });
        }
    }
    handleAccessGroup10SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value, 10)
        )[0];
        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  10 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup10Value: selectedAccessGroup.value,
                dataChanged: true
            });
        }
    }
    handleAccessGroup11SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value, 10)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  11 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup11Value: selectedAccessGroup.value,
                dataChanged: true
            });
        }
    }
    handleAccessGroup12SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value, 10)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  12 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup12Value: selectedAccessGroup.value,
                dataChanged: true
            });
        }
    }
    handleAccessGroup13SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value, 10)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  13 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup13Value: selectedAccessGroup.value,
                dataChanged: true
            });
        }
    }
    handleAccessGroup14SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value, 10)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  14 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup14Value: selectedAccessGroup.value,
                dataChanged: true
            });
        }
    }
    handleAccessGroup15SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value, 10)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  15 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup15Value: selectedAccessGroup.value,
                dataChanged: true
            });
        }
    }
    handleAccessGroup16SelectionChanged(event) {
        const selectedAccessGroup = this.props.accessGroups.filter(
            accessGroup => accessGroup.value === parseInt(event.target.value, 10)
        )[0];

        if (this.checkIfDuplicateAccessGroupSelected(selectedAccessGroup.value)) {
            alert('Duplicate Access Group!');
        } else {
            console.log(
                'Selected Access group Value  16 :',
                selectedAccessGroup.value
            );
            this.setState({
                ...this.state,
                selectedAccessGroup16Value: selectedAccessGroup.value,
                dataChanged: true
            });
        }
    }

    handleAddButtonClicked() {
        if (this.state.dataChanged === false && this.props.pinViewDataChanged === false) {
            this.setState({
                ...this.state,
                showModal: true
            });
        }
    }

    handleEditButtonClicked() {
     
            this.setState({
                ...this.state,
                showEditModal: true
            });
    }


    handleAddButtonInModalClicked() {
        if (this.state.newAddedBadge.badge &&(
            this.state.newAddedBadge.badge.length === 0 ||
            this.state.newAddedBadge.badge <= 0)
        ) {
            alert('Please enter Badge No.');
            return;
        }

        if (this.state.newAddedBadge.facility && (
            this.state.newAddedBadge.facility.length === 0 ||
            this.state.newAddedBadge.facility < 0 ||
            this.state.newAddedBadge.facility > MAX_FACILITY_CODE)
        ) {
            if (this.state.newAddedBadge.facility.length === 0) {
                alert('Please enter Facility No.');
            } else {
                alert('Facility NO can not be more than ' + MAX_FACILITY_CODE+'.');
            }
            
            return;
        }

        const badgeInfo = { badge: this.state.newAddedBadge.badge, editedBadgeNo: this.state.newAddedBadge.badge, facility: this.state.newAddedBadge.facility, editedFacilityNo: this.state.newAddedBadge.facility };
        this.props.setEditedBadgeInfo(badgeInfo);
        this.props.writeLog('OperatorID: ' + this.props.operatorId + "PersonnelEditView -->  CheckIfAddedBadgeExits() --> BadgeInfo :" + JSON.stringify(badgeInfo));

        this.props.badgeAddedInDropDown();
        this.props.setBadgeLinked(false);
        this.props.checkIfBadgeExists({
            badgeNo: this.state.newAddedBadge.badge,
            facility: this.state.newAddedBadge.facility
        });
    }

    handleEditButtonInModalClicked() {
        console.log("Edited Badge: ", this.props.editedBadge);
        if (
            this.props.editedBadge.editedBadgeNo.length === 0 ||
            this.props.editedBadge.editedBadgeNo <= 0
        ) {
            alert('Please enter Badge No.');
            return;
        }

        if (
            this.props.editedBadge.editedFacilityNo.length === 0 ||
            this.props.editedBadge.editedFacilityNo < 0 ||
            this.props.editedBadge.editedFacilityNo > MAX_FACILITY_CODE
        ) {
            if (this.state.editedBadge.facility.length === 0) {
                alert('Please enter Facility No.');
            } else {
                alert('Facility NO can not be more than ' + MAX_FACILITY_CODE + '.');
            }
            return;
        }

        if (this.props.editedBadge.editedBadgeNo !== this.props.editedBadge.badge || this.props.editedBadge.editedFacilityNo !== this.props.editedBadge.faciltiy) {
            //console.log('Edited Bagde :::::::', `${this.props.editedBadge.editedBadgeNo}/${this.props.editedBadge.editedFacilityNo}`);
            this.props.writeLog('OperatorID: ' + this.props.operatorId + "PersonnelEditView --> Badge Edited--> Edited Badge: " + `${this.props.editedBadge.editedBadgeNo}/${this.props.editedBadge.editedFacilityNo}` );
            const badgeList = [...this.state.badgeList];
            let updatedbadgeList = [...this.state.badgeList];

            for (let i = 0; i < badgeList.length; ++i) {
                console.log("old Badge No:", badgeList[i].badge, this.props.editedBadge.oldBadgeNo , this.props.editedBadge.oldFacilityNo);
                const badge = badgeList[i];
                if (badgeList[i].badge.toString() === this.props.editedBadge.oldBadgeNo.toString()) {
                   
                    const updatedBadge = { ...badge, editedBadgeNo: this.props.editedBadge.editedBadgeNo, editedFacilityNo: this.props.editedBadge.editedFacilityNo };
                    Object.assign(updatedbadgeList[i], updatedBadge);
                    console.log("UpdatedBadgeList:", updatedbadgeList);
                }
            }
            this.setState({ showEditModal: false, dataChanged: true, badgeList: [...updatedbadgeList], selectedCredentialNo: `${this.props.editedBadge.oldBadgeNo}/${this.props.editedBadge.oldFacilityNo}` }, () => { console.log("==", this.state.badgeList, this.state.selectedCredentialNo); });

        }


    }


    handleCredentialNoChanged(event) {
        if (event.target.value.length <= this.props.sysBadgeLen) {
            this.setState({
                ...this.state,
                newAddedBadge: {
                    ...this.state.newAddedBadge,
                    badge: event.target.value,
                    editedBadgeNo: event.target.value,
                    dataChanged: true
                }
            });
        } else {
            alert('Badge Number is too large or invalid.');
            event.target.value = '';
            return;
        }
    }

    handleFacilityNoChanged(event) {
     //   if (parseInt(event.target.value, 10) <= MAX_FACILITY_CODE) {
            this.setState({
                ...this.state,
                newAddedBadge: {
                    ...this.state.newAddedBadge,
                    facility: event.target.value,
                    editedFacilityNo: event.target.value,
                    dataChanged: true
                }
            });
        //} else {
        //    alert('Facility NO can not be more than 10.');
        //    event.target.value = '';
        //    return;
        //}
    }


    handleEditCredentialNoChanged(event) {
        if (event.target.value.length <= this.props.sysBadgeLen) {

            const editedBadge = { oldBadge:this.state.badgeNo, editedBadgeNo: event.target.value };
            console.log("Edited Badge:", editedBadge);

            this.props.editBadgeNo(editedBadge.oldBadge, editedBadge.editedBadgeNo);

        } else {
            alert('Badge Number is too large or invalid.');
            event.target.value = '';
            return;
        }
    }

    handleEditFacilityNoChanged(event) {
      ///  if (parseInt(event.target.value, 10) <= MAX_FACILITY_CODE) {
            this.props.editFacilityNo(this.state.facilityNo, event.target.value);

        //} else {
        //    alert('Facility NO can not be more than 10.');
        //    event.target.value = '';
        //    return;
        //}
    }


    savePersonnel() {

        if (
            this.state.badgeNo === this.state.currentPersonnel.badge &&
            this.state.facilityNo === this.state.currentPersonnel.facility
        ) {

            this.setState(
                { ...this.state, pin: this.props.personnelPIN },
                () => {
                    if (this.state.currentPersonnel.firstName.length === 0) {
                        alert('Please enter First Name.');
                        return;
                    }

                    if (this.state.currentPersonnel.lastName.length === 0) {
                        alert('Please enter Last Name.');
                        return;
                    }

                    if (this.state.currentPersonnel.badge.length === 0) {
                        alert('Please enter Badge No.');
                        return;
                    }

                    if (this.state.currentPersonnel.facility.length === 0) {
                        alert('Please enter Facility No.');
                        return;
                    }

                    const updatedPersonnel = {
                        firstName: this.state.currentPersonnel.firstName,
                        lastName: this.state.currentPersonnel.lastName,
                        pin: this.props.personnelPIN,  // nested set State that is why set pin directly
                        badge: this.state.badgeNo,
                        facility: this.state.facilityNo,
                        newBadge: this.props.editedBadge.editedBadgeNo,
                        newFacility: this.props.editedBadge.editedFacilityNo,
                        agNo1: this.state.selectedAccessGroup1Value,
                        agNo2: this.state.selectedAccessGroup2Value,
                        agNo3: this.state.selectedAccessGroup3Value,
                        agNo4: this.state.selectedAccessGroup4Value,
                        agNo5: this.state.selectedAccessGroup5Value,
                        agNo6: this.state.selectedAccessGroup6Value,
                        agNo7: this.state.selectedAccessGroup7Value,
                        agNo8: this.state.selectedAccessGroup8Value,
                        agNo9: this.state.selectedAccessGroup9Value,
                        agNo10: this.state.selectedAccessGroup10Value,
                        agNo11: this.state.selectedAccessGroup11Value,
                        agNo12: this.state.selectedAccessGroup12Value,
                        agNo13: this.state.selectedAccessGroup13Value,
                        agNo14: this.state.selectedAccessGroup14Value,
                        agNo15: this.state.selectedAccessGroup15Value,
                        agNo16: this.state.selectedAccessGroup16Value,
                        enable: this.state.currentPersonnel.enable
                    };
                    //console.log("Updated Personnel:", updatedPersonnel);
                    this.props.writeLog('OperatorID: ' + this.props.operatorId + "PersonnelEditView --> Update Personnel() -->  " + JSON.stringify(updatedPersonnel));

                    this.props.updatePersonnel(updatedPersonnel);
                }
            );
        } else {
            this.setState(
                { ...this.state, pin: this.props.personnelPIN },
                () => {
                    const badgeInfo = {
                        personnelId: this.state.currentPersonnel.id,
                        firstName: this.state.currentPersonnel.firstName,
                        lastName: this.state.currentPersonnel.lastName,
                        pin: this.props.personnelPIN,   // nested set State that is why set pin directly
                        badge: parseInt(this.state.badgeNo, 10),
                        facility: parseInt(this.state.facilityNo, 10),
                        agNo1: this.state.selectedAccessGroup1Value,
                        agNo2: this.state.selectedAccessGroup2Value,
                        agNo3: this.state.selectedAccessGroup3Value,
                        agNo4: this.state.selectedAccessGroup4Value,
                        agNo5: this.state.selectedAccessGroup5Value,
                        agNo6: this.state.selectedAccessGroup6Value,
                        agNo7: this.state.selectedAccessGroup7Value,
                        agNo8: this.state.selectedAccessGroup8Value,
                        agNo9: this.state.selectedAccessGroup9Value,
                        agNo10: this.state.selectedAccessGroup10Value,
                        agNo11: this.state.selectedAccessGroup11Value,
                        agNo12: this.state.selectedAccessGroup12Value,
                        agNo13: this.state.selectedAccessGroup13Value,
                        agNo14: this.state.selectedAccessGroup14Value,
                        agNo15: this.state.selectedAccessGroup15Value,
                        agNo16: this.state.selectedAccessGroup16Value,
                        enable: this.state.currentPersonnel.enable
                    };

                    this.props.writeLog('OperatorID: ' + this.props.operatorId + "PersonnelEditView --> AddBadgeToPersonnel() -->  BadgeInfo: " + JSON.stringify(badgeInfo));
                    this.props.addBadgeToPersonnel(badgeInfo);
                }
            );
        }

    }

    render() {
        if (this.props.personnelUpdated === true) {
            this.props.resetPersonnalUpdatedStatus();
            return (
                <Redirect
                    to={{
                        pathname: '/personnel',
                        state: { currentIndexPosition: this.props.currentIndexPosition }
                    }}
                />
            );
        }
        if (this.props.newBadgeAdded === true) {
            this.props.resetNewBadgeAddedStatus();
            return (
                <Redirect
                    to={{
                        pathname: '/personnel',
                        state: { currentIndexPosition: this.props.currentIndexPosition }
                    }}
                />
            );
        }
        if (this.state.collapseButtonPressed === true) {
            if (this.state.dataChanged === true) {
                alert('Press Save button to save the changes or Cancel to discard.');
                this.setState({ ...this.state, collapseButtonPressed: false });
            } else return <Redirect to="/personnel" />;
        }

        return (
            <div style={styles.container}>
                <div style={styles.expandContainer}>
                    <div
                        onClick={() => {
                            this.setState({ ...this.state, collapseButtonPressed: true });
                        }}
                    >
                        <FontAwesomeIcon icon={faMinus} size="1x" color={Colors.White} />
                    </div>

                    <div style={styles.functionText}>Personnel</div>
                </div>
                <div style={styles.blackLine} />
                <div style={styles.expandableArea}>
                    <div style={styles.buttonContainer}>
                        {  //<div style={{ marginLeft: 20 , marginRight: 20}}>
                           
                        //    <ButtonView label="Discard Changes" instyle={styles.discard}
                        //        onPress={() => {
                        //             Setting initial BadgeInfo
                        //            const badgeInfo = { badge: this.props.currentPersonnel.badge, editedBadgeNo: this.props.currentPersonnel.badge, facility: this.props.currentPersonnel.facility, editedFacilityNo: this.props.currentPersonnel.facility };
                        //            this.props.setEditedBadgeInfo(badgeInfo);
                        //            this.discardChanges();
                                  
                        //        }}
                        //    />
                          
                            //</div>
                        }
                        <ButtonView
                            ref={this.refSavePersonnel}
                            label="Save"
                            instyle={this.props.isBadgeLinked === true && this.state.dataChanged == false ? styles.saveDisabled : styles.save}
                            enabled={this.props.isBadgeLinked !== true || this.state.dataChanged === true || this.props.pinViewDataChanged=== true}
                            onPress={event => {
                                const selectedAG1Value = this.refAG1.current && this.refAG1.current.value ?this.refAG1.current.value:0 ;
                                const selectedAG2Value = this.refAG2.current && this.refAG2.current.value ?this.refAG2.current.value:0;
                                const selectedAG3Value = this.refAG3.current && this.refAG3.current.value ?this.refAG3.current.value:0;
                                const selectedAG4Value = this.refAG4.current && this.refAG4.current.value ?this.refAG4.current.value:0;
                                const selectedAG5Value = this.refAG5.current && this.refAG5.current.value ?this.refAG5.current.value:0;
                                const selectedAG6Value = this.refAG6.current && this.refAG6.current.value ?this.refAG6.current.value:0;
                                const selectedAG7Value = this.refAG7.current && this.refAG7.current.value ?this.refAG7.current.value:0;
                                const selectedAG8Value = this.refAG8.current && this.refAG8.current.value ?this.refAG8.current.value:0;
                                const selectedAG9Value = this.refAG9.current && this.refAG9.current.value ?this.refAG9.current.value:0;
                                const selectedAG10Value = this.refAG10.current && this.refAG10.current.value ?this.refAG10.current.value:0;
                                const selectedAG11Value = this.refAG11.current && this.refAG11.current.value ?this.refAG11.current.value:0;
                                const selectedAG12Value = this.refAG12.current && this.refAG12.current.value ?this.refAG12.current.value:0;
                                const selectedAG13Value = this.refAG13.current && this.refAG13.current.value ?this.refAG13.current.value:0;
                                const selectedAG14Value = this.refAG14.current && this.refAG14.current.value ?this.refAG14.current.value:0;
                                const selectedAG15Value = this.refAG15.current && this.refAG15.current.value ?this.refAG15.current.value:0;
                                const selectedAG16Value = this.refAG16.current && this.refAG16.current.value ?this.refAG16.current.value:0;

                                event.preventDefault();
                                if (this.props.hidePinCode === true && this.props.pinViewDataChanged === true) {
                                    this.props.reEnterPIN();
                                    event.preventDefault();
                                    return;
                                }

                                if (
                                    this.state.badgeNo === this.state.currentPersonnel.badge &&
                                    this.state.facilityNo === this.state.currentPersonnel.facility
                                ) {

                                    this.setState(
                                        {
                                            ...this.state, pin: this.props.personnelPIN, selectedAccessGroup1Value: selectedAG1Value,
                                            selectedAccessGroup2Value: selectedAG2Value,
                                            selectedAccessGroup3Value: selectedAG3Value,
                                            selectedAccessGroup4Value: selectedAG4Value,
                                            selectedAccessGroup5Value: selectedAG5Value,
                                            selectedAccessGroup6Value: selectedAG6Value,
                                            selectedAccessGroup7Value: selectedAG7Value,
                                            selectedAccessGroup8Value: selectedAG8Value,
                                            selectedAccessGroup9Value: selectedAG9Value,
                                            selectedAccessGroup10Value: selectedAG10Value,
                                            selectedAccessGroup11Value: selectedAG11Value,
                                            selectedAccessGroup12Value: selectedAG12Value,
                                            selectedAccessGroup13Value: selectedAG13Value,
                                            selectedAccessGroup14Value: selectedAG14Value,
                                            selectedAccessGroup15Value: selectedAG15Value,
                                            selectedAccessGroup16Value: selectedAG16Value },
                                        () => {
                                            if (this.state.currentPersonnel.firstName.length === 0) {
                                                alert('Please enter First Name.');
                                                return;
                                            }

                                            if (this.state.currentPersonnel.lastName.length === 0) {
                                                alert('Please enter Last Name.');
                                                return;
                                            }

                                            if (this.state.currentPersonnel.badge.length === 0) {
                                                alert('Please enter Badge No.');
                                                return;
                                            }

                                            if (this.state.currentPersonnel.facility.length === 0) {
                                                alert('Please enter Facility No.');
                                                return;
                                            }

                                            const updatedPersonnel = {
                                                firstName: this.state.currentPersonnel.firstName,
                                                lastName: this.state.currentPersonnel.lastName,
                                                pin: this.state.pin,
                                                badge: this.state.badgeNo,
                                                facility: this.state.facilityNo,
                                                newBadge: this.props.editedBadge.editedBadgeNo,
                                                newFacility: this.props.editedBadge.editedFacilityNo,
                                                agNo1: this.state.selectedAccessGroup1Value,
                                                agNo2: this.state.selectedAccessGroup2Value,
                                                agNo3: this.state.selectedAccessGroup3Value,
                                                agNo4: this.state.selectedAccessGroup4Value,
                                                agNo5: this.state.selectedAccessGroup5Value,
                                                agNo6: this.state.selectedAccessGroup6Value,
                                                agNo7: this.state.selectedAccessGroup7Value,
                                                agNo8: this.state.selectedAccessGroup8Value,
                                                agNo9: this.state.selectedAccessGroup9Value,
                                                agNo10: this.state.selectedAccessGroup10Value,
                                                agNo11: this.state.selectedAccessGroup11Value,
                                                agNo12: this.state.selectedAccessGroup12Value,
                                                agNo13: this.state.selectedAccessGroup13Value,
                                                agNo14: this.state.selectedAccessGroup14Value,
                                                agNo15: this.state.selectedAccessGroup15Value,
                                                agNo16: this.state.selectedAccessGroup16Value,
                                                enable: this.state.currentPersonnel.enable
                                            };
                                          //  console.log("Updated Personnel:", updatedPersonnel);
                                            this.props.writeLog('OperatorID: ' + this.props.operatorId + "PersonnelEditView --> UpdatePersonnel() -->  " + JSON.stringify(updatedPersonnel));

                                            this.props.updatePersonnel(updatedPersonnel);
                                        }
                                    );
                                } else {
                                    this.setState(
                                        {
                                            ...this.state, pin: this.props.personnelPIN, selectedAccessGroup1Value: selectedAG1Value,
                                            selectedAccessGroup2Value: selectedAG2Value,
                                            selectedAccessGroup3Value: selectedAG3Value,
                                            selectedAccessGroup4Value: selectedAG4Value,
                                            selectedAccessGroup5Value: selectedAG5Value,
                                            selectedAccessGroup6Value: selectedAG6Value,
                                            selectedAccessGroup7Value: selectedAG7Value,
                                            selectedAccessGroup8Value: selectedAG8Value,
                                            selectedAccessGroup9Value: selectedAG9Value,
                                            selectedAccessGroup10Value: selectedAG10Value,
                                            selectedAccessGroup11Value: selectedAG11Value, 
                                            selectedAccessGroup12Value: selectedAG12Value,
                                            selectedAccessGroup13Value: selectedAG13Value,
                                            selectedAccessGroup14Value: selectedAG14Value,
                                            selectedAccessGroup15Value: selectedAG15Value,
                                            selectedAccessGroup16Value: selectedAG16Value },
                                        () => {
                                            const badgeInfo = {
                                                personnelId: this.state.currentPersonnel.id,
                                                firstName: this.state.currentPersonnel.firstName,
                                                lastName: this.state.currentPersonnel.lastName,
                                                pin: this.state.pin,
                                                badge: parseInt(this.state.badgeNo, 10),
                                                facility: parseInt(this.state.facilityNo, 10),
                                                agNo1: this.state.selectedAccessGroup1Value,
                                                agNo2: this.state.selectedAccessGroup2Value,
                                                agNo3: this.state.selectedAccessGroup3Value,
                                                agNo4: this.state.selectedAccessGroup4Value,
                                                agNo5: this.state.selectedAccessGroup5Value,
                                                agNo6: this.state.selectedAccessGroup6Value,
                                                agNo7: this.state.selectedAccessGroup7Value,
                                                agNo8: this.state.selectedAccessGroup8Value,
                                                agNo9: this.state.selectedAccessGroup9Value,
                                                agNo10: this.state.selectedAccessGroup10Value,
                                                agNo11: this.state.selectedAccessGroup11Value,
                                                agNo12: this.state.selectedAccessGroup12Value,
                                                agNo13: this.state.selectedAccessGroup13Value,
                                                agNo14: this.state.selectedAccessGroup14Value,
                                                agNo15: this.state.selectedAccessGroup15Value,
                                                agNo16: this.state.selectedAccessGroup16Value,
                                                enable: this.state.currentPersonnel.enable
                                            };
                                            this.props.writeLog('OperatorID: ' + this.props.operatorId + "PersonnelEditView --> AddBadgeToPersonnel() --> BadgeInfo : " + JSON.stringify(badgeInfo));
                                            this.props.addBadgeToPersonnel(badgeInfo);
                                        }
                                    );
                                }
                            }}
                        />
                        <div style={{ marginLeft: 20 }}>
                            <Link
                                style={styles.underlineless}
                                to={{
                                    pathname: '/personnel',
                                    state: {
                                        currentIndexPosition: this.props.currentIndexPosition
                                    }
                                }}
                            >
                                <ButtonView label="Cancel" instyle={styles.cancel} />
                            </Link>
                        </div>
                    </div>
                    <div style={styles.nameWithEnabled}>
                        <div style={styles.namePortion}>
                            <div style={styles.nameRow}>
                                <p> First:</p>
                                <input
                                    type="text"
                                    disabled={this.props.isBadgeLinked === true ? true : false}
                                    name="firstName"
                                    value={this.state.currentPersonnel.firstName}
                                    onChange={this.handleFirstNameChanged}
                                    style={styles.firstName}
                                />
                            </div>
                            <div style={styles.nameRow}>
                                <p>Last:</p>
                                <input
                                    type="text"
                                    name="lasttName"
                                    disabled={this.props.isBadgeLinked === true ? true : false}
                                    value={this.state.currentPersonnel.lastName}
                                    onChange={this.handleLastNameChanged}
                                    style={styles.lastName}
                                />
                            </div>
                        </div>
                        <div style={styles.enabledRow}>
                            <p>Enabled</p>
                            <input
                                style={styles.enabled}
                                checked={this.state.currentPersonnel.enable}
                                type="checkbox"
                                disabled= {this.props.isBadgeLinked=== true? true: false}
                                onChange={this.props.isBadgeLinked === true ? {} : () => {

                                    if ((this.props.personnel.actvDate && this.props.personnel.actvDate.length > 0) || (this.props.personnel.exprDate &&  this.props.personnel.exprDate.length > 0)) {
                                        alert('Badge cannot be Enabled or Disabled when Activation or Expiration datetime is used.');
                                        return;
                                    }

                                    this.setState(
                                        {
                                            ...this.state,
                                            dataChanged: true,
                                            currentPersonnel: {
                                                ...this.state.currentPersonnel,
                                                enable: !this.state.currentPersonnel.enable
                                              
                                            }
                                        },
                                        () => {
                                            console.log(
                                                'currentPersonnel>>',
                                                this.state.currentPersonnel
                                            );
                                        }
                                    );
                                }}
                            />
                        </div>
                    </div>

                    <div style={styles.badgeNoListLabel}>
                        <p>Badge/Facilty:</p>

                        { 
                            <div style={{ display: 'flex', flexDirection: 'row' }}>

                                <div ref={this.refEditBadge} style={this.props.isBadgeLinked === true  ? styles.editBadgeDisabled : styles.editBadge} onClick={this.props.isBadgeLinked === true ? {} : this.handleEditButtonClicked}>
                                    Edit Badge
                           </div>

                                <div ref={this.refAddBadge} style={this.props.newBadgeAddedInDropDownList === true || this.state.dataChanged === true || this.props.pinViewDataChanged === true ? styles.addBadgeDisabled : styles.addBadge} onClick={this.handleAddButtonClicked}>
                                    Add Badge +
            </div>
                            </div>
                        }
                    </div>
                    <div style={styles.badgeNoListContainer}>
                        {this.showBadgeList(this.state.badgeList)}
                    </div>

                    {this.state.showModal === true && (
                        <ModalView
                            onClose={this.onCloseModal}
                            dialogStyle={styles.dialogStyle}
                            shouldCloseOnOverlayClick
                        >
                            <div
                                style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                            >
                                <div
                                    style={{
                                        fontSize: 18,
                                        flex: 1,
                                        backgroundColor: Colors.Green,
                                        flexDirection: 'row',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        fontWeight: 'bold',
                                        padding: 5,
                                        color: Colors.White
                                    }}
                                >
                                    Add New Badge
                </div>
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <p style={{ fontSize: 14 }}> Badge No:</p>
                                    <input
                                        type="number"
                                        name="txtCredentialNo"
                                        onChange={this.handleCredentialNoChanged}
                                        style={{ width: 140, height: 20, marginLeft: 5 }}
                                    />
                                </div>
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <p style={{ fontSize: 14 }}>Facility No:</p>
                                    <input
                                        type="number"
                                        name="txtFacilityNo"
                                        onChange={this.handleFacilityNoChanged}
                                        style={{ width: 140, height: 20, marginLeft: 5 }}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <ButtonView
                                        label="Cancel"
                                        onPress={() => {
                                            this.onCloseModal();
                                        }}
                                        instyle={styles.cancel}
                                    />

                                    <ButtonView
                                        label="Add"
                                        onPress={this.handleAddButtonInModalClicked}
                                        instyle={styles.add}
                                    />
                                </div>
                            </div>
                        </ModalView>
                    )}
                    {this.state.showEditModal === true && (
                        <ModalView
                            onClose={this.onCloseModal}
                            dialogStyle={styles.dialogStyle}
                            shouldCloseOnOverlayClick
                        >
                            <div
                                style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                            >
                                <div
                                    style={{
                                        fontSize: 18,
                                        flex: 1,
                                        backgroundColor: Colors.Green,
                                        flexDirection: 'row',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        fontWeight: 'bold',
                                        padding: 5,
                                        color: Colors.White
                                    }}
                                >
                                    Edit Badge
                </div>
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <p style={{ fontSize: 14 }}> Badge No:</p>
                                    <input
                                        type="number"
                                        name="txtEditCredentialNo"
                                        onChange={this.handleEditCredentialNoChanged}
                                        value={this.props.editedBadge.editedBadgeNo} 
                                        style={{ width: 140, height: 20, marginLeft: 5 }}
                                    />
                                </div>
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <p style={{ fontSize: 14 }}>Facility No:</p>
                                    <input
                                        type="number"
                                        name="txtEditFacilityNo"
                                        onChange={this.handleEditFacilityNoChanged}
                                        value={this.props.editedBadge.editedFacilityNo}
                                        style={{ width: 140, height: 20, marginLeft: 5 }}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <ButtonView
                                        label="Cancel"
                                        onPress={() => {
                                            this.onCloseEditModal();
                                        }}
                                        instyle={styles.cancel}
                                    />

                                    <ButtonView
                                        label="Done"
                                        onPress={this.handleEditButtonInModalClicked}
                                        instyle={styles.edit}
                                    />
                                </div>
                            </div>
                        </ModalView>
                    )}
                    {this.state.badgeNo === this.state.currentPersonnel.badge &&
                        this.state.facilityNo === this.state.currentPersonnel.facility &&
                        this.state.currentPersonnel.pin != null && typeof this.state.currentPersonnel.pin !== 'undefined' && this.props.keypadOption === 0 && (
                            <div style={this.props.isBadgeLinked === true ? styles.pinviewDisabled : styles.pinview}>
                                <p>PIN:</p>

                            <input
                                disabled={true}
                                type={this.props.hidePinCode=== true ? "password":"number"}
                                ref={this.txtPINCode}
                                min="0"
                                max="999999999"
                                name="txtPINCode"
                                defaultValue={this.state.currentPersonnel.pin}
                                style={{
                                    paddingLeft: 2,
                                    width: 140,
                                    height: 20,
                                    fontWeight: 'bold',
                                    marginLeft: 5,
                                    backgroundColor: Colors.VeryPaleCyan
                                }}
                            />
                            </div>
                        )}

                    {this.state.badgeNo === this.state.currentPersonnel.badge &&
                        this.state.facilityNo === this.state.currentPersonnel.facility &&
                        this.state.currentPersonnel.pin != null && typeof this.state.currentPersonnel.pin !== 'undefined' && this.props.keypadOption=== 1 && (
                            <div style={this.props.isBadgeLinked === true ? styles.pinviewDisabled : styles.pinview}>
                                <p>PIN:</p>

                            <input

                                type={this.props.hidePinCode === true ? "password" : "number"}
                                ref={this.txtPINCode}
                                min="0"
                                max="999999999"
                                name="txtPINCode"
                                inputMode="numeric"
                                defaultValue={this.state.currentPersonnel.pin}
                                style={{
                                    paddingLeft: 2,
                                    width: 70,
                                    height: 20,
                                    fontWeight: 'bold',
                                    marginLeft: 5,
                                    backgroundColor: Colors.VeryPaleCyan
                                }}

                                onKeyPress={(e) => {

                                    if (e.charCode >= 48 && e.charCode <= 57) {
                                        const pinInputted = e.target.value + String.fromCharCode(e.charCode);
                                        if (pinInputted >= 0 && pinInputted <= 9999) {
                                            this.props.changePersonnelPIN(pinInputted);
                                            this.props.personnelPINViewDataChanged();
                                        } else {
                                            e.preventDefault();
                                        }
                                    }
                                    else {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }


                                }}

                                onKeyDown={(e) => {  // for backspace handling 
                                    this.props.personnelPINViewDataChanged();
                                }}


                                />

                            </div>
                        )}

                    {this.state.badgeNo === this.state.currentPersonnel.badge &&
                        this.state.facilityNo === this.state.currentPersonnel.facility &&
                        this.state.currentPersonnel.pin != null && typeof this.state.currentPersonnel.pin !== 'undefined' && this.props.keypadOption === 2 && (
                            <div style={this.props.isBadgeLinked === true ? styles.pinviewDisabled : styles.pinview}>
                                <p>PIN:</p>

                            <input
                                type={this.props.hidePinCode === true ? "password" : "number"}
                                ref={this.txtPINCode}
                                min="0"
                                max="999999999"
                                name="txtPINCode"
                                inputmode="numeric"
                                defaultValue={this.state.currentPersonnel.pin}
                                style={{
                                    paddingLeft: 2,
                                    width: 140,
                                    height: 20,
                                    fontWeight: 'bold',
                                    marginLeft: 5,
                                    backgroundColor: Colors.VeryPaleCyan
                                }}

                                onKeyPress={(e) => {

                                    if (e.charCode >= 48 && e.charCode <= 57) {
                                        const pinInputted = e.target.value + String.fromCharCode(e.charCode);
                                        if (pinInputted >= 0 && pinInputted <= 999999999) {
                                            this.props.changePersonnelPIN(pinInputted);
                                            this.props.personnelPINViewDataChanged();
                                        } else {
                                            e.preventDefault();
                                        }
                                    }
                                    else {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }



                                }}

                                onKeyDown={(e) => {  // for backspace handling 
                                    this.props.personnelPINViewDataChanged();
                                }}
                            />
                            </div>
                        )}
                    {(this.state.badgeNo !== this.state.currentPersonnel.badge ||
                        this.state.facilityNo !== this.state.currentPersonnel.facility) && this.props.keypadOption === 0 && (
                            <div style={this.props.isBadgeLinked === true ? styles.pinviewDisabled : styles.pinview}>
                                <p>PIN :</p>

                            <input
                                disabled={true}
                                type={this.props.hidePinCode === true ? "password" : "number"}
                                ref={this.txtPINCode}
                                min="0"
                                max="999999999"
                                name="txtPINCode"
                                inputmode="numeric"
                                defaultValue={this.state.currentPersonnel.pin}
                                style={{
                                    paddingLeft: 2,
                                    width: 140,
                                    height: 20,
                                    fontWeight: 'bold',
                                    marginLeft: 5,
                                    backgroundColor: Colors.VeryPaleCyan
                                }}
                               
                            />
                            </div>
                        )}

                    {(this.state.badgeNo !== this.state.currentPersonnel.badge ||
                        this.state.facilityNo !== this.state.currentPersonnel.facility) && this.props.keypadOption===1 && (
                        <div style={this.props.isBadgeLinked === true ? styles.pinviewDisabled : styles.pinview}>
                                <p>PIN :</p>

                            <input
                                type={this.props.hidePinCode === true ? "password" : "number"}
                                ref={this.txtPINCode}
                                min="0"
                                max="999999999"
                                name="txtPINCode"
                                inputmode="numeric"
                                defaultValue={this.state.currentPersonnel.pin}
                                style={{
                                    paddingLeft: 2,
                                    width: 140,
                                    height: 20,
                                    fontWeight: 'bold',
                                    marginLeft: 5,
                                    backgroundColor: Colors.VeryPaleCyan
                                }}
                                onKeyPress={(e) => {

                                    if (e.charCode >= 48 && e.charCode <= 57) {
                                        const pinInputted = e.target.value + String.fromCharCode(e.charCode);
                                        if (pinInputted >= 0 && pinInputted <= 9999) {
                                            this.props.changePersonnelPIN(pinInputted);
                                            this.props.personnelPINViewDataChanged();
                                        } else {
                                            e.preventDefault();
                                        }
                                    }
                                    else {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }


                                }}

                                onKeyDown={(e) => {  // for backspace handling 
                                    this.props.personnelPINViewDataChanged();
                                }}

                            />
                            </div>
                        )}

                    {(this.state.badgeNo !== this.state.currentPersonnel.badge ||
                        this.state.facilityNo !== this.state.currentPersonnel.facility) && this.props.keypadOption === 2&& (
                            <div style={this.props.isBadgeLinked === true ? styles.pinviewDisabled : styles.pinview}>
                                <p>PIN :</p>

                            <input
                                type={this.props.hidePinCode === true ? "password" : "number"}
                                ref={this.txtPINCode}
                                min="0"
                                max="999999999"
                                name="txtPINCode"
                                defaultValue={this.state.currentPersonnel.pin}
                                inputmode="numeric"
                                style={{
                                    paddingLeft: 2,
                                    width: 140,
                                    height: 20,
                                    fontWeight: 'bold',
                                    marginLeft: 5,
                                    backgroundColor: Colors.VeryPaleCyan
                                }}
                                onKeyPress={(e) => {

                                    if (e.charCode >= 48 && e.charCode <= 57) {
                                         const pinInputted = e.target.value + String.fromCharCode(e.charCode);
                                         if (pinInputted >= 0 && pinInputted <= 999999999) {
                                            this.props.changePersonnelPIN(pinInputted);
                                            this.props.personnelPINViewDataChanged();
                                        } else {
                                            e.preventDefault();
                                        }
                                    }
                                    else {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }

                                    

                                }}

                                onKeyDown={(e) => {  // for backspace handling 
                                    this.props.personnelPINViewDataChanged();
                                }}
                            />
                            </div>
                        )}

                    <div style={ styles.accessGroupListContainer} >
                        <div style={ this.props.isBadgeLinked === true ? styles.accessGroupListContainerDisabled : styles.accessGroupContainer}>
                            {this.props.noOfAccessGroupsPerBadge >= 1 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 1
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG1(e.target.value);

                                            }}
                                        ></input>

                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList1(this.props.accessGroups)}
                                    </div>{' '}
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 2 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 2
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG2(e.target.value);

                                            }}
                                        ></input>     
                                     </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList2(this.props.accessGroups)}
                                    </div>{' '}
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 3 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 3
                                          <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG3(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList3(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 4 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 4
                                        <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG4(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList4(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 5 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 5
                                          <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG5(e.target.value);

                                            }}
                                        ></input>

                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList5(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 6 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 6
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG6(e.target.value);

                                            }}
                                        ></input>

                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList6(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 7 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 7
                                        <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG7(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList7(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 8 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 8
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG8(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList8(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 9 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 9
                                        <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG9(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList9(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 10 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 10
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG10(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList10(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 11 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 11
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG11(e.target.value);

                                            }}
                                        ></input>

                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList11(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 12 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 12
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG12(e.target.value);

                                            }}
                                        ></input>
                                    </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList12(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 13 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 13
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG13(e.target.value);

                                            }}
                                        ></input>

                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList13(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge >= 14 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 14
                                          <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG14(e.target.value);

                                            }}
                                        ></input>

                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList14(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}
                            {this.props.noOfAccessGroupsPerBadge >= 15 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 15
                                         <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG15(e.target.value);

                                            }}
                                        ></input>

                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList15(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}

                            {this.props.noOfAccessGroupsPerBadge === 16 && (
                                <div>
                                    <div style={styles.label}>Access Groups: 16
                                            <input style={styles.searchAG} type="text" placeholder={Messages.MSG_HINT_SEARCH_AG}
                                            onChange={(e) => {
                                                this.props.changeSearchTextAG16(e.target.value);

                                            }}
                                        ></input>
                                        </div>
                                    <div style={styles.accessGroupsContainer}>
                                        {this.showAccessGroupsList16(this.props.accessGroups)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {this.props.reEnterPINCode === true && (
                    <ModalView
                        onClose={{}}
                        dialogStyle={styles.dialogStyle}
                        
                    >
                        <div
                            style={{ flex: 1, display: 'flex', flexDirection: 'column', background: Colors.White, width: 'auto', height: 'auto', padding: 30 }}
                        >
                            <div
                                style={{
                                    fontSize: 18,
                                    flex: 1,
                                    backgroundColor: Colors.Green,
                                    flexDirection: 'row',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    fontWeight: 'bold',
                                    padding: 5,
                                    color: Colors.White
                                }}
                            >
                              Confirm PIN
                </div>

                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <p style={{ fontSize: 14 }}>Re-Enter PIN :</p>
                                <input
                                    type="number"
                                    ref={this.txtConfirmPINCode}
                                    min="0"
                                    max="999999999"
                                    name="txtConfirmPINCode"
                                    inputmode="numeric"
                                    style={{
                                        paddingLeft: 2,
                                        width: 140,
                                        height: 20,
                                        fontWeight: 'bold',
                                        marginLeft: 5,
                                        backgroundColor: Colors.VeryPaleCyan
                                    }}

                                    onChange={(e) => {
                                        if (this.txtConfirmPINCode.current.value.length <= this.props.personnelPIN.length) {
                                            if (this.txtConfirmPINCode.current.value === this.props.personnelPIN) {
                                                this.props.resetReEnterPINCode();
                                                this.savePersonnel();

                                            }
                                        } else {
                                            alert('PIN Code was not confirmed. Please retry.');
                                            this.txtConfirmPINCode.current.value = "";
                                            
                                        }
                                    }}

                                    onKeyDown={(e) => {
                                            if ((e.charCode >= 48 && e.charCode <= 57) || (e.keyCode >= 48 && e.keyCode <= 57)|| (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 9) {
                                            } else {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }

                                    }}

                                    //onKeyPress={(e) => {

                                    //    if (e.charCode >= 48 && e.charCode <= 57) {
                                    //        console.log("Confirm PIN:", this.txtConfirmPINCode.current.value, this.props.personnelPIN, this.state.pin);
                                    //        if (this.txtConfirmPINCode.current.value.length <= this.props.personnelPIN.length) {
                                    //            if (this.txtConfirmPINCode.current.value === this.props.personnelPIN) {
                                    //                this.props.resetReEnterPINCode();
                                    //                this.savePersonnel();

                                    //            }
                                    //        } else {
                                    //            alert('PIN Code was not confirmed. Please retry.');
                                    //            this.txtConfirmPINCode.current.value = "";

                                    //        }
                                    //    }
                                    //    else {
                                    //        e.preventDefault();
                                    //        e.stopPropagation();
                                    //    }


                                    //}}

                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <ButtonView
                                    label="Cancel"
                                    onPress={() => {
                                        //this.props.changePersonnelPIN(this.props.personnelPIN);
                                        this.props.resetReEnterPINCode();

                                    }}
                                    instyle={styles.cancel}
                                />
                            </div>
                          
                        </div>
                    </ModalView>
                )}
                
                {this.props.loading === true && (
                    <div>
                        <ModalProgressView
                            Message="Please wait..."
                            onCloseModal={() => {
                                this.props.closeLoaderModal();
                            }}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const styles = {
    dialogStyle: {
        backgroundColor: Colors.VeryPaleBlue,
        padding: 15,
        width: 350,
        height: 'auto'
    },
    add: {
        margin: 2,
        width: 80,
        height: 32,
        fontSize: 15,
        borderRadius: 10,
        color: Colors.White,
        textAlign: 'center',
        backgroundColor: Colors.MostPureBlue,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer'
    },
    edit: {
        margin: 2,
        width: 80,
        height: 32,
        fontSize: 15,
        borderRadius: 10,
        color: Colors.White,
        textAlign: 'center',
        backgroundColor: Colors.MostPureBlue,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer'
    },
    save: {
        margin: 2,
        width: 80,
        height: 32,
        fontSize: 15,
        color: Colors.White,
        textAlign: 'center',
        backgroundColor: Colors.MostPureBlue,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    saveDisabled: {
        margin: 2,
        width: 80,
        height: 32,
        fontSize: 15,
        color: Colors.White,
        textAlign: 'center',
        backgroundColor: Colors.LightGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        pointerEvents: 'none',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    cancel: {
        margin: 2,
        width: 80,
        height: 32,
        color: Colors.Black,
        fontSize: 15,
        textAlign: 'center',
        backgroundColor: Colors.LightGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    }, discard: {
        margin: 2,
        width: 130,
        height: 32,
        color: Colors.White,
        fontSize: 14,
        textAlign: 'center',
        backgroundColor: Colors.SlateGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 8,
        borderColor: Colors.Black,
        display: 'inline-block',
        textDecoration: 'none',
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },
    label: {
        fontWeight: 800,
        fontSize: 12,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 3
        
    },
    underlineless: {
        textDecoration: 'none'
    },
    select: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'black',
        borderRadius: 0,
        background: 'none',
        padding: 5,
        display: 'flex',
        flex: 1
    },
    firstName: {
        backgroundColor: Colors.VeryPaleCyan,
        flex: 1,
        height: 30,
        width: 70,
        display: 'flex',
        paddingLeft: 8,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray,
        borderRadius: 6
    },
    lastName: {
        backgroundColor: Colors.VeryPaleCyan,
        flex: 1,
        width: 70,
        height: 30,
        paddingLeft: 8,
        marginRight: 10,
        display: 'flex',
        marginLeft: 10,
        marginBottom: 2,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.LightGray,
        borderRadius: 6
    },
    option: {
        flex: 1,
        display: 'flex'
    },
    checkbox: {
        backgroundColor: Colors.Black
    },
    addBadge: {
        backgroundColor: Colors.MostPureBlue,
        fontWeight: 'bold',
        fontSize: 12,
        width: 90,
        height: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: Colors.White,
        textDecoration: 'none',
        textAlign: 'center',
        padding: 3,
        cursor: 'pointer',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 5,
        borderColor: Colors.Black,
        marginBottom: 6,
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },

    editBadge: {
        backgroundColor: Colors.Orange,
        fontWeight: 'bold',
        width: 88,
        fontSize: 12,
        height: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: Colors.White,
        textDecoration: 'none',
        textAlign: 'center',
        padding: 3,
        cursor: 'pointer',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 5,
        borderColor: Colors.Black,
        marginBottom: 6,
        marginRight: 6,
        boxShadow: '0.5px 0.5px 1px #00000000',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },

    editBadgeDisabled: {
        backgroundColor: Colors.LightGray,
        fontWeight: 'bold',
        width: 88,
        height: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: Colors.White,
        textDecoration: 'none',
        textAlign: 'center',
        padding: 3,
        cursor: 'pointer',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 5,
        borderColor: Colors.Black,
        marginBottom: 6,
        marginRight: 6,
        boxShadow: '0.5px 0.5px 1px #00000000',
        pointerEvents: 'none',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }
    },

    addBadgeDisabled: {
        backgroundColor: Colors.LightGray,
        fontWeight: 'bold',
        width: 92,
        height: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: Colors.White,
        textDecoration: 'none',
        textAlign: 'center',
        padding: 3,
        cursor: 'pointer',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 5,
        borderColor: Colors.Black,
        marginBottom: 6,
        boxShadow: '0.5px 0.5px 1px #00000000',
        pointerEvents: 'none',
        ':active': {
            backgroundColor: Colors.Gray,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black'
        }

    },
    buttonContainer: {
        display: 'flex',
        flex: 1,
        height: 80,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginLeft: 30,
        marginBottom: 10,
        marginTop: 10
    },
    nameWithEnabled: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        marginBottom: 8
    },
    nameRow: {
        display: 'flex',
        height: 45,
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'flex-start'
    },
    enabledRow: {
        display: 'flex',
        flexDirection: 'row',
        height: 40,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    enabled: {
        marginLeft: 10
    },
    namePortion: {
        display: 'flex',
        flex: 1,
        fontWeight: 'bold',
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    badgeNoListLabel: {
        fontSize: 13,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        fontWeight: '700',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    badgeNoListContainer: {
        flex: 1,
        display: 'flex',
        height: 'auto',
        justifyContent: 'center'
    },
    pinview: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        fontWeight: 'bold',
        fontSize: 14 
    },
    pinviewDisabled: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        fontWeight: 'bold',
        fontSize: 14,
        backgroundColor: Colors.LightGray,
        paddingLeft: 15,
        pointerEvents: 'none'
    },
    accessGroupContainer: {
        flex: 1,
        borderColor: Colors.Gray,
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: 5,
        justifyContent: 'space-around',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: Colors.Cream
    },
    partitionGroupsContainer: {
        flex: 1,
        display: 'flex',
        marginBottom: 10
    },
    listContainer: {
        height: 120,
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'black',
        overflowY: 'scroll',
        fontWeight: 'bold',
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        paddingTop: 10
    },
    container: {
        display: 'flex',
        flex: 1,
        height: 'auto',
        justifyContent: 'center',
        marginRight: 20,
        flexDirection: 'column',
        backgroundColor: Colors.White,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10
    },
    expandContainer: {
        flex: 1,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        paddingLeft: 10,
        backgroundColor: Colors.Green,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10
    },

    titleContainer: {
        marginLeft: 30,
        marginRight: 10,
        flex: 1,
        height: 45,
        display: 'flex',
        fontWeight: 'bold',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: Colors.White,
        flexDirection: 'row'
    },
    accessGroupListContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10
      
    },

    accessGroupListContainerDisabled: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10,
        padding: 10,
        backgroundColor: Colors.LightGray,
        pointerEvents: 'none'
    },
    expandableArea: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
        paddingRight: 10
    },

    functionText: {
        color: Colors.White,
        textAlign: 'left',
        marginLeft: 20,
        fontSize: 20,
        fontWeight: 800
    },
    horizontalLine: {
        height: 1,
        width: '100%',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: Colors.Purple
    },
    searchAG: {
        fontSize: 10,
        marginBottom: 2,
        marginTop: 2
    }
};

const mapStateToProps = state => ({
    accessToken: state.token.accessToken,
    badgeListPersonnelIdWise: state.personnel.badgeListPersonnelIdWise,
    personnelPIN: state.pin.personnelPIN,
    personnelUpdated: state.personnel.personnelUpdated,
    accessGroups: state.accessGroups.accessGroups,
    personnel: state.personnel.personnel,
    personnels: state.personnel.personnels,
    noOfAccessGroupsPerBadge: state.settings.systemSettings.aGCountPerBadge,
    sysBadgeLen: state.settings.systemSettings.sysBadgeLen,
    newBadgeAdded: state.personnel.newBadgeAdded,
    badgeExists: state.personnel.badgeExists,
    loading: state.loading.loading,
    badgeInfoUpdatedStatus: state.personnel.badgeInfoUpdatedStatus,
    editedBadge: state.personnel.editedBadge,
    tmpSelectedBadgeOfUser: state.token.tmpSelectedBadgeOfUser,
    isBadgeLinked: state.personnel.isBadgLinked,
    pinViewDataChanged: state.personnel.pinViewDataChanged,
    newBadgeAddedInDropDownList: state.personnel.newBadgeAddedInDropDownList,
    keypadOption: state.settings.systemSettings.keypadOption,
    hidePinCode: state.settings.systemSettings.hidePinCode,
    reEnterPINCode: state.personnel.reEnterPINCode,
    operatorId: state.token.operatorId,
    searchTextAG1: state.accessGroups.searchTextAG1,
    searchTextAG2: state.accessGroups.searchTextAG2,
    searchTextAG3: state.accessGroups.searchTextAG3,
    searchTextAG4: state.accessGroups.searchTextAG4,
    searchTextAG5: state.accessGroups.searchTextAG5,
    searchTextAG6: state.accessGroups.searchTextAG6,
    searchTextAG7: state.accessGroups.searchTextAG7,
    searchTextAG8: state.accessGroups.searchTextAG8,
    searchTextAG9: state.accessGroups.searchTextAG9,
    searchTextAG10: state.accessGroups.searchTextAG10,
    searchTextAG11: state.accessGroups.searchTextAG11,
    searchTextAG12: state.accessGroups.searchTextAG12,
    searchTextAG13: state.accessGroups.searchTextAG13,
    searchTextAG14: state.accessGroups.searchTextAG14,
    searchTextAG15: state.accessGroups.searchTextAG15,
    searchTextAG16: state.accessGroups.searchTextAG16
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setCurrentPageName: actions.setCurrentPageName,
            updatePersonnel: actions.updatePersonnel,
            resetPersonnalUpdatedStatus: actions.resetPersonnalUpdatedStatus,
            fetchAccessGroupList: actions.fetchAccessGroupList,
            fetchPersonnel: actions.fetchPersonnel,
            addBadgeToPersonnel: actions.addBadgeToPersonnel,
            resetNewBadgeAddedStatus: actions.resetNewBadgeAddedStatus,
            checkIfBadgeExists: actions.checkIfBadgeExists,
            closeLoaderModal: actions.closeLoaderModal,
            resetBadgeExistsFlag: actions.resetBadgeExistsFlag,
            resetLoadingFlag: actions.resetLoadingFlag,
            fetchBadgeListByPersonnelId: actions.fetchBadgeListByPersonnelId,
            resetBadgeList: actions.resetBadgeList,
            changePersonnelPIN: actions.changePersonnelPIN,
            resetSinglePersonnel: actions.resetSinglePersonnel,
            updateBadgeInfoRequest: actions.updateBadgeInfoRequest,
            editBadgeNo: actions.editBadgeNo,
            editFacilityNo: actions.editFacilityNo,
            setEditedBadgeInfo: actions.setEditedBadgeInfo,
            setBadgeLinked: actions.setBadgeLinked,
            resetBadgeLinkedStatus: actions.resetBadgeLinkedStatus,
            personnelPINViewDataChanged: actions.personnelPINViewDataChanged,
            resetPersonnelPINViewDataChanged: actions.resetPersonnelPINViewDataChanged,
            badgeAddedInDropDown: actions.badgeAddedInDropDown,
            resetNewBadgeAddedInDropDown: actions.resetNewBadgeAddedInDropDown,
            fetchSystemSettings: actions.fetchSystemSettings,
            reEnterPIN: actions.reEnterPIN,
            resetReEnterPINCode: actions.resetReEnterPINCode,
            writeLog: actions.writeLog,
            changeSearchTextAG1: actions.changeSearchTextAG1,
            changeSearchTextAG2: actions.changeSearchTextAG2,
            changeSearchTextAG3: actions.changeSearchTextAG3,
            changeSearchTextAG4: actions.changeSearchTextAG4,
            changeSearchTextAG5: actions.changeSearchTextAG5,
            changeSearchTextAG6: actions.changeSearchTextAG6,
            changeSearchTextAG7: actions.changeSearchTextAG7,
            changeSearchTextAG8: actions.changeSearchTextAG8,
            changeSearchTextAG9: actions.changeSearchTextAG9,
            changeSearchTextAG10: actions.changeSearchTextAG10,
            changeSearchTextAG11: actions.changeSearchTextAG11,
            changeSearchTextAG12: actions.changeSearchTextAG12,
            changeSearchTextAG13: actions.changeSearchTextAG13,
            changeSearchTextAG14: actions.changeSearchTextAG14,
            changeSearchTextAG15: actions.changeSearchTextAG15,
            changeSearchTextAG16: actions.changeSearchTextAG16,
            resetSearchTextAllAG: actions.resetSearchTextAllAG
           
        },
        dispatch
    );

export default Radium(
    connect(mapStateToProps, mapDispatchToProps)(PersonnelEditView)
);

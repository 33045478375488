import * as ActionTypes from '../actions/types';

const defaultState = {
    login: false,
    allDoors: false,
    doors: false,
    logout: false,
    loading: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.LOGOUT: {
            return {
                ...state,
                logout: true
            };
        }
        case ActionTypes.LOGIN: {
            return {
                ...state,
                login: true
            };
        }
        case ActionTypes.LOGIN_SUCCEEDED: {
            //console.log('LOGIN SUCCEDED triggered');
            return {
                ...state,
                login: false,
                logout: false
            };
        }
        case ActionTypes.LOGIN_FAILED:
            return {
                ...state,
                login: false
            };

        case ActionTypes.SET_CURRENT_OPERATING_DOOR: {
            return {
                ...state,
                doors: true
            };
        }

        case ActionTypes.GET_DOOR_STATUS: {
            return {
                ...state,
                doors: false
            };
        }

        case ActionTypes.FETCH_ALL_DOORS_SUCCEEDED: {
            return { ...state, AllDoors: false };
        }

        case ActionTypes.FETCH_ACCESS_READER_LIST_BY_BADGE_SUCCEEDED: {
            return { ...state, doors: false };
        }

        case ActionTypes.CLOSE_LOADER_MODAL: {
            return { ...state, doors: false, login: false, allDoors: false };
        }

        case ActionTypes.CHECK_BADGE_EXISTS: {
            return { ...state, loading: true };
        }

        case ActionTypes.CHECK_BADGE_EXISTS_SUCCEEDED: {
            return { ...state, loading: false };
        }

        case ActionTypes.CHECK_BADGE_EXISTS_FAILED: {
            return { ...state, loading: false };
        }

        case ActionTypes.RESET_LOADING_FLAG: {
            return { ...state, loading: false };
        }

        case ActionTypes.CANCEL_ACTIVE_SCHEDULE_CHANGES: {
            return { ...state, loading: true };
        }

        case ActionTypes.CANCEL_ACTIVE_SCHEDULE_CHANGES_SUCCEEDED: {
            return { ...state, loading: false };
        }

        case ActionTypes.CANCEL_ACTIVE_SCHEDULE_CHANGES_FAILED: {
            return { ...state, loading: false };
        }

        case ActionTypes.FETCH_ALL_PERSONNELS: {
            return { ...state, loading: true };
        }
        case ActionTypes.FETCH_ALL_PERSONNELS_SUCCEEDED: {
            return { ...state, loading: false };
        }
        case ActionTypes.FETCH_ALL_PERSONNELS_FAILED: {
            return { ...state, loading: false };
        }

        case ActionTypes.FETCH_PERSONNEL: {
            return { ...state, loading: true };
        }

        case ActionTypes.FETCH_PERSONNEL_SUCCEEDED: {
            return { ...state, loading: false };
        }

        case ActionTypes.FETCH_PERSONNEL_FAILED: {
            return { ...state, loading: false };
        }

        case ActionTypes.CREATE_NEW_SCHEDULE_CHANGES: {
            return { ...state, loading: true };
        }

        case ActionTypes.CREATE_NEW_SCHEDULE_CHANGES_SUCCEEDED: {
            return { ...state, loading: false };
        }

        case ActionTypes.CREATE_NEW_SCHEDULE_CHANGES_FAILED: {
            return { ...state, loading: false };
        }

        case ActionTypes.DELETE_SCHEDULE: {
            return { ...state, loading: true };
        }

        case ActionTypes.DELETE_SCHEDULE_SUCCEEDED: {
            return { ...state, loading: false };
        }

        case ActionTypes.DELETE_SCHEDULE_FAILED: {
            return { ...state, loading: false };
        }

        case ActionTypes.FETCH_ACCESS_GROUP_LIST: {
            return { ...state, loading: true };
        }

        case ActionTypes.FETCH_ACCESS_GROUP_LIST_SUCCEEDED: {
            return { ...state, loading: false };
        }

        case ActionTypes.FETCH_ACCESS_GROUP_LIST_FAILED: {
            return { ...state, loading: false };
        }

        case ActionTypes.CREATE_NEW_PERSONNEL: {
            return { ...state, loading: true };
        }

        case ActionTypes.CREATE_NEW_PERSONNEL_SUCCEEDED: {
            return { ...state, loading: false };
        }

        case ActionTypes.CREATE_NEW_PERSONNEL_FAILED: {
            return { ...state, loading: false };
        }

        case ActionTypes.FETCH_DEVICE_LIST_BY_DEVICE_TYPE: {
            return { ...state, loading: true };
        }

        case ActionTypes.FETCH_DEVICE_LIST_BY_DEVICE_TYPE_SUCCEEDED: {
            return { ...state, loading: false };
        }

        case ActionTypes.FETCH_DEVICE_LIST_BY_DEVICE_TYPE_FAILED: {
            return { ...state, loading: false };
        }

        case ActionTypes.FETCH_FIELD_LIST_BY_DEVICE_TYPE: {
            return { ...state, loading: true };
        }

        case ActionTypes.FETCH_FIELD_LIST_BY_DEVICE_TYPE_SUCCEEDED: {
            return { ...state, loading: false };
        }

        case ActionTypes.FETCH_FIELD_LIST_BY_DEVICE_TYPE_FAILED: {
            return { ...state, loading: false };
        }



        default:
            return state;
    }
};

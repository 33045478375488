import * as ActionTypes from '../actions/types';

const defaultState = {
    eventPosted: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.POST_EVENT_SUCCEEDED: {
          return {
              ...state,
          eventPosted: action.payload === 0 || action.payload === '0' || action.payload
         
      };
      }

    default:
      return state;
  }
};

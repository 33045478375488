import React from 'react';
import Radium from 'radium';
import * as Colors from '../../consts/colors';
import '../../style.css';

const ButtonView = props => (
  <button
    disabled={'enabled' in props ? !props.enabled : false}
    type="button"
    style={props.instyle ? props.instyle : styles.container}
        onClick={props.enabled === false ? () => {} : props.onPress}
  >
    {props.label}
  </button>
);

const styles = {
  underlineless: {
    textDecoration: 'none'
  },

  container: {
    margin: 2,
    width: 80,
    height: 32,
    fontSize: 17,
    textAlign: 'center',
    backgroundColor: Colors.Gray,
    border: '2px solid',
    borderColor: Colors.Black,
    cursor: 'pointer'
  },
  label: {
    color: Colors.Black,
    textAlign: 'left',
    marginLeft: 20,
    fontSize: 20,
    fontWeight: 800
  }
};

export default Radium(ButtonView);

import * as ActionTypes from '../actions/types';

const defaultState = {
  configChanged: false,
  pin: '',
  serverIPAddress: '',
  portNo: '',
  user: '',
  password: '',
  viewSelection: [
    {
      serialNo: 1,
      status: true,
      name: 'My Doors',
      itemList: [],
      updated: false
    },
    { serialNo: 2, status: true, name: 'Lockdown', updated: false },
    { serialNo: 3, status: true, name: 'Threat Levels', updated: false },
    { serialNo: 4, status: true, name: 'Status', updated: false },
    { serialNo: 5, status: true, name: 'Control', updated: false },
    { serialNo: 6, status: true, name: 'Personnel', updated: false },
    { serialNo: 7, status: true, name: 'Badge Holders In', updated: false },
    { serialNo: 8, status: true, name: 'Scheduled Changes', updated: false }
  ],
  viewSelectionListSize: 8,
  messageOptionUntilCleared: false,
  msgDisplayTimeInSeconds: 10,
  inListRefreshTimeInSeconds: 10,
  keepMyDoorExpanded: false,
  badgeInfo: {
    badgeNo: 2323,
    facilityCode: 0,
    badgeHolderName: 'Test Personnel'
  },
  badgeInfoChanged: false,
  inListRefreshInSecondsChanged: false,
  displayMessageTimeInSecondsChanged: false,
  untilClearedChanged: false,
  systemSettings: { aGCountPerBadge: 16, keypadOption:1, hidePinCode: false},

  tmpViewSelection: [
    {
      serialNo: 1,
      status: true,
      name: 'My Doors',
      itemList: [],
      updated: false
    },
    { serialNo: 2, status: true, name: 'Lockdown', updated: false },
    { serialNo: 3, status: true, name: 'Threat Levels', updated: false },
    { serialNo: 4, status: true, name: 'Status', updated: false },
    { serialNo: 5, status: true, name: 'Control', updated: false },
    { serialNo: 6, status: true, name: 'Personnel', updated: false },
    { serialNo: 7, status: true, name: 'Badge Holders In', updated: false },
    { serialNo: 8, status: true, name: 'Scheduled Changes', updated: false }
  ],
  tmpViewSelectionListSize: 8,
  tmpMessageOptionUntilCleared: false,
  tmpMsgDisplayTimeInSeconds: 10,
  tmpInListRefreshTimeInSeconds: 10,
  tmpKeepMyDoorExpanded: false,
  tmpBadgeInfo: {
    badgeNo: 2323,
    facilityCode: 0,
    badgeHolderName: 'Test Personnel'
  },
  tmpBadgeInfoChanged: false,
  tmpInListRefreshInSecondsChanged: false,
  tmpDisplayMessageTimeInSecondsChanged: false,
  tmpUntilClearedChanged: false,
  sessionTimeOut: 5,
    tmpSessionTimeOut: 5,
   isDebugLogEnabled: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_APP_CONFIG_SETTINGS_FROM_LOCAL_STORAGE: {
      //console.log('Settings Reducer: Load Config from Local Storage->', action.payload);

      return {
        ...action.payload
      };
    }

    case ActionTypes.SAVE_CONFIG_SETTINGS: {
      return {
        ...state,
        messageOptionUntilCleared: state.tmpMessageOptionUntilCleared,
        msgDisplayTimeInSeconds: state.tmpMsgDisplayTimeInSeconds,
        inListRefreshTimeInSeconds: state.tmpInListRefreshTimeInSeconds,
        inListRefreshInSecondsChanged: state.tmpInListRefreshInSecondsChanged,
        keepMyDoorExpanded: state.tmpKeepMyDoorExpanded,
        badgeInfo: state.tmpBadgeInfo,
        badgeInfoChanged: state.tmpBadgeInfoChanged,
        displayMessageTimeInSecondsChanged:
          state.tmpDisplayMessageTimeInSecondsChanged,
        untilClearedChanged: state.tmpUntilClearedChanged,
        viewSelection: [...state.tmpViewSelection],
        sessionTimeOut: state.tmpSessionTimeOut,
        configChanged: false
      };
    }

    case ActionTypes.CANCEL_SAVE_CONFIG_SETTINGS: {
      return {
        ...state,
        tmpViewSelection: [...state.viewSelection],
        tmpViewSelectionListSize: state.viewSelectionListSize,
        tmpMessageOptionUntilCleared: state.messageOptionUntilCleared,
        tmpMsgDisplayTimeInSeconds: state.msgDisplayTimeInSeconds,
        tmpInListRefreshTimeInSeconds: state.inListRefreshTimeInSeconds,
        tmpKeepMyDoorExpanded: state.keepMyDoorExpanded,
        tmpBadgeInfo: { ...state.badgeInfo },
        tmpBadgeInfoChanged: state.badgeInfoChanged,
        tmpInListRefreshInSecondsChanged: state.InListRefreshInSecondsChanged,
        tmpDisplayMessageTimeInSecondsChanged:
          state.displayMessageTimeInSecondsChanged,
        tmpUntilClearedChanged: state.untilClearedChanged,
        tmpSessionTimeOut: state.sessionTimeOut,
        configChanged: false
      };
    }

    // case ActionTypes.FETCH_ALL_DOORS_SUCCEEDED: {
    //   const doors = action.payload;

    //   let itemList =
    //     state.tmpViewSelection[0].itemList.length > 0 ? state.tmpViewSelection[0].itemList : [];

    //   console.log('ItemList-> StartSize', itemList.length);

    //   for (let i = 0; i < doors.length; ++i) {
    //     const item = {
    //       itemNo: `${doors[i].panelNo}-${doors[i].doorNo}`,
    //       itemName: doors[i].doorName,
    //       checked:
    //         state.tmpViewSelection[0].itemList.length > 0 &&
    //         typeof state.tmpViewSelection[0].itemList[i] !== 'undefined'
    //           ? state.tmpViewSelection[0].itemList[i].checked
    //           : false
    //     };

    //     itemList[i] = item;
    //     console.log('ItemList[i]', i, itemList[i]);
    //   }

    //   if (doors && doors.length < itemList.length) {
    //     itemList = itemList.slice(0, doors.length);
    //   }

    //   console.log('Modified itemList', itemList);

    //   const viewSelectionItemOne = {
    //     ...state.tmpViewSelection[0],
    //     itemList
    //   };

    //   state.tmpViewSelection.splice(0, 1, viewSelectionItemOne);

    //   return {
    //     ...state,
    //     tmpViewSelection: [...state.tmpViewSelection]
    //   };
    // }

    case ActionTypes.FETCH_ACCESS_READER_LIST_BY_BADGE_SUCCEEDED: {
      const doors = filteredDoors(action.payload);
      //console.log('Doors::::', doors);

      const itemList =
        state.tmpViewSelection[0].itemList.length > 0
          ? state.tmpViewSelection[0].itemList
          : [];

      const newItemList = [];
      //console.log('Door List:', itemList, doors);

      for (let i = 0; i < doors.length; ++i) {
        const item = {
          itemNo: `${doors[i].panelNo}-${doors[i].doorNo}`,
          itemName: doors[i].doorName,
          checked: findChecked(
            itemList,
            `${doors[i].panelNo}-${doors[i].doorNo}`
          )
          // checked:
          //   state.tmpViewSelection[0].itemList.length > 0 &&
          //   typeof state.tmpViewSelection[0].itemList[i] !== 'undefined'
          //     ? state.tmpViewSelection[0].itemList[i].checked
          //     : false
        };

        //itemList[i] = item;
        newItemList.push(item);
      }

      // console.log('Door new:', newItemList);

      // if (doors && doors.length < itemList.length) {
      //   itemList = itemList.slice(0, doors.length);
      // }

      // const viewSelectionItemOne = {
      //   ...state.tmpViewSelection[0],
      //   itemList
      // };

      const viewSelectionItemOne = {
        ...state.tmpViewSelection[0],
        itemList: newItemList
      };

      state.tmpViewSelection.splice(0, 1, viewSelectionItemOne);

      //console.log('tempViewSelection', state.tmpViewSelection);
      return {
        ...state,
        tmpViewSelection: [...state.tmpViewSelection]
      };
    }

    case ActionTypes.CHANGE_SESSION_TIMEOUT: {
      return {
        ...state,
        tmpSessionTimeOut: action.payload,
        configChanged: true
      };
    }

    case ActionTypes.SET_APP_PIN: {
      return {
        ...state,
        pin: action.payload,
        configChanged: true
      };
    }

    case ActionTypes.SET_SERVER_IP_ADDRESS: {
      return {
        ...state,
        serverIPAddress: action.payload
      };
    }

    case ActionTypes.SET_PORT_NO: {
      return {
        ...state,
        portNo: action.payload
      };
    }

    case ActionTypes.SET_USER: {
      return {
        ...state,
        user: action.payload
      };
    }

    case ActionTypes.SET_PASSWORD: {
      return {
        ...state,
        password: action.payload
      };
    }

    case ActionTypes.TOGGLE_MY_DOORS_MENU: {
      const updatedObj = {
        ...state.tmpViewSelection[0],
        status: action.payload,
        updated: true
      };
      return {
        ...state,
        tmpViewSelection: [
          updatedObj,
          ...state.tmpViewSelection.slice(1, state.tmpViewSelectionListSize)
        ],
        configChanged: true
      };
    }

    case ActionTypes.TOGGLE_LOCKDOWN_MENU: {
      const index = 1;
      const updatedObj = {
        ...state.tmpViewSelection[index],
        status: action.payload,
        updated: true
      };
      return {
        ...state,
        // viewSelection: [
        //   ...state.viewSelection.slice(0, index),
        //   updatedObj,
        //   ...state.viewSelection.slice(index + 1, state.viewSelectionListSize)
        // ],
        tmpViewSelection: [
          ...state.tmpViewSelection.slice(0, index),
          updatedObj,
          ...state.tmpViewSelection.slice(
            index + 1,
            state.viewSelectionListSize
          )
        ],
        configChanged: true
      };
    }

    case ActionTypes.TOGGLE_THREAT_LEVELS_MENU: {
      const index = 2;
      const updatedObj = {
        ...state.tmpViewSelection[index],
        status: action.payload,
        updated: true
      };
      return {
        ...state,
        // viewSelection: [
        //   ...state.viewSelection.slice(0, index),
        //   updatedObj,
        //   ...state.viewSelection.slice(index + 1, state.viewSelectionListSize)
        // ],
        tmpViewSelection: [
          ...state.tmpViewSelection.slice(0, index),
          updatedObj,
          ...state.tmpViewSelection.slice(
            index + 1,
            state.viewSelectionListSize
          )
        ],
        configChanged: true
      };
    }

    case ActionTypes.TOGGLE_STATUS_MENU: {
      const index = 3;
      const updatedObj = {
        ...state.tmpViewSelection[index],
        status: action.payload,
        updated: true
      };
      return {
        ...state,
        // viewSelection: [
        //   ...state.viewSelection.slice(0, index),
        //   updatedObj,
        //   ...state.viewSelection.slice(index + 1, state.viewSelectionListSize)
        // ],
        tmpViewSelection: [
          ...state.tmpViewSelection.slice(0, index),
          updatedObj,
          ...state.tmpViewSelection.slice(
            index + 1,
            state.viewSelectionListSize
          )
        ],
        configChanged: true
      };
    }
    case ActionTypes.TOGGLE_CONTROL_MENU: {
      const index = 4;
      const updatedObj = {
        ...state.tmpViewSelection[index],
        status: action.payload,
        updated: true
      };
      return {
        ...state,
        // viewSelection: [
        //   ...state.viewSelection.slice(0, index),
        //   updatedObj,
        //   ...state.viewSelection.slice(index + 1, state.viewSelectionListSize)
        // ],
        tmpViewSelection: [
          ...state.tmpViewSelection.slice(0, index),
          updatedObj,
          ...state.tmpViewSelection.slice(
            index + 1,
            state.viewSelectionListSize
          )
        ],
        configChanged: true
      };
    }

    case ActionTypes.TOGGLE_PERSONNEL_MENU: {
      const index = 5;
      const updatedObj = {
        ...state.tmpViewSelection[index],
        status: action.payload,
        updated: true
      };
      return {
        ...state,
        // viewSelection: [
        //   ...state.viewSelection.slice(0, index),
        //   updatedObj,
        //   ...state.viewSelection.slice(index + 1, state.viewSelectionListSize)
        // ],
        tmpViewSelection: [
          ...state.tmpViewSelection.slice(0, index),
          updatedObj,
          ...state.tmpViewSelection.slice(
            index + 1,
            state.viewSelectionListSize
          )
        ],
        configChanged: true
      };
    }

    case ActionTypes.TOGGLE_SCHEDULED_CHANGES_MENU: {
      const index = 7;
      const updatedObj = {
        ...state.tmpViewSelection[index],
        status: action.payload,
        updated: true
      };
      return {
        ...state,
        // viewSelection: [
        //   ...state.viewSelection.slice(0, index),
        //   updatedObj,
        //   ...state.viewSelection.slice(index + 1, state.viewSelectionListSize)
        // ],
        tmpViewSelection: [
          ...state.tmpViewSelection.slice(0, index),
          updatedObj,
          ...state.tmpViewSelection.slice(
            index + 1,
            state.viewSelectionListSize
          )
        ],
        configChanged: true
      };
    }

    case ActionTypes.TOGGLE_BADGEHOLDERSIN_MENU: {
      const index = 6;
      const updatedObj = {
        ...state.tmpViewSelection[index],
        status: action.payload,
        updated: true
      };
      return {
        ...state,
        // viewSelection: [
        //   ...state.viewSelection.slice(0, index),
        //   updatedObj,
        //   ...state.viewSelection.slice(index + 1, state.viewSelectionListSize)
        // ],
        tmpViewSelection: [
          ...state.tmpViewSelection.slice(0, index),
          updatedObj,
          ...state.tmpViewSelection.slice(
            index + 1,
            state.viewSelectionListSize
          )
        ],
        configChanged: true
      };
    }

    case ActionTypes.SET_MSG_DISPLAY_TIME: {
      return {
        ...state,
        // msgDisplayTimeInSeconds: action.payload,
        // displayMessageTimeInSecondsChanged: true
        tmpMsgDisplayTimeInSeconds: action.payload,
        tmpDisplayMessageTimeInSecondsChanged: true,
        configChanged: true
      };
    }

    case ActionTypes.SET_MSG_OPTION_UNTIL_CLEARED: {
      return {
        ...state,
        // messageOptionUntilCleared: action.payload,
        // untilClearedChanged: true
        tmpMessageOptionUntilCleared: action.payload,
        tmpUntilClearedChanged: true,
        configChanged: true
      };
    }

    case ActionTypes.SET_INLIST_REFRESH_TIME: {
      return {
        ...state,
        // inListRefreshTimeInSeconds: action.payload,
        // inListRefreshInSecondsChanged: true
        tmpInListRefreshTimeInSeconds: action.payload,
        tmpInListRefreshInSecondsChanged: true,
        configChanged: true
      };
    }

    case ActionTypes.TOGGLE_KEEP_MYDOORS_EXPANDED: {
      return {
        ...state,
        // keepMyDoorExpanded: action.payload
        tmpKeepMyDoorExpanded: action.payload,
        configChanged: true
      };
    }

    case ActionTypes.TOGGLE_VIEW_SELECTION_SUBITEM: {
      const index = 0;
      const updatedViewSelection = state.tmpViewSelection;
      const updatedItemList = [...state.tmpViewSelection[index].itemList];

      const itemIndex = state.tmpViewSelection[index].itemList.findIndex(
        item => item.itemNo === action.payload.itemNo
      );

      //console.log('Before:', updatedItemList);

      const updatedObj = {
        ...state.tmpViewSelection[index].itemList.find(
          item => item.itemNo === action.payload.itemNo
        ),
        checked: action.payload.checked
      };

      updatedItemList[itemIndex] = updatedObj;

      //console.log('view selection in reducer:', updatedViewSelection);
      updatedViewSelection[index].itemList = updatedItemList;
      updatedViewSelection.updated = true;

      return {
        ...state,
        tmpViewSelection: [
          ...updatedViewSelection 
        ],
        configChanged: true
      };
    }

    case ActionTypes.LOAD_BADGE_INFO: {
      return {
        ...state,
        //badgeInfo: action.payload
        tmpBadgeInfo: action.payload
      };
    }

    case ActionTypes.FETCH_SYSTEM_SETTINGS_SUCCEEDED: {
      return {
        ...state,
        systemSettings:
          action.payload != null ? action.payload : this.state.systemSettings
      };
    }
    case ActionTypes.SET_SELECTED_BADGE_OF_USER: {
      return {
        ...state,
        configChanged: true
      };
    }

    case ActionTypes.CONFIGURATION_CHANGED: {
      return {
        ...state,
        configChanged: true
      };
    }

    case ActionTypes.RESET_CONFIGURATION_CHANGED: {
      return {
        ...state,
        configChanged: false
      };
      }

      case ActionTypes.ENABLE_DEBUG_LOG_SUCCEEDED: {
          return {
              ...state,
              isDebugLogEnabled: !state.isDebugLogEnabled
          };
      }

      case ActionTypes.ENABLE_DEBUG_LOG_FAILED: {
          return {
              ...state,
              isDebugLogEnabled: state.isDebugLogEnabled
          };
      }

    default:
      return state;
  }
};

const filteredDoors = doors => {
  const updatedDoors = [];
  for (let i = 0; i < doors.length; ++i) {
    if (doors[i].statusDef && doors[i].statusDef === 'Not Configured') {
      continue;
    }

    updatedDoors.push(doors[i]);
  }
  return updatedDoors;
};

const findChecked = (itemList, itemNo) => {
  if (
    itemList &&
    itemNo &&
    itemList.find(item => item.itemNo === itemNo) != null &&
    itemList.find(item => item.itemNo === itemNo).checked
  ) {
    return itemList.find(item => item.itemNo === itemNo).checked;
  }
  return false;
};

import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import * as Colors from '../../consts/colors';
import * as actions from '../../actions/index';

class MessageOptionsView extends Component {
  render() {
    return (
      <div style={styles.container}>
        <div style={styles.titleRow}>
          <FontAwesomeIcon icon={faCircle} size="xs" color={Colors.Black} />
          <p style={styles.messageoptions}>Message Options</p>
        </div>
        <div style={styles.displayMessageTimeRow}>
          <p style={styles.label}> - Display Message Time</p>
          <input
            disabled={this.props.tmpMessageOptionUntilCleared}
            value={this.props.tmpMsgDisplayTimeInSeconds}
            style={styles.displayMessageTime}
            type="number"
            onChange={event => {
              if (event.target.value > 0) {
                this.props.setMsgDisplayTime(event.target.value);
                //  this.props.addMessage('System Settings Updated [ Display Message Time Changed ]');
              } else {
                alert('Please enter number greater than 0');
              }
            }}
          />
          Seconds
        </div>
        <div style={styles.untilClearedRow}>
          <p style={styles.label}> or until cleared</p>
          <div style={styles.untillClear}>
            <input
              type="checkbox"
              checked={this.props.tmpMessageOptionUntilCleared}
              onChange={() => {
                this.props.setMsgOptionUntilCleared(
                  !this.props.tmpMessageOptionUntilCleared
                );
                //this.props.addMessage('System Settings Updated [ Display Message Until Cleared ]');
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    height: 180,
    justifyContent: 'flex-start',
    marginRight: 20,
    marginLeft: 20,
    flexDirection: 'column',
    backgroundColor: Colors.White
  },
  messageoptions: { marginLeft: 10, fontSize: 17 },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    marginLeft: 10
  },
  displayMessageTimeRow: {
    display: 'flex',
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    marginLeft: 40
  },
  displayMessageTime: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.LightGray,
    marginLeft: 10,
    marginRight: 10,
    width: 28,
    borderRadius: 6
  },
  untilClearedRow: {
    display: 'flex',
    flexDirection: 'row',
    height: 30,
    alignItems: 'center',
    marginLeft: 100
  },
  untillClear: {
    height: 30,
    marginLeft: 10,
    display: 'flex',
    alignItems: 'center',
    marginRight: 10
  },
  standardMessageSoundRow: {
    display: 'flex',
    flexDirection: 'row',
    height: 50,
    alignItems: 'center',
    marginLeft: 100
  },
  standardMessageSound: {
    height: 28,
    marginLeft: 10,
    display: 'flex',
    alignItems: 'center',
    marginRight: 10
  },
  alertMessageSoundRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: 50,
    alignItems: 'center',
    marginLeft: 100
  },
  alertMessageSound: {
    height: 28,
    marginLeft: 10,
    display: 'flex',
    alignItems: 'center',
    marginRight: 10
  },
  label: { width: 'auto', fontSize: '0.90em' },
  blackLine: { height: 1, width: '100%', borderBottom: '2px solid black' }
};

const mapStateToProps = state => ({
  // msgDisplayTimeInSeconds: state.settings.msgDisplayTimeInSeconds,
  // messageOptionUntilCleared: state.settings.messageOptionUntilCleared,
  tmpMsgDisplayTimeInSeconds: state.settings.tmpMsgDisplayTimeInSeconds,
  tmpMessageOptionUntilCleared: state.settings.tmpMessageOptionUntilCleared
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setMsgDisplayTime: actions.setMsgDisplayTime,
      setMsgOptionUntilCleared: actions.setMsgOptionUntilCleared,
      addMessage: actions.addMessage
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(MessageOptionsView)
);

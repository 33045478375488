import React from 'react';
import Radium from 'radium';
import * as Colors from '../../consts/colors';

const StatusDetailsListItemView = props => {
  if (props.deviceType === 4) {
    return (
      <div
        style={
          props.currentIndex % 2 === 0
            ? styles.alterBackgroundPanelContainer
            : styles.panelContainer
        }
      >
        <div style={styles.dataColumn}>
          <div style={styles.dataRow}>
            <div style={styles.deviceName}>{props.deviceName}</div>
            <div style={styles.status}> {props.description}</div>
          </div>
        </div>
      </div>
    );
  } else if (props.deviceType === 5) {
    return (
      <div
        style={
          props.currentIndex % 2 === 0 ? styles.alterBackgroundDoorContainer : styles.doorContainer
        }
      >
        <div style={styles.dataColumn}>
          <div style={styles.dataRow}>
            <div style={styles.deviceName}>{props.deviceName}</div>
            <div style={styles.status}> {props.description}</div>
          </div>
        </div>
      </div>
    );
  } else if (props.deviceType === 2) {
    return (
      <div
        style={
          props.currentIndex % 2 === 0
            ? styles.alterBackgroundRelayContainer
            : styles.relayContainer
        }
      >
        <div style={styles.dataColumn}>
          <div style={styles.dataRow}>
            <div style={styles.deviceName}>{props.deviceName}</div>
            <div style={styles.status}> {props.description}</div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      style={
        props.currentIndex % 2 === 0 ? styles.alterBackgroundInputContainer : styles.inputContainer
      }
    >
      <div style={styles.dataColumn}>
        <div style={styles.dataRow}>
          <div style={styles.deviceName}>{props.deviceName}</div>
          <div style={styles.status}> {props.description}</div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    height: 45,
    flexDirection: 'row',
    backgroundColor: Colors.LightGrayishBlue
  },
  panelContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    height: 45,
    flexDirection: 'row',
    backgroundColor: Colors.Cream
  },
  alterBackgroundPanelContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    height: 45,
    flexDirection: 'row',
    backgroundColor: Colors.VeryPaleYellow
  },
  doorContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    height: 45,
    flexDirection: 'row',
    backgroundColor: Colors.PaleBlue
  },
  alterBackgroundDoorContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    height: 45,
    flexDirection: 'row',
    backgroundColor: Colors.VeryLightPaleBlue
  },
  relayContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    height: 45,
    flexDirection: 'row',
    backgroundColor: Colors.LightViolet
  },
  alterBackgroundRelayContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    height: 45,
    flexDirection: 'row',
    backgroundColor: Colors.LightGrayishViolet
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    height: 45,
    flexDirection: 'row',
    backgroundColor: Colors.VeryPaleCyan
  },
  alterBackgroundInputContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    height: 45,
    flexDirection: 'row',
    backgroundColor: Colors.VeryPaleBlue
  },

  verticalLine: {
    width: 2,
    height: '100%'
  },
  dataRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginBottom: 1,
    fontWeight: '600'
  },
  deviceName: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingLeft: 5,
    height: 45,
    justifyContent: 'center',
    fontSize: 14,
    textAlign: 'left',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Colors.Gray
  },
  status: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    fontSize: 14,
    height: 45,
    justifyContent: 'center',
    textAlign: 'center',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Colors.Gray
  },
  cardCount: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    fontSize: 14,
    height: 45,
    textAlign: 'center',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Colors.Gray
  },
  dataColumn: {
    flex: 1,

    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column'
  }
};

export default Radium(StatusDetailsListItemView);

import React, { Component } from 'react';
import Radium from 'radium';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import * as Colors from '../../consts/colors';
import BadgeHoldersInListItemView from '../badgeholdersin/BadgeHoldersInListItemView';
import * as actions from '../../actions/index';
import {
  PAGE_NAMES,
  SCREEN_CODES,
  SECURITY_LEVEL_CODES,
  ADMIN_ROLE_ID
} from '../../consts/consts';

class BadgeHoldersInTimeView extends Component {
  constructor(props) {
    super(props);

    try {
      const badgeHoldersInPrivilege = this.props.privileges.find(
        privilege => privilege.ScreenID === SCREEN_CODES.BADGEHOLDERIN
      );
        this.state = { badgeHoldersInPrivilege };
      this.props.writeLog('OperatorID: ' + this.props.operatorId + " --> " + 'Badge Holders In Privilege: ' + JSON.stringify(badgeHoldersInPrivilege));
      this.showBadgeHoldersInList = this.showBadgeHoldersInList.bind(this);
      this.props.fetchAllBadgeHolderIn();
      this.props.startInListRefresh();
    } catch (error) {
      this.setState(() => {
        throw error;
      });
    }
  }

  UNSAFE_componentWillMount() {
    this.props.setCurrentPageName(PAGE_NAMES.BADGEHOLDERIN);
  }

  componentWillUnmount() {
    this.props.cancelInListRefresh();
  }

  showBadgeHoldersInList(badgeholdersin) {
    const badgeHoldersInList = badgeholdersin.map((badgeholderin, index) => {
      const time = moment
        .utc(badgeholderin.time)
        .local()
        .format('MM/DD/YYYY HH:mm:ss');

        this.props.writeLog('OperatorID: ' + this.props.operatorId + " --> " + 'Badge Holders In Time: ' + JSON.stringify(time)); 
      return (
        <BadgeHoldersInListItemView
          key={index}
          currentIndex={index}
          badgeNo={badgeholderin.badge}
          reader={badgeholderin.reader}
          name={badgeholderin.name}
          timein={time}
          instyle={styles.listItemNormal}
        />
      );
    });
    return <div>{badgeHoldersInList}</div>;
  }

  render() {
    return (
      <div style={styles.container}>
        <div>
          <div style={styles.expandContainer}>
            <Link style={styles.underlineless} to="/home">
              <div>
                <FontAwesomeIcon
                  icon={faMinus}
                  size="1x"
                  color={Colors.White}
                />
              </div>
            </Link>
            <div style={styles.functionText}>Badge Holders In</div>
          </div>
            </div>
        {this.props.roleId === ADMIN_ROLE_ID && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={styles.titleContainer}>
              <div style={styles.name}>Name</div>
              <div style={styles.reader}>Reader</div>
              <div style={styles.timein}>Time In</div>
            </div>
                    <div className="customScrollbar" style={styles.listContainer}>
                        {this.props.badgeholdersin && this.props.badgeholdersin.length === 0 && (
                            <div style={styles.emptyList}>No Result Found</div>
                        )}
              <div>
                {this.showBadgeHoldersInList(this.props.badgeholdersin)}
              </div>
            </div>
            <div style={styles.totalIn}>
              Total In: {this.props.badgeholdersin.length}
            </div>
          </div>
        )}

        {this.props.roleId !== ADMIN_ROLE_ID &&
          this.state.badgeHoldersInPrivilege &&
          this.state.badgeHoldersInPrivilege.SecurityLevel ===
            SECURITY_LEVEL_CODES.FULL_CONTROL && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={styles.titleContainer}>
                <div style={styles.name}>Name</div>
                <div style={styles.reader}>Reader</div>
                <div style={styles.timein}>Time In</div>
              </div>
                    <div className="customScrollbar" style={styles.listContainer}>
                        {this.props.badgeholdersin && this.props.badgeholdersin.length === 0 && (
                            <div style={styles.emptyList}>No Result Found</div>
                        )}
                <div>
                  {this.showBadgeHoldersInList(this.props.badgeholdersin)}
                </div>
              </div>
              <div style={styles.totalIn}>
                Total In: {this.props.badgeholdersin.length}
              </div>
            </div>
          )}
      </div>
    );
  }
}

const styles = {
  underlineless: {
    textDecoration: 'none'
  },

  container: {
    display: 'flex',
    border: '1px solid',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderColor: Colors.Green,
    flex: 1,
    height: 'auto',
    justifyContent: 'center',
    marginRight: 20,
    flexDirection: 'column',
    padding: 0.3,
    backgroundColor: Colors.White
    },
    emptyList: {
        display: 'flex',
        flex: 1,
        fontSize: 17,
        fontWeight: '600',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.White,
        height: 100,
        marginBottom: 5
    },
  totalIn: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-end',
    height: 30,
    marginRight: 20,
    alignItems: 'center'
  },
  listItemGray: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    height: 25,
    backgroundColor: Colors.LightGray,
    alignItems: 'center',
    paddingLeft: 5,
    paddingRight: 5
  },
  listItemNormal: {
    display: 'flex',
    flex: 1,
    height: 30,
    fontSize: 14,
    fontWeight: 'normal',
    flexDirection: 'row',
    marginBottom: 2,
    backgroundColor: Colors.LightGrayishBlue,
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10
  },

  listContainer: {
    height: 120,
    border: '1px solid black',
    overflowY: 'scroll',
    fontWeight: 'bold',
    marginLeft: 10,
    marginRight: 10,
    paddingTop: 10
  },
  expandContainer: {
    flex: 1,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 10,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    backgroundColor: Colors.Green
  },
  titleContainer: {
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 10,
    flex: 1,
    fontSize: '1em',
    padding: 5,
    backgroundColor: Colors.VividBlue,
    height: 32,
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    color: Colors.White
  },
  name: {
    flex: 1,
    alignItems: 'center',
    width: 120,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginLeft: 5,
    fontSize: '.85em',
    fontWeight: 'normal'
  },
  reader: {
    flex: 1,
    alignItems: 'center',
    width: 120,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginLeft: 2,
    fontSize: '.85em',
    fontWeight: 'normal'
  },
  timein: {
    width: 120,
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginLeft: 2,
    marginRight: 25,
    fontWeight: 'normal',
    fontSize: '.85em'
  },
  functionText: {
    color: Colors.White,
    textAlign: 'left',
    marginLeft: 20,
    fontSize: 20,
    fontWeight: 800
  },
  horizontalLine: {
    height: 1,
    width: '10s0%',
    borderBottom: '1px solid',
    borderColor: Colors.Purple
  }
};

const mapStateToProps = state => ({
  accessToken: state.token.accessToken,
  badgeholdersin: state.badgeholder.badgeholdersin,
  privileges: state.token.privileges,
  operatorId: state.token.operatorId,
  roleId: state.token.roleId
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCurrentPageName: actions.setCurrentPageName,
      fetchAllBadgeHolderIn: actions.fetchAllBadgeHolderIn,
      startInListRefresh: actions.startInListRefresh,
      cancelInListRefresh: actions.cancelInListRefresh,
      writeLog: actions.writeLog
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(BadgeHoldersInTimeView)
);

import { put, call, takeLatest, select } from 'redux-saga/effects';
import moment from 'moment';
import * as ActionTypes from '../actions/types';


export default function* localStorageSaga() {
    //yield console.log('running local storage saga');
    yield call(loadFromLocalStorage);
    yield takeLatest(ActionTypes.SET_BASE_URL, storeBaseURL);
    yield takeLatest(ActionTypes.LOGIN_SUCCEEDED, storeAccessToken);
    yield takeLatest(ActionTypes.SAVE_CONFIG_SETTINGS, storeBadgeInfo);
    // yield takeLatest(ActionTypes.LOGIN, loadBadgeInfo);
    yield takeLatest(ActionTypes.SET_USERINFO, storeUserIno);
    yield takeLatest(ActionTypes.LOGOUT, resetAccessToken);
    yield takeLatest(ActionTypes.LOGOUT, resetUserInfo);

    yield takeLatest(ActionTypes.SET_LOGIN_REMEMBER, setRememberLogin);
    yield takeLatest(ActionTypes.GET_LOGIN_REMEMBER, getRememberLogin);

    yield takeLatest(ActionTypes.LOGOUT, saveSettingsPin);
    yield takeLatest(ActionTypes.LOGIN_SUCCEEDED, getSettingsPin);

    yield takeLatest(ActionTypes.LOGIN_SUCCEEDED, loadAppConfig);
    yield takeLatest(ActionTypes.LOGOUT, storeAppConfigSettings);
    // yield takeLatest(ActionTypes.LOGOUT, storeBadgeInfo);
    yield takeLatest(ActionTypes.LOGIN, loadBadgeInfo);
    yield takeLatest(ActionTypes.STORE_SESSION_TIMEOUT_INFO, storeSessionTimeOutInfo);
    yield takeLatest(ActionTypes.CHECK_SESSION_TIMEOUT_STATUS, loadSessionTimeOutInfo);
}

function* getSettingsPin() {
    try {
        const settings = JSON.parse(localStorage.getItem('settingspin'));

        //console.log(settings);
        yield put({
            type: ActionTypes.LOAD_SETTINGS_PIN,
            payload: settings
        });
    } catch (error) {
        yield console.log('storage>>error :', error);
    }
}

function* storeAppConfigSettings() {
    const appConfigSettings = yield select(getAppConfigSettings);

    try {
        //console.log('storing app config settings : ', JSON.stringify(appConfigSettings));

        localStorage.setItem('AppConfig', JSON.stringify(appConfigSettings));
    } catch (error) {
        yield console.log('storage>>error :', error);
    }
}

function* saveSettingsPin() {
    try {
        const appEntryChecked = yield select(getappEntryChecked);
        const settingsPin = appEntryChecked ? yield select(getSettingspin) : '####';

        //console.log('saveSettingsPin', appEntryChecked, settingsPin);

        localStorage.setItem(
            'settingspin',
            JSON.stringify({ settingsPin, appEntryChecked })
        );
    } catch (error) {
        yield console.log('storage>>error :', error);
    }
}

function* setRememberLogin(action) {
    const userinfo = action.payload;
    try {
        if (userinfo.remember) {
            localStorage.setItem('remeberUserInfo', userinfo.userName);
        } else {
            localStorage.setItem('remeberUserInfo', '');
        }
    } catch (error) {
        yield console.log('storage>>error :', error);
    }
}

function* getRememberLogin() {
    try {
        const username = localStorage.getItem('remeberUserInfo');
        yield put({
            type: ActionTypes.REMEMBERED_USERNAME,
            payload: username
        });
    } catch (error) {
        yield console.log('storage>>error :', error);
    }
}

function* storeBadgeInfo() {
    const selectedBadgeOfUser = yield select(getSelectedBadgeOfUser);
    const tmpSelectedBadgeOfUser = yield select(getTmpSelectedBadgeOfUser);

    const badgesOfTheUser = yield select(getBadgesOfTheUser);

    //yield console.log(
    //  'storing badgeInfo in local  storage',
    //  tmpSelectedBadgeOfUser,
    //  selectedBadgeOfUser,
    //  badgesOfTheUser
    //);
    try {
        localStorage.setItem('badgeInfo', JSON.stringify(selectedBadgeOfUser));
        localStorage.setItem(
            'selectedBadgeOfUser',
            JSON.stringify(tmpSelectedBadgeOfUser)
        );
        localStorage.setItem('badgesOfTheUser', JSON.stringify(badgesOfTheUser));
    } catch (error) {
        yield console.log('storage>>error :', error);
    }
}

function* loadBadgeInfo() {
    //yield console.log('getting badgeInfo from local storage');
    try {
        // const badgeInfo = JSON.parse(localStorage.getItem('badgeInfo'));
        const selectedBadgeInfo = JSON.parse(
            localStorage.getItem('selectedBadgeOfUser')
        );
        //yield console.log(
        //  'getting badgeInfo from local storage',
        //  selectedBadgeInfo
        //);
        if (selectedBadgeInfo) {
            yield put({
                type: ActionTypes.LOAD_BADGE_INFO,
                payload: selectedBadgeInfo
            });
        }
    } catch (error) {
        yield console.log('storage>>error :', error);
    }
}

function* storeAccessToken(accessTokenDetails) {
    //yield console.log('storing accessToken in local  storage');
    try {
        localStorage.setItem(
            'accessToken',
            JSON.stringify(accessTokenDetails.payload)
        );
    } catch (error) {
        yield console.log('storage>>error :', error);
    }
}

function* storeUserIno(action) {
    //yield console.log('storing userinfo in local  storage');
    try {
        localStorage.setItem('username', action.payload.username);
    } catch (error) {
        yield console.log('storage>>error :', error);
    }
}

function* loadUserInfo() {
    //yield console.log('getting userInfo from local storage');
    try {
        const username = localStorage.getItem('username');
        if (username) {
            yield put({
                type: ActionTypes.LOAD_USERINFO,
                payload: username
            });
        }
    } catch (error) {
        yield console.log('storage>>error :', error);
    }
}

function* loadAppConfig() {
    //yield console.log('getting app config from local storage');
    try {
        const appConfigSettings = JSON.parse(localStorage.getItem('AppConfig'));
        //console.log('appConfigSettings', appConfigSettings);

        if (appConfigSettings) {
            yield put({
                type: ActionTypes.LOAD_APP_CONFIG_SETTINGS_FROM_LOCAL_STORAGE,
                payload: appConfigSettings
            });
        }
    } catch (error) {
        yield console.log('storage>>error :', error);
    }
}

function* storeSessionTimeOutInfo() {
    //yield console.log('storing accessToken in local  storage');
    try {

        const sessionTimeOut = yield select(getSessionTimeOut);
        const lastInactivityTime = yield select(getLastInactivityTime);
        const sessionTimOutInfo = { sessionTimeOut, lastInactivityTime };

        localStorage.setItem('SessionTimeOutInfo', JSON.stringify(sessionTimOutInfo));

    } catch (error) {
        yield console.log('storage>>error :', error);
    }
}

function* loadSessionTimeOutInfo() {
    yield console.log('Getting sessioTimeOut from local storage');
    try {
        const sessionTimeOutInfo = JSON.parse(localStorage.getItem('SessionTimeOutInfo'));

        if (sessionTimeOutInfo) {

            const sessionTimeOut = sessionTimeOutInfo.sessionTimeOut;
            const lastInactivityTime = sessionTimeOutInfo.lastInactivityTime;
            const currentTime = moment.now();

            const timeDiff = currentTime - lastInactivityTime;

            const isSesionTimeOut = timeDiff >= sessionTimeOut * 60 * 1000;

            if (isSesionTimeOut === true) {
                yield call(resetAccessToken);
            }

        }
        else {
            yield call(resetAccessToken);
        }
    } catch (error) {
        yield console.log('storage>>error :', error);
        yield call(resetAccessToken);
    }
}

function* loadFromLocalStorage() {
    yield call(loadBaseURL);
    yield call(loadBadgeInfo);
    yield call(loadAccessToken);
    yield call(loadUserInfo);
    yield call(getSettingsPin);
    yield call(loadSessionTimeOutInfo);
}

function* loadAccessToken() {
    //console.log('getting accessToken from local storage');
    try {
        const accessTokenDetailsJSON = localStorage.getItem('accessToken');
        const tmpSelectedBadgeOfUser = localStorage.getItem('selectedBadgeOfUser');
        const badgesOfTheUser = localStorage.getItem('badgesOfTheUser');

        if (accessTokenDetailsJSON) {
            if (tmpSelectedBadgeOfUser) {
                Object.assign(
                    accessTokenDetailsJSON,
                    { tmpSelectedBadgeOfUser },
                    { badgesOfTheUser }
                );
            }

            //yield console.log('storage>>accessToken :', accessTokenDetailsJSON);
            yield put({
                type: ActionTypes.LOAD_ACCESSTOKEN,
                payload: JSON.parse(accessTokenDetailsJSON)
            });
        }
    } catch (error) {
        yield console.log('storage>>error :', error);
    }
}

function* resetAccessToken() {
    //yield console.log('reset acccessToken in local storage');
    try {
        if (localStorage.getItem('accessToken') != null) {
            localStorage.removeItem('accessToken');
        }
        yield put({
            type: ActionTypes.RESET_ACCESSTOKEN
        });
    } catch (error) {
        yield console.log('storage>>error :', error);
    }
}

function* resetUserInfo() {
    //yield console.log('reset userinfo in local storage');
    try {
        if (localStorage.getItem('username') != null) {
            localStorage.removeItem('username');
        }
        yield put({
            type: ActionTypes.RESET_USERINFO
        });
    } catch (error) {
        yield console.log('storage>>error :', error);
    }
}

function* storeBaseURL() {
    //yield console.log('storing baseURL in local  storage');
    try {
        const baseURL = yield select(getBaseURL);
        localStorage.setItem('baseURL', baseURL);
    } catch (error) {
        yield console.log('storage>>error :', error);
    }
}

function* loadBaseURL() {
    //yield console.log('getting baseURL from local storage');
    try {
        const baseURL = localStorage.getItem('baseURL');

        if (baseURL) {
            yield put({
                type: ActionTypes.LOAD_BASE_URL,
                payload: baseURL
            });
            yield put({
                type: ActionTypes.SET_BASE_URL,
                payload: baseURL
            });
        }
    } catch (error) {
        yield console.log('storage>>error :', error);
    }
}

const getBaseURL = state => state.appConfig.baseURL;

const getAppConfigSettings = state => state.settings;

const getSettingspin = state => state.pin.settingsPin;

const getSelectedBadgeOfUser = state => state.token.selectedBadgeOfUser;
const getTmpSelectedBadgeOfUser = state => state.token.tmpSelectedBadgeOfUser;
const getBadgesOfTheUser = state => state.token.badgesOfTheUser;
const getappEntryChecked = state => state.pin.appPinEntryChecked;
const getLastInactivityTime = state => state.token.lastInactivityTime;
const getSessionTimeOut = state => state.settings.sessionTimeOut;

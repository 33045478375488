import React from 'react';
import Radium from 'radium';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import * as Colors from '../../consts/colors';
import ButtonView from '../common/ButtonView';
import * as actions from '../../actions/index';

const ManualControlItemView = props => (
  <div
    style={
      props.currentIndex % 2 === 0
        ? styles.alterBackgroundContainer
        : styles.container
    }
  >
    <div style={styles.titleRow}>
      <div style={styles.dot}>
        <FontAwesomeIcon icon={faCircle} size="xs" color={Colors.Black} />
      </div>
      <p style={styles.name}>{props.name}</p>

      <div style={styles.relayStatusContainer}>
        {props.isActivated === true && <div style={styles.active}>Active</div>}
        {props.isActivated === false && (
          <div style={styles.inactive}>Inactive</div>
        )}
      </div>
    </div>

    <div style={styles.buttonRow}>
      <Link style={styles.underlineless} to="/control">
        <ButtonView
          label="Activate"
          instyle={styles.activate}
          onPress={() => {
            try {
              const isConfirmed = window.confirm(`Confirm Activate ${props.name}?`);

              if (isConfirmed === true) {
                const relayInfo = {
                  panelNo: props.panelNo,
                  relayNo: props.relayNo
                };
                  props.writeLog("OperatorID: " + props.operatorId + "--> Operation: Activate --> RelayInfo: " + JSON.stringify(relayInfo));

                props.sendActivateRelay(relayInfo);
                alert('Activate command is sent.');
              }
            } catch (error) {
              this.setState(() => {
                throw error;
              });
            }
          }}
        />
      </Link>
      <Link style={styles.underlineless} to="/control">
        <ButtonView
          label="Deactivate"
          instyle={styles.deactivate}
          onPress={() => {
            try {
              const isConfirmed = window.confirm(`Confirm Deactivate ${props.name}?`);

              if (isConfirmed === true) {
                const relayInfo = {
                  panelNo: props.panelNo,
                  relayNo: props.relayNo
                };
                props.writeLog("OperatorID: " + props.operatorId + "-->Operation: Deactivate --> RelayInfo: " + JSON.stringify(relayInfo));

                props.sendDeactivateRelay(relayInfo);
                alert('Deactivate command is sent.');
              }
            } catch (error) {
              this.setState(() => {
                throw error;
              });
            }
          }}
        />
      </Link>

      <Link style={styles.underlineless} to="/control">
        <ButtonView
          label="Activate & Hold"
          instyle={styles.activatehold}
          onPress={() => {
            try {
              const isConfirmed = window.confirm(
                `Confirm Activate and Hold ${props.name}?`
              );

              if (isConfirmed === true) {
                const relayInfo = {
                  panelNo: props.panelNo,
                  relayNo: props.relayNo
                };

                  props.writeLog("OperatorID: " + props.operatorId + "--> Operation: Activate & Hold  --> RelayInfo: " + JSON.stringify(relayInfo));

                props.sendActivateHoldRelay(relayInfo);
                alert('Activate and Hold command is sent.');
              }
            } catch (error) {
              this.setState(() => {
                throw error;
              });
            }
          }}
        />
      </Link>
    </div>
    <div style={styles.line} />
  </div>
);

const styles = {
  name: {
    fontWeight: 'bold',
    fontSize: 16
  },
  underlineless: {
    textDecoration: 'none'
  },
  relayStatusContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    flex: 1
  },
  inactive: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: 40,
    width: 100,
    fontWeight: 'bold',
    color: Colors.Red,
    fontSize: 14
  },
  active: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: 40,
    width: 100,
    fontWeight: 'bold',
    color: Colors.DeepGreen,
    fontSize: 14
  },
  activate: {
    backgroundColor: Colors.White,
    color: Colors.DeepGreen,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.Green,
    borderRadius: 6,
    padding: 5,
    marginRight: 5,
    height: 38,
    fontSize: 13,
    cursor: 'pointer',
    boxShadow: '1px 0.5px 1px #000000',
    ':active': {
      backgroundColor: Colors.Gray
    }
  },
  deactivate: {
    backgroundColor: Colors.White,
    color: Colors.Red,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.Red,
    borderRadius: 6,
    padding: 5,
    marginRight: 5,
    height: 38,
    fontSize: 13,
    cursor: 'pointer',
    boxShadow: '0.5px 0.5px 1px #000000',
    ':active': {
      backgroundColor: Colors.Gray
    }
  },
  activatehold: {
    backgroundColor: Colors.White,
    color: Colors.DeepGreen,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: Colors.Green,
    borderRadius: 6,
    paddingRight: 5,
    paddingLeft: 5,
    marginRight: 5,
    fontSize: 13,
    height: 38,
    cursor: 'pointer',
    boxShadow: '1px 0.5px 1px #000000',
    ':active': {
      backgroundColor: Colors.Gray
    }
  },
  container: {
    display: 'flex',
    flex: 1,
    height: 100,
    marginBottom: 3,
    marginTop: 3,
    flexDirection: 'column',
    backgroundColor: Colors.White
  },
  alterBackgroundContainer: {
    display: 'flex',
    flex: 1,
    height: 100,
    marginBottom: 3,
    marginTop: 3,
    flexDirection: 'column',
    backgroundColor: Colors.VeryPaleYellow
  },
  titleRow: {
    height: 35,
    flex: 1,
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  dot: {
    width: 20,
    textAlign: 'center'
  },

  buttonRow: {
    paddingLeft: 20,
    paddingRight: 10,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between'
  },
  dottedLine: {
    height: 1,
    width: '95%',
    borderBottom: '1px dotted black'
  },
  line: {
    height: 1,
    width: '100%',
    borderBottom: '1px solid',
    marginTop: 4,
    borderColor: Colors.LightGrayishViolet
  }
};

const mapStateToProps = state => ({
  accessToken: state.token.accessToken,
  baseURL: state.appConfig.baseURL,
  operatorId: state.token.operatorId
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendActivateRelay: actions.sendActivateRelay,
      sendDeactivateRelay: actions.sendDeactivateRelay,
      sendActivateHoldRelay: actions.sendActivateHoldRelay,
      writeLog: actions.writeLog
    },
    dispatch
  );

export default Radium(
  connect(mapStateToProps, mapDispatchToProps)(ManualControlItemView)
);

//import moment from 'moment';
import * as ActionTypes from '../actions/types';
import * as CONSTS from '../consts/consts';

const defaultState = {
  connected: false,
  offLineMessage: CONSTS.MSG_SYSTEM_OFFLINE,
  onLineMessage: CONSTS.MSG_SYSTEM_ONLINE
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.CONNECTION_ONLINE: {
      return {
        ...state,
        connected: true
      };
    }

    case ActionTypes.CONNECTION_OFFLINE: {
      return {
        ...state,
        connected: false
      };
    }

    default:
      return state;
  }
};
